var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-upload" },
    [
      _c(
        "el-upload",
        {
          ref: "el_upload",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.$http.requestUrl + _vm.action,
            "list-type": _vm.uploadType,
            "before-upload": _vm.beforeUpload,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "before-remove": _vm.beforeRemove,
            multiple: _vm.multiple,
            limit: _vm.limit,
            "on-exceed": _vm.handleExceed,
            drag: _vm.drag,
            accept: _vm.accept,
            "file-list": _vm.fileList,
            data: _vm.param,
            "on-success": _vm.uploadSuccess,
            headers: _vm.headers,
            "show-file-list": _vm.showFileList,
            "on-error": _vm.uploadError,
            "auto-upload": _vm.autoUpload,
            "on-change": _vm.handleChange,
            disabled: _vm.disabled,
          },
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.tip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.tip))]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片预览",
            visible: _vm.dialogVisible_1,
            modal: false,
            width: "60%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _vm.fileType === "image"
              ? _c("div", { staticClass: "image-box" }, [
                  _c("img", { attrs: { src: _vm.preview, alt: "" } }),
                ])
              : _c("div", [
                  _c(
                    "video",
                    {
                      attrs: {
                        id: "video",
                        autoplay: "",
                        src: _vm.preview,
                        controls: "controls",
                        width: "100%",
                        height: _vm.videoHeight,
                      },
                    },
                    [_vm._v("视频描述")]
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }