import request from '@/utils/request'

// 获取优惠券二维码列表
export function getCouponQrCodeData(data) {
  return request.requestPost({
    url: '/marketing/couponQrCodeList',
    param: data,
  })
}

// 批量设置红包or优惠卷二维码关联批次
export function batchUpdateQrCode(data) {
  return request.requestPost({
    url: '/marketing/batchEditProductBatch',
    param: data,
  })
}

/**
 * 请求生成优惠券二维码列表
 *
 * @param {Object} data - 请求参数对象，包含获取福利优惠券二维码列表所需的所有参数
 * @returns {Promise} - 返回一个Promise对象，包含服务器响应的数据
 */
export function couponQrCodeList(data) {
  return request.requestPost({
    url: '/marketing/couponQrCodeList',
    param: data,
  })
}

/**
 * 查询福利优惠卷列表的绑定/未绑定的二维码列表
 *
 * @param {Object} data - 请求参数对象，包含查询福利优惠卷列表的绑定/未绑定的二维码列表所需参数
 * @returns {Promise} - 返回一个Promise对象，包含服务器响应的数据
 */
export function queryCouponQrCodeByCouponId(data) {
  return request.requestPost({
    url: '/marketing/queryCouponQrCodeByCouponId',
    param: data,
  })
}

/**
 * 根据二维码ID查询绑定的优惠卷
 *
 * @param {String} qrCodeId - 二维码Id
 * @returns {Promise} - 返回一个Promise对象，包含服务器响应的数据
 */
export function queryCouponByQrCodeId(qrCodeId) {
  return request.requestGet({
    url: `/marketing/queryCouponByQrCodeId/${qrCodeId}`,
  })
}

// 红包转账明细列表
export function getRedPacketTransferList(data) {
  return request.requestPost({
    url: '/marketing/queryRedPacketTransferList',
    method: 'post',
    param: data
  })
}

// 红包转账详情
export function getRedPacketTransferDetail(id) {
  return request.requestGet({
    url: '/marketing/redPacketTransferDetail/' + id,
    method: 'get'
  })
}

/** 红包二维码列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { String } redPacketQrCodeId - 红包二维码id
 * @param { String } productName - 产品名称
 * @param { String } batchNo - 批次号
 * @param { String } redPacketMinAmount - 红包最小金额
 * @param { String } redPacketMaxAmount - 红包最大金额
 * @param { String } createBeginTime - 创建开始时间
 * @param { String } createEndTime - 创建结束时间
 * @param { Number } redPacketQrCodeStatus - 红包二维码状态 1.生效 2.未生效
 * */
export function redPacketQrCodeList(param) {
  return request.requestPost({
    url: '/marketing/redPacketQrCodeList',
    method: 'post',
    param
  })
}

/** 创建红包or优惠卷二维码
 * @param { String } merchantId - 商家id
 * @param { Number } specifiedPath - 跳转路径 1.首页 2.产品列表 3.产品详情
 * @param { String } specifiedPathId - 跳转路径id
 * @param { Number } createNum - 创建数量,
 * @param { Number } type - 二维码所属类型 1商家二维码  2经销品个人码 3电商品个人码 4宝滋泉商家码 5宝滋泉个人码 6红包二维码 7优惠券二维码
 * @param { String } assignMerchantTypeList - 指定扫码商家类型id集字段 值是掉接口获取的值是掉接口获取的(vuex：user.merchantTypeData)
 * @param { String } isEffect - 是否立即生效 1.是 2.否
 * @param { String } redPacketAmount - 红包金额
 * @param { String } productId - 批次关联的产品id
 * @param { String } batchNo - 产品批次号
 * */
export function createQrCode(param) {
  return request.requestPost({
    url: '/customer/merchant/createQrCode',
    method: 'post',
    param
  })
}

/** 编辑红包or优惠卷二维码
 * @param { String } qrCodeId - 二维码id
 * @param { Number } specifiedPath - 跳转路径 1.首页 2.产品列表 3.产品详情
 * @param { String } specifiedPathId - 跳转路径id
 * @param { String } productId - 产品id
 * @param { String } batchNo - 产品批次号
*/
export function modifyQrCode(param) {
  return request.requestPut({
    url: '/customer/merchant/modifyQrCode',
    method: 'post',
    param
  })
}

/** 红包or优惠卷二维码详情
 * @param { String } qrCodeId - 二维码id
*/
export function qrCodeDetail(qrCodeId) {
  return request.requestGet({
    url: `/customer/merchant/qrCodeDetail/${qrCodeId}`,
    method: 'post',
  })
}

/** 获取待发售、在售产品列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { Object } param - 查询参数
 * @desc param.productName: "", //产品名称:''
*/
export function getForAndOnShelvesProductList(param) {
  return request.requestPost({
    url: `/product/getForAndOnShelvesProductList`,
    method: 'post',
    param
  })
}

/** 批量生效红包二维码
 * @param { Array } 二维码id数组
*/
export function batchEffect(param) {
  return request.requestPost({
    url: '/marketing/batchEffect',
    method: 'post',
    param
  })
}

/** 批量设置红包二维码金额
 * @param { Array } qrCodeIdList - 二维码id数组
 * @param { String } redPacketAmount - 红包金额
 * */
export function batchEditRedPacketAmount(param) {
  return request.requestPost({
    url: '/marketing/batchEditRedPacketAmount',
    method: 'post',
    param
  })
}

/** 红包or优惠卷二维码会员列表
 * @param { String } qrCodeId - 二维码id
 * @param { String } memberNickname - 会员昵称
 * @param { String } memberCode - 会员编号
 * @param { String } memberTelephone - 会员手机号
 * */
export function qrCodeMemberList(param) {
  return request.requestPost({
    url: '/customer/merchant/qrCodeMemberList',
    method: 'post',
    param
  })
}

/** 红包or优惠卷二维码销售记录
 * @param { String } qrCodeId - 二维码id
 * */
export function qrCodeSaleRecord(qrCodeId) {
  return request.requestPost({
    url: `customer/merchant/qrCodeSaleRecord/${qrCodeId}`,
    method: 'post',
  })
}

/** 重新转账
 * @param { String } qrCodeId - 二维码id
 * */
export function afreshTransfer(qrCodeId) {
  return request.requestPost({
    url: `/marketing/afreshTransfer/${qrCodeId}`,
    method: 'post',
  })
}
