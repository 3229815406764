import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * // redirect: noRedirect           if set noRedirect will no // redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    control the page roles (you can set multiple roles)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  // 开发者页面
  {
    path: '/developersMode',
    component: Layout,
    // redirect: '/home/dashboard',
    meta: { title: '开发者模式' },
    children: [
      {
        path: 'uploadCloud',
        name: 'uploadCloud',
        component: () => import('@/views/developersMode/uploadCloud'),
        meta: { title: '上传云', icon: 'el-icon- iconfont icontubiao1' }
      }
    ]
  },
  // 数据大屏2.0登录
  {
    path: '/largeScreenLogin',
    component: () => import('@/views/largeScreenLogin/index'),
    hidden: true
  },
  // 1920*1080
  {
    path: '/smallProportion',
    component: () => import('@/views/largeScreenLogin/smallProportion.vue'),
    hidden: true
  },
  // 3840*1080
  {
    path: '/largeProportion',
    component: () => import('@/views/largeScreenLogin/largeProportion.vue'),
    hidden: true
  },
  // 1450*535
  {
    path: '/middleProportion',
    component: () => import('@/views/largeScreenLogin/middleProportion.vue'),
    hidden: true
  },
  // 自适应
  {
    path: '/flexProportion',
    component: () => import('@/views/largeScreenLogin/flexProportion.vue'),
    hidden: true
  },
  // 首頁
  {
    path: '/home',
    component: Layout,
    // redirect: '/home/dashboard',
    meta: { title: '首页' },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/dashboard'),
        meta: { title: '数据看板', icon: 'el-icon- iconfont icontubiao1' }
      }
      // 数据大屏
      // {
      //   path: 'largeScreen',
      //   name: 'LargeScreen',
      //   component: () => import('@/views/dashboard/largeScreen.vue'),
      //   meta: { title: '数据大屏', icon: 'el-icon- iconfont icontubiao1' },
      // },
    ]
  },
  // 订单管理
  {
    path: '/order',
    component: Layout,
    meta: { title: '订单' },
    children: [
      {
        path: 'medical',
        name: 'Medical',
        component: () => import('@/views/order/index'),
        meta: { title: '医品订单管理', icon: 'el-icon- iconfont iconyaopin' },
        children: [
          {
            path: 'hospitalDelivery',
            name: 'hospitalDelivery',
            component: () => import('@/views/order/index'),
            meta: { title: '医院配送订单管理', icon: '' },
            children: [
              {
                path: 'obligation',
                name: 'Obligation',
                component: () => import('@/views/order/medical/hospitalDelivery/obligation'),
                meta: { title: '待付款订单' }
              },
              {
                path: 'waitForSending',
                name: 'WaitForSending',
                component: () => import('@/views/order/medical/hospitalDelivery/waitForSending'),
                meta: { title: '待发货订单' }
              },
              {
                path: 'waitForReceiving',
                name: 'waitForReceiving',
                component: () => import('@/views/order/medical/hospitalDelivery/waitForReceiving'),
                meta: { title: '待收货订单' }
              },
              {
                path: 'fetchYourOwnThings',
                name: 'FetchYourOwnThings',
                component: () => import('@/views/order/medical/hospitalDelivery/fetchYourOwnThings'),
                meta: { title: '待自提订单' }
              },
              {
                path: 'completed',
                name: 'Completed',
                component: () => import('@/views/order/medical/hospitalDelivery/completed'),
                meta: { title: '已完成订单' }
              },
              {
                path: 'cancel',
                name: 'cancel',
                component: () => import('@/views/order/medical/hospitalDelivery/cancel'),
                meta: { title: '已取消订单' }
              },
              {
                path: 'particulars',
                name: 'particulars',
                hidden: true,
                component: () => import('@/views/order/medical/hospitalDelivery/particulars'),
                meta: { title: '订单详情' }
              },
              // 退货单管理
              {
                path: 'medicalReturnedGoods',
                name: 'MedicalReturnedGoods',
                meta: { title: '退货单管理' },
                component: () => import('@/views/order/index'),
                children: [
                  {
                    path: 'waitingForReview',
                    name: 'WaitingForReview',
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/waitingForReview'),
                    meta: { title: '待审核退货单' }
                  },
                  {
                    path: 'forARefundM',
                    name: 'forARefundM',
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/forARefund'),
                    meta: { title: '待退货退货单' }
                  },
                  {
                    path: 'refunded',
                    name: 'refunded',
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/refunded'),
                    meta: { title: '已退款退货单' }
                  },
                  {
                    path: 'noPasses',
                    name: 'NoPasses',
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/noPasses'),
                    meta: { title: '审核不通过退货单' }
                  },
                  {
                    path: 'waitingSignFor',
                    name: 'WaitingSignFor',
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/waitingSignFor'),
                    meta: { title: '待签收退货单' }
                  },
                  {
                    path: 'medicalParticulars',
                    name: 'MedicalParticulars',
                    hidden: true,
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/particulars'),
                    meta: { title: '退货订单详情' }
                  },
                  {
                    path: 'pendingReviewAfterSales',
                    name: 'pendingReviewAfterSales',
                    hidden: true,
                    component: () => import('@/views/order/medical/hospitalDelivery/returnedGoods/pendingReviewAfterSales'),
                    meta: { title: '待审核售后单' }
                  }
                ]
              },
              {
                path: 'afterSales',
                name: 'afterSales',
                hidden: true,
                component: () => import('@/views/order/medical/hospitalDelivery/afterSales'),
                meta: { title: '售后订单列表' }
              }
            ]
          },
          {
            path: 'nationwideDelivery',
            name: 'nationwideDelivery',
            component: () => import('@/views/order/index'),
            meta: { title: '快递配送订单管理', icon: '' },
            children: [
              {
                path: 'obligation',
                name: 'Obligation',
                component: () => import('@/views/order/medical/nationwide/obligation'),
                meta: { title: '待付款订单' }
              },
              {
                path: 'waitForSending',
                name: 'WaitForSending',
                component: () => import('@/views/order/medical/nationwide/waitForSending'),
                meta: { title: '待发货订单' }
              },
              {
                path: 'waitForReceiving',
                name: 'waitForReceiving',
                component: () => import('@/views/order/medical/nationwide/waitForReceiving'),
                meta: { title: '待收货订单' }
              },
              // {
              //   path: 'fetchYourOwnThings',
              //   name: 'FetchYourOwnThings',
              //   component: () => import('@/views/order/medical/fetchYourOwnThings'),
              //   meta: { title: '待自提订单' }
              // },
              {
                path: 'completed',
                name: 'Completed',
                component: () => import('@/views/order/medical/nationwide/completed'),
                meta: { title: '已完成订单' }
              },
              {
                path: 'cancel',
                name: 'cancel',
                component: () => import('@/views/order/medical/nationwide/cancel'),
                meta: { title: '已取消订单' }
              },
              {
                path: 'particulars',
                name: 'particulars',
                hidden: true,
                component: () => import('@/views/order/medical/nationwide/particulars'),
                meta: { title: '订单详情' }
              },
              // 退货单管理
              {
                path: 'medicalReturnedGoods',
                name: 'MedicalReturnedGoods',
                meta: { title: '退货单管理' },
                component: () => import('@/views/order/index'),
                children: [
                  {
                    path: 'waitingForReview',
                    name: 'WaitingForReview',
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/waitingForReview'),
                    meta: { title: '待审核退货单' }
                  },
                  {
                    path: 'forARefundM',
                    name: 'forARefundM',
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/forARefund'),
                    meta: { title: '待退货退货单' }
                  },
                  {
                    path: 'refunded',
                    name: 'refunded',
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/refunded'),
                    meta: { title: '已退款退货单' }
                  },
                  {
                    path: 'noPasses',
                    name: 'NoPasses',
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/noPasses'),
                    meta: { title: '审核不通过退货单' }
                  },
                  {
                    path: 'waitingSignFor',
                    name: 'WaitingSignFor',
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/waitingSignFor'),
                    meta: { title: '待签收退货单' }
                  },
                  {
                    path: 'medicalParticulars',
                    name: 'MedicalParticulars',
                    hidden: true,
                    component: () => import('@/views/order/medical/nationwide/returnedGoods/particulars'),
                    meta: { title: '退货订单详情' }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'commerce',
        name: 'Commerce',
        meta: { title: '电商品订单管理', icon: 'el-icon- iconfont icon23' },
        component: () => import('@/views/order/index'),
        children: [
          {
            path: 'obligation',
            name: 'Obligation',
            component: () => import('@/views/order/ECommerce/obligation'),
            meta: { title: '待付款订单' }
          },
          {
            path: 'waitForSending',
            name: 'WaitForSending',
            component: () => import('@/views/order/ECommerce/waitForSending'),
            meta: { title: '待发货订单' }
          },
          {
            path: 'shipped',
            name: 'Shipped',
            component: () => import('@/views/order/ECommerce/shipped'),
            meta: { title: '待收货订单' }
          },
          {
            path: 'completed',
            name: 'Completed',
            component: () => import('@/views/order/ECommerce/completed'),
            meta: { title: '已完成订单' }
          },
          {
            path: 'cancel',
            name: 'cancel',
            component: () => import('@/views/order/ECommerce/cancel'),
            meta: { title: '已取消订单' }
          },
          {
            path: 'particulars',
            name: 'orderParticulars',
            hidden: true,
            component: () => import('@/views/order/ECommerce/particulars'),
            meta: { title: '订单详情' }
          },
          // 退货单管理
          {
            path: 'returnedGoods',
            name: 'ReturnedGoods',
            meta: { title: '退货单管理' },
            component: () => import('@/views/order/index'),
            children: [
              {
                path: 'waitingForReview',
                name: 'WaitingForReview',
                component: () => import('@/views/order/ECommerce/returnedGoods/waitingForReview'),
                meta: { title: '待审核退货单' }
              },
              {
                path: 'forARefund',
                name: 'forARefund',
                component: () => import('@/views/order/ECommerce/returnedGoods/forARefund'),
                meta: { title: '待退货退货单' }
              },
              {
                path: 'waitingSignFor',
                name: 'WaitingSignFor',
                component: () => import('@/views/order/ECommerce/returnedGoods/waitingSignFor'),
                meta: { title: '待签收退货单' }
              },
              {
                path: 'done',
                name: 'Done',
                component: () => import('@/views/order/ECommerce/returnedGoods/done'),
                meta: { title: '已完成退货单' }
              },
              {
                path: 'noPasses',
                name: 'NoPasses',
                component: () => import('@/views/order/ECommerce/returnedGoods/noPasses'),
                meta: { title: '审核不通过退货单' }
              },
              {
                path: 'particulars',
                name: 'CommerceParticulars',
                hidden: true,
                component: () => import('@/views/order/ECommerce/returnedGoods/particulars'),
                meta: { title: '退货订单详情' }
              }
            ]
          }
        ]
      },
      // 营养配餐订单管理
      {
        path: 'NutritionOrder',
        name: 'NutritionOrder',
        meta: { title: '营养餐订单管理', icon: 'el-icon- iconfont nutrition' },
        component: () => import('@/views/order/index'),
        children: [
          {
            path: 'obligation',
            name: 'Obligation',
            component: () => import('@/views/order/NutritionOrder/obligation'),
            meta: { title: '待付款订单' }
          },
          {
            path: 'waitForSending',
            name: 'WaitForSending',
            component: () => import('@/views/order/NutritionOrder/waitForSending'),
            meta: { title: '待发货订单' }
          },
          {
            path: 'waitForReceiving',
            name: 'waitForReceiving',
            component: () => import('@/views/order/NutritionOrder/waitForReceiving'),
            meta: { title: '待收货订单' }
          },
          {
            path: 'completed',
            name: 'Completed',
            component: () => import('@/views/order/NutritionOrder/completed'),
            meta: { title: '已完成订单' }
          },
          {
            path: 'cancel',
            name: 'cancel',
            component: () => import('@/views/order/NutritionOrder/cancel'),
            meta: { title: '已取消订单' }
          },
          {
            path: 'particulars',
            name: 'orderParticulars',
            hidden: true,
            component: () => import('@/views/order/NutritionOrder/particulars'),
            meta: { title: '订单详情' }
          },
          // 退货单管理
          {
            path: 'returnedGoods',
            name: 'ReturnedGoods',
            meta: { title: '退货单管理' },
            component: () => import('@/views/order/index'),
            children: [
              {
                path: 'waitingForReview',
                name: 'WaitingForReview',
                component: () => import('@/views/order/NutritionOrder/returnedGoods/waitingForReview'),
                meta: { title: '待审核退货单' }
              },
              {
                path: 'refunded',
                name: 'Refunded',
                component: () => import('@/views/order/NutritionOrder/returnedGoods/refunded'),
                meta: { title: '已退款退货单' }
              },
              {
                path: 'noPasses',
                name: 'NoPasses',
                component: () => import('@/views/order/NutritionOrder/returnedGoods/noPasses'),
                meta: { title: '审核不通过退货单' }
              },
              {
                path: 'forARefund',
                name: 'forARefund',
                component: () => import('@/views/order/NutritionOrder/returnedGoods/forARefund'),
                meta: { title: '待退货退货单' }
              },
              {
                path: 'waitingSignFor',
                name: 'waitingSignFor',
                component: () => import('@/views/order/NutritionOrder/returnedGoods/waitingSignFor'),
                meta: { title: '待签收退货单' }
              },
              {
                path: 'particulars',
                name: 'CommerceParticulars',
                hidden: true,
                component: () => import('@/views/order/NutritionOrder/returnedGoods/particulars'),
                meta: { title: '退货订单详情' }
              }
            ]
          }
        ]
      },
      {
        path: 'externalProductOrder',
        name: 'ExternalProductOrder',
        meta: { title: '经销产品订单管理', icon: 'el-icon-takeaway-box' },
        component: () => import('@/views/order/index'),
        children: [
          {
            path: 'obligation',
            name: 'Obligation',
            component: () => import('@/views/order/ExternalProductOrder/obligation'),
            meta: { title: '待付款订单' }
          },
          {
            path: 'waitForSending',
            name: 'WaitForSending',
            component: () => import('@/views/order/ExternalProductOrder/waitForSending'),
            meta: { title: '待发货订单' }
          },
          {
            path: 'waitForReceiving',
            name: 'WaitForReceiving',
            component: () => import('@/views/order/ExternalProductOrder/waitForReceiving'),
            meta: { title: '待收货订单' }
          },
          {
            path: 'completed',
            name: 'Completed',
            component: () => import('@/views/order/ExternalProductOrder/completed'),
            meta: { title: '已完成订单' }
          },
          {
            path: 'cancel',
            name: 'cancel',
            component: () => import('@/views/order/ExternalProductOrder/cancel'),
            meta: { title: '已取消订单' }
          },
          {
            path: 'particulars',
            name: 'orderParticulars',
            hidden: true,
            component: () => import('@/views/order/ExternalProductOrder/particulars'),
            meta: { title: '订单详情' }
          },
          // 退货单管理
          {
            path: 'returnedGoods',
            name: 'ReturnedGoods',
            meta: { title: '退货单管理' },
            component: () => import('@/views/order/index'),
            children: [
              {
                path: 'waitingForReview',
                name: 'WaitingForReview',
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/waitingForReview'),
                meta: { title: '待审核退货单' }
              },
              {
                path: 'forARefund',
                name: 'forARefund',
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/forARefund'),
                meta: { title: '待退货退货单' }
              },
              {
                path: 'waitingSignFor',
                name: 'WaitingSignFor',
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/waitingSignFor'),
                meta: { title: '待签收退货单' }
              },
              {
                path: 'refunded',
                name: 'Refunded',
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/refunded'),
                meta: { title: '已退款退货单' }
              },
              {
                path: 'noPasses',
                name: 'NoPasses',
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/noPasses'),
                meta: { title: '审核不通过退货单' }
              },
              {
                path: 'particulars',
                name: 'CommerceParticulars',
                hidden: true,
                component: () => import('@/views/order/ExternalProductOrder/returnedGoods/particulars'),
                meta: { title: '退货订单详情' }
              }
            ]
          },
          {
            // 售后单管理
            path: 'afterSalesOrder',
            name: 'AfterSalesOrder',
            meta: { title: '售后单管理' },
            component: () => import('@/views/order/index'),
            children: [
              {
                path: 'reviewedAfterSalesOrder',
                name: 'ReviewedAfterSalesOrder',
                component: () => import('@/views/order/ExternalProductOrder/afterSalesOrder/reviewedAfterSalesOrder'),
                meta: { title: '待审核售后单' }
              },
              {
                path: 'approvedAfterSalesOrder',
                name: 'ApprovedAfterSalesOrder',
                component: () => import('@/views/order/ExternalProductOrder/afterSalesOrder/approvedAfterSalesOrder'),
                meta: { title: '审核已通过售后单' }
              },
              {
                path: 'rejectAfterSalesOrder',
                name: 'RejectAfterSalesOrder',
                component: () => import('@/views/order/ExternalProductOrder/afterSalesOrder/rejectAfterSalesOrder'),
                meta: { title: '审核不通过售后单' }
              },
              {
                path: 'orderDetail',
                name: 'OrderDetail',
                component: () => import('@/views/order/ExternalProductOrder/afterSalesOrder/orderDetail'),
                meta: { title: '售后订单详情' }
              }
            ]
          }
        ]
      },
      {
        path: 'lease',
        name: 'Leases',
        component: () => import('@/views/order/index'),
        meta: { title: '租赁品订单管理', icon: 'el-icon- iconfont iconzulinwuguanli' },
        children: [
          {
            path: 'obligation',
            name: 'Obligation',
            component: () => import('@/views/order/lease/obligation'),
            meta: { title: '待付款订单' }
          },
          {
            path: 'waitForSending',
            name: 'WaitForSending',
            component: () => import('@/views/order/lease/waitForSending'),
            meta: { title: '待发货订单' }
          },
          {
            path: 'rent',
            name: 'rent',
            component: () => import('@/views/order/lease/rent'),
            meta: { title: '租赁中订单' }
          },
          {
            path: 'maturity',
            name: 'Maturity',
            component: () => import('@/views/order/lease/maturity'),
            meta: { title: '已到期订单' }
          },
          {
            path: 'buyOut',
            name: 'BuyOut',
            component: () => import('@/views/order/lease/buyOut'),
            meta: { title: '已买断订单' }
          },
          {
            path: 'completedL',
            name: 'completedL',
            component: () => import('@/views/order/lease/completed'),
            meta: { title: '已完成订单' }
          },
          {
            path: 'cancel',
            name: 'cancel',
            component: () => import('@/views/order/lease/cancel'),
            meta: { title: '已取消订单' }
          },
          {
            path: 'particulars',
            name: 'LeaseParticulars',
            hidden: true,
            component: () => import('@/views/order/lease/particulars'),
            meta: { title: '订单详情' }
          }
        ]
      },
      {
        path: 'billing',
        name: 'Billing',
        component: () => import('@/views/order/index'),
        meta: { title: '开票管理', icon: 'el-icon- iconfont iconkaipiao' },
        children: [
          {
            path: 'wait',
            name: 'wait',
            component: () => import('@/views/order/billing/wait'),
            meta: { title: '待开票订单列表' }
          },
          {
            path: 'billings',
            name: 'Billings',
            component: () => import('@/views/order/billing/billingList'),
            meta: { title: '已开票订单列表' }
          },
          {
            path: 'reject',
            name: 'reject',
            component: () => import('@/views/order/billing/reject'),
            meta: { title: '驳回开票订单' }
          },
          {
            path: 'particulars',
            name: 'BillingParticulars',
            hidden: true,
            component: () => import('@/views/order/billing/particulars'),
            meta: { title: '订单详情' }
          }
        ]
      },
      {
        path: 'orderdownloadlist',
        name: 'orderdownloadlist',
        component: () => import('@/views/order/orderdownloadlist'),
        meta: { title: '导出文件列表', icon: 'el-icon- iconfont icontubiao1' }
      }
    ]
  },
  // 申购单管理
  {
    path: '/form',
    component: Layout,
    // redirect: '/form/purchaseRequisition',
    meta: { title: '申购单' },
    children: [
      {
        path: 'purchaseRequisition',
        name: 'PurchaseRequisitionForm',
        // redirect: '/form/purchaseRequisition/pendingReview',
        meta: { title: '产品申购单管理', icon: 'el-icon- iconfont iconshengou' },
        component: () => import('@/views/form/index'),
        children: [
          {
            path: 'pendingReview',
            name: 'PendingReview',
            component: () => import('@/views/form/purchaseRequisition/pendingReview'),
            meta: { title: '待审核申购单' }
          },
          {
            path: 'passed',
            name: 'Passeds',
            component: () => import('@/views/form/purchaseRequisition/alreadyPassed'),
            meta: { title: '审核已通过申购单' }
          },
          {
            path: 'notPass',
            name: 'NotPassSubscribe',
            component: () => import('@/views/form/purchaseRequisition/notPass'),
            meta: { title: '审核未通过申购单' }
          },
          {
            path: 'completedSubscribe',
            name: 'CompletedSubscribe',
            component: () => import('@/views/form/purchaseRequisition/completedSubscribe'),
            meta: { title: '已完成申购单' }
          },
          {
            path: 'particulars',
            name: 'purchaseRequisitionParticulars',
            hidden: true,
            component: () => import('@/views/form/purchaseRequisition/particulars'),
            meta: { title: '申购详情' }
          }
        ]
      },
      {
        path: 'returnedGoods',
        name: 'returnedGoods',
        // redirect: '/form/returnedGoods/pendingReview',
        meta: { title: '产品退货单管理', icon: 'el-icon- iconfont iconsaleorderreturnManage' },
        component: () => import('@/views/form/index'),
        children: [
          {
            path: 'pendingReview',
            name: 'PendingReviews',
            component: () => import('@/views/form/returnedGoods/pendingReview'),
            meta: { title: '待审核退货单' }
          },
          {
            path: 'passed',
            name: 'Passed',
            component: () => import('@/views/form/returnedGoods/alreadyPassed'),
            meta: { title: '审核已通过退货单' }
          },
          {
            path: 'signFor',
            name: 'SignFor',
            component: () => import('@/views/form/returnedGoods/signFor'),
            meta: { title: '待签收退货单' }
          },
          {
            path: 'notPass',
            name: 'NotPass',
            component: () => import('@/views/form/returnedGoods/notPass'),
            meta: { title: '审核未通过退货单' }
          },
          {
            path: 'completedReturn',
            name: 'CompletedReturn',
            component: () => import('@/views/form/returnedGoods/completedReturn'),
            meta: { title: '已完成退货单' }
          },
          {
            path: 'particulars',
            name: 'returnedGoodsParticulars',
            hidden: true,
            component: () => import('@/views/form/returnedGoods/particulars'),
            meta: { title: '申退详情' }
          }
        ]
      },
      {
        path: 'exportDownLoadList',
        name: 'exportDownLoadList',
        component: () => import('@/views/form/exportDownLoadList'),
        meta: { title: '导出文件列表', icon: 'el-icon- iconfont icontubiao1' }
      }
    ]
  },
  // 产品管理
  {
    path: '/product',
    component: Layout,
    // redirect: '/product/commerces/saleProduct',
    meta: { title: '产品库' },
    children: [
      {
        path: 'medicine',
        name: 'Medicine',
        // redirect: '/product/medicine/saleProduct',
        meta: { title: '医药品管理', icon: 'el-icon- iconfont iconyaopin' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'saleProduct',
            name: 'MedicineSaleProduct',
            component: () => import('@/views/product/medicine/saleProduct_m'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'readyToSell',
            name: 'MedicineReadyToSell',
            component: () => import('@/views/product/medicine/readyToSell_m'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'giftList',
            name: 'giftList',
            component: () => import('@/views/product/medicine/giftList'),
            meta: { title: '赠品列表' }
          },
          {
            path: 'offShelves',
            name: 'MedicineOffShelves',
            component: () => import('@/views/product/medicine/offShelves_m'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'addProduct',
            name: 'MedicineAddProduct',
            hidden: true,
            component: () => import('@/views/product/medicine/addProduct_m'),
            meta: { title: '新增/编辑产品' }
          }
        ]
      },
      {
        path: 'commerces',
        name: 'Commerces',
        // redirect: '/product/commerces/saleProduct',
        meta: { title: '电商品管理', icon: 'el-icon- iconfont icon23' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'saleProduct',
            name: 'CommercesSaleProduct',
            component: () => import('@/views/product/ECommerce/saleProduct'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'readyToSell',
            name: 'CommercesReadyToSell',
            component: () => import('@/views/product/ECommerce/readyToSell'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'offShelves',
            name: 'CommercesOffShelves',
            component: () => import('@/views/product/ECommerce/offShelves'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'addProduct',
            name: 'CommercesAddProduct',
            hidden: true,
            component: () => import('@/views/product/ECommerce/addProduct'),
            meta: { title: '新增/编辑产品' }
          },
          {
            path: 'giftList',
            name: 'giftList',
            component: () => import('@/views/product/ECommerce/giftList'),
            meta: { title: '赠品列表' }
          }
        ]
      },
      {
        path: 'nutrition',
        name: 'Nutrition',
        meta: { title: '营养餐管理', icon: 'el-icon- iconfont nutrition' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'nutritionSaleList',
            name: 'NutritionSaleList',
            component: () => import('@/views/product/nutrition/nutritionSaleList'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'nutritionPendingSaleList',
            name: 'NutritionPendingSaleList',
            component: () => import('@/views/product/nutrition/nutritionPendingSaleList'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'nutritionDownList',
            name: 'NutritionDownList',
            component: () => import('@/views/product/nutrition/nutritionDownList'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'nutritiousMealPreferentialSetting',
            name: 'NutritiousMealPreferentialSetting',
            component: () => import('@/views/product/nutrition/nutritiousMealPreferentialSetting'),
            meta: { title: '优惠设置' }
          },
          {
            path: 'editDiscounts',
            name: 'NutritionEditDiscounts',
            hidden: true,
            component: () => import('@/views/product/nutrition/editDiscounts'),
            meta: { title: '优惠设置' }
          },
          {
            path: 'addNutrition',
            name: 'CommercesAddNutrition',
            hidden: true,
            component: () => import('@/views/product/nutrition/addNutrition'),
            meta: { title: '新增/编辑营养餐' }
          },
          {
            path: 'giftList',
            name: 'giftList',
            component: () => import('@/views/product/nutrition/giftList'),
            meta: { title: '赠品列表' }
          }
        ]
      },
      {
        path: 'externalProduct',
        name: 'ExternalProduct',
        meta: { title: '经销产品管理', icon: 'el-icon-takeaway-box' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'saleProduct',
            name: 'externalSaleProduct',
            component: () => import('@/views/product/externalProduct/saleProduct'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'readyToSell',
            name: 'externalReadyToSell',
            component: () => import('@/views/product/externalProduct/readyToSell'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'offShelves',
            name: 'externalOffShelves',
            component: () => import('@/views/product/externalProduct/offShelves'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'addProduct',
            name: 'externalAddProduct',
            hidden: true,
            component: () => import('@/views/product/externalProduct/addProduct'),
            meta: { title: '新增/编辑产品' }
          }
        ]
      },
      {
        path: 'crossBorderProduct',
        name: 'CrossBorderProduct',
        meta: { title: '跨境产品管理', icon: 'el-icon-suitcase-1' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'saleProduct',
            name: 'crossBorderSaleProduct',
            component: () => import('@/views/product/crossBorderProduct/saleProduct'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'readyToSell',
            name: 'crossBorderReadyToSell',
            component: () => import('@/views/product/crossBorderProduct/readyToSell'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'offShelves',
            name: 'crossBorderOffShelves',
            component: () => import('@/views/product/crossBorderProduct/offShelves'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'addProduct',
            name: 'crossBorderAddProduct',
            hidden: true,
            component: () => import('@/views/product/crossBorderProduct/addProduct'),
            meta: { title: '新增/编辑产品' }
          }
        ]
      },
      {
        path: 'lease',
        name: 'Lease',
        // redirect: '/product/lease/saleProduct',
        meta: { title: '租赁品管理', icon: 'el-icon- iconfont iconzulinwuguanli' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'saleProduct',
            name: 'LeaseSaleProduct',
            component: () => import('@/views/product/lease/saleProduct_l'),
            meta: { title: '在售产品列表' }
          },
          {
            path: 'readyToSell',
            name: 'LeaseReadyToSell',
            component: () => import('@/views/product/lease/readyToSell_l'),
            meta: { title: '待发售产品列表' }
          },
          {
            path: 'offShelves',
            name: 'LeaseOffShelves',
            component: () => import('@/views/product/lease/offShelves_l'),
            meta: { title: '已下架产品列表' }
          },
          {
            path: 'addProduct',
            name: 'LeaseAddProduct',
            hidden: true,
            component: () => import('@/views/product/lease/addProduct_l'),
            meta: { title: '新增/编辑产品' }
          }
        ]
      },
      {
        path: 'classManage',
        name: 'ClassManage',
        // redirect: '/product/classManage/productClass',
        meta: { title: '产品类别管理', icon: 'el-icon- iconfont iconleibieguanli' },
        component: () => import('@/views/product/index'),
        children: [
          {
            path: 'productClass',
            name: 'ProductClass',
            component: () => import('@/views/product/classManage/productClass'),
            meta: { title: '产品类别列表' }
          },
          {
            path: 'productUnit',
            name: 'ProductUnit',
            component: () => import('@/views/product/classManage/productUnit'),
            meta: { title: '产品单位列表' }
          }
        ]
      }
    ]
  },
  // 产品定制
  {
    path: '/custom',
    component: Layout,
    meta: { title: '产品定制' },
    children: [
      {
        path: 'customList',
        name: 'CustomList',
        component: () => import('@/views/custom/customList'),
        meta: { title: '产品定制列表', icon: 'el-icon- iconfont icondingzhi' }
      },
      {
        path: 'customType',
        name: 'CustomType',
        component: () => import('@/views/custom/customType'),
        meta: { title: '产品定制类别列表', icon: 'el-icon- iconfont iconleibie' }
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () => import('@/views/custom/feedback'),
        meta: { title: '意见反馈', icon: 'el-icon- iconfont iconyijianfankui' }
      }
    ]
  },
  // 医院管理
  {
    path: '/hospital',
    component: Layout,
    // redirect: '/hospital/hospitalList',
    meta: { title: '医院' },
    children: [
      // {
      //   path: 'hospitalList',
      //   name: 'hospitalList',
      //   component: () => import('@/views/hospital/hospitalList'),
      //   meta: { title: '医院列表', icon: 'el-icon- iconfont iconyiyuan' }
      // },
      {
        path: 'hospitalList',
        name: 'hospitalList',
        component: () => import('@/views/hospital/index'),
        meta: { title: '医院管理', icon: 'el-icon- iconfont iconyiyuan' },
        children: [
          {
            path: 'activateHospital',
            name: 'activateHospital',
            component: () => import('@/views/hospital/activateHospital'),
            meta: { title: '使用中医院列表' }
          },
          {
            path: 'deactivateHospital',
            name: 'deactivateHospital',
            component: () => import('@/views/hospital/deactivateHospital'),
            meta: { title: '已停用医院列表' }
          }
        ]
      },
      {
        path: 'shippingAddress',
        name: 'ShippingAddress',
        component: () => import('@/views/hospital/shippingAddressList'),
        meta: { title: '配送地址列表', icon: 'el-icon- iconfont iconaddress_icon' }
      },
      {
        path: 'theyTookAddressList',
        name: 'TheyTookAddressList',
        component: () => import('@/views/hospital/theyTookAddressList'),
        meta: { title: '自提地址列表', icon: 'el-icon- iconfont iconaddress_icon' }
      },
      {
        path: 'hierarchyAddressList',
        name: 'HierarchyAddressList',
        component: () => import('@/views/hospital/hierarchyAddressList'),
        meta: { title: '地址层级列表', icon: 'el-icon- iconfont iconorg' }
      }
    ]
  },
  // 用户管理
  {
    path: '/user',
    component: Layout,
    // redirect: '/user/member',
    meta: { title: '用户' },
    children: [
      {
        path: 'member',
        name: 'Member',
        component: () => import('@/views/user/memberList'),
        meta: { title: '会员列表', icon: 'el-icon- iconfont iconhuiyuan' }
      },
      {
        path: 'shops',
        name: 'shops',
        component: () => import('@/views/user/index.vue'),
        meta: { title: '商家列表', icon: 'el-icon- iconfont iconshangjia' },
        children: [
          {
            path: 'startShops',
            name: 'startShops',
            component: () => import('@/views/user/shops.vue'),
            meta: { title: '在使用列表' }
          },
          {
            path: 'stopShops',
            name: 'stopShops',
            component: () => import('@/views/user/shops.vue'),
            meta: { title: '已停用列表' }
          },
          {
            path: 'merchantTypeConfig',
            name: 'merchantTypeConfig',
            component: () => import('@/views/user/merchantTypeConfig.vue'),
            meta: { title: '商家类型配置' }
          }
        ]
      },
      {
        path: 'attache',
        name: 'Attache',
        component: () => import('@/views/user/attache'),
        meta: { title: '专员列表', icon: 'el-icon- iconfont iconnav_bd' }
      },
      {
        path: 'qrCodeManage',
        name: 'QrCodeManage',
        component: () => import('@/views/user/qrCodeManage'),
        meta: { title: '二维码列表', icon: 'el-icon- iconfont el-icon-full-screen' }
      },
      {
        path: 'talentPool',
        name: 'talentPool',
        component: () => import('@/views/user/talentPool/index.vue'),
        meta: { title: '人才库', icon: 'el-icon- iconfont el-icon-user' },
        children: [
          {
            path: 'personnelList',
            name: 'personnelList',
            component: () => import('@/views/user/talentPool/personnelList.vue'),
            meta: { title: '人员列表' }
          },
          {
            path: 'groupManagement',
            name: 'groupManagement',
            component: () => import('@/views/user/talentPool/groupManagement.vue'),
            meta: { title: '分组管理' }
          }
        ]
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () => import('@/views/custom/feedback'),
        meta: { title: '意见反馈', icon: 'el-icon- iconfont iconyijianfankui' }
      }
      // {
      //   path: 'message',
      //   name: 'Message',
      //   component: () => import('@/views/user/messageList'),
      //   meta: { title: '信息员列表', icon: 'el-icon- iconfont iconxinxi' }
      // },
    ]
  },
  // 财务管理
  {
    path: '/financial',
    component: Layout,
    meta: { title: '财务' },
    children: [
      {
        path: 'paymentManage',
        name: 'PaymentManage',
        component: () => import('@/views/financial/index'),
        meta: { title: '付款管理', icon: 'el-icon- iconfont iconfukuan' },
        children: [
          {
            path: 'commissionForUnpaidMerchants',
            name: 'CommissionForUnpaidMerchants',
            component: () => import('@/views/financial/paymentManage/commissionForUnpaidMerchants'),
            meta: { title: '待付款商家提成' }
          },
          {
            path: 'paidMerchantCommission',
            name: 'PaidMerchantCommission',
            component: () => import('@/views/financial/paymentManage/paidMerchantCommission'),
            meta: { title: '已付款商家提成' }
          }
        ]
      },
      {
        path: 'financialStatement',
        name: 'FinancialStatement',
        component: () => import('@/views/financial/index'),
        meta: { title: '财务报表', icon: 'el-icon- iconfont iconcaiwubaobiao' },
        children: [
          {
            path: 'operatingReports',
            name: 'operatingReports',
            component: () => import('@/views/financial/financialStatement/operatingReports'),
            meta: { title: '综合运营报表' }
          },
          {
            path: 'dailyIncomeStatistics',
            name: 'DailyIncomeStatistics',
            component: () => import('@/views/financial/financialStatement/dailyIncomeStatistics'),
            meta: { title: '每日收入统计报表' }
          },
          {
            path: 'transactionDetail',
            name: 'TransactionDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/transactionDetail'),
            meta: { title: '每日收入统计报表交易明细' }
          },
          {
            path: 'statisticalForms',
            name: 'StatisticalForms',
            component: () => import('@/views/financial/financialStatement/statisticalForms'),
            meta: { title: '订单统计分析报表' }
          },
          {
            path: 'salesStatistics',
            name: 'SalesStatistics',
            component: () => import('@/views/financial/financialStatement/salesStatistics'),
            meta: { title: '商家销售统计报表' }
          },
          {
            path: 'hospitalSalesStatistics',
            name: 'HospitalSalesStatistics',
            component: () => import('@/views/financial/financialStatement/hospitalSalesStatistics'),
            meta: { title: '医院销售统计报表' }
          },
          {
            path: 'hospitalSalesStatisticsDetail',
            name: 'HospitalSalesStatisticsDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/hospitalSalesStatisticsDetail'),
            meta: { title: '医院销售分析报表' }
          },
          {
            path: 'electronicCommoditySalesDataReport',
            name: 'ElectronicCommoditySalesDataReport',
            component: () => import('@/views/financial/financialStatement/electronicCommoditySalesDataReport'),
            meta: { title: '电商品销售数据报表' }
          },
          {
            path: 'electronicCommoditySalesDataReportDetail',
            name: 'ElectronicCommoditySalesDataReportDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/electronicCommoditySalesDataReportDetail'),
            meta: { title: '电商品销售交易明细' }
          },
          {
            path: 'pharmaceuticalSalesDataStatement',
            name: 'PharmaceuticalSalesDataStatement',
            component: () => import('@/views/financial/financialStatement/pharmaceuticalSalesDataStatement'),
            meta: { title: '医药品销售数据报表' }
          },
          {
            path: 'pharmaceuticalSalesDataStatementDetail',
            name: 'PharmaceuticalSalesDataStatementDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/pharmaceuticalSalesDataStatementDetail'),
            meta: { title: '医药品销售交易明细' }
          },
          {
            path: 'leasingItemSalesDataReport',
            name: 'LeasingItemSalesDataReport',
            component: () => import('@/views/financial/financialStatement/leasingItemSalesDataReport'),
            meta: { title: '租赁品销售数据报表' }
          },
          {
            path: 'leasingItemSalesDataReportDetail',
            name: 'LeasingItemSalesDataReportDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/leasingItemSalesDataReportDetail'),
            meta: { title: '租赁品销售交易明细' }
          },
          {
            path: 'specialistSalesStatistics',
            name: 'SpecialistSalesStatistics',
            component: () => import('@/views/financial/financialStatement/specialistSalesStatistics'),
            meta: { title: '专员销售统计报表' }
          },
          //
          // {
          //   path: 'specialistSalesStatisticsDetail',
          //   name: 'SpecialistSalesStatisticsDetail',
          //   hidden: true,
          //   component: () => import('@/views/financial/financialStatement/specialistSalesStatisticsDetail'),
          //   meta: { title: '专员销售统计报表明细' }
          // },
          // {
          //   path: 'specialistOrderDetails',
          //   name: 'SpecialistOrderDetails',
          //   hidden: true,
          //   component: () => import('@/views/financial/financialStatement/specialistOrderDetails'),
          //   meta: { title: '专员订单明细' }
          // },
          {
            path: 'regionalSalesStatistics',
            name: 'RegionalSalesStatistics',
            component: () => import('@/views/financial/financialStatement/regionalSalesStatistics'),
            meta: { title: '区域销售统计报表' }
          },
          {
            path: 'regionalSalesStatisticsDetail',
            name: 'RegionalSalesStatisticsDetail',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/regionalSalesStatisticsDetail'),
            meta: { title: '区域销售统计报表明细' }
          },
          {
            path: 'orderDetails',
            name: 'orderDetails',
            hidden: true,
            component: () => import('@/views/financial/financialStatement/orderDetails'),
            meta: { title: '订单明细' }
          }
        ]
      },
      {
        path: 'listOfAdditionalServiceCharges',
        name: 'ListOfAdditionalServiceCharges',
        component: () => import('@/views/financial/listOfAdditionalServiceCharges'),
        meta: { title: '额外服务费列表', icon: 'el-icon- iconfont iconfuwufei' }
      },
      {
        path: 'mchManage',
        name: 'mchManage',
        component: () => import('@/views/financial/index'),
        meta: { title: '商户管理', icon: 'el-icon- iconfont iconhuiyuan' },
        children: [
          {
            path: 'mchList',
            name: 'mchList',
            component: () => import('@/views/financial/mchManage/mchList'),
            meta: { title: '商户列表' }
          }
        ]
      }
    ]
  },
  // 运营管理
  {
    path: '/operation',
    component: Layout,
    // redirect: '/operation/comment/commentL',
    meta: { title: '运营' },
    children: [
      {
        path: 'marketingTool',
        name: 'MarketingTool',
        component: () => import('@/views/operation/index'),
        meta: { title: '营销工具管理', icon: 'el-icon-s-shop iconpinglun' },
        children: [
          {
            path: 'fullGiftPromotion',
            name: 'FullGiftPromotion',
            component: () => import('@/views/operation/marketingTool/FullGiftPromotion'),
            meta: { title: '满赠活动' }
          },
          {
            path: 'platformCoupon',
            name: 'PlatformCoupon',
            component: () => import('@/views/operation/marketingTool/PlatformCoupon'),
            meta: { title: '平台优惠券列表' }
          },
          {
            path: 'salesmanCoupon',
            name: 'SalesmanCoupon',
            component: () => import('@/views/operation/marketingTool/SalesmanCoupon'),
            meta: { title: '业务员优惠券列表' }
          },
          {
            path: 'bonusCouponList',
            name: 'BonusCoupon',
            component: () => import('@/views/operation/marketingTool/BonusCoupon'),
            meta: { title: '福利优惠券列表' }
          },
          {
            path: 'addCoupon',
            name: 'operationAddCoupon',
            hidden: true,
            component: () => import('@/views/operation/marketingTool/addCoupon'),
            meta: { title: '新增/编辑产品' }
          },
          {
            path: 'fullGift',
            name: 'fullGift',
            hidden: true,
            component: () => import('@/views/operation/marketingTool/fullGift'),
            meta: { title: '满赠' }
          },
          {
            path: 'couponQrcodeList',
            name: 'CouponQrcodeList',
            hidden: true,
            component: () => import('@/views/operation/marketingTool/CouponQrcodeList'),
            meta: { title: '优惠券二维码列表' }
          },
          {
            path: 'redPacketQrcodeList',
            name: 'RedPacketQrcodeList',
            meta: { title: '红包二维码列表' },
            component: () => import('@/views/operation/marketingTool/index'),
            children: [
              {
                path: 'unclaimedRedPacketQrcodeList',
                name: 'unclaimedRedPacketQrcodeList',
                component: () => import('@/views/operation/marketingTool/redPacketQrcodeList/unclaimedRedPacketQrcodeList'),
                meta: { title: '未领取红包二维码列表' }
              },
              {
                path: 'claimedRedPacketQrcodeList',
                name: 'claimedRedPacketQrcodeList',
                component: () => import('@/views/operation/marketingTool/redPacketQrcodeList/claimedRedPacketQrcodeList'),
                meta: { title: '已领取红包二维码列表' }
              },
              {
                path: 'redPacketTransfer',
                name: 'redPacketTransfer',
                component: () => import('@/views/operation/marketingTool/redPacketQrcodeList/redPacketTransfer'),
                meta: { title: '红包转账明细' }
              }
            ]
          }
        ]
      },
      {
        path: 'comment',
        name: 'Comment',
        // redirect: '/operation/comment/commentL',
        component: () => import('@/views/operation/index'),
        meta: { title: '产品评论管理', icon: 'el-icon- iconfont iconpinglun' },
        children: [
          {
            path: 'commentL',
            name: 'CommentL',
            component: () => import('@/views/operation/comment/comment'),
            meta: { title: '产品评论列表' }
          },
          {
            path: 'hideComment',
            name: 'HideComment',
            component: () => import('@/views/operation/comment/hideComment'),
            meta: { title: '隐藏产品评论列表' }
          }
        ]
      },
      {
        path: 'nationalArea',
        name: 'NationalArea',
        // redirect: '/operation/nationalArea/office',
        component: () => import('@/views/operation/index'),
        meta: { title: '全国区域管理', icon: 'el-icon- iconfont iconquyuguanli' },
        children: [
          {
            path: 'office',
            name: 'Office',
            component: () => import('@/views/operation/nationalArea/office'),
            meta: { title: '办事处列表' }
          },
          {
            path: 'area',
            name: 'Area',
            component: () => import('@/views/operation/nationalArea/area'),
            meta: { title: '片区列表' }
          },
          {
            path: 'region',
            name: 'Region',
            component: () => import('@/views/operation/nationalArea/region'),
            meta: { title: '大区列表' }
          }
        ]
      },
      {
        path: 'banner',
        name: 'Banner',
        // redirect: '/operation/banner/member',
        component: () => import('@/views/operation/index'),
        meta: { title: 'banner管理', icon: 'el-icon- iconfont icontubiao-' },
        children: [
          {
            path: 'member',
            name: 'Members',
            component: () => import('@/views/operation/banner/member'),
            meta: { title: '会员端banner' }
          },
          {
            path: 'merchant',
            name: 'Merchant',
            component: () => import('@/views/operation/banner/merchant'),
            meta: { title: '商家端banner' }
          }
        ]
      },
      {
        path: 'consult',
        name: 'Consult',
        // redirect: '/operation/consult/content',
        component: () => import('@/views/operation/index'),
        meta: { title: '资讯管理', icon: 'el-icon- iconfont iconzixun' },
        children: [
          {
            path: 'content',
            name: 'Content',
            component: () => import('@/views/operation/consult/content'),
            meta: { title: '内容管理' }
          },
          {
            path: 'sort',
            name: 'Sort',
            component: () => import('@/views/operation/consult/sort'),
            meta: { title: '类别管理' }
          }
        ]
      },
      {
        path: 'knowledgeManagement',
        name: 'knowledgeManagement',
        component: () => import('@/views/operation/index'),
        meta: { title: '知识库管理', icon: 'el-icon- iconfont iconpinglun' },
        children: [
          {
            path: 'articleList',
            name: 'articleList',
            component: () => import('@/views/operation/knowledgeManagement/articleList'),
            meta: { title: '文章列表' }
          },
          {
            path: 'alreadyRemoveArticleList',
            name: 'alreadyRemoveArticleList',
            component: () => import('@/views/operation/knowledgeManagement/alreadyRemoveArticleList'),
            meta: { title: '已删除文章列表' }
          },
          {
            path: 'categoryManagement',
            name: 'categoryManagement',
            component: () => import('@/views/operation/knowledgeManagement/categoryManagement'),
            meta: { title: '类别管理' }
          },
          {
            path: 'addArticle',
            name: 'addArticle',
            component: () => import('@/views/operation/knowledgeManagement/management/addArticle'),
            meta: { title: '新增文章' }
          }
        ]
      },
      {
        path: 'community',
        name: 'community',
        // redirect: '/operation/consult/content',
        component: () => import('@/views/operation/index'),
        meta: { title: '社区管理', icon: 'el-icon- iconfont iconzixun' },
        children: [
          {
            path: 'article',
            name: 'article',
            component: () => import('@/views/operation/community/article'),
            meta: { title: '全部文章列表' }
          },
          {
            path: 'deletedArticle',
            name: 'deletedArticle',
            component: () => import('@/views/operation/community/deletedArticle'),
            meta: { title: '已删除文章列表' }
          },
          {
            path: 'articleType',
            name: 'articleType',
            component: () => import('@/views/operation/community/articleType'),
            meta: { title: '类别列表' }
          },
          {
            path: 'comment',
            name: 'comment',
            component: () => import('@/views/operation/community/comment'),
            meta: { title: '评论' }
          }
        ]
      },
      {
        path: 'navigation',
        name: 'Navigation',
        component: () => import('@/views/operation/navigation'),
        meta: { title: '首页导航按钮', icon: 'el-icon- iconfont iconbutton' }
      },
      {
        path: 'typeIcon',
        name: 'TypeIcon',
        component: () => import('@/views/operation/typeIcon'),
        meta: { title: '产品类型图标管理', icon: 'el-icon- iconfont iconmingxinghuodong' }
      },
      {
        path: 'hotWord',
        name: 'HotWord',
        component: () => import('@/views/operation/hotWord'),
        meta: { title: '热门搜索词管理', icon: 'el-icon- iconfont iconremen' }
      },
      {
        path: 'param',
        name: 'param',
        component: () => import('@/views/operation/paramSet'),
        meta: { title: '参数设置', icon: 'el-icon- iconfont iconcanshu' }
      },
      {
        path: 'protocol',
        name: 'protocol',
        component: () => import('@/views/operation/protocol'),
        meta: { title: '协议管理', icon: 'el-icon- iconfont iconM_yinsixieyi' }
      },
      {
        path: 'warningLine',
        name: 'WarningLine',
        component: () => import('@/views/operation/warningLine'),
        meta: { title: '产品库存警戒线管理', icon: 'el-icon- iconfont iconjinggao' }
      }
    ]
  },
  // 咨询
  {
    path: '/consult',
    component: Layout,
    meta: { title: '咨询' },
    children: [
      {
        path: 'dietitianList',
        name: 'dietitianList',
        component: () => import('@/views/consult/dietitianList'),
        meta: { title: '营养师列表', icon: '' }
      },
      {
        path: 'dafiles',
        name: 'dafiles',
        component: () => import('@/views/consult/dafiles'),
        meta: { title: '营养师信息', icon: '' }
      },
      {
        path: 'productdetail',
        name: 'productdetail',
        component: () => import('@/views/consult/productdetail'),
        meta: { title: '基本信息', icon: '' }
      },
      {
        path: 'dietitianEvaluateList',
        name: 'dietitianEvaluateList',
        component: () => import('@/views/consult/evaluation'),
        meta: { title: '营养师评价列表', icon: '' }
      },
      {
        path: 'autoRecoverSetting',
        name: 'autoRecoverSetting',
        component: () => import('@/views/consult/automaticReply'),
        meta: { title: '自动回复设置', icon: '' }
      },
      {
        path: 'questionnaireList',
        name: 'questionnaireList',
        component: () => import('@/views/consult/questionnaireManage/index'),
        meta: { title: '问卷模板管理', icon: '' },
        children: [
          {
            path: 'nutritionArchivesList',
            name: 'nutritionArchivesList',
            component: () => import('@/views/consult/questionnaireManage/files'),
            meta: { title: '营养档案模板' }
          },
          {
            path: 'nutritionRecordList',
            name: 'nutritionRecordList',
            component: () => import('@/views/consult/questionnaireManage/record'),
            meta: { title: '营养记录模板' }
          },
          {
            path: 'questionnaireList',
            name: 'questionnaireList',
            component: () => import('@/views/consult/questionnaireManage/questionnaire'),
            meta: { title: '营养师问卷模板' }
          },
          {
            path: 'dietitianQuestionnaireList',
            name: 'dietitianQuestionnaireList',
            component: () => import('@/views/consult/questionnaireManage/selfQuestionnaire'),
            meta: { title: '营养师自创问卷模板' }
          },
          {
            path: 'nutritionArchivesDetail',
            name: 'nutritionArchivesDetail',
            component: () => import('@/views/consult/questionnaireManage/fileDetails'),
            meta: { title: '查看营养档案模板' }
          }
        ]
      },
      {
        path: 'consultList',
        name: 'consultList',
        component: () => import('@/views/consult/consultList/index'),
        meta: { title: '咨询列表', icon: '' },
        children: [
          {
            path: 'userConsultList',
            name: 'userConsultList',
            component: () => import('@/views/consult/consultList/userList'),
            meta: { title: '用户咨询列表' }
          },
          {
            path: 'groupList',
            name: 'groupList',
            component: () => import('@/views/consult/consultList/groupChatList/index'),
            meta: { title: '群聊列表' },
            children: [
              {
                path: 'useGroupChatList',
                name: 'useGroupChatList',
                component: () => import('@/views/consult/consultList/groupChatList/use'),
                meta: { title: '使用中群聊' }
              },
              {
                path: 'notUseGroupChatList',
                name: 'notUseGroupChatList',
                component: () => import('@/views/consult/consultList/groupChatList/dissolved'),
                meta: { title: '已解散群聊' }
              }
            ]
          }
        ]
      },
      {
        path: 'healthInformationList',
        name: 'healthInformationList',
        component: () => import('@/views/consult/healthList/index'),
        meta: { title: '健康信息列表', icon: '' },
        children: [
          {
            path: 'memberList',
            name: 'memberList',
            component: () => import('@/views/consult/healthList/memberList'),
            meta: { title: '成员列表' }
          },
          {
            path: 'healthInfoDetail',
            name: 'healthInfoDetail',
            component: () => import('@/views/consult/healthList/healthInfoDetail'),
            meta: { title: '成员信息' }
          },
          {
            path: 'userNutritionArchivesList',
            name: 'userNutritionArchivesList',
            meta: { title: '营养档案列表' },
            component: () => import('@/views/consult/healthList/filesList')
          },
          {
            path: 'recordDet',
            name: 'recordDet',
            meta: { title: '营养档案列表' },
            component: () => import('@/views/consult/healthList/recordDet')
          },
          {
            path: 'userNutritionRecordList',
            name: 'userNutritionRecordList',
            meta: { title: '营养记录列表' },
            component: () => import('@/views/consult/healthList/recordList')
          },
          {
            path: 'recordDetails',
            name: 'recordDetails',
            meta: { title: '营养记录详情' },
            component: () => import('@/views/consult/healthList/recordDetails')
          },
          {
            path: 'userQuestionnaireList',
            name: 'userQuestionnaireList',
            meta: { title: '营养师问卷列表' },
            component: () => import('@/views/consult/healthList/userQuestionnaireList')
          },
          {
            path: 'userQuestionnaireDetails',
            name: 'userQuestionnaireDetails',
            meta: { title: '营养师问卷详情' },
            component: () => import('@/views/consult/healthList/userQuestionnaireDetails')
          },
          {
            path: 'userDietitianQuestionnaireList',
            name: 'userDietitianQuestionnaireList',
            meta: { title: '营养师自创问卷列表' },
            component: () => import('@/views/consult/healthList/userDietitianQuestionnaireList')
          },
          {
            path: 'userDietitianQuestionnaireDetails',
            name: 'userDietitianQuestionnaireDetails',
            meta: { title: '营养师自创问卷详情' },
            component: () => import('@/views/consult/healthList/userDietitianQuestionnaireDetails')
          }
        ]
      }
    ]
  },
  // 仓库管理
  {
    path: '/warehouse',
    component: Layout,
    // redirect: '/warehouse/warehousing/warehousedProductionOrder',
    meta: { title: '仓库' },
    children: [
      {
        path: 'warehousing',
        name: 'Warehousing',
        // redirect: '/warehouse/warehousing/warehousedProductionOrder',
        component: () => import('@/views/warehouse/index'),
        meta: { title: '入库管理', icon: 'el-icon- iconfont iconruku1' },
        children: [
          {
            path: 'warehousedProductionOrder',
            name: 'WarehousedProductionOrder',
            component: () => import('@/views/warehouse/warehousing/warehousedProductionOrder'),
            meta: { title: '已入库生产单' }
          },
          {
            path: 'enterProductionOrder',
            name: 'EnterProductionOrder',
            component: () => import('@/views/warehouse/warehousing/enterProductionOrder'),
            meta: { title: '录入生产单' }
          }
        ]
      },
      {
        path: 'inventory',
        name: 'Inventory',
        // redirect: '/warehouse/inventory/inventoryProducts',
        component: () => import('@/views/warehouse/index'),
        meta: { title: '库存管理', icon: 'el-icon- iconfont iconkucun' },
        children: [
          {
            path: 'inventoryProducts',
            name: 'InventoryProducts',
            component: () => import('@/views/warehouse/inventory/inventoryProducts'),
            meta: { title: '库存产品' }
          }
        ]
      },
      {
        path: 'outbound',
        name: 'Outbound',
        // redirect: '/warehouse/Outbound/inventoryProducts',
        component: () => import('@/views/warehouse/index'),
        meta: { title: '出库管理', icon: 'el-icon- iconfont iconchuku1' },
        children: [
          {
            path: 'orderOutbound',
            name: 'OrderOutbound',
            meta: { title: '订单出库' },
            component: () => import('@/views/warehouse/index'),
            children: [
              {
                path: 'notDelivered',
                name: 'NotDelivered',
                component: () => import('@/views/warehouse/outbound/orderOutbound/notDelivered'),
                meta: { title: '待出库订单' }
              },
              {
                path: 'delivered',
                name: 'Delivered',
                component: () => import('@/views/warehouse/outbound/orderOutbound/delivered'),
                meta: { title: '已出库订单' }
              }
            ]
          },
          {
            path: 'purchaseOrderDelivery',
            name: 'PurchaseOrderDelivery',
            meta: { title: '申购单出库' },
            component: () => import('@/views/warehouse/index'),
            children: [
              {
                path: 'notDelivered',
                name: 'NotDelivered',
                component: () => import('@/views/warehouse/outbound/purchaseOrderDelivery/notDelivered'),
                meta: { title: '待出库申购单' }
              },
              {
                path: 'delivered',
                name: 'Delivered',
                component: () => import('@/views/warehouse/outbound/purchaseOrderDelivery/delivered'),
                meta: { title: '已出库申购单' }
              }
            ]
          },
          {
            path: 'batchOutbound',
            name: 'batchOutbound',
            meta: { title: '退货出库' },
            component: () => import('@/views/warehouse/index'),
            children: [
              {
                path: 'getList',
                name: 'getList',
                component: () => import('@/views/warehouse/outbound/batchOutbound/getList'),
                meta: { title: '已出库退货单' }
              },
              {
                path: 'save',
                name: 'save',
                component: () => import('@/views/warehouse/outbound/batchOutbound/save'),
                meta: { title: '录入出库单' }
              }
            ]
          }
        ]
      },
      {
        path: 'warehouseManage',
        name: 'WarehouseManage',
        // redirect: '/warehouse/warehouseManage/warehouseInUse',
        component: () => import('@/views/warehouse/index'),
        meta: { title: '仓库管理', icon: 'el-icon- iconfont iconcangku' },
        children: [
          {
            path: 'warehouseInUse',
            name: 'WarehouseInUse',
            component: () => import('@/views/warehouse/warehouseManage/warehouseInUse'),
            meta: { title: '使用中仓库' }
          }
        ]
      }
    ]
  },
  // 系统设置
  {
    path: '/system',
    component: Layout,
    // redirect: '/system/basicInfo',
    meta: { title: '系统' },
    children: [
      {
        path: 'basicInfo',
        name: 'basicInfo',
        component: () => import('@/views/system/basicInfo'),
        meta: { title: '基本信息', icon: 'el-icon- iconfont iconjibenxinxi' }
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import('@/views/system/adminList'),
        meta: { title: '管理员列表', icon: 'el-icon- iconfont iconguanliyuan' }
      },
      {
        path: 'group',
        name: 'Group',
        component: () => import('@/views/system/groupList'),
        meta: { title: '权限组列表', icon: 'el-icon- iconfont iconquanxian' }
      },
      {
        path: 'logisticsCompany',
        name: 'LogisticsCompany',
        component: () => import('@/views/system/logisticsCompany'),
        meta: { title: '物流公司管理', icon: 'el-icon- iconfont iconwuliu' }
      },
      {
        path: 'returnAddress',
        name: 'ReturnAddress',
        component: () => import('@/views/system/returnAddress'),
        meta: { title: '退货地址管理', icon: 'el-icon- iconfont iconsaleorderreturnManage' }
      },
      // 该模块暂时注释
      // {
      //   path: 'config',
      //   name: 'config',
      //   component: () => import('@/views/system/config'),
      //   meta: { title: '第三方服务配置', icon: 'el-icon- iconfont iconpeizhi' },
      // },
      // {
      //   path: 'msgNotice',
      //   name: 'MsgNotice',
      //   // redirect: '/system/msgNotice/weChatOfficialAccount',
      //   component: () => import('@/views/system/index'),
      //   meta: { title: '消息通知模板', icon: 'el-icon- iconfont iconziyuan' },
      //   children: [
      //     {
      //       path: 'weChatOfficialAccount',
      //       name: 'WeChatOfficialAccount',
      //       component: () => import('@/views/system/msgNotice/weChatOfficialAccount'),
      //       meta: { title: '微信公众号模板管理'},
      //     },
      //     {
      //       path: 'appletOfWeChat',
      //       name: 'AppletOfWeChat',
      //       component: () => import('@/views/system/msgNotice/appletOfWeChat'),
      //       meta: { title: '微信小程序模板管理'},
      //     },
      //     {
      //       path: 'msgTemplate',
      //       name: 'MsgTemplate',
      //       component: () => import('@/views/system/msgNotice/msgTemplate'),
      //       meta: { title: '短信模板管理'},
      //     },
      //     {
      //       path: 'msgChannelSet',
      //       name: 'MsgChannelSet',
      //       component: () => import('@/views/system/msgNotice/msgChannelSet'),
      //       meta: { title: '短信通道设置'},
      //     },
      //   ]
      // },
      {
        path: 'msgCenter',
        name: 'MsgCenter',
        component: () => import('@/views/system/msgCenter'),
        meta: { title: '消息中心', icon: 'el-icon- iconfont iconxiaoxizhongxin' }
      },
      {
        path: 'orderConfiguration',
        name: 'orderConfiguration',
        component: () => import('@/views/consult/questionnaireManage/index'),
        meta: { title: '订单配置', icon: '' },
        children: [
          {
            path: 'smsMessage',
            name: 'SMSSettings',
            component: () => import('@/views/system/orderConfiguration/smsSettings'),
            meta: { title: '短信消息设置', icon: 'el-icon- iconfont iconxiaoxizhongxin' }
          },
          {
            path: 'externalOutbound',
            name: 'externalOutbound',
            component: () => import('@/views/system/orderConfiguration/externalOutbound'),
            meta: { title: '经销产品出库配置' }
          }
        ]
      },
      // 该模块暂时注释
      // {
      //   path: 'dataSecurity',
      //   name: 'DataSecurity',
      //   // redirect: '/system/dataSecurity/dataBackup',
      //   component: () => import('@/views/system/index'),
      //   meta: { title: '数据安全', icon: 'el-icon- iconfont iconanquan' },
      //   children: [
      //     {
      //       path: 'dataBackup',
      //       name: 'DataBackup',
      //       component: () => import('@/views/system/dataSecurity/dataBackup'),
      //       meta: { title: '数据备份'},
      //     },
      //     {
      //       path: 'wipeData',
      //       name: 'WipeData',
      //       component: () => import('@/views/system/dataSecurity/wipeData'),
      //       meta: { title: '清空数据'},
      //     },
      //   ]
      // },
      {
        path: 'operateLog',
        name: 'OperateLog',
        component: () => import('@/views/system/operateLog'),
        // component: () => import('@/views/consult/test3'),
        meta: { title: '操作日志', icon: 'el-icon- iconfont iconwj-rz' }
      },
      // 该模块暂时注释
      // {
      //   path: 'dictionaries',
      //   name: 'Dictionaries',
      //   component: () => import('@/views/system/dictionaries'),
      //   meta: { title: '字典管理', icon: 'el-icon- iconfont iconzidian' },
      // },
      // {
      //   path: 'businessCode',
      //   name: 'BusinessCode',
      //   component: () => import('@/views/system/businessCode'),
      //   meta: { title: '业务编码', icon: 'el-icon- iconfont iconbianma' },
      // },
      {
        path: 'exportLog',
        name: 'exportLog',
        component: () => import('@/views/order/exportLog'),
        meta: { title: '导出文件日志', icon: 'el-icon- iconfont icontubiao1' }
      },
      {
        path: 'burialData',
        name: 'burialData',
        component: () => import('@/views/system/burialData'),
        meta: { title: '埋点文档导出', icon: 'el-icon- iconfont el-icon-document' }
      }
    ]
  },
  // 404 page must be placed at the end !!!
  {
    path: '*',
    // redirect: '/404',
    hidden: true
  }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  })

const router = createRouter()

const allow = [
  '/login',
  '/',
  '/404',
  '/order/commerce/particulars',
  '/order/commerce/returnedGoods/particulars',
  '/order/medical/hospitalDelivery/particulars',
  '/order/medical/hospitalDelivery/returnedGoods/particulars',
  '/order/lease/particulars',
  '/order/medical/medicalReturnedGoods/medicalParticulars',
  '/form/purchaseRequisition/particulars',
  '/form/returnedGoods/particulars',
  '/product/commerces/addProduct',
  '/product/medicine/addProduct',
  '/product/lease/addProduct',
  '/operation/marketingTool/addCoupon',
  '/financial/financialStatement/hospitalSalesStatisticsDetail',
  '/financial/financialStatement/transactionDetail',
  '/financial/financialStatement/orderDetails',
  '/financial/financialStatement/electronicCommoditySalesDataReportDetail',
  '/financial/financialStatement/pharmaceuticalSalesDataStatementDetail',
  '/financial/financialStatement/leasingItemSalesDataReportDetail',
  // '/financial/financialStatement/specialistSalesStatisticsDetail',
  '/financial/financialStatement/regionalSalesStatisticsDetail',
  '/largeScreenLogin',
  '/dashboard'
]

router.beforeEach((to, from, next) => {
  const menuList = Store.state.user.menuList
  // console.log('前置路由守卫：===========')
  // console.log(to)
  // console.log(from)
  // console.log(menuList);

  // console.log(to.fullPath);

  const path = to.fullPath.split('?')[0]

  if (allow.indexOf(path) !== -1 || menuList.length === 0) {
    return next()
  }

  // let pathList = path.slice(1).split('/')
  // pathList[0] = '/' + pathList[0]
  // let audit = verifyRouter(pathList, menuList)
  // if (!audit) return next({ path: '/404' })
  next()
})

router.afterEach((to, from) => {
  // console.log('后置路由守卫：===========')
  // console.log(to)
  // console.log(from)
})

// 验证路由
function verifyRouter(arr, menu) {
  const index = menu.findIndex(item => item.path === arr[0])
  // console.log(menu);
  // console.log(index);
  // console.log(arr);
  // console.log(menu);
  // console.log('=======');
  if (index !== -1) {
    arr.splice(0, 1)
    if (arr.length > 0) {
      if (menu[index].children && menu[index].children.length > 0) {
        return verifyRouter(arr, menu[index].children)
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return false
  }
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
