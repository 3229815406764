import {getCount4Unread, getMerchantTypeData} from '@/api/user'
import {getSessionStorage, removeToken, setSessionStorage} from '@/utils/auth'
import {resetRouter} from '@/router'


const getDefaultState = () => {
    return {
        token: getSessionStorage(),
        name: '',
        avatar: '',
        userInfo: {},
        menuList: [],
        msgNum: 0,  
        merchantTypeData: [], // 商家类型数据
        merchantTypeTotal: 0,
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_USER_INFO: (state, info) => {
        state.userInfo = info
    },
    SET_MENU_LIST: (state, arr) => {
        state.menuList = arr
    },
    SET_MSG_NUM: (state, num) => {
        state.msgNum = num
    },
    SET_MERCHANT_DATA: (state, data) => {
        state.merchantTypeData = data;
        setSessionStorage('merchantTypeData', data);
    },
    SET_MERCHANT_TOTAL: (state, total) => {
        state.merchantTypeTotal = total;
        setSessionStorage('merchantTypeTotal', total);
    },
}

const actions = {
    // 获取未读信息数
    getCount4Unread({commit}) {
        getCount4Unread().then(res => {
            if (state.msgNum != res.data) {
                commit('SET_MSG_NUM', res.data)
            }
        })
    },

    // // user login
    // login({commit}, userInfo) {
    //     const {username, password} = userInfo;
    //     console.log(username)
    //     return new Promise((resolve, reject) => {
    //         login({username: username.trim(), password: password}).then(response => {
    //             const {data} = response
    //             console.log(response)
    //             commit('SET_TOKEN', data.token)
    //             setSessionStorage('userToken', data.token)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // // get user info
    // getInfo({commit, state}) {
    //     return new Promise((resolve, reject) => {
    //         getInfo(state.token).then(response => {
    //             const {data} = response

    //             if (!data) {
    //                 reject('Verification failed, please Login again.')
    //             }

    //             const {name, avatar} = data

    //             commit('SET_NAME', name)
    //             commit('SET_AVATAR', avatar)
    //             resolve(data)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // // user logout
    // logout({commit, state}) {
    //     return new Promise((resolve, reject) => {
    //         logout(state.token).then(() => {
    //             removeToken() // must remove  token  first
    //             resetRouter()
    //             commit('RESET_STATE')
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    },

    // 获取商家类型列表
    getMerchantTypeList({ commit }) {
        getMerchantTypeData().then(res => {
            if (res.code === 200) {
                commit('SET_MERCHANT_DATA', res.data.list);
                commit('SET_MERCHANT_TOTAL', res.data.total);
            }
        }).catch(err => {
            console.error("获取商家类型失败：", err);
        });
    }

}

if(!!getSessionStorage('merchantTypeTotal')){
    state.merchantTypeTotal = getSessionStorage('merchantTypeTotal')
}
if(!!getSessionStorage('merchantTypeData')){
    state.merchantTypeData = getSessionStorage('merchantTypeData')
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
