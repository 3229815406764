var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box qrcode_list" },
    [
      _c(
        "div",
        { staticClass: "head-search-row search_box" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务员姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "业务员姓名", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("qrcodeTable")
                      },
                    },
                    model: {
                      value: _vm.queryData.intendantName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "intendantName", $$v)
                      },
                      expression: "queryData.intendantName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.thirdPartyPermissions
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属区域" } },
                    [
                      _vm.$public.isAllAreaPower()
                        ? _c("el-cascader", {
                            attrs: {
                              props: _vm.addressConfigure,
                              options: _vm.addressList,
                              clearable: "",
                              placeholder: "请选择地址",
                            },
                            model: {
                              value: _vm.queryData.areaId,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryData, "areaId", $$v)
                              },
                              expression: "queryData.areaId",
                            },
                          })
                        : _c("d-area", {
                            attrs: { values: _vm.queryData.areaId },
                            on: {
                              "update:values": function ($event) {
                                return _vm.$set(_vm.queryData, "areaId", $event)
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.thirdPartyPermissions
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商家端名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家端名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.updateList("qrcodeTable")
                          },
                        },
                        model: {
                          value: _vm.queryData.merchantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "merchantName", $$v)
                          },
                          expression: "queryData.merchantName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "状态" } }, [
                _c(
                  "div",
                  { staticStyle: { width: "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.queryData.qrCodeStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "qrCodeStatus", $$v)
                          },
                          expression: "queryData.qrCodeStatus",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "启用", value: 1 } }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { label: "禁用", value: 2 } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务员编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "业务员编号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("qrcodeTable")
                      },
                    },
                    model: {
                      value: _vm.queryData.intendantCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "intendantCode", $$v)
                      },
                      expression: "queryData.intendantCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "二维码编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "二维码编号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("qrcodeTable")
                      },
                    },
                    model: {
                      value: _vm.queryData.qrCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "qrCode", $$v)
                      },
                      expression: "queryData.qrCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "二维码类型" } }, [
                _c(
                  "div",
                  { staticStyle: { width: "180px" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.queryData.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "type", $$v)
                          },
                          expression: "queryData.type",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "商家二维码", value: 1 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "经销品个人码", value: 2 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "电商品个人码", value: 3 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "领取状态" } }, [
                _c(
                  "div",
                  { staticStyle: { width: "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.queryData.claimStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "claimStatus", $$v)
                          },
                          expression: "queryData.claimStatus",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "已领取", value: 1 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "未领取", value: 2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "指定扫码商家类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.queryData.assignMerchantType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryData, "assignMerchantType", $$v)
                        },
                        expression: "queryData.assignMerchantType",
                      },
                    },
                    _vm._l(_vm.merchantTypeData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.merchantType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updateList("qrcodeTable")
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _vm.userInfo.rightsGroupNames.includes("超级管理员")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.operateButton("modify_qrcode")
                        },
                      },
                    },
                    [_vm._v("\n        新增\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userInfo.rightsGroupNames.includes("超级管理员")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.operateButton("download_qrcode")
                        },
                      },
                    },
                    [_vm._v("\n        下载二维码\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userInfo.rightsGroupNames.includes("超级管理员")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.operateMerchants(2)
                        },
                      },
                    },
                    [_vm._v("\n        更换商家\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userInfo.rightsGroupNames.includes("超级管理员")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.operateButton("export_table")
                        },
                      },
                    },
                    [_vm._v("\n        导出\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.userInfo.rightsGroupNames.includes("超级管理员")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.operateButton("batch_operation")
                        },
                      },
                    },
                    [_vm._v("\n        批量操作\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table_box" },
        [
          _c("TableList", {
            ref: "qrcodeTable",
            staticClass: "tableList",
            attrs: {
              "table-header": _vm.tableData.tableHeader,
              "is-selection": true,
              "row-key-name": "qrCodeId",
              "table-data": _vm.tableData.list,
              total: _vm.tableData.total,
              "custom-page-size": [10, 20, 30, 50, 100, 1000],
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event, "qrcodeTable")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "qrcodeTable")
              },
              delXuan: _vm.selectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "qrCodeSalePrice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.qrCodeSalePrice
                            ? "￥" + data.qrCodeSalePrice
                            : "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "qrCodeStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(_vm._s(data.qrCodeStatus == 1 ? "启用" : "禁用")),
                    ]),
                  ]
                },
              },
              {
                key: "claimStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [_c("span", [_vm._v(_vm._s(data.claimStatus))])]
                },
              },
              {
                key: "qrCodeUserNum",
                fn: function (ref) {
                  var data = ref.data
                  return [_c("span", [_vm._v(_vm._s(data.qrCodeUserNum || 0))])]
                },
              },
              {
                key: "regionName",
                fn: function (ref) {
                  var data = ref.data
                  return [_c("span", [_vm._v(_vm._s(data.regionName || "无"))])]
                },
              },
              {
                key: "districtName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [_vm._v(_vm._s(data.districtName || "无"))]),
                  ]
                },
              },
              {
                key: "agencyName",
                fn: function (ref) {
                  var data = ref.data
                  return [_c("span", [_vm._v(_vm._s(data.agencyName || "无"))])]
                },
              },
              {
                key: "merchantCode",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [_vm._v(_vm._s(data.merchantCode || "无"))]),
                  ]
                },
              },
              {
                key: "merchantName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [_vm._v(_vm._s(data.merchantName || "无"))]),
                  ]
                },
              },
              {
                key: "type",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    data.type === 1
                      ? _c("span", [_vm._v("商家二维码")])
                      : _vm._e(),
                    _vm._v(" "),
                    data.type === 2
                      ? _c("span", [_vm._v("经销品个人码")])
                      : _vm._e(),
                    _vm._v(" "),
                    data.type === 3
                      ? _c("span", [_vm._v("电商品个人码")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm.userInfo.rightsGroupNames.includes("超级管理员")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "operate_btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.operateButton("modify_qrcode", data)
                              },
                            },
                          },
                          [_vm._v("\n          二维码\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.operateButton("sales_records", data)
                          },
                        },
                      },
                      [_vm._v("销售记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.operateButton("member_list", data)
                          },
                        },
                      },
                      [_vm._v("会员列表")]
                    ),
                    _vm._v(" "),
                    _vm.userInfo.rightsGroupNames.includes("超级管理员")
                      ? [
                          _c(
                            "el-button",
                            {
                              staticClass: "operate_btn",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.operateMerchants(1, data)
                                },
                              },
                            },
                            [_vm._v("更换商家")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "operate_btn",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.operateButton(
                                    "operate_enable",
                                    data
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    data.qrCodeStatus == 1 ? "禁用" : "启用"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          data.claimStatus === "已领取"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "operate_btn",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRemark(data)
                                    },
                                  },
                                },
                                [_vm._v("修改备注")]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "二维码管理导出",
            visible: _vm.exportDialog,
            width: "28%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.exportQueryData,
                "label-width": "100px",
                size: "medium",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属区域" } },
                [
                  _vm.$public.isAllAreaPower()
                    ? _c("el-cascader", {
                        attrs: {
                          props: _vm.addressConfigure,
                          options: _vm.exportAddressList,
                          clearable: "",
                          placeholder: "请选择地址",
                        },
                        model: {
                          value: _vm.exportQueryData.areaId,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportQueryData, "areaId", $$v)
                          },
                          expression: "exportQueryData.areaId",
                        },
                      })
                    : _c("d-area", {
                        attrs: { values: _vm.exportQueryData.areaId },
                        on: {
                          "update:values": function ($event) {
                            return _vm.$set(
                              _vm.exportQueryData,
                              "areaId",
                              $event
                            )
                          },
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "领取状态" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.exportQueryData.claimStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportQueryData, "claimStatus", $$v)
                          },
                          expression: "exportQueryData.claimStatus",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "全部", value: 0 } }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "已领取", value: 1 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "未领取", value: 2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "状态" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.exportQueryData.qrCodeStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportQueryData, "qrCodeStatus", $$v)
                          },
                          expression: "exportQueryData.qrCodeStatus",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "全部", value: 0 } }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { label: "启用", value: 1 } }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { label: "禁用", value: 2 } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "text-align": "right",
                    "padding-bottom": "20px",
                    margin: "0",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportQrcode },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.exportDialog = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add_qrcode",
          attrs: {
            title:
              (!_vm.qrcode_isEdit
                ? "新增"
                : _vm.currentClickData.qrCodeSerial + " ") + "二维码",
            visible: _vm.addQrcode_dialog,
            width: "65%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addQrcode_dialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("addQrcode")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "qrCodeForm",
              attrs: {
                model: _vm.qrCodeForm,
                rules: _vm.qrCodeRules,
                size: "medium",
                "label-width": "140px",
              },
            },
            [
              _vm.qrcode_isEdit
                ? _c(
                    "el-form-item",
                    { staticStyle: { "margin-bottom": "0px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.operateButton("qrcode_preview")
                            },
                          },
                        },
                        [_vm._v("查看二维码")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: (!_vm.qrcode_isEdit ? "请选择" : "") + "商家",
                  },
                },
                [
                  !_vm.qrcode_isEdit
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.operateButton(
                                    "openSelect_merchants"
                                  )
                                },
                              },
                            },
                            [_vm._v("添加商家")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("TableList", {
                    attrs: {
                      "table-header": _vm.merchantsData.tableHeader,
                      "table-data": _vm.merchantsData.selectList,
                      "is-show-page": false,
                      "is-show-index": false,
                      "is-fix-height": false,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "指定路径", prop: "specifiedPath" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "url_select",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changePath },
                      model: {
                        value: _vm.qrCodeForm.specifiedPath,
                        callback: function ($$v) {
                          _vm.$set(_vm.qrCodeForm, "specifiedPath", $$v)
                        },
                        expression: "qrCodeForm.specifiedPath",
                      },
                    },
                    _vm._l(_vm.pathsOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.qrCodeForm.specifiedPath &&
                            _vm.qrCodeForm.specifiedPath != 1,
                          expression:
                            "qrCodeForm.specifiedPath && qrCodeForm.specifiedPath != 1",
                        },
                      ],
                      staticStyle: { "margin-top": "10px" },
                    },
                    [
                      _c("el-cascader", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.qrCodeForm.specifiedPath == 2,
                            expression: "qrCodeForm.specifiedPath == 2",
                          },
                        ],
                        ref: "productCategory_cascader",
                        staticClass: "url_select",
                        attrs: {
                          props: _vm.productTypeProps,
                          options: _vm.productTypeList,
                        },
                        on: { change: _vm.changeProductCategory },
                        model: {
                          value: _vm.productCategoryIdVal,
                          callback: function ($$v) {
                            _vm.productCategoryIdVal = $$v
                          },
                          expression: "productCategoryIdVal",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.qrCodeForm.specifiedPath == 3,
                              expression: "qrCodeForm.specifiedPath == 3",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.operateButton("openSelect_product")
                                },
                              },
                            },
                            [_vm._v("选择产品")]
                          ),
                          _vm._v(" "),
                          _c("TableList", {
                            attrs: {
                              "table-header": _vm.productData.tableHeader,
                              "table-data": _vm.productData.selectList,
                              "is-show-page": false,
                              "is-show-index": false,
                              "is-fix-height": false,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "productCategoryName",
                                fn: function (ref) {
                                  var data = ref.data
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        data.productCategoryName ||
                                          data.categoryName ||
                                          "-"
                                      )
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "productStatus",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                上架\n              "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.qrcode_isEdit,
                      expression: "!qrcode_isEdit",
                    },
                  ],
                  attrs: { label: "数量", prop: "createNum" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "350px" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "l-input": "",
                          placeholder: "生成二维码数量",
                          clearable: "",
                        },
                        model: {
                          value: _vm.qrCodeForm.createNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.qrCodeForm, "createNum", $$v)
                          },
                          expression: "qrCodeForm.createNum",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "指定扫码商家类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        disabled: _vm.merchantDisable,
                        placeholder: _vm.isAllMerchant ? "所有" : "请选择",
                      },
                      model: {
                        value: _vm.qrCodeForm.assignMerchantTypeList,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.qrCodeForm,
                            "assignMerchantTypeList",
                            $$v
                          )
                        },
                        expression: "qrCodeForm.assignMerchantTypeList",
                      },
                    },
                    _vm._l(_vm.merchantTypeData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.merchantType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addQrcode_dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "preview_qrcodeImg",
          attrs: {
            width: "400px",
            title: "查看二维码",
            visible: _vm.qrcodePreview_dialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.qrcodePreview_dialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "preview_box" }, [
            _c("img", {
              staticClass: "qrcode_img",
              attrs: { src: _vm.qrCodeDetailData.qrCodeUrl, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "preview_btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.operateButton("single_qrCode")
                    },
                  },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "select_merchants",
          attrs: {
            title: "选择商家",
            visible: _vm.merchantsData.dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.merchantsData, "dialog", $event)
            },
            close: function ($event) {
              return _vm.closeDialog("merchantsTable")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "search_box",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商家端名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商家端名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("merchantsTable")
                      },
                    },
                    model: {
                      value: _vm.merchantsQueryData.param.merchantName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.merchantsQueryData.param,
                          "merchantName",
                          $$v
                        )
                      },
                      expression: "merchantsQueryData.param.merchantName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务员姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "业务员姓名", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("merchantsTable")
                      },
                    },
                    model: {
                      value: _vm.merchantsQueryData.param.intendantName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.merchantsQueryData.param,
                          "intendantName",
                          $$v
                        )
                      },
                      expression: "merchantsQueryData.param.intendantName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateList("merchantsTable")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            ref: "merchantsTable",
            attrs: {
              height: "300px",
              loading: _vm.merchantsData.loading,
              "table-header": _vm.merchantsData.tableHeader,
              "table-data": _vm.merchantsData.list,
              total: _vm.merchantsData.total,
              "highlight-current-row": true,
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event, "merchantsTable")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "merchantsTable")
              },
              rowClick: function ($event) {
                return _vm.currentRow($event, "merchantsTable")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.merchantsData.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSelect("merchantsTable")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "select_merchants",
          attrs: {
            title: "选择产品",
            visible: _vm.productData.dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.productData, "dialog", $event)
            },
            close: function ($event) {
              return _vm.closeDialog("productTable")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "search_box",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "搜索产品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("productTable")
                      },
                    },
                    model: {
                      value: _vm.productQueryData.param.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.productQueryData.param, "productName", $$v)
                      },
                      expression: "productQueryData.param.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品类别" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择产品类别" },
                      model: {
                        value: _vm.productQueryData.param.productClassify,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.productQueryData.param,
                            "productClassify",
                            $$v
                          )
                        },
                        expression: "productQueryData.param.productClassify",
                      },
                    },
                    _vm._l(_vm.$api.orderClassify, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateList("productTable")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            ref: "productTable",
            attrs: {
              height: "300px",
              loading: _vm.productData.loading,
              "table-header": _vm.productData.tableHeader,
              "table-data": _vm.productData.list,
              total: _vm.productData.total,
              "highlight-current-row": true,
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event, "productTable")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "productTable")
              },
              rowClick: function ($event) {
                return _vm.currentRow($event, "productTable")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "productCategoryName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      _vm._s(
                        data.productCategoryName || data.categoryName || "-"
                      )
                    ),
                  ]
                },
              },
              {
                key: "productStatus",
                fn: function () {
                  return [_vm._v("\n        上架\n      ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.productData.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSelect("productTable")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.currentClickData.qrCodeSerial + " 销售记录",
            visible: _vm.salesRecordsData.dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.salesRecordsData, "dialog", $event)
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.salesRecordsData.loading,
              "table-header": _vm.salesRecordsData.tableHeader,
              "table-data": _vm.salesRecordsData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "userName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(data.memberNickName + " / " + data.recipient)
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderTotalPrice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.orderTotalPrice
                            ? "￥" + data.orderTotalPrice
                            : "￥0.00"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.EcommerceOrderState,
                            data.orderStatus,
                            "name"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.operateButton(
                              "salesRecords_detail",
                              data
                            )
                          },
                        },
                      },
                      [_vm._v("订单详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.currentClickData.qrCodeSerial + " 会员列表",
            visible: _vm.memberListData.dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.memberListData, "dialog", $event)
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.memberListData.loading,
              "table-header": _vm.memberListData.tableHeader,
              "table-data": _vm.memberListData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "cumulativeConsumption",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.cumulativeConsumption
                            ? "￥" + data.cumulativeConsumption
                            : "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.currentClickData.qrCodeSerial + " 优惠券列表",
            visible: _vm.couponListData.dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.couponListData, "dialog", $event)
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.couponListData.loading,
              "table-header": _vm.couponListData.tableHeader,
              "table-data": _vm.couponListData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "couponStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.couponStatus,
                            data.couponStatus,
                            "name"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "" + _vm.currentClickData.qrCodeSerial,
            visible: _vm.changeMerchant_dialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeMerchant_dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.qrCodeForm,
                size: "medium",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { "margin-bottom": "0px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.operateButton("qrcode_preview")
                        },
                      },
                    },
                    [_vm._v("查看二维码")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商家", required: "" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.operateButton("openSelect_merchants")
                            },
                          },
                        },
                        [_vm._v("更换商家")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("TableList", {
                    attrs: {
                      "table-header": _vm.merchantsData.tableHeader,
                      "table-data": _vm.merchantsData.selectList,
                      "is-show-page": false,
                      "is-show-index": false,
                      "is-fix-height": false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changeMerchant_dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改备注",
            visible: _vm.remark_dialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.remark_dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "remark_form",
              attrs: { model: _vm.remarkParams, size: "medium" },
            },
            [
              _c("el-form-item", { staticStyle: { "margin-bottom": "0px" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.remarkParams.remark,
                      expression: "remarkParams.remark",
                    },
                  ],
                  attrs: { maxlength: "50" },
                  domProps: { value: _vm.remarkParams.remark },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.remarkParams, "remark", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "remark_num" }, [
                  _vm._v(_vm._s(_vm.remarkParams.remark.length) + "/50"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.remark_dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.upDateRemark } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.merchantType_dialog,
            title: "批量操作",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.merchantType_dialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "merchantTypeForm",
                  attrs: {
                    model: _vm.merchantTypeForm,
                    "label-width": "200px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "更改指定扫码商家类型名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.merchantTypeForm.assignMerchantType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.merchantTypeForm,
                                "assignMerchantType",
                                $$v
                              )
                            },
                            expression: "merchantTypeForm.assignMerchantType",
                          },
                        },
                        _vm._l(_vm.merchantTypeData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.merchantType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.merchantType_dialog = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitMerchantClick },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }