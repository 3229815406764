<template>
  <div class="box">
    <div class="header">
      <span>营养师信息</span>
    </div>
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="border-section"></div>
    <el-form ref="submitForm" label-width="160px" :model="formData" :rules="rules">
      <el-form-item label="营养师照片" :required="true">
        <d-upload
          upload-type="picture-card"
          :file-list="dietitianList"
          :limit="1"
          accept=".gif,.jpeg,.jpg,.png"
          :disabled="true"
        >
          <div class="tip-box">
            <i class="el-icon-plus"></i>
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <el-form-item label="营养师名称" prop="dietitianName">
        <el-input v-model="formData.dietitianName" placeholder="请输入营养师名称" disabled clearable style="width: 240px;" />
      </el-form-item>
      <el-form-item label="机构名称">
        <el-input v-model="formData.dietitianOrganization" placeholder="请输入医院/机构/企业名称" disabled clearable style="width: 240px;" />
      </el-form-item>
      <el-form-item label="所属地区" prop="regionId">
        <el-cascader v-model="formData.regionId" :options="addressList" :props="props_List" disabled clearable placeholder="请选择地址" style="width: 240px" />
      </el-form-item>
      <el-form-item label="手机号码" prop="phoneNumber">
        <el-input v-model="formData.phoneNumber" placeholder="请输入手机号码" maxlength="11" disabled clearable style="width: 240px;" />
      </el-form-item>

      <div class="header">
        <span>身份证信息</span>
      </div>
      <div class="border-section"></div>
      <el-form-item label="身份证正反面照片" :required="true">
        <d-upload
          upload-type="picture-card"
          :file-list="idCardList"
          :limit="2"
          accept=".gif,.jpeg,.jpg,.png"
          :disabled="true"
        >
          <div class="tip-box">
            <i class="el-icon-plus"></i>
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <el-form-item label="手持身份证照片" :required="true">
        <d-upload
          upload-type="picture-card"
          :file-list="idHandList"
          :limit="1"
          accept=".gif,.jpeg,.jpg,.png"
          :disabled="true"
        >
          <div class="tip-box">
            <i class="el-icon-plus"></i>
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <div class="header">
        <span>资格认证</span>
      </div>
      <div class="border-section"></div>
      <el-form-item label="认证证书" :required="true">
        <d-upload
          upload-type="picture-card"
          :file-list="certificateList"
          :limit="1"
          accept=".gif,.jpeg,.jpg,.png"
          :disabled="true"
        >
          <div class="tip-box">
            <i class="el-icon-plus"></i>
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dUpload from '@/components/d-upload/index'
export default {
  components: {
    dUpload
  },
  data() {
    return {
      routerData: {},
      formData: {
        id: '',
        dietitianCode: '',
        dietitianPicture: '',
        dietitianName: '',
        dietitianOrganization: '',
        regionId: '',
        phoneNumber: '',
        idCardFrontUrl: '',
        idCardBackUrl: '',
        idCardHandUrl: '',
        certificateUrl: '',
        addressId: ''
      },
      // 是否添加
      isAdd: false,

      dietitianList: [], // 营养师照片
      idCardList: [], // 身份证照片
      idHandList: [], // 手持身份证照片
      certificateList: [], // 认证证书

      // 所属地区
      addressList: [],
      props_List: {
        label: 'addressName',
        value: 'regionId',
        children: 'child',
        emitPath: false
      },

      rules: {
        dietitianName: [{ required: true, message: '请输入营养师名称', trigger: 'blur' }],
        regionId: [{ required: true, message: '请选择所属地区', trigger: 'change' }],
        phoneNumber: [{ required: true, message: '请输入手机号码', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.routerData = this.$route.params.queryFrom
    this.gerAddress()
    if (!this.isAdd) {
      this.getDietitianRecord()
    }
  },
  methods: {
    // 获取营养师信息
    getDietitianRecord() {
      this.$http.requestGet({ url: '/consult/dietitianRecord/' + this.routerData.dietitianCode }).then(res => {
        console.log('res===>>>>', res)
        if (res.code === 200) {
          const data = res.data || {}
          this.dietitianList = (data.dietitianPicture && [{ url: data.dietitianPicture }]) || []
          if (!data.idCardFrontUrl && !data.idCardBackUrl) {
            this.idCardList = []
          } else {
            if (!data.idCardBackUrl) {
              this.idCardList = [{ url: data.idCardFrontUrl }]
            } else {
              this.idCardList = [
                { url: data.idCardFrontUrl }, { url: data.idCardBackUrl }
              ]
            }
          }
          this.idHandList = (data.idCardHandUrl && [{ url: data.idCardHandUrl }]) || []
          this.certificateList = (data.certificateUrl && [{ url: data.certificateUrl }]) || []

          Object.keys(this.formData).forEach((keys1, index) => {
            Object.keys(data).forEach((keys2, index2) => {
              if (keys1 === keys2) {
                this.formData[keys1] = data[keys1]
                if (keys1 === 'dietitianOrganization' && !data[keys1]) {
                  this.formData[keys1] = '无'
                }
              }
            })
          })
        }
      })
    },
    // 所属地区
    gerAddress() {
      this.$http.requestGet({ url: '/common/address/getDietitianAllWithTree', loading: false }).then(res => {
        this.addressList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  background-color: #fff;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.border-section {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.tip-box {
  position: relative;
  .tip {
    position: absolute;
    top: 26px;
    left: 34%;
    font-size: 12px;
    color: #ccc;
  }
}
</style>
