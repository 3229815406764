var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_2 bg_white flex-box" }, [
        _c("div", { staticClass: "row_left" }, [
          _c("h4", [_vm._v("配送信息")]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "配送方式：" +
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.deliveryMethod,
                    _vm.pageData.deliveryMethod,
                    "name"
                  )
                )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("收货人姓名：" + _vm._s(_vm.pageData.recipient)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "收货人手机号：" + _vm._s(_vm.pageData.recipientPhoneNumber)
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "color_light-black" },
            [
              _vm._v("\n        收货人地址：\n        "),
              _c("span", [_vm._v(_vm._s(_vm.pageData.recipientAddressDetail))]),
              _vm._v(" "),
              _vm.pageData.orderStatus === "pending_payment" ||
              _vm.pageData.orderStatus === "pending_ship"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible_3 = true
                        },
                      },
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticStyle: { height: "24px" } }),
          _vm._v(" "),
          _c("div", { staticClass: "color_light-black flex-box" }, [
            _c("span", { staticClass: "row-title" }, [_vm._v("购物凭证：")]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageUrl,
                    expression: "imageUrl",
                  },
                ],
              },
              [
                _c(
                  "d-upload",
                  {
                    attrs: {
                      "upload-type": "picture-card",
                      "file-list": _vm.fileList,
                      accept: ".gif,.jpeg,.jpg,.png",
                    },
                  },
                  [
                    _c("div", { staticClass: "tip-box" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.imageUrl,
                    expression: "!imageUrl",
                  },
                ],
              },
              [_vm._v("无")]
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("备注信息：" + _vm._s(_vm.pageData.orderRemark || "无")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row_right" }, [
          _c("h4", [
            _vm._v("\n        订单状态："),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.EcommerceOrderState,
                    _vm.pageData.orderStatus,
                    "name"
                  )
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.pageData.orderStatus == "pending_payment"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "medium" },
                      on: { click: _vm.cancelOrder },
                    },
                    [_vm._v("取消订单")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_3 bg_white" }, [
        _c("h4", [_vm._v("商品信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.$index + 1))]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "flex-box" }, [
                            scope.row.productImg
                              ? _c("img", {
                                  staticClass: "img-box",
                                  attrs: { src: scope.row.productImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "hospital-name" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "txt_box",
                                  class: {
                                    "flex-box": !scope.row.productImg,
                                    "flex-center": !scope.row.productImg,
                                  },
                                },
                                [
                                  scope.row.isGifts
                                    ? _c("img", {
                                        staticClass: "icon_box",
                                        class: {
                                          no_img: !scope.row.productImg,
                                        },
                                        attrs: {
                                          src: require("@/assets/image/gift_icon.png"),
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(scope.row.productName) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + scope.row.productPrice || 0) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "数量", "min-width": "100", prop: "itemNum" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "小计",
                    "min-width": "100",
                    prop: "itemAmount",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + (scope.row.itemAmount || 0)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.couponDiscountAmount
                                  ? "￥" + scope.row.couponDiscountAmount
                                  : 0
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "实收金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "￥" + (scope.row.paidInAmountCoupon || 0)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "show-money" }, [
          _c("ul", [
            _c("li", [
              _vm._v("商品金额："),
              _c("span", { staticClass: "block_span" }, [
                _vm._v("￥" + _vm._s(_vm.pageData.orderTotalPrice || 0)),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("运费："),
              _c("span", { staticClass: "block_span" }, [
                _vm._v("+￥" + _vm._s(_vm.pageData.shippingFee || 0)),
              ]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "line-box" }),
            _vm._v(" "),
            _c("li", { staticClass: "bold" }, [
              _vm._v("\n          实付总额："),
              _c("span", { staticClass: "block_span color_red" }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm.pageData.paidInAmount ||
                        _vm.pageData.orderTotalPrice ||
                        0
                    )
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.pageData.returnAmount
              ? _c("li", { staticClass: "bold" }, [
                  _vm._v("\n          退款总额："),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("￥" + _vm._s(_vm.pageData.returnAmount)),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("订单信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单类型：")]),
          _vm._v(
            _vm._s(
              _vm.$api.getValue(
                _vm.$api.orderClassify,
                _vm.pageData.orderClassify,
                "name"
              )
            ) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单编号：")]),
          _vm._v(_vm._s(_vm.pageData.orderCode)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("下单时间：")]),
          _vm._v(_vm._s(_vm.pageData.orderTime)),
        ]),
        _vm._v(" "),
        _vm.pageData.orderStatus !== "pending_payment" &&
        _vm.pageData.orderStatus !== "completed" &&
        _vm.pageData.orderStatus !== "cancel"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("付款时间：")]),
              _vm._v(_vm._s(_vm.pageData.paymentTime)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.orderStatus === "pending_receipt" ||
        _vm.pageData.orderStatus === "pending_evaluate"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("发货时间：")]),
              _vm._v(_vm._s(_vm.pageData.shipTime)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.orderStatus === "pending_evaluate"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [
                _vm._v("确认收货时间："),
              ]),
              _vm._v(_vm._s(_vm.pageData.receiptTime)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.pageData.orderStatus != "pending_payment"
        ? _c(
            "div",
            { staticClass: "row_5 row_4 bg_white" },
            [
              _c("h4", [_vm._v("出库批次信息")]),
              _vm._v(" "),
              _vm._l(_vm.pageData.items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { "margin-bottom": "10px" } },
                  [
                    item.batch.length >= 1
                      ? _c(
                          "div",
                          [
                            _c("div", [
                              _vm._v("商品名称：" + _vm._s(item.productName)),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              item.batch,
                              function (batchItem, batchIndex) {
                                return _c("div", { key: batchIndex }, [
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("批次号："),
                                      ]),
                                      _vm._v(" " + _vm._s(batchItem.batchNo)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("发货数量："),
                                      ]),
                                      _vm._v(_vm._s(batchItem.deliverCount)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("有效期："),
                                      ]),
                                      _vm._v(
                                        _vm._s(batchItem.produceTime) +
                                          " ~ " +
                                          _vm._s(batchItem.expireTime)
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pageData.orderStatus != "pending_payment" &&
      _vm.pageData.orderStatus == "completed"
        ? _c(
            "div",
            { staticClass: "row_5 row_4 bg_white" },
            [
              _c("h4", [_vm._v("退货批次信息")]),
              _vm._v(" "),
              _vm._l(_vm.pageData.items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { "margin-bottom": "10px" } },
                  [
                    item.returnBatch.length >= 1
                      ? _c(
                          "div",
                          [
                            _c("div", [
                              _vm._v("商品名称：" + _vm._s(item.productName)),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              item.returnBatch,
                              function (batchItem, batchIndex) {
                                return _c("div", { key: batchIndex }, [
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("批次号："),
                                      ]),
                                      _vm._v(" " + _vm._s(batchItem.batchNo)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("发货数量："),
                                      ]),
                                      _vm._v(_vm._s(batchItem.deliverCount)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "color_light-black" },
                                    [
                                      _c("span", { staticClass: "title-box" }, [
                                        _vm._v("有效期："),
                                      ]),
                                      _vm._v(
                                        _vm._s(batchItem.produceTime) +
                                          " ~ " +
                                          _vm._s(batchItem.expireTime)
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("电商品业务员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [
            _vm._v("电商品业务员姓名："),
          ]),
          _vm._v(_vm._s(_vm.pageData.electronicBindingName || "无")),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [
            _vm._v("电商品二维码编号："),
          ]),
          _vm._v(_vm._s(_vm.pageData.electronicQrCodeSerial || "无")),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_6 row_4 bg_white" }, [
        _c("h4", [_vm._v("优惠信息")]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "color_light-black",
            staticStyle: { margin: "0 0 10px 50px" },
          },
          [
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v("优惠券名称：" + _vm._s(_vm.pageData.couponName || "-")),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v(
                "\n        优惠券ID：" +
                  _vm._s(
                    _vm.pageData.customerCoupon &&
                      _vm.pageData.customerCoupon.couponCode
                      ? _vm.pageData.customerCoupon.couponCode
                      : "-"
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v(
                "会员等级：" +
                  _vm._s(_vm.$public.getVipLevel(_vm.pageData.vipLevel))
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.pageData.couponIntendantVo
          ? _c(
              "p",
              {
                staticClass: "color_light-black",
                staticStyle: { margin: "0 0 10px 50px" },
              },
              [
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "业务员编号：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.intendantCode || "无"
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "业务员姓名：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.intendantName || "无"
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "二维码编号：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.qrCodeSerial || "无"
                      )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: true },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.$index + 1))]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "flex-box",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              scope.row.productImg
                                ? _c("img", {
                                    staticClass: "img-box",
                                    attrs: {
                                      src: scope.row.productImg,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v(_vm._s(scope.row.productName))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    "min-width": "100",
                    prop: "productNorms",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + scope.row.productPrice) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "数量", "min-width": "100", prop: "itemNum" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "优惠方式",
                    "min-width": "100",
                    prop: "couponMode",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.couponMode || "-") +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.amountCouponInformation
                                  ? "￥" + scope.row.amountCouponInformation
                                  : 0
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠价", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.priceCouponInformation
                                  ? "￥" + scope.row.priceCouponInformation
                                  : 0
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_1,
            title: "修改价格",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData_1, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", prop: "date", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "产品信息",
                          "min-width": "120",
                          prop: "available",
                        },
                      },
                      [void 0],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "单价",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "小计",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "加价/减价",
                          "min-width": "120",
                          prop: "available",
                        },
                      },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "flex-box flex-center" },
                            [
                              _vm._v("\n                ￥\n                "),
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.tableData_1.input,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableData_1, "input", $$v)
                                  },
                                  expression: "tableData_1.input",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "实收金额",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "show-money" }, [
              _c("ul", [
                _c("li", [
                  _vm._v("商品金额："),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("￥200.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("运费："),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("+￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n            加价/减价："),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("-￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "line-box" }),
                _vm._v(" "),
                _c("li", { staticClass: "bold" }, [
                  _vm._v("\n            实付总额："),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("￥180.00"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_2,
            title: "发货",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "订单编号" },
                        model: {
                          value: _vm.submitForm.orderCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "orderCode", $$v)
                          },
                          expression: "submitForm.orderCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流公司", prop: "logisticsCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.submitForm.logisticsCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "logisticsCompany", $$v)
                            },
                            expression: "submitForm.logisticsCompany",
                          },
                        },
                        _vm._l(_vm.logisticsCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流单号", prop: "logisticsCode" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", placeholder: "物流单号" },
                        model: {
                          value: _vm.submitForm.logisticsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "logisticsCode", $$v)
                          },
                          expression: "submitForm.logisticsCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSendOn },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_3,
            "before-close": _vm.closeAddresCPM,
            title: "修改收货地址",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "changeAddressForm",
                  attrs: {
                    model: _vm.changeAddressForm,
                    rules: _vm.changeAddressRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地址", prop: "addressId" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          props: _vm.props_add,
                          options: _vm.addressList_add,
                          placeholder: "请选项地址",
                        },
                        model: {
                          value: _vm.changeAddressForm.addressId,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeAddressForm, "addressId", $$v)
                          },
                          expression: "changeAddressForm.addressId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址详情", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "150",
                          placeholder: "地址详情",
                        },
                        model: {
                          value: _vm.changeAddressForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeAddressForm, "address", $$v)
                          },
                          expression: "changeAddressForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeAddresCPM } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.amendAddress } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }