<template>
  <!-- 产品退货单管理-订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <div class="steps-box bg_white">
      <h4>申购退还状态：{{ $api.getValue($api.purchaseRequisitionReturnStatus, pageData.purchaseStatus, 'name') }}</h4>
      <h4 v-if="pageData.purchaseStatus === 'unaudited'" style="margin-top: 24px;">驳回原因：{{ pageData.rejectReason || '无' }}</h4>
    </div>

    <div class="row_3 bg_white">
      <h4>退还商品</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="200" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">
                  <div class="txt_box" :class="{ 'flex-box': !scope.row.productImg, 'flex-center': !scope.row.productImg }">
                    <img
                      v-if="scope.row.isGifts"
                      src="@/assets/image/gift_icon.png"
                      alt=""
                      class="icon_box"
                      :class="{ no_img: !scope.row.productImg }"
                    />
                    {{ scope.row.productName }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="批次号" min-width="100" prop="batchNo">
            <template slot-scope="scope">
              {{ scope.row.batchNo }}
            </template>
          </el-table-column>
          <el-table-column label="发货数量" min-width="100" prop="productNum">
            <template slot-scope="scope">
              {{ scope.row.productNum }}
            </template>
          </el-table-column>
          <el-table-column label="有效期" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.produceTime }}&nbsp;{{ '~' }}&nbsp;{{ scope.row.expireTime }}
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="itemPrice">
            <template slot-scope="scope">
              {{ '￥' + scope.row.itemPrice }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="小计" min-width="100" prop="">
            <template slot-scope="scope">
              {{ '￥' + (scope.row.itemPrice * scope.row.itemNum).toFixed(2) || '0' }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥ {{ goodsMoney.toFixed(2) || '0.00' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_4 bg_white">
      <h4>商家/医院信息</h4>
      <p class="color_light-black">
        <span class="title-box">商家名称：</span>
        {{ pageData.merchantName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家编号：</span>
        {{ pageData.merchantCode }}
      </p>
    </div>
    <!-- <div class="row_5 row_4 bg_white">
      <h4>批次信息</h4>
      <div v-for="(v, vIindex) in pageData.items" :key="vIindex">
        <div v-if="v.batchInfos">
          <div style="margin-left:10px; margin-top: 10px;">
            {{ v.productName }}
          </div>
          <div v-for="(item, index) in v.batchInfos" :key="index">
            <p class="color_light-black">
              <span class="title-box">批次号：</span> {{ item.batchNo }}
            </p>
            <p class="color_light-black">
              <span class="title-box">发货数量：</span>{{ item.productNum }}
            </p>
            <p class="color_light-black">
              <span class="title-box">有效期：</span>{{ item.produceTime }} ~
              {{ item.expireTime }}
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- v4.4.5新增物流轨迹 -->
    <div v-if="showBtn(pageData.purchaseStatus)" class="row_6 row_4 bg_white">
      <h4>物流信息</h4>
      <p class="color_light-black">
        <span class="title-box">物流公司：</span>
        {{ pageData.logisticsCompany }}
      </p>
      <p class="color_light-black">
        <span class="title-box">物流单号：</span>
        {{ pageData.logisticsCode }}
      </p>
      <p class="color_light-blue">
        <span class="title" @click="handleLogistics(pageData)">查看物流</span>
      </p>
    </div>

    <div class="row_5 row_4 bg_white">
      <h4>退货单信息</h4>
      <p class="color_light-black">
        <span class="title-box">退货单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">申购时间：</span>
        {{ pageData.purchaseTime }}
      </p>
      <p v-if="pageData.purchaseStatus !== 'auditing'" class="color_light-black">
        <span class="title-box">审核时间：</span>
        {{ pageData.auditTime }}
      </p>
      <p v-if="pageData.purchaseStatus !== 'auditing'" class="color_light-black">
        <span class="title-box">审核人：</span>
        {{ pageData.auditByName }}
      </p>
      <p v-if="pageData.purchaseStatus == 'completed' || pageData.purchaseStatus == 'returned'" class="color_light-black">
        <span class="title-box">物流单号：</span>
        {{ pageData.logisticsCode }}
      </p>

      <p v-if="pageData.purchaseStatus == 'returned'" class="color_light-black">
        <span class="title-box">退还时间：</span>
        {{ pageData.purchaseTime }}
      </p>
      <p v-if="pageData.purchaseStatus === 'unaudited'" class="color_light-black">
        <span class="title-box">驳回原因：</span>
        {{ pageData.rejectReason || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">备注：</span>
        {{ pageData.remark || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">退还理由：</span>
        {{ formatReturn(pageData.reasonForReturn) }}
      </p>
      <p class="color_light-black">
        <span class="title-box">请示报告号：</span>
        {{ pageData.reportNumber || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">调货去向：</span>
        {{ pageData.receiveMerchantName || '无' }}
      </p>
    </div>

    <div v-if="pageData.purchaseStatus === 'completed'" class="row_5 row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black">
        <span class="title-box">申请时间：</span>
        {{ pageData.purchaseTime }}
      </p>
      <p class="color_light-black">
        <span class="title-box">审核时间：</span>
        {{ pageData.auditTime }}
      </p>
      <p class="color_light-black">
        <span class="title-box">签收时间：</span>
        {{ pageData.signTime }}
      </p>
      <p class="color_light-black">
        <span class="title-box">退货单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">退还原因：</span>
        {{ pageData.rejectReason }}
      </p>
    </div>

    <div v-if="pageData.purchaseStatus === 'auditing'" class="row_6 row_4 bg_white">
      <div class="flex-box">
        <el-button type="primary" @click="pass">通过</el-button>
        <el-button @click="reject">驳回</el-button>
      </div>
    </div>

    <div v-if="pageData.purchaseStatus === 'returned' && pageData.reasonForReturn !== 2" class="row_6 row_4 bg_white">
      <div class="flex-box">
        <el-button type="primary" @click="receiptOfGoods">确认收货</el-button>
      </div>
    </div>

    <el-dialog :before-close="close" :visible.sync="dialogVisible_1" title="驳回原因" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item label="驳回原因" prop="rejectReason">
            <el-input v-model="submitForm.rejectReason" placeholder="请输入" rows="6" type="textarea" maxlength="150" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- v4.4.5物流信息弹出框 -->
<!--    <d-dialog ref="logisticsDialog" :logistics-list="logisticsList" :data-obj="dataObj" :route-info="routeInfo" @handleClose="handleClose" />-->
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth.js'
import DDialog from '@/components/d-dialog'
export default {
  components: {
    DDialog
  },
  data() {
    return {
      active: 0,
      userInfo: {},
      // 申购单类型
      orderType: ['待审核', '审核已通过', '待收货', '审核未通过', '已完成'],
      Id_: null, // 主键id
      pageData: {}, // 页面数据
      goodsMoney: 0,

      // 申购信息
      tableData: [],
      logisticsList: [], // 物流信息
      // 审核
      dialogVisible_1: false, // 审核弹窗
      dialogVisible_2: false, // 物流轨迹弹框
      submitForm: {
        rejectReason: ''
      },
      submitRules: {
        rejectReason: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }]
      },
      logisticsCompanyList: [], // 物流公司列表
      auditState: 1 // 1 通过， 2 驳回
    }
  },
  created() {
    this.Id_ = this.$route.query.id
    // 判断当前订单状态
    // this.active = parseInt(this.$route.query.type) - 1;

    this.userInfo = getSessionStorage('userInfo')

    this.getPageData()
    this.getLogisticsCompany()
  },
  mounted() {},
  methods: {
    // 按钮显示
    showBtn(purchaseStatus) {
      if (purchaseStatus == 'returned') {
        return true
      }
      if (purchaseStatus == 'completed') {
        return true
      } else {
        return false
      }
    },
    handleClose() {
      this.$refs.logisticsDialog.showDialog = false
    },
    formatReturn(val) {
      const MAP = {
        1: '售后',
        2: '调货至商家',
        3: '转样品',
        4: '退还至总部',
        5: '差异调整'
      }
      return MAP[val]
    },
    // 查看物流
    async handleLogistics(row) {
      try {
        //    发起查看物理轨迹请求
        await this.getReturnPostInfo(row.orderCode)
        console.info('查看物流', row)
      } catch (error) {}
    },
    // 查询物流轨迹
    async getReturnPostInfo(code) {
      try {
        const res = await this.$http.requestGet({
          url: `/customer/purchase/order/return/postInfo/${code}`,
          loading: false
        })
        if (!res.data || (res.code == 200 && res.data.status != 200)) {
          this.$message({
            message: '查无结果，请检查单号和快递公司是否有误',
            type: 'warning',
            duration: 3000
          })
        } else {
          console.log('res.data===', res.data)
          this.dataObj = res.data
          this.routeInfo = res.data.routeInfo
          this.logisticsList = res.data.data
          this.$refs.logisticsDialog.showDialog = true
        }
      } catch (error) {}
    },
    // 获取物流公司列表
    getLogisticsCompany() {
      this.$http.requestPost({ url: '/system/logistics/company/list' }).then(res => {
        this.logisticsCompanyList = res.data
      })
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.label === '序号' || column.label === '产品信息' || column.label === '单价' || column.label === '数量' || column.label === '小计') {
        if (rowIndex % 1 === 0) {
          return {
            rowspan: 1,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },

    // 获取页面数据
    getPageData() {
      const arr = []
      this.$http.requestGet({ url: '/customer/return/order/get/' + this.Id_ }).then(res => {
        this.pageData = res.data
        const money = this.pageData.items.reduce((num, cur) => {
          return (num += cur.itemPrice * cur.itemNum)
        }, 0)
        this.goodsMoney = money
        this.pageData.items.filter((item1, index1) => {
          this.pageData.batchInfos.map((item2, index2) => {
            if (item2.itemId === item1.itemId) {
              const dataNew = JSON.parse(JSON.stringify(item1))
              // dataNew.productImg = item2.productImg || '';
              dataNew.batchNo = item2.batchNo || ''
              dataNew.expireTime = item2.expireTime || ''
              dataNew.produceTime = item2.produceTime || ''
              dataNew.productNum = item2.productNum || ''
              dataNew.itemNum = item2.productNum || ''
              arr.push(dataNew)
            }
          })
        })
        this.pageData.items = arr
      })
    },

    // 通过
    pass() {
      this.$confirm('是否确认通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.auditState = 1
          this.submitAudit()
        })
        .catch(() => {})
    },
    // 驳回
    reject() {
      this.auditState = 2
      this.dialogVisible_1 = true
    },
    // 审核驳回确定
    confirm() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          this.submitAudit()
        } else {
          return false
        }
      })
    },
    submitAudit() {
      var url = '/customer/return/order/pass/'
      var param = {
        managerId: this.userInfo.managerId,
        managerName: this.userInfo.managerName
      }
      if (this.auditState === 2) {
        // 驳回
        url = '/customer/return/order/reject/'
        param = {
          rejectReason: this.submitForm.rejectReason,
          managerId: this.userInfo.managerId,
          managerName: this.userInfo.managerName
        }
      }
      this.$http.requestPut({ url: url + this.Id_, param }).then(res => {
        this.$message.success('操作成功')
        this.$router.go(-1)
      })
    },
    // 关闭弹窗
    close() {
      this.$refs.submitForm.resetFields()
      this.dialogVisible_1 = false
    },

    // 确认收货
    receiptOfGoods() {
      this.$confirm('是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/customer/return/order/confirm/receipt/' + this.pageData.orderCode
            })
            .then(res => {
              this.$message.success('操作成功')
              this.getPageData()
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  & >>> .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }
  .color_light-blue {
    margin-top: 10px;
    .title {
      font-size: 16px;
      color: #4f96e6;
      width: 88px;
      display: inline-block;
      text-align: right;
      cursor: pointer;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }

    .show-money {
      padding: 10px 0;

      ul {
        li {
          display: flex;
          justify-content: flex-end;
          line-height: 30px;
          font-size: 16px;
        }

        .line-box {
          margin-left: 70%;
          padding-top: 20px;
          border-bottom: 1px solid #ccc;
        }

        .bold {
          font-weight: bolder;
        }
      }
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }

  .row_6 {
    .flex-box {
      justify-content: center;

      button {
        padding: 12px 34px;
      }
    }
  }
}

.title-box {
  display: inline-block;
  width: 100px;
  text-align: right;
}

/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img {
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
