<template>
  <!-- 人员列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium" label-suffix=":">
        <el-form-item label="人员姓名">
          <div class="flex-box">
            <el-input v-model="inquire.memberNickname" placeholder="输入人员姓名" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item label="手机号">
          <div class="flex-box">
            <el-input v-model="inquire.memberTelephone" placeholder="输入手机号" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item label="员工工号">
          <div class="flex-box">
            <el-input v-model="inquire.qyUserId" placeholder="输入员工工号" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item label="所属分组">
          <div class="flex-box">
            <el-select v-model="inquire.talentCategoryId" placeholder="请选择所属分组" class="width-180" clearable>
              <el-option v-for="item in groupManagementList" :key="item.id" :label="item.categoryName"
                         :value="item.id"
              />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="会员编号">
          <div class="flex-box">
            <el-input v-model="inquire.memberCode" placeholder="输入会员编号" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker v-model="inquire.times" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
            />
          </div>
        </el-form-item>
        <el-form-item label="二维码编号">
          <div class="flex-box">
            <!--qrCodeSerial可以筛选出睿长太和电商品二维码编号-->
            <el-input v-model="inquire.qrCodeSerial" placeholder="输入二维码编号" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="handleAddPerson">
        新增人员
      </el-button>
      <el-button size="small" type="primary" @click="uploadDialogVisible = true">
        导入
      </el-button>
      <el-button size="small" type="primary" @click="exportPersonsRecords">
        导出
      </el-button>
      <el-button size="small" type="primary" @click="createQrCode">
        生成二维码（{{ selectionList.length || 0 }}）
      </el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table ref="personListTable" v-loading="loading" :data="tableData" border height="400px"
                    style="width: 100%" row-key="memberId"
                    @selection-change="handlePersonSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed :reserve-selection="true" :selectable="isSelectAbleRow"/>
            <el-table-column prop="date" label="序号" width="60" fixed>
              <template slot-scope="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="memberNickname" label="人员姓名" min-width="200" fixed show-overflow-tooltip/>
            <el-table-column prop="memberTelephone" label="手机号" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="qyUserId" label="员工工号" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="memberCode" label="会员编号" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="categoryName" label="所属分组" min-width="150" show-overflow-tooltip/>
            <el-table-column label="所属大区" min-width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                <span>{{ row.regionName || '无' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属片区" min-width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                <span>{{ row.districtName || '无' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属办事处" min-width="150" show-overflow-tooltip>
              <template v-slot="{row}">
                <span>{{ row.agencyName || '无' }}</span>
              </template>
            </el-table-column>>
            <!--categoryCreateTime = 增加时间记录人员添加时间，而非会员注册时间-->
            <el-table-column prop="categoryCreateTime" label="增加时间" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="qrCodeSerial" label="睿长太二维码编号" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="electronicQrCodeSerial" label="电商品二维码编号" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="" label="操作" min-width="260" fixed="right">
              <template v-slot="{row}">
                <span class="operation-btn" @click="handleEdit(row)">编辑</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleDeletePerson(row)">删除</span>
                <template v-if="handleIsEmployee(row)">
                  <span class="operation-btn_partition">/</span>
                  <span class="operation-btn" @click="createRowQrCode(row)">
                    生成二维码
                  </span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes"
                         :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper"
                         :total="pageParam.total"
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加/编辑人员弹框 -->
    <EditPersonnelDialog v-model="personDialogVisible" :group-management-list="groupManagementList"
                         :edit-form="editPersonRow" @edit-done="searchList"
    />

    <!-- 导入/模板 -->
    <UploadBatchPersonDialog v-model="uploadDialogVisible" @edit-done="searchList"/>

  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import {
  getTalentCategoryList,
  getTalentPersonnelList,
  exportPersonTalentList,
  saveOrUpdateTalentItem,
  createExclusiveQrCodeIds
} from '@/api/user'
import EditPersonnelDialog from '@/views/user/talentPool/components/editPersonnelDialog.vue'
import UploadBatchPersonDialog from '@/views/user/talentPool/components/uploadBatchPersonDialog.vue'

export default {
  components: { EditPersonnelDialog, UploadBatchPersonDialog },
  data() {
    return {
      // ==================== 列表 ====================
      inquire: {}, // 查询
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      tableData: [], // 表格数据
      selectionList: [],
      userInfo: {},
      groupManagementList: [], // 分组管理

      // ==================== 新增人员 ====================
      personDialogTitle: '新增人员',
      personDialogVisible: false,
      editPersonRow: {},

      // ==================== 批量导入 ====================
      uploadDialogVisible: false
    }
  },
  computed: {},
  async created() {
    this.userInfo = getSessionStorage('userInfo')
    // 获取分组管理列表
    await this.getGroupManagementList()
    // 获取人员列表
    await this.getPersonList()
  },
  methods: {
    // 获取筛选参数
    getFilterParam({ hasPage = true }) {
      const param = {
        ...this.inquire
      }
      if (hasPage) {
        param.pageNum = this.pageParam.pageNum
        param.pageSize = this.pageParam.pageSize
      }
      if (this.inquire.times && this.inquire.times.length > 0) {
        param.createTimeBegin = this.inquire.times[0]
        param.createTimeEnd = this.inquire.times[1]
        delete param.times
      }
      return param
    },

    // 设置列是否可选
    isSelectAbleRow(row) {
      return this.handleIsEmployee(row)
    },
    handleIsEmployee(row) {
      // 不是员工且不存在睿长太二维码且不存在电商品二维码才不可选 true=不可选
      const ctName = row.categoryName.split('/') || []
      return !row.qrCodeSerial && !row.electronicQrCodeSerial && ctName.includes('员工')
    },
    handlePersonSelectionChange(sList) {
      this.selectionList = sList
    },

    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getPersonList()
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getPersonList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getPersonList()
    },

    // 获取分组管理列表
    async getGroupManagementList() {
      const params = {
        pageNum: 1,
        pageSize: 200
      }
      const res = await getTalentCategoryList(params)
      if (!res || res.data === false) {
        this.$message.error(res.data.message)
        return
      }
      this.groupManagementList = res.data.list || []
      // 默认选中'员工'
      if (!this.inquire.talentCategoryId) {
        const item = this.groupManagementList.find(item => item.categoryName === '员工')
        item ? this.$set(this.inquire, 'talentCategoryId', item.id) : ''
      }
    },

    // 获取数据列表
    async getPersonList() {
      const data = this.getFilterParam({ hasPage: true })
      this.loading = true
      const res = await getTalentPersonnelList(data)
      this.loading = false
      this.tableData = res.data.list
      this.pageParam.total = res.data.total
    },

    // 添加人员
    handleAddPerson() {
      this.personDialogTitle = '新增人员'
      this.personDialogVisible = true
      this.editPersonRow = {}
    },
    // 修改人员
    handleEdit(row) {
      this.personDialogTitle = '编辑人员'
      this.editPersonRow = { ...row }
      this.personDialogVisible = true
    },
    // 生成二维码
    createQrCode() {
      if (this.selectionList.length === 0) {
        this.$message.error('请至少选择一位用户！')
        return
      }
      const ids = this.selectionList.map(item => item.memberId).filter(item => item)
      this.postCreatCode({ ids, isBatch: true })
    },
    createRowQrCode(row) {
      if (!row.memberId) return
      this.postCreatCode({ ids: [row.memberId] })
    },
    // 单个/批量生成二维码
    postCreatCode({ ids = [], isBatch = false }) {
      this.$confirm('生成二维码后用户即可在小程序中查看，是否确认生成?', '确认生成二维码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        const res = await createExclusiveQrCodeIds(ids)
        if (res.code === 200) {
          this.searchList()
          this.$message.success('操作成功！')
          // 批量生成二维码
          if (isBatch) {
            this.selectionList = []
            this.$refs.personListTable.clearSelection() // 清空
          }
        }
      }).catch(() => {
      })
    },
    handleDeletePerson(row) {
      this.$confirm(`是否确定删除该人员【${row.memberNickname}】?`, '删除人员确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        const params = {
          memberId: row.memberId,
          talentCategoryId: '' // 所属分组id 为空就是删除
        }
        const res = await saveOrUpdateTalentItem(params)
        if (res.code !== 200) {
          return
        }
        this.$message.success('操作成功！')
        this.searchList()
      }).catch(() => {
      })
    },
    // 导出
    async exportPersonsRecords() {
      try {
        const paramData = this.getFilterParam({ hasPage: false })
        const fileName = `人才库_人员列表_${new Date().getTime()}.xls`
        const res = await exportPersonTalentList(paramData)
        this.$public.downloadFile(res, fileName)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Popout_content {
  .flex-box .demo-form-inline {
    flex: 1;
  }
}
</style>
