var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否显示" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.inquire.navStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "navStatus", $$v)
                        },
                        expression: "inquire.navStatus",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "显示", value: "display" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "隐藏", value: "hide" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "导航名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "导航名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.navName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "navName", $$v)
                      },
                      expression: "inquire.navName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addition },
            },
            [_vm._v("新增导航")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      "row-key": "navId",
                      "tree-props": {
                        children: "child",
                        hasChildren: "hasChildren",
                      },
                      height: "400px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "", label: "", "min-width": "60" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "navName",
                        label: "导航名称",
                        "min-width": "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "navImg",
                        label: "导航图标(90*90)",
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.navImg
                                ? _c("div", { staticClass: "table-view-box" }, [
                                    _c("img", {
                                      attrs: { src: scope.row.navImg, alt: "" },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryId",
                        label: "关联分类",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "navOrder",
                        label: "排序",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "navStatus",
                        label: "是否显示",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.navStatus === "display"
                                      ? "显示"
                                      : "隐藏"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "添加时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "140",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              scope.row.navLevel == 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleIcon(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("增加区域icon")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.navLevel == 1
                                ? _c(
                                    "span",
                                    { staticClass: "operation-btn_partition" },
                                    [_vm._v("/")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.navLevel == 2
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRun(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("运营范围")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.navLevel == 2
                                ? _c(
                                    "span",
                                    { staticClass: "operation-btn_partition" },
                                    [_vm._v("/")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.navStatus !== "display",
                                      expression:
                                        "scope.row.navStatus !== 'display'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleHide(
                                        scope.row,
                                        "display"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("显示")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.navStatus === "display",
                                      expression:
                                        "scope.row.navStatus === 'display'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleHide(scope.row, "hide")
                                    },
                                  },
                                },
                                [_vm._v("隐藏")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCity
        ? _c("navigationCity", {
            attrs: { "show-city": _vm.showCity },
            on: { setCityValue: _vm.getCityValue },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            width: "650px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    "label-width": "120px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _vm.dialogTitle == "添加区域Icon"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "父类名称", required: true } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "12",
                              placeholder: "父类名称",
                              disabled: "",
                            },
                            model: {
                              value: _vm.submitForm.fuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.submitForm, "fuName", $$v)
                              },
                              expression: "submitForm.fuName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "导航名称", prop: "navName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "12", placeholder: "导航名称" },
                        model: {
                          value: _vm.submitForm.navName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "navName", $$v)
                          },
                          expression: "submitForm.navName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "导航图标", prop: "navImg" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            "upload-type": "picture-card",
                            "file-list": _vm.fileList,
                            accept: ".gif,.jpeg,.jpg,.png",
                          },
                          on: {
                            uploadSuccess: _vm.uploadSuccess,
                            removeFile: _vm.removeFile,
                            cloudFileData: _vm.setCloudFileData,
                          },
                        },
                        [
                          _c("div", { staticClass: "tip-box" }, [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("图片大小：90*90")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联分类", prop: "categoryId" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          options: _vm.productCategoryList,
                          props: _vm.cascaderProps,
                        },
                        model: {
                          value: _vm.submitForm.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryId", $$v)
                          },
                          expression: "submitForm.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dialogIcon
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择区域" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleRun },
                            },
                            [_vm._v("运营范围")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "navOrder" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "排序" },
                        model: {
                          value: _vm.submitForm.navOrder,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "navOrder", _vm._n($$v))
                          },
                          expression: "submitForm.navOrder",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否显示", prop: "navStatus" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "display" },
                          model: {
                            value: _vm.submitForm.navStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "navStatus", $$v)
                            },
                            expression: "submitForm.navStatus",
                          },
                        },
                        [_vm._v("是")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "hide" },
                          model: {
                            value: _vm.submitForm.navStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "navStatus", $$v)
                            },
                            expression: "submitForm.navStatus",
                          },
                        },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }