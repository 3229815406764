<template>
  <!-- 红包转账明细列表 -->
  <div class="main-box transfer_detail_list">
    <!-- 搜索 -->
    <div class="head-search-row search_box">
      <el-form class="demo-form-inline" :inline="true" size="medium">
        <el-form-item label="商家发起转账时间">
          <el-date-picker v-model="dateObj.sjzzDate" type="datetimerange" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择日期范围" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期" @change="handleChangeSjzzDate"
          />
        </el-form-item>
        <el-form-item label="转账时间">
          <el-date-picker v-model="dateObj.zzDate" type="datetimerange" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择日期范围" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期" @change="handleChangeZzDate"
          />
        </el-form-item>
        <el-form-item label="用户注册手机号">
          <el-input v-model="queryData.memberPhoneNumber" placeholder="请输入用户注册手机号" clearable
                    @keyup.enter.native="updateList('transferDetailTable')"
          />
        </el-form-item>
        <el-form-item label="二维码编号">
          <el-input v-model="queryData.qrCodeId" placeholder="请输入二维码编号" clearable
                    @keyup.enter.native="updateList('transferDetailTable')"
          />
        </el-form-item>
        <el-form-item label="微信支付批次单号">
          <el-input v-model="queryData.transferWechatBatchId" placeholder="请输入微信支付批次单号" clearable
                    @keyup.enter.native="updateList('transferDetailTable')"
          />
        </el-form-item>
        <el-form-item label="转账状态">
          <el-select v-model="queryData.transferStatus" style="width: 180px" clearable placeholder="请选择">
            <el-option v-for="item in transferStatusOptions" :key="item.value" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="updateList('transferDetailTable')">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="table_box">
      <TableList
          ref="transferDetailTable"
          class="tableList"
          :table-header="tableData.tableHeader"
          :is-selection="false"
          :table-data="tableData.list"
          :total="tableData.total"
          :custom-page-size="[10, 20, 30, 50, 100, 1000]"
          @setPage="pageChange($event, 'transferDetailTable')"
          @setSize="sizeChange($event, 'transferDetailTable')"
      >
        <template #transferStatus="{data}">
          <span>{{ getTransferStatus(data.transferStatus) }}</span>
        </template>
        <template #transferAmount="{data}">
          <span v-if="data.transferAmount">¥{{ data.transferAmount }}</span>
        </template>
        <template #transferWay="{data}">
          <span v-if="data.transferWay === 1">用户扫码</span>
          <span v-if="data.transferWay === 2">重新转账</span>
        </template>
        <template #paymentScenarios="{data}">
          <span>营销活动</span>
        </template>
        <template #operation="{data}">
          <el-button class="operate_btn" type="text" @click="operateButton('transfer_detail', data)">
            转账详情
          </el-button>
        </template>
      </TableList>
    </div>

    <!-- ——————————————————————————弹窗相关 start—————————————————————————— -->
    <!-- 批量操作指定商家类型 -->
    <el-dialog :visible.sync="transferDetailData.dialog" title="转账详情" width="600px" top="6vh"
               @close="getRedPacketDetailList"
    >
      <div v-loading="transferDetailData.loading" class="Popout_content">
        <el-form :model="transferDetailData.data" label-width="180px" label-suffix=":">
          <div v-for="item in tableData.tableHeader" :key="item.prop">
            <!--不展示操作栏和失败原因-->
            <el-form-item v-if="item.prop !== 'operation' && item.prop!=='transferFailReason'" :label="item.label">
              <template v-if="item.prop === 'transferAmount'">
                <span v-if="currentClickData.transferAmount">¥{{ currentClickData.transferAmount }}</span>
              </template>
              <template v-else-if="item.prop === 'transferStatus'">
                <span>{{ getTransferStatus(currentClickData.transferStatus) }}</span>
              </template>
              <template v-else-if="item.prop === 'paymentScenarios'">
                <span>营销活动</span>
              </template>
              <template v-else-if="item.prop === 'transferWay'">
                <span v-if="currentClickData.transferWay === 1">用户扫码</span>
                <span v-if="currentClickData.transferWay === 2">重新转账</span>
              </template>
              <template v-else>
                {{ currentClickData[item.prop] }}
              </template>
            </el-form-item>
            <el-form-item v-if="item.prop === 'transferFailReason' && currentClickData.transferStatus === 2"
                          :label="item.label"
            >
              <span>{{ currentClickData.transferFailReason }}</span>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- ——————————————————————————弹窗相关 end———————————————————————————— -->
  </div>
</template>

<script>
import { getRedPacketTransferList, getRedPacketTransferDetail } from '@/api/operation'

export default {
  data() {
    return {
      // 区域数据
      addressList: [],
      exportAddressList: [], // 存放导出的区域选择数据
      // 请求数据
      dateObj: { sjzzDate: [], zzDate: [] },
      queryData: {
        pageNum: 1,
        pageSize: 10,
        transferBeginTime: '', // 转账开始时间
        transferEndTime: '', // 转账结束时间
        createBeginTime: '', // 商家转账开始时间
        createEndTime: '', // 商家转账结束时间
        memberPhoneNumber: '', // 手机号
        qrCodeId: '', // 二维码编号
        transferWechatBatchId: '', // 转账批次号
        transferStatus: '', // 转账状态 1-成功 2-失败 3-转账中
        transferWay: '' // (预防后期添加条件)转账方式 1.用户扫码 2.重新转账
      },
      // 表格数据
      tableData: {
        tableHeader: [
          // 表头 => 页面表格需要用到以及转账详情都有用到
          { label: '转账时间', prop: 'transferTime', width: '200px' },
          { label: '商家发起转账时间', prop: 'createTime', width: '200px' },
          { label: '微信支付批次单号', prop: 'transferWechatBatchId', width: '350px' },
          { label: '二维码编号', prop: 'qrCodeId', width: '180px' },
          { label: '微信支付明细单号', prop: 'transferWechatDetailId', width: '200px' },
          { label: '收款用户openid', prop: 'memberOpenId', width: '260px' },
          { label: '收款用户注册手机号', prop: 'memberPhoneNumber', width: '160px' },
          { label: '转账金额', prop: 'transferAmount', type: 'slot', slotName: 'transferAmount', width: '130px' },
          { label: '转账状态', prop: 'transferStatus', type: 'slot', slotName: 'transferStatus', width: '130px' },
          { label: '失败原因', prop: 'transferFailReason', width: '200px' },
          { label: '付款场景', prop: 'paymentScenarios', type: 'slot', slotName: 'paymentScenarios', width: '160px' },
          { label: '转账方式', prop: 'transferWay', type: 'slot', slotName: 'transferWay', width: '160px' },
          { label: '操作', prop: 'operation', type: 'slot', slotName: 'operation', fixed: 'right', width: '120px' }
        ],
        list: [], // 返回的列表数据
        total: 0
      },
      currentClickData: {}, // 当前点击的数据

      // ----------------------转账详情-------------------
      transferDetailData: {
        dialog: false,
        loading: false,
        data: {}
      }
    }
  },
  computed: {
    // 转账状态
    transferStatusOptions() {
      return [
        { label: '成功', value: 1 },
        { label: '失败', value: 2 },
        { label: '转账中', value: 3 }
      ]
    }
  },
  watch: {},
  created() {
    this.getRedPacketDetailList()
  },
  methods: {
    // 获取转账状态
    getTransferStatus(status) {
      const item = this.transferStatusOptions.find(item => item.value === status)
      if (item) {
        return item.label
      }
      return ''
    },
    // 改变转账时间
    handleChangeZzDate(times) {
      if (!times) {
        this.queryData.transferBeginTime = ''
        this.queryData.transferEndTime = ''
        return
      }
      this.queryData.transferBeginTime = times[0]
      this.queryData.transferEndTime = times[1]
    },
    // 改变商家转账时间
    handleChangeSjzzDate(times) {
      if (!times) {
        this.queryData.createBeginTime = ''
        this.queryData.createEndTime = ''
        return
      }
      this.queryData.createBeginTime = times[0]
      this.queryData.createEndTime = times[1]
    },

    // 改变页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'transferDetailTable': // 二维码列表
          this.queryData.pageNum = pageVal
          this.getRedPacketDetailList()
          break
        default:
          break
      }
    },
    // 改变页数
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'transferDetailTable': // 二维码列表
          this.queryData.pageSize = sizeVal
          this.getRedPacketDetailList()
          break
        default:
          break
      }
    },
    // 搜索
    updateList(type) {
      switch (type) {
        case 'transferDetailTable': // 二维码列表
          this.queryData.pageNum = 1
          this.getRedPacketDetailList()
          break
        default:
          break
      }
    },

    // 操作按钮
    operateButton(operateType, data) {
      switch (operateType) {
        case 'transfer_detail': // 查看详情
          this.transferDetailData.dialog = true
          this.getRedPacketDetail(data.transferId)
          break
        default:
          break
      }
    },

    // 获取详情
    async getRedPacketDetail(id) {
      this.currentClickData = {}
      this.transferDetailData.loading = true
      const res = await getRedPacketTransferDetail(id)
      this.transferDetailData.loading = false
      if (res.code === 200) {
        this.currentClickData = res.data
      }
    },

    // 获取列表
    async getRedPacketDetailList() {
      const params = { ...this.queryData }
      const res = await getRedPacketTransferList(params)
      if (res.code === 200) {
        this.tableData.list = res.data.list
        this.tableData.total = res.data.total
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer_detail_list {
  .search_box {
    /deep/ .el-form {
      :last-child {
        margin-right: 0;
      }
    }
  }

  .table {
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }

  .table_box {
    .tableList {
      width: 100%;
      margin: 0;
      padding: 20px 20px 10px;

      /deep/ .el-button {
        padding: 0;
      }

      /deep/ .el-button + .el-button {
        margin: 0;
      }

      .operate_btn {
        &::before {
          content: "/";
          font-size: 12px;
          color: #4e9aef;
          vertical-align: top;
          margin-right: 5px;
        }

        &:first-child::before {
          content: "";
          margin: 0;
        }
      }
    }
  }
}
</style>
