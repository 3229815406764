import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control

// 富文本编辑器
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// 全屏组件
import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

import '@/assets/iconfont/iconfont.css'
import '@/assets/icons/iconfont.css'
import '@/styles/common.css'

import api from '@/utils/api/index.js'
Vue.prototype.$api = api

import http from '@/utils/request.js'
Vue.prototype.$http = http

import publics from '@/utils/public.js'
Vue.prototype.$public = publics

import auth from '@/utils/auth.js'
Vue.prototype.$auth = auth

import validates from '@/utils/validate.js'
Vue.prototype.$validate = validates

import TcVod from 'vod-js-sdk-v6'
Vue.prototype.$TcVod = TcVod

import axios from 'axios'
Vue.prototype.$axios = axios

// 注册表格列表组件
import TableList from './components/TableList/index.vue'
Vue.component('TableList', TableList)
// 注册区域筛选组件
import dArea from '@/components/d-area'
Vue.component('dArea', dArea)

// 注册一键复制
import VueClipBoard from 'vue-clipboard2'

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(VueClipBoard)
Vue.use(ElementUI)
Vue.use(mavonEditor)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
