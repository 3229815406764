var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠券二维码编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入优惠券二维码编号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTableList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.couponQrCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "couponQrCode", $$v)
                      },
                      expression: "inquire.couponQrCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTableList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "productName", $$v)
                      },
                      expression: "inquire.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品批次" },
                    model: {
                      value: _vm.inquire.productBatch,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "productBatch", $$v)
                      },
                      expression: "inquire.productBatch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入优惠券ID" },
                    model: {
                      value: _vm.inquire.couponId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "couponId", $$v)
                      },
                      expression: "inquire.couponId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "创建时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "—",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.inquire.daterange,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "daterange", $$v)
                        },
                        expression: "inquire.daterange",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.getTableList },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.resetInquire },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "addBtn" },
        [
          _vm._l(_vm.operateButton, function (item) {
            return [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.operateBtnClick(item.id)
                    },
                  },
                },
                [_vm._v(_vm._s(item.buttonName))]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      height: "400px",
                      "row-key": "id",
                    },
                    on: { "selection-change": _vm.selectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "60",
                        fixed: "",
                        "reserve-selection": true,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "qrCodeSerial",
                        label: "优惠券二维码编号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "batchNo",
                        label: "批次",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponQuantity",
                        label: "优惠券数量",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salesPrice",
                        label: "销售额",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "userNum",
                        label: "用户总数",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "scanNum",
                        label: "扫码次数",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "创建时间",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "280",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.tableHandle("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("二维码")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.tableHandle("sales", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("销售记录")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.tableHandle("user", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("会员列表")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.tableHandle(
                                        "coupon",
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("优惠券列表")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("QrCodeDialog", {
        ref: "qrCodeDialog_ref",
        attrs: { "qr-code-type": _vm.qrCodeType, dialogTitle: _vm.dialogTitle },
        on: { getValue: _vm.getQrCodeData },
        scopedSlots: _vm._u([
          {
            key: "tips",
            fn: function () {
              return [
                _vm.qrCodeType === "couponBatch"
                  ? _c("p", { staticStyle: { padding: "20px" } }, [
                      _vm._v(
                        "\n        已选中 " +
                          _vm._s(_vm.selectionTableData.length) +
                          " 个优惠券二维码，设置后，优惠券二维码关联批次将以本次设置的批次为准。\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickRowData.qrCodeSerial + " 销售记录",
            visible: _vm.salesRecordsData.dialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.salesRecordsData, "dialog", $event)
            },
            close: function ($event) {
              _vm.salesRecordsData.dialog = false
            },
          },
        },
        [
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.salesRecordsData.loading,
              "table-header": _vm.salesRecordsData.tableHeader,
              "table-data": _vm.salesRecordsData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "userName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(data.memberNickName + " / " + data.recipient)
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderTotalPrice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.orderTotalPrice
                            ? "￥" + data.orderTotalPrice
                            : "￥0.00"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.EcommerceOrderState,
                            data.orderStatus,
                            "name"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.salesRecordsDetail(data)
                          },
                        },
                      },
                      [_vm._v("订单详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickRowData.qrCodeSerial + " 会员列表",
            visible: _vm.memberListData.dialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.memberListData, "dialog", $event)
            },
            close: function ($event) {
              return _vm.memberListDialog(false)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "memberList_ref",
              attrs: { model: _vm.memberQueryData, inline: "", size: "small" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信昵称", prop: "memberNickname" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入微信昵称" },
                    model: {
                      value: _vm.memberQueryData.memberNickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberQueryData, "memberNickname", $$v)
                      },
                      expression: "memberQueryData.memberNickname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "memberTelephone" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      maxlength: "11",
                      placeholder: "请输入手机号",
                    },
                    model: {
                      value: _vm.memberQueryData.memberTelephone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.memberQueryData,
                          "memberTelephone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "memberQueryData.memberTelephone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员编号", prop: "memberCode" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入会员编号" },
                    model: {
                      value: _vm.memberQueryData.memberCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberQueryData, "memberCode", $$v)
                      },
                      expression: "memberQueryData.memberCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getQrCodeMemberList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.memberListData.loading,
              "table-header": _vm.memberListData.tableHeader,
              "table-data": _vm.memberListData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "cumulativeConsumption",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.cumulativeConsumption
                            ? "￥" + data.cumulativeConsumption
                            : "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickRowData.qrCodeSerial + " 优惠券列表",
            visible: _vm.couponListData.dialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.couponListData, "dialog", $event)
            },
            close: function ($event) {
              _vm.couponListData.dialog = false
            },
          },
        },
        [
          _c("TableList", {
            attrs: {
              height: "400px",
              loading: _vm.couponListData.loading,
              "table-header": _vm.couponListData.tableHeader,
              "table-data": _vm.couponListData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "couponStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.couponStatus,
                            data.couponStatus,
                            "name"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }