<script>
import ShareList from '@/views/operation/marketingTool/ShareList.vue'

export default {
  name: 'BonusCouponList',
  components: { ShareList }
}
</script>

<template>
  <ShareList couponType="welfare"/>
</template>

<style scoped lang="scss">

</style>
