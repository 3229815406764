<template>
  <!-- 订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <!-- <div class="steps-box bg_white">
      <el-steps :active="active">
        <el-step v-for="item in steps" :key="item.title" :title="item.title" :description="item.time" />
      </el-steps>
    </div> -->

    <div class="row_2 bg_white flex-box">
      <div class="row_left">
        <h4>配送信息</h4>
        <p class="color_light-black">配送方式：{{ $api.getValue($api.deliveryMethod, pageData.deliveryMethod, 'name') }}</p>
        <p class="color_light-black">收货人姓名：{{ pageData.recipient }}</p>
        <p class="color_light-black">收货人手机号：{{ pageData.recipientPhoneNumber }}</p>
        <p class="color_light-black">
          收货人地址：
          <span>{{ pageData.recipientAddressDetail }}</span>
          <el-button
            v-if="pageData.orderStatus === 'pending_payment' || pageData.orderStatus === 'pending_ship'"
            style="padding: 0"
            type="text"
            @click="dialogVisible_3 = true"
          >
            修改
          </el-button>
        </p>
        <p style="height: 24px"></p>
        <div class="color_light-black flex-box">
          <span class="row-title">购物凭证：</span>
          <div v-show="imageUrl">
            <d-upload upload-type="picture-card" :file-list="fileList" accept=".gif,.jpeg,.jpg,.png">
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </div>
          <span v-show="!imageUrl">无</span>
        </div>
        <p class="color_light-black">备注信息：{{ pageData.orderRemark || '无' }}</p>
      </div>

      <div class="row_right">
        <h4>
          订单状态：
          <span>{{ $api.getValue($api.EcommerceOrderState, pageData.orderStatus, 'name') }}</span>
        </h4>
        <div v-if="pageData.orderStatus == 'pending_payment'">
          <!-- <el-button size="medium" type="primary" @click="amendP rice">修改价格</el-button> -->
          <el-button plain size="medium" @click="cancelOrder">取消订单</el-button>
        </div>
        <!-- <div v-else-if="pageData.orderStatus == 'pending_ship'">
          <el-button plain size="medium" @click="sendOut">发货</el-button>
        </div> -->
      </div>
    </div>

    <div class="row_3 bg_white">
      <h4>商品信息</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ '￥' + scope.row.productPrice }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="小计" min-width="100" prop="">
            <template slot-scope="scope">
              {{ `￥${scope.row.itemAmount || 0}` }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.couponDiscountAmount ? `￥${scope.row.couponDiscountAmount}` : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="实收金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ `￥${scope.row.paidInAmountCoupon || 0}` }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="提成比例" min-width="100" prop="brokerageRate" />
          <el-table-column label="提成金额" min-width="100" prop="brokerageAmount" /> -->
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥{{ pageData.orderTotalPrice || 0 }}</span>
          </li>
          <li>
            运费：
            <span class="block_span">+￥{{ pageData.shippingFee || 0 }}</span>
          </li>
          <li class="line-box"></li>
          <li class="bold">
            实付总额：
            <span class="block_span color_red">￥{{ pageData.paidInAmount || pageData.orderTotalPrice || 0 }}</span>
          </li>
          <li v-if="pageData.returnAmount" class="bold">
            退款总额：
            <span class="block_span color_red">￥{{ pageData.couponId ? pageData.paidInAmount : pageData.orderTotalPrice }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black">
        <span class="title-box">订单类型：</span>
        {{ $api.getValue($api.orderClassify, pageData.orderClassify, 'name') }}
      </p>
      <p class="color_light-black">
        <span class="title-box">订单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">下单时间：</span>
        {{ pageData.orderTime }}
      </p>
      <p
        v-if="pageData.orderStatus !== 'pending_payment' && pageData.orderStatus !== 'completed' && pageData.orderStatus !== 'cancel'"
        class="color_light-black"
      >
        <span class="title-box">付款时间：</span>
        {{ pageData.paymentTime }}
      </p>
      <p v-if="pageData.orderStatus === 'pending_receipt' || pageData.orderStatus === 'pending_evaluate'" class="color_light-black">
        <span class="title-box">发货时间：</span>
        {{ pageData.shipTime }}
      </p>
      <p v-if="pageData.orderStatus === 'pending_evaluate'" class="color_light-black">
        <span class="title-box">确认收货时间：</span>
        {{ pageData.receiptTime }}
      </p>
    </div>
    <div v-if="pageData.orderStatus != 'pending_payment'" class="row_5 row_4 bg_white">
      <h4>出库批次信息</h4>
      <div v-for="(item, index) in pageData.items" :key="index" style="margin-bottom:10px">
        <div v-if="item.batch.length >= 1">
          <div>商品名称：{{ item.productName }}</div>
          <div v-for="(batchItem, batchIndex) in item.batch" :key="batchIndex">
            <p class="color_light-black">
              <span class="title-box">批次号：</span>
              {{ batchItem.batchNo }}
            </p>
            <p class="color_light-black">
              <span class="title-box">发货数量：</span>
              {{ batchItem.deliverCount }}
            </p>
            <p class="color_light-black">
              <span class="title-box">有效期：</span>
              {{ batchItem.produceTime }} ~ {{ batchItem.expireTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageData.orderStatus != 'pending_payment' && pageData.orderStatus == 'completed'" class="row_5 row_4 bg_white">
      <h4>退货批次信息</h4>
      <div v-for="(item, index) in pageData.items" :key="index" style="margin-bottom:10px">
        <div v-if="item.returnBatch.length >= 1">
          <div>商品名称：{{ item.productName }}</div>
          <div v-for="(batchItem, batchIndex) in item.returnBatch" :key="batchIndex">
            <p class="color_light-black">
              <span class="title-box">批次号：</span>
              {{ batchItem.batchNo }}
            </p>
            <p class="color_light-black">
              <span class="title-box">发货数量：</span>
              {{ batchItem.deliverCount }}
            </p>
            <p class="color_light-black">
              <span class="title-box">有效期：</span>
              {{ batchItem.produceTime }} ~ {{ batchItem.expireTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>业务员信息</h4>
      <p class="color_light-black">
        <span class="title-box">业务员姓名：</span>
        {{ pageData.intendantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">业务员编号：</span>
        {{ pageData.intendantCode || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">二维码编号：</span>
        {{ pageData.qrCodeSerial || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">二维码备注：</span>
        {{ pageData.qrCodeRemark || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端名称：</span>
        {{ pageData.qrMerchantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端编号：</span>
        {{ pageData.qrMerchantCode || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家类型：</span>
        {{ pageData.merchantType || '无' }}
      </p>
    </div>
    <div class="row_5 row_4 bg_white" v-if="pageData.sampleLogistics && pageData.sampleLogistics.length > 0">
      <h4>寄送样本</h4>
      <p class="color_light-black" v-for="(item, ind) in pageData.sampleLogistics" :key="ind">
        <span class="title-box">物流单号：</span>{{ item.logistics || '无' }}
        <span class="lookLogistics" @click="sendLogistics(item.logistics)">查看物流</span>
      </p>
    </div>

    <!-- 优惠明细-start -->
    <div class="row_6 row_4 bg_white">
      <h4>优惠信息</h4>
      <p class="color_light-black" style="margin:0 0 10px 50px;">
        <span style="margin-right:50px">优惠券名称：{{ pageData.couponName || '-' }}</span>
        <span style="margin-right:50px">
          优惠券ID：{{ pageData.customerCoupon && pageData.customerCoupon.couponCode ? pageData.customerCoupon.couponCode : '-' }}
        </span>
        <span style="margin-right:50px">会员等级：{{ $public.getVipLevel(pageData.vipLevel) }}</span>
      </p>
      <p v-if="pageData.couponIntendantVo" class="color_light-black" style="margin:0 0 10px 50px">
        <span style="margin-right:50px">业务员编号：{{ pageData.couponIntendantVo.intendantCode || '无' }}</span>
        <span style="margin-right:50px">业务员姓名：{{ pageData.couponIntendantVo.intendantName || '无' }}</span>
        <span style="margin-right:50px">二维码编号：{{ pageData.couponIntendantVo.qrCodeSerial || '无' }}</span>
      </p>
      <div class="table">
        <el-table :data="pageData.items" :border="true" style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box" style="align-items:center">
                <img v-if="scope.row.productImg" class="img-box" :src="scope.row.productImg" alt="" />
                <span style="margin-left:5px">{{ scope.row.productName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="100" prop="productNorms" />
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ `￥${scope.row.productPrice}` }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="优惠方式" min-width="100" prop="couponMode">
            <template slot-scope="scope">
              {{ scope.row.couponMode || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.amountCouponInformation ? `￥${scope.row.amountCouponInformation}` : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="优惠价" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.priceCouponInformation ? `￥${scope.row.priceCouponInformation}` : 0 }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 优惠明细-end -->

    <!-- 弹框-修改价格 -->
    <el-dialog :visible.sync="dialogVisible_1" title="修改价格" width="70%">
      <div class="Popout_content">
        <div class="table">
          <el-table :data="tableData_1" border style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="产品信息" min-width="120" prop="available">
              <template></template>
            </el-table-column>
            <el-table-column label="单价" min-width="100" prop="available" />
            <el-table-column label="数量" min-width="100" prop="available" />
            <el-table-column label="小计" min-width="100" prop="available" />
            <el-table-column label="加价/减价" min-width="120" prop="available">
              <template>
                <div class="flex-box flex-center">
                  ￥
                  <el-input v-model="tableData_1.input" placeholder="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实收金额" min-width="100" prop="available" />
          </el-table>
        </div>
        <div class="show-money">
          <ul>
            <li>
              商品金额：
              <span class="block_span">￥200.00</span>
            </li>
            <li>
              运费：
              <span class="block_span">+￥20.00</span>
            </li>
            <li>
              加价/减价：
              <span class="block_span color_red">-￥20.00</span>
            </li>
            <li class="line-box"></li>
            <li class="bold">
              实付总额：
              <span class="block_span color_red">￥180.00</span>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible_2" title="发货" width="600px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="80px">
          <el-form-item label="订单编号">
            <el-input v-model="submitForm.orderCode" disabled placeholder="订单编号" />
          </el-form-item>

          <el-form-item label="物流公司" prop="logisticsCompany">
            <el-select v-model="submitForm.logisticsCompany" class="form-select" placeholder="请选择">
              <el-option v-for="item in logisticsCompanyList" :key="item.companyId" :label="item.companyName" :value="item.companyId" />
            </el-select>
          </el-form-item>

          <el-form-item label="物流单号" prop="logisticsCode">
            <el-input v-model="submitForm.logisticsCode" maxlength="30" placeholder="物流单号" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmSendOn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改收货地址 -->
    <el-dialog :visible.sync="dialogVisible_3" :before-close="closeAddresCPM" title="修改收货地址" width="600px">
      <div class="Popout_content">
        <el-form ref="changeAddressForm" :model="changeAddressForm" :rules="changeAddressRules" label-width="80px">
          <el-form-item label="所在地址" prop="addressId">
            <el-cascader
              v-model="changeAddressForm.addressId"
              :props="props_add"
              :options="addressList_add"
              placeholder="请选项地址"
              class="width-inherit"
            />
          </el-form-item>

          <el-form-item label="地址详情" prop="address">
            <el-input v-model="changeAddressForm.address" type="textarea" maxlength="150" placeholder="地址详情" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddresCPM">取 消</el-button>
        <el-button type="primary" @click="amendAddress">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 寄送物流轨迹弹窗 -->
    <z-dialog 
      ref="sendRefs"
      :config= "{
        sendCode: this.sendOrderCode,
        logisticsCode: this.logisticsCode
      }"
      :logisticsData="logisticsInfo"
      @handleClose="handleCloseChange"
    />

  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import ZDialog from '@/components/z-dialog'

export default {
  components: {
    dUpload,
    ZDialog
  },
  data() {
    return {
      ids: null, // 主键id
      // 步骤条
      // steps: [
      //     { title: '买家下单', time: '' },
      //     { title: '买家付款', time: '' },
      //     { title: '商家发货', time: '' },
      //     { title: '已评论', time: '' },
      //     { title: '交易完成', time: '' }
      // ],
      active: 0,

      // 购物凭证
      imageUrl: [],
      fileList: [],

      // 商品信息
      tableData: [],

      pageData: {}, // 页面数据
      goodsMoney: 0,

      //  ============= 代付款 =============
      // 修改价格
      dialogVisible_1: false,
      tableData_1: [{ available: ' *** ' }],

      //  ============= 代发货 =============
      dialogVisible_2: false,
      submitForm: {
        orderCode: '',
        logisticsCompany: '',
        logisticsCode: ''
      },
      submitRules: {
        logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        logisticsCode: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z]+$/, message: '请输入字母和数字', trigger: 'blur' }
        ]
      },
      logisticsCompanyList: [], // 物流公司列表

      // ============= 修改收货地址 =============
      addressList_add: [],
      props_add: {
        label: 'addressName',
        value: 'addressName',
        children: 'child'
        // checkStrictly: true,
        // emitPath: false
      },
      dialogVisible_3: false,
      changeAddressForm: {
        addressId: [],
        address: ''
      },
      changeAddressRules: {
        addressId: [{ required: true, message: '请选择所在地址', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      },
      logisticsInfo: [], //物流信息
      sendOrderCode: "",  //订单号
      logisticsCode: "",  //物流单号
    }
  },
  watch: {
    imageUrl: (old, news) => {
      if (old) {
        const upEl = document.getElementsByClassName('el-upload')[0]
        upEl.style.display = 'none'
      }
    }
  },
  created() {
    this.ids = this.$route.query.id
    // 判断当前订单状态
    this.active = parseInt(this.$route.query.type) - 1
    this.getOrderInfo()
    this.getLogisticsCompanyList()
    this.getAddress()
  },
  mounted() {},
  methods: {
    // 获取省市区
    getAddress() {
      this.$http.requestGet({ url: '/common/address', loading: false }).then(res => {
        this.addressList_add = res.data
      })
    },

    // 获取详情数据
    getOrderInfo() {
      this.$http.requestGet({ url: '/customer/order/get/' + this.ids }).then(res => {
        this.pageData = res.data
        this._statisticsOrderCode(this.pageData.orderCode)
        this.imageUrl = res.data.prescriptionUrl ? res.data.prescriptionUrl.split(',') : []
        this.fileList = []
        if (this.imageUrl.length > 0) {
          this.imageUrl.forEach(e => {
            this.fileList.push({
              name: e.slice(e.lastIndexOf('/')),
              url: e
            })
          })
        }

        // 总金额
        const money = this.pageData.items.reduce((num, cur) => {
          return (num += cur.productPrice * cur.itemNum)
        }, 0)
        this.goodsMoney = money

        // this.steps[0].time = this.pageData.orderTime
        // this.steps[1].time = this.pageData.paymentTime
        // this.steps[2].time = this.pageData.shipTime
        // this.steps[3].time = this.pageData.evaluateTime
        // this.steps[4].time = this.pageData.completeTime
      })
    },
    // 获取物流公司列表
    getLogisticsCompanyList() {
      const param = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$http.requestPost({ url: '/system/logistics/company/page', param, loading: false }).then(res => {
        this.logisticsCompanyList = res.data.list
      })
    },

    //  ========================== 代付款 ==========================
    // 修改价格
    amendPrice() {
      this.dialogVisible_1 = true
    },

    //  ========================== 代发货 ==========================
    // 打开发货弹框
    sendOut() {
      this.submitForm = {
        orderCode: this.pageData.orderCode,
        logisticsCompany: '',
        logisticsCode: ''
      }
      this.dialogVisible_2 = true
    },
    // 确认发货
    confirmSendOn() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            orderId: this.pageData.orderId,
            logisticsCompany: this.submitForm.logisticsCompany,
            logisticsCode: this.submitForm.logisticsCode
          }
          this.$http.requestPut({ url: '/customer/order/electronic/ship', param }).then(res => {
            this.$message.success('操作成功!')
            this.dialogVisible_2 = false
            this.$router.go(-1)
          })
        } else {
          return false
        }
      })
    },

    //  ========================== 修改发货地址 ==========================
    amendAddress() {
      this.$refs.changeAddressForm.validate(valid => {
        if (valid) {
          const param = {
            address: this.changeAddressForm.addressId.join('') + this.changeAddressForm.address,
            orderId: this.pageData.orderId
          }
          this.$http
            .requestPut({
              url: '/customer/order/changeAddress',
              param
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.closeAddresCPM()
              this.getOrderInfo()
            })
        } else {
          return false
        }
      })
    },
    // 关闭发货地址
    closeAddresCPM() {
      this.changeAddressForm = {
        addressId: '',
        address: ''
      }
      this.dialogVisible_3 = false
    },

    //  ========================== 取消订单 ==========================
    cancelOrder() {
      this.$http.requestPut({ url: '/customer/order/cancel/' + this.pageData.orderId }).then(res => {
        if (res.data) {
          this.$message.success('操作成功')
          this.getOrderInfo()
        }
      })
    },

    // 埋点
    _statisticsOrderCode(id) {
      this.$http.requestPost({
        url: `/listenerLog/orderDetails?orderCode=${id}`,
        loading: false,
      }).then(res => {
      }).catch(err => {
        console.log('【埋点失败】', err)
      })
    },

    //寄送样本查看物流
    async sendLogistics(code){
      try {
        this.sendOrderCode = this.pageData.orderCode + " - 寄送样本";  //订单号
        this.logisticsCode = code;  //物流单号
        const response = await this.$http.requestGet({ url: `/general/external/sampleLogistics/${code}` });
        if (response.code === 200 && response.data) {
          this.logisticsInfo = this.groupByDate(response.data.data);
          setTimeout(() => {
            this.$refs.sendRefs.showDialog = true;
          }, 200);
        } else {
          this.$message.warning("暂无物流信息");
        }
      } catch (error) {
        console.error('获取物流信息失败：', error);
      }
    },

    //寄送物流关闭弹窗
    handleCloseChange(){
      this.$refs.sendRefs.showDialog = false;
    },

    //共有方法：将数据结构转化为二维数组
    groupByDate(logisticsData) {
      const groupedData = {};
      // 遍历原始数据
      logisticsData.forEach(item => {
        //分割字符，获取日期
        const date = item.time.split(' ')[0];
        //如果groupedData中不存在该日期，创建一个新的子数组
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        //将日期和时间添加到对应子数组中
        groupedData[date].push({
          time: item.time.split(' ')[1],
          context: item.context,
        });
      });
      // 将对象转换为数组传给组件
      const result = Object.keys(groupedData).map(date => ({
        date,
        child: groupedData[date],
      }));
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  & >>> .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }
}

.title-box {
  display: inline-block;
  width: 116px;
  text-align: right;
}
.lookLogistics {
  margin-left: 20px;
  color: #429fff;
  cursor: pointer;
}

.show-money {
  padding: 10px 0;

  ul {
    li {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      font-size: 16px;
    }

    .line-box {
      margin-left: 70%;
      padding-top: 20px;
      border-bottom: 1px solid #ccc;
    }

    .bold {
      font-weight: bolder;
    }
  }
}

/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}
</style>
