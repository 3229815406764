var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box", attrs: { id: "main-scroll" } },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-bar bg_white" },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "a",
            {
              key: item.id,
              class: { active: item.active },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.skip(index)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_1" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "basicRef",
                attrs: {
                  model: _vm.basicInfo,
                  rules: _vm.basicInfoRules,
                  "label-width": "100px",
                  size: "medium",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "名称", prop: "couponName" } },
                  [
                    _c("el-input", {
                      staticClass: "inputW",
                      attrs: {
                        disabled: _vm.editDisable,
                        placeholder: "请输入名称",
                        maxlength: "10",
                        "show-word-limit": "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.basicInfo.couponName,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicInfo, "couponName", $$v)
                        },
                        expression: "basicInfo.couponName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "使用时间", prop: "useDate" } },
                  [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            format: "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.editDisable,
                            "range-separator": " - ",
                            "picker-options": _vm.pickerOptions,
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            clearable: false,
                          },
                          on: {
                            change: _vm.useDateChange,
                            focus: function ($event) {
                              return _vm.handleFocus(0)
                            },
                          },
                          model: {
                            value: _vm.basicInfo.useDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.basicInfo, "useDate", $$v)
                            },
                            expression: "basicInfo.useDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "consumeP" },
                  [
                    _c("el-checkbox", {
                      staticClass: "dCheck",
                      attrs: { disabled: _vm.editDisable },
                      on: { change: _vm.consumePersonCheckChange },
                      model: {
                        value: _vm.consumePersonCheck,
                        callback: function ($$v) {
                          _vm.consumePersonCheck = $$v
                        },
                        expression: "consumePersonCheck",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { height: "18px" },
                            attrs: { label: "定向人群" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "form-select inputW",
                                attrs: {
                                  disabled:
                                    _vm.editDisable || !_vm.consumePersonCheck,
                                  placeholder: "请选择定向人群",
                                },
                                on: { change: _vm.consumePersonChange },
                                model: {
                                  value: _vm.basicInfo.consumePerson,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.basicInfo,
                                      "consumePerson",
                                      $$v
                                    )
                                  },
                                  expression: "basicInfo.consumePerson",
                                },
                              },
                              _vm._l(_vm.$api.consumePerson, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "textColor textW" }, [
                          _vm._v(
                            "\n              选择定向人群后优惠券会向指定消费者透出，非定向人群无法使用此优惠券；若不选这一项，则优惠券会向平台所有用户透出；\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowVIPType,
                            expression: "isShowVIPType",
                          },
                        ],
                        staticClass: "VIPCheck",
                        attrs: { disabled: _vm.editDisable },
                        model: {
                          value: _vm.VipType,
                          callback: function ($$v) {
                            _vm.VipType = $$v
                          },
                          expression: "VipType",
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: 3 } }, [
                          _vm._v("银星会员"),
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", { attrs: { label: 4 } }, [
                          _vm._v("玉星会员"),
                        ]),
                        _vm._v(" "),
                        _c("el-checkbox", { attrs: { label: 5 } }, [
                          _vm._v("金星会员"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "consumeP" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { height: "18px" },
                          attrs: { label: "禁用分组" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form-select inputW",
                              attrs: {
                                disabled: _vm.editDisable,
                                placeholder: "请选择",
                              },
                              on: { change: _vm.disableGroupListChange },
                            },
                            _vm._l(_vm.groupOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.categoryName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.basicInfo.disableGroupList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "group-box" },
                            _vm._l(
                              _vm.basicInfo.disableGroupList,
                              function (tag, ind) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag.categoryName,
                                    staticClass: "group-tag",
                                    attrs: {
                                      type: tag.id,
                                      closable: _vm.disableClosable,
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.removeDisable(ind)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(tag.categoryName) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "textColor textW" }, [
                        _vm._v(
                          "选择禁用分组后，优惠券面向人群中属于该分组的用户不能查看此优惠券"
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "consumeP" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { height: "18px" },
                          attrs: { label: "定向分组" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form-select inputW",
                              attrs: {
                                disabled: _vm.editDisable,
                                placeholder: "请选择",
                              },
                              on: { change: _vm.orientedGroupListChange },
                            },
                            _vm._l(_vm.groupOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.categoryName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.basicInfo.orientedGroupList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "group-box" },
                            _vm._l(
                              _vm.basicInfo.orientedGroupList,
                              function (tag, ind) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag.categoryName,
                                    staticClass: "group-tag",
                                    attrs: {
                                      type: tag.id,
                                      closable: _vm.orientedClosable,
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.removeConsume(ind)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(tag.categoryName) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "textColor textW" }, [
                        _vm._v(
                          "\n              选择定向分组后优惠券会向优惠券面向人群中指定分组人员透出，非指定分组人员无法查看此优惠券；若不选这一项，则优惠券会向优惠券面向人群透出；\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "透出时间", prop: "tcDate" } },
                  [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          staticClass: "inputW",
                          attrs: {
                            type: "datetime",
                            "value-format": "yyyy-MM-dd HH:mm",
                            disabled:
                              _vm.editDisable ||
                              !(
                                _vm.basicInfo.useDate &&
                                _vm.basicInfo.useDate[0].length > 0
                              ),
                            format: "yyyy-MM-dd HH:mm",
                            "picker-options": _vm.tcPickerOptions,
                            placeholder: "请选择时间",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.handleFocus(1)
                            },
                          },
                          model: {
                            value: _vm.basicInfo.tcDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.basicInfo, "tcDate", $$v)
                            },
                            expression: "basicInfo.tcDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "textColor textColorRules" }, [
                          _vm._v(
                            "不得晚于优惠券开始时间；不能早于优惠开始前60天"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ditchBox" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: _vm.editDisable },
                        on: {
                          change: function (bool) {
                            return !bool && (_vm.basicInfo.useChannel = "")
                          },
                        },
                        model: {
                          value: _vm.useChannelCheck,
                          callback: function ($$v) {
                            _vm.useChannelCheck = $$v
                          },
                          expression: "useChannelCheck",
                        },
                      },
                      [_c("strong", [_vm._v("使用渠道")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.editDisable || !_vm.useChannelCheck,
                        },
                        model: {
                          value: _vm.basicInfo.useChannel,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "useChannel", $$v)
                          },
                          expression: "basicInfo.useChannel",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("小程序"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("APP"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "textColor ditchText" }, [
                      _vm._v(
                        "\n            若勾选此项，则优惠券会在小程序或 APP当中一条渠道使用；若不勾选此项，则默认从小程序和 APP皆可使用，同时计入用户使用次数。\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "优惠门槛" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.editDisable },
                        on: { change: _vm.changeThresholdWay },
                        model: {
                          value: _vm.basicInfo.yhMK,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "yhMK", $$v)
                          },
                          expression: "basicInfo.yhMK",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("金额"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("数量"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "优惠方式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.editDisable },
                        on: {
                          change: function () {
                            _vm.wayText =
                              _vm.basicInfo.yhWay === 1
                                ? "优惠金额"
                                : "优惠折扣"
                          },
                        },
                        model: {
                          value: _vm.basicInfo.yhWay,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "yhWay", $$v)
                          },
                          expression: "basicInfo.yhWay",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("扣减"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("折扣"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_2" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "discountsRef",
                attrs: {
                  model: _vm.discountsInfo,
                  rules: _vm.discountsInfoRules,
                  "label-width": "100px",
                  size: "medium",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.wayText, prop: "preferenceAmount" } },
                  [
                    _c("el-input", {
                      staticClass: "inputW",
                      attrs: {
                        clearable: "",
                        placeholder: "请输入",
                        disabled: _vm.editDisable,
                      },
                      model: {
                        value: _vm.discountsInfo.preferenceAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.discountsInfo, "preferenceAmount", $$v)
                        },
                        expression: "discountsInfo.preferenceAmount",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.basicInfo.yhWay === 1 ? "元" : "折")
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "使用门槛", required: "" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "condition",
                        attrs: { disabled: _vm.editDisable },
                        on: { change: _vm.useThresholdChange },
                        model: {
                          value: _vm.useThresholdRadio,
                          callback: function ($$v) {
                            _vm.useThresholdRadio = $$v
                          },
                          expression: "useThresholdRadio",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { attrs: { label: 1 } },
                          [
                            _vm._v("\n              满 \n              "),
                            _c(
                              "el-form-item",
                              {
                                ref: "threshold",
                                staticStyle: {
                                  display: "inline-block",
                                  "padding-bottom": "3px",
                                },
                                attrs: { prop: "threshold" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "inputW",
                                  attrs: {
                                    disabled: _vm.useThresholdFlag,
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.discountsInfo.useThreshold,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.discountsInfo,
                                        "useThreshold",
                                        $$v
                                      )
                                    },
                                    expression: "discountsInfo.useThreshold",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.basicInfo.yhMK === 1 ? "元" : "件")
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { staticClass: "cRadio2", attrs: { label: 2 } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.basicInfo.yhMK === 1
                                  ? "满 0.01元（无门槛）"
                                  : "满1件（无门槛）"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        _vm.getRoute === "SalesmanCoupon"
                          ? "二维码额度"
                          : "发行量",
                      prop: "circulation",
                    },
                  },
                  [
                    _c("el-input-number", {
                      staticClass: "inputW",
                      attrs: {
                        min: 1,
                        max: 10000,
                        "controls-position": "right",
                        disabled: _vm.editDisable,
                        placeholder: "需大于1，不超过10000张",
                      },
                      model: {
                        value: _vm.discountsInfo.circulation,
                        callback: function ($$v) {
                          _vm.$set(_vm.discountsInfo, "circulation", $$v)
                        },
                        expression: "discountsInfo.circulation",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("  张")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "每人限领", prop: "collarLimit" } },
                  [
                    _c("el-input-number", {
                      staticClass: "inputW",
                      attrs: {
                        min: 1,
                        max: 20,
                        "controls-position": "right",
                        disabled: _vm.editDisable,
                        placeholder: "请输入",
                      },
                      model: {
                        value: _vm.discountsInfo.collarLimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.discountsInfo, "collarLimit", $$v)
                        },
                        expression: "discountsInfo.collarLimit",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("  张")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#c0c4cc",
                          "padding-left": "15px",
                        },
                      },
                      [_vm._v("1-20张")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.getRoute === "BonusCoupon"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "仅快递配送使用",
                          prop: "isOnlyExpressDelivery",
                          "label-width": "130px",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.editDisable },
                            on: { change: function () {} },
                            model: {
                              value: _vm.discountsInfo.isOnlyExpressDelivery,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.discountsInfo,
                                  "isOnlyExpressDelivery",
                                  $$v
                                )
                              },
                              expression: "discountsInfo.isOnlyExpressDelivery",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_3" } }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "div",
              [
                _vm._v("\n        商品范围：\n        "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.selectProduct },
                  },
                  [_vm._v("选择商品")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.productLoading,
                          expression: "productLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.productDataSource,
                        border: "",
                        height: "400px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "date",
                          label: "序号",
                          "min-width": "60",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      (_vm.productPageParam.pageNum - 1) *
                                        _vm.productPageParam.pageSize +
                                        (scope.$index + 1)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productCode",
                          label: "产品编号",
                          "min-width": "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "产品名称",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productCategoryName",
                          label: "所属类别",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "price",
                          label: "销售价",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                ￥" +
                                    _vm._s(scope.row.productPriceMin) +
                                    " - ￥" +
                                    _vm._s(scope.row.productPriceMax) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "onSaleHospitalNumber",
                          label: "在售医院数",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productStatus",
                          label: "状态",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.findEnumName(
                                        _vm.$api.productStatus,
                                        scope.row.productStatus
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          label: "操作",
                          width: "150",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delProduct(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.productPageParam.pageNum,
                    "page-sizes": _vm.$api.pageSizes,
                    "page-size": _vm.productPageParam.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.productPageParam.total,
                  },
                  on: {
                    "size-change": _vm.productHandleSizeChange,
                    "current-change": _vm.productHandleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_4" } }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "editor" },
            [
              _c("mavon-editor", {
                ref: "md",
                attrs: { toolbars: _vm.toolbars },
                on: {
                  change: _vm.editorChange,
                  imgAdd: _vm.handleEditorImgAdd,
                  imgDel: _vm.handleEditorImgDel,
                },
                model: {
                  value: _vm.editor,
                  callback: function ($$v) {
                    _vm.editor = $$v
                  },
                  expression: "editor",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.$route.query.detailFlag
        ? _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.visible,
            "before-close": _vm.cancelChange,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "搜索产品" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.inquire.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productName", $$v)
                          },
                          expression: "inquire.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.productProps,
                          options: _vm.productTypeData,
                          placeholder: "请输入产品名称",
                        },
                        model: {
                          value: _vm.inquire.productType,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productType", $$v)
                          },
                          expression: "inquire.productType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getProductList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading2,
                      expression: "loading2",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.dataSource2,
                    "row-key": function (row) {
                      return row.productId
                    },
                    "tooltip-effect": "dark",
                    border: "",
                    height: "400px",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      "reserve-selection": true,
                      selectable: function (row) {
                        return row.isThreeFavorable ? false : true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.productName)),
                              ]),
                              _vm._v(" "),
                              scope.row.isThreeFavorable
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "padding-left": "8px",
                                      },
                                    },
                                    [_vm._v("已有6个优惠")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCategoryName",
                      label: "产品类别",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "产品单价", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            ￥" +
                                _vm._s(scope.row.productPriceMin) +
                                " - ￥" +
                                _vm._s(scope.row.productPriceMax) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onSaleHospitalNumber",
                      label: "在售医院数",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productInventoryTotal",
                      label: "产品库存",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam2.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam2.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam2.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange2,
                      "current-change": _vm.handleCurrentChange2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelChange } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: { click: _vm.confirmProductList },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("优惠信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("产品管理")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("使用说明")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }