<template>
  <!-- 已停用医院列表 -->
  <hospitalList :enable="0"/>
</template>

<script>
import hospitalList from './components/hospitalList.vue'

export default {
  components: {
    hospitalList
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
