<template>
  <!-- 业务员二维码弹窗 -->
  <div class="memberQrcodeList">
    <el-dialog :title="`${title ? title+'二维码' : couponData.couponCode+' - 二维码列表'}`" :visible.sync="showDialog"
               width="82%" top="6vh" @close="closeDialog"
    >
      <div class="main-box qrcode_list">
        <!-- 搜索 -->
        <div class="head-search-row search_box">
          <el-form ref="searchForm" :model="queryData" class="demo-form-inline" :inline="true" size="medium">
            <el-form-item v-if="couponType !== 'welfare'" label="业务员姓名" prop="intendantName">
              <el-input
                  v-model="queryData.intendantName"
                  placeholder="业务员姓名"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="!thirdPartyPermissions && couponType !== 'welfare'" label="所属区域" prop="areaId">
              <el-cascader
                  v-if="$public.isAllAreaPower()"
                  v-model="queryData.areaId"
                  :props="addressConfigure"
                  :options="addressList"
                  clearable
                  placeholder="请选择地址"
              />
              <d-area v-else :values.sync="queryData.areaId"/>
            </el-form-item>
            <el-form-item v-if="!thirdPartyPermissions && couponType !== 'welfare'" label="商家端名称"
                          prop="merchantName"
            >
              <el-input
                  v-model="queryData.merchantName"
                  placeholder="商家端名称"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="couponType !== 'welfare'" label="业务员编号" prop="intendantCode">
              <el-input
                  v-model="queryData.intendantCode"
                  placeholder="业务员编号"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item label="二维码编号" prop="qrCodeSerial">
              <el-input
                  v-model="queryData.qrCodeSerial"
                  placeholder="二维码编号"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="couponType === 'welfare'" label="产品名称" prop="productName">
              <el-input
                  v-model="queryData.productName"
                  placeholder="产品名称"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="couponType === 'welfare'" label="批次" prop="consignment">
              <el-input
                  v-model="queryData.consignment"
                  placeholder="批次"
                  clearable
                  @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="couponType === 'welfare'" label="创建时间">
              <div class="flex-box">
                <el-date-picker v-model="queryData.tcDate" type="daterange" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择日期范围" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期"
                />
              </div>
            </el-form-item>
          </el-form>
          <div style="margin-bottom: 20px">
            <el-button size="small" type="primary" @click="updateList('qrcodeTable')">查询</el-button>
            <slot name="searchButton"/>
            <!-- <el-button size="small" type="primary" @click="bindQrcode">绑定二维码</el-button>
            <el-button size="small" type="primary" @click="unbindQrcode(2)">解绑二维码</el-button> -->
          </div>
        </div>
        <!-- 表格 -->
        <div class="qrcodeTable_box">
          <TableList
              ref="qrcodeTable"
              class="table_list"
              height="400px"
              :table-header="tableHeader"
              :loading="loading"
              :table-data="dataList"
              :total="listTotal"
              :is-selection="isSelection"
              row-key-name="qrCodeId"
              :custom-page-size="[10, 20, 30, 50, 100, 1000]"
              @setPage="handlePageChange($event, 'qrcodeTable')"
              @setSize="handleSizeChange($event, 'qrcodeTable')"
              @delXuan="selectionChange"
          >
            <template #operation="{data}">
              <el-button class="btn" type="text" @click="openPreviewQrcode(data)">二维码</el-button>
              <template v-if="showOperateButton">
                <span style="color:#4e9aef;">/</span>
                <el-button class="btn" type="text" @click="unbindQrcode(1, data)">解绑</el-button>
              </template>
            </template>
          </TableList>
        </div>
      </div>
      <span v-if="showFooterButton" slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="confirmOperateQrcode">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看二维码弹窗 -->
    <el-dialog title="查看二维码" :visible.sync="previewQrcodeDialog" width="400px">
      <div style="padding:0 0 10px; text-align:center">
        <img :src="currentRowData.qrCodeUrl" alt="">
      </div>
    </el-dialog>
    <slot/>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { queryQrCodeListByCouponId, batchBoundCouponQrCode, batchUntieCouponQrCode } from '@/api/user.js'
import { queryCouponQrCodeByCouponId } from '@/api/operation'

export default {
  components: {},
  props: {
    // 是否展示操作按钮
    showOperateButton: {
      type: Boolean,
      default: true
    },
    // 是否展示底部按钮
    showFooterButton: {
      type: Boolean,
      default: true
    },
    // 是否开启多选
    isSelection: {
      type: Boolean,
      default: true
    },
    // 列表类型 1-已绑定列表 2-未绑定列表
    title: {
      type: String,
      default: ''
    },
    // 列表类型 1-已绑定列表 2-未绑定列表
    boundType: {
      type: Number,
      default: 1
    },
    // 优惠券数据
    couponData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 优惠券类型
    couponType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      thirdPartyPermissions: false, // 是不是第三方权限组
      // 区域选择下拉配置
      addressConfigure: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],
      // couponData: {}, // 优惠券数据

      showDialog: false, // 列表弹窗
      loading: true, // 列表loading
      dataList: [],
      listTotal: 0,
      // 表格数据
      tableHeader: [
        // 表头
        { label: '二维码编号', prop: 'qrCodeSerial', width: '200px' },
        { label: '业务员编号', prop: 'intendantCode', width: '200px' },
        { label: '业务员姓名', prop: 'intendantName', width: '160px' },
        { label: '业务员手机号', prop: 'intendantPhone', width: '160px' },
        { label: '所属大区', prop: 'regionName', width: '160px' },
        { label: '所属片区', prop: 'districtName', width: '160px' },
        { label: '所属办事处', prop: 'agencyName', width: '160px' },
        { label: '商家端编号', prop: 'merchantCode', width: '200px' },
        { label: '商家端名称', prop: 'merchantName', width: '160px' },
        { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', width: '160px' }
      ],
      // 请求参数
      queryData: {
        pageNum: 1, // 分页参数
        pageSize: 10, // 分页参数
        couponId: '', // 优惠券id
        isBound: 1, // 是否绑定 1.已绑定 2.未绑定
        intendantCode: '', // 业务员编码
        intendantName: '', // 业务员姓名
        merchantName: '', // 商家名称
        qrCodeSerial: '', // 二维码编号
        areaId: '' // 区域id
      },

      previewQrcodeDialog: false, // 二维码预览弹窗
      currentRowData: '', // 当前点击的项
      selectData: [], // 当前选中的数据
      selectedQrCodeIdList: [] // 当前选中的二维码id数组
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取用户信息
    const userInfo = getSessionStorage('userInfo') || {}
    // 判断是不是第三方权限
    this.thirdPartyPermissions = userInfo && userInfo.rightsGroupNames.length == 1 && '第三方权限组'.includes(userInfo.rightsGroupNames)
    if (userInfo.managerType === 'all') {
      this.getAreaList()
    }
    if (this.couponType === 'welfare') {
      this.tableHeader = [
        { label: '二维码编号', prop: 'qrCodeSerial', width: '200px' },
        { label: '产品名称', prop: 'productName', width: '200px' },
        { label: '批次', prop: 'batchNo', width: '160px' },
        { label: '创建时间', prop: 'createTime', width: '160px' },
        { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', width: '160px' }
      ]
    }
  },
  methods: {
    async open(data) {
      // this.couponData = data
      if (this.thirdPartyPermissions) {
        await this.handlerLimit()
      }
      this.showDialog = true
      this.$nextTick(() => {
        // 重置数据
        this.$refs.searchForm.resetFields()
        this.queryData.pageNum = 1
        this.queryData.pageSize = 10
        this.$refs.qrcodeTable.handleCurrentChange(this.queryData.pageNum, false)
        this.$refs.qrcodeTable.handleSizeChange(this.queryData.pageSize, false)
        this.$refs.qrcodeTable.clearSelection()

        this.getQueryQrCodeListByCouponId()
      })
    },
    closeDialog() {
      this.showDialog = false
    },
    // 第三方权限限制
    handlerLimit() {
      /**
       * 对第三方管理权限组，二维码列表中需隐藏“所属区域”、“商家端名称”筛选条件，
       * 隐藏列表中“所属大区” “所属片区” “所属办事处”“商家端编号” “商家端名称”等字段；
       */
      const hideKeyName = ['regionName', 'districtName', 'agencyName', 'merchantCode', 'merchantName']
      hideKeyName.forEach(value1 => {
        this.tableHeader.some((value2, index2) => {
          if (value1 === value2.prop) {
            value2.isHide = true
          }
        })
      })
    },
    // 切换页码条数
    handleSizeChange(size, type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.queryData.pageNum = 1
          this.queryData.pageSize = size
          this.getQueryQrCodeListByCouponId()
          break

        default:
          break
      }
    },
    // 切换页码
    handlePageChange(page, type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.queryData.pageNum = page
          this.getQueryQrCodeListByCouponId()
          break

        default:
          break
      }
    },
    // 刷新列表
    updateList() {
      this.queryData.pageNum = 1
      this.getQueryQrCodeListByCouponId()
    },
    // 批量选中
    selectionChange(rows) {
      this.selectData = rows
    },
    // 打开二维码预览弹窗
    openPreviewQrcode(row) {
      this.currentRowData = row
      this.previewQrcodeDialog = true
    },
    // 确定绑定/解绑二维码
    async confirmOperateQrcode() {
      const loading = await this.$loading({
        lock: true,
        background: 'rgba(0, 0, 0, 0.5)'
      })
      // boundType: 1-已绑定列表 2-未绑定列表
      if (this.boundType === 1) { // 解绑操作
        await this.unbindQrcode(2)
        loading.close()
      } else { // 绑定操作
        await this.bindQrcode()
        loading.close()
      }
    },
    // 获取区域级联数据
    getAreaList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },
    // 获取业务员二维码列表 or 福利二维码列表
    async getQueryQrCodeListByCouponId() {
      // isBound：是否绑定 1.已绑定 2.未绑定
      this.loading = true
      this.dataList = []
      this.queryData.isBound = this.boundType
      this.queryData.couponId = this.couponData.couponId

      // console.log('this.params===>>>>', this.params)
      let res = ''
      if (this.couponType === 'welfare') {
        const params = {
          pageNum: this.queryData.pageNum,
          pageSize: this.queryData.pageSize,
          param: {
            couponId: this.queryData.couponId || '',
            isBound: this.queryData.isBound === 1,
            qrCodeSerial: this.queryData.qrCodeSerial || '',
            productName: this.queryData.productName || '',
            batchNo: this.queryData.batchNo || '',
            createBeginTime: this.queryData.createBeginTime || '',
            createEndTime: this.queryData.createEndTime || ''
          }
        }
        console.log(params, 'params')
        res = await queryCouponQrCodeByCouponId(params)
      } else {
        const params = { ...this.queryData }
        res = await queryQrCodeListByCouponId(params)
      }
      // console.log('res.data===>>>>', res.data)
      if (res.code === 200) {
        this.dataList = res.data.list
        this.listTotal = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    // 绑定二维码
    async bindQrcode() {
      if (!this.selectData.length) {
        return this.$message.warning('请至少选择一个二维码')
      }
      // 获取当前选中的二维码id
      this.selectedQrCodeIdList = []
      this.selectData.forEach(value => {
        this.selectedQrCodeIdList.push(this.couponType === 'welfare' ? value.id : value.qrCodeId)
      })
      const warningText = '绑定后用户扫描二维码可获取到达透出时间的优惠券，是否确认绑定？'
      await this.$confirm(warningText, `确认绑定`, {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async() => {
          const params = {
            couponId: this.couponData.couponId, // 优惠券id
            qrCodeIds: this.selectedQrCodeIdList // 需要绑定的二维码id数组
          }
          const res = await batchBoundCouponQrCode(params)
          if (res.code === 200) {
            this.$message.success('绑定成功')
            // this.updateList()
            this.$emit('update')
            this.showDialog = false
          } else {
            this.$message.success('绑定失败，请刷新后重试')
            console.log('绑定失败==>>', res)
          }
        })
        .catch(err => {
        })
    },
    // 解绑二维码
    async unbindQrcode(type, data) {
      // type：解绑类型  1-单个解绑 2-批量解绑
      if (type === 1) {
        this.selectData = [data]
      } else {
        if (!this.selectData.length) {
          return this.$message.warning('请至少选择一个二维码')
        }
      }
      // 获取当前选中的二维码id
      this.selectedQrCodeIdList = []
      this.selectData.forEach(value => {
        this.selectedQrCodeIdList.push(this.couponType === 'welfare' ? value.id : value.couponQrCodeId)
      })
      const warningText = '解绑后，用户扫描二维码将无法获取优惠券，是否解绑？'
      await this.$confirm(warningText, `确认解绑`, {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async() => {
        const params = this.selectedQrCodeIdList
        const res = await batchUntieCouponQrCode(params)
        if (res.code === 200) {
          this.$message.success('解绑成功')
          this.$emit('update')
          if (type === 1) {
            this.updateList()
          } else {
            // this.updateList()
            this.showDialog = false
          }
        } else {
          this.$message.success('解绑失败，请刷新后重试')
          console.log('解绑失败==>>', res)
        }
      }).catch(() => {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.qrcodeTable_box {
  padding: 0 20px 10px;

  .table_list {
    width: 100%;
  }
}
</style>
