var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qrCode_dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "" + _vm.dialogTitle,
            visible: _vm.qrcodeData.visible,
            width: "55%",
            top: "4vh",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.qrcodeData, "visible", $event)
            },
            close: function ($event) {
              return _vm.openQrCode(false)
            },
          },
        },
        [
          _vm._t("tips"),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "qrCodeForm_ref",
              attrs: {
                model: _vm.formData,
                rules: _vm.qrcodeData.formRules,
                "label-width": "130px",
                size: "medium",
              },
            },
            [
              _vm.qrcodeData.dialogType === "edit" && _vm.showItem("preview")
                ? _c(
                    "el-form-item",
                    { key: "preview", attrs: { prop: "preview" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.qrcodePreview_dialog = true
                            },
                          },
                        },
                        [_vm._v("查看二维码")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showItem("specifiedPath")
                ? _c(
                    "el-form-item",
                    {
                      key: "specifiedPath",
                      attrs: { label: "指定路径", prop: "specifiedPath" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-260",
                          attrs: { placeholder: "请选择指定路径" },
                          on: { change: _vm.changePath },
                          model: {
                            value: _vm.formData.specifiedPath,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "specifiedPath", $$v)
                            },
                            expression: "formData.specifiedPath",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "首页", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "产品类别", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "产品详情页", value: 3 },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.formData.specifiedPath !== 1,
                              expression: "formData.specifiedPath !== 1",
                            },
                          ],
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c("el-cascader", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.formData.specifiedPath === 2,
                                expression: "formData.specifiedPath === 2",
                              },
                            ],
                            ref: "productCategory_cascader",
                            staticClass: "width-260",
                            attrs: {
                              clearable: "",
                              props: _vm.productTypeProps,
                              options: _vm.productTypeList,
                            },
                            on: { change: _vm.changeCategory },
                            model: {
                              value: _vm.productCategoryIdVal,
                              callback: function ($$v) {
                                _vm.productCategoryIdVal = $$v
                              },
                              expression: "productCategoryIdVal",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.specifiedPath === 3,
                                  expression: "formData.specifiedPath === 3",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseProduct(true, "path")
                                    },
                                  },
                                },
                                [_vm._v("选择产品")]
                              ),
                              _vm._v(" "),
                              _vm.productData.previewList.length
                                ? _c("TableList", {
                                    attrs: {
                                      "table-header":
                                        _vm.productData.tableHeader,
                                      "table-data": _vm.productData.previewList,
                                      "is-show-page": false,
                                      "is-show-index": false,
                                      "is-fix-height": false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "productCategoryName",
                                          fn: function (ref) {
                                            var data = ref.data
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    data.productCategoryName ||
                                                      data.categoryName ||
                                                      "-"
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "productStatus",
                                          fn: function (ref) {
                                            var data = ref.data
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$api.getValue(
                                                      _vm.$api.productStatus,
                                                      data.productStatus,
                                                      "name"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      122717193
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.qrcodeData.dialogType === "add" && _vm.showItem("isEffect")
                ? _c(
                    "el-form-item",
                    {
                      key: "isEffect",
                      attrs: { label: "是否立即生效", prop: "isEffect" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "width-110",
                          model: {
                            value: _vm.formData.isEffect,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isEffect", $$v)
                            },
                            expression: "formData.isEffect",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "tips_text" }, [
                        _vm._v(
                          "若选择“是”，则二维码生成后，用户即可扫码领取红包。"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showItem("redPacketAmount")
                ? _c(
                    "el-form-item",
                    {
                      key: "redPacketAmount",
                      attrs: { label: "红包金额", prop: "redPacketAmount" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "width-110",
                        attrs: {
                          disabled: _vm.disabledEdit("redPacketAmount"),
                          clearable: "",
                          placeholder: "红包金额",
                          maxlength: "6",
                        },
                        model: {
                          value: _vm.formData.redPacketAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "redPacketAmount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.redPacketAmount",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "tips_text" }, [
                        _vm._v("金额需在0.1~200元之间，保留2位小数。"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.qrcodeData.dialogType === "add" && _vm.showItem("createNum")
                ? _c(
                    "el-form-item",
                    {
                      key: "createNum",
                      attrs: { label: "数量", prop: "createNum" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "width-260",
                        attrs: { clearable: "", placeholder: "生成二维码数量" },
                        model: {
                          value: _vm.formData.createNum,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "createNum",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.createNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showItem("linkBatch")
                ? _c(
                    "el-form-item",
                    {
                      key: "linkBatch",
                      attrs: { label: _vm.batchTxt, prop: "linkBatch" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.disabledEdit("linkBatch"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.chooseProduct(true, "batch")
                            },
                          },
                        },
                        [_vm._v("选择产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.linkBatchProduct.previewList.length ||
                                _vm.batchRequire,
                              expression:
                                "linkBatchProduct.previewList.length || batchRequire",
                            },
                          ],
                        },
                        [
                          _c("TableList", {
                            attrs: {
                              "table-header": _vm.linkBatchProduct.tableHeader,
                              "table-data": _vm.linkBatchProduct.previewList,
                              "is-show-page": false,
                              "is-show-index": false,
                              "is-fix-height": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "productCategoryName",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data.productCategoryName ||
                                              data.categoryName ||
                                              "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "productStatus",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$api.getValue(
                                              _vm.$api.productStatus,
                                              data.productStatus,
                                              "name"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "operation",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled:
                                              _vm.disabledEdit("linkBatch"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteBatchProduct(
                                                data
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1485325296
                            ),
                          }),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "batchNo",
                                "hide-required-asterisk": "",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "width-260",
                                attrs: {
                                  disabled: _vm.disabledEdit("linkBatch"),
                                  clearable: "",
                                  placeholder: "请填写批次",
                                },
                                model: {
                                  value: _vm.formData.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "batchNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.qrcodeData.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "preview_qrcodeImg",
          attrs: {
            width: "400px",
            title: "查看二维码",
            visible: _vm.qrcodePreview_dialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.qrcodePreview_dialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "preview_box" }, [
            _c("img", {
              staticClass: "qrcode_img",
              attrs: { src: _vm.detailData.qrCodeUrl, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "preview_btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadSingleQrCode },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "select_merchants",
          attrs: {
            title: "选择产品",
            visible: _vm.productData.visible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.productData, "visible", $event)
            },
            close: function ($event) {
              return _vm.chooseProduct(false)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "search_box",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "搜索产品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("productTable_update")
                      },
                    },
                    model: {
                      value: _vm.productQueryData.param.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.productQueryData.param, "productName", $$v)
                      },
                      expression: "productQueryData.param.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品类别" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择产品类别" },
                      model: {
                        value: _vm.productQueryData.param.productClassify,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.productQueryData.param,
                            "productClassify",
                            $$v
                          )
                        },
                        expression: "productQueryData.param.productClassify",
                      },
                    },
                    _vm._l(_vm.$api.orderClassify, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateList("productTable_update")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            ref: "productTable_ref",
            attrs: {
              height: "300px",
              loading: _vm.productData.loading,
              "table-header": _vm.productData.tableHeader,
              "table-data": _vm.productData.list,
              total: _vm.productData.total,
              "highlight-current-row": true,
              "set-page": _vm.productQueryData.pageNum,
              "set-size": _vm.productQueryData.pageSize,
            },
            on: {
              "update:setPage": function ($event) {
                return _vm.$set(_vm.productQueryData, "pageNum", $event)
              },
              "update:set-page": function ($event) {
                return _vm.$set(_vm.productQueryData, "pageNum", $event)
              },
              "update:setSize": function ($event) {
                return _vm.$set(_vm.productQueryData, "pageSize", $event)
              },
              "update:set-size": function ($event) {
                return _vm.$set(_vm.productQueryData, "pageSize", $event)
              },
              setPage: function ($event) {
                return _vm.pageChange($event, "productTable_page")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "productTable_size")
              },
              rowClick: function ($event) {
                return _vm.currentRow($event, "productTable_row")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "productCategoryName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          data.productCategoryName || data.categoryName || "-"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "productStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.productStatus,
                            data.productStatus,
                            "name"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.chooseProduct(false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSelectProduct },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }