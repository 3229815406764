<!-- 商家类型配置页 -->
<template>
  <div class="main-box">
    <div class="header">
      <div class="title">商家类型配置</div>
      <el-button size="small" type="primary" @click="addMerchantType">新增商家类型</el-button>
    </div>
    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="tableLoading" :data="tableDataSource" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantType" label="商家类型" min-width="160" />
            <el-table-column prop="createTime" label="新增时间" min-width="160" />
            <el-table-column prop="createBy" label="操作者" min-width="140" />
            <el-table-column label="操作项" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="merchantTypeTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 新增 & 编辑 -->
    <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="450px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="140px">
          <el-form-item label="商家类型名称" prop="merchantType">
            <el-input v-model="submitForm.merchantType" maxlength="10" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">关 闭</el-button>
        <el-button type="primary" @click="submitMerchantClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      pageParam: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
      dialogVisible: false,
      dialogTitle: "商家类型",
      submitForm: {
        merchantType: "",
      },
      submitRules: {
        merchantType: [{ required: true, message: "请输入商家类型名称", trigger: "change" }],
      },
      merchantId: "",
    };
  },
  created() { },
  computed: {
    // 获取商家类型数据
    tableDataSource() {
      return this.$store.state.user.merchantTypeData;
    },
    // 获取列表总数
    merchantTypeTotal(){
      return this.$store.state.user.merchantTypeTotal;
    }
  },
  methods: {
    // 新增商家类型
    addMerchantType() {
      this.dialogVisible = true;
      this.dialogTitle = "新增商家类型";
      this.submitForm.merchantType = "";
    },
    // 编辑商家类型
    handleEdit(row) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑商家类型";
      this.submitForm.merchantType = row.merchantType; // 赋值
      this.merchantId = row.id;
    },
    // 提交商家类型
    submitMerchantClick() {
      this.$refs.submitForm.validate((valid) => {
        if (valid) {
          const param = {
            id: this.merchantId,
            merchantType: this.submitForm.merchantType,
          };
          this.$http.requestPut({ url: "/customer/merchant/saveOrUpdateMerchantType", param })
          .then((res) => {
            if (res.code === 200) {
              this.dialogVisible = false;
              this.$message.success("操作成功");
              this.$store.dispatch('user/getMerchantTypeList');
            }
          });
        }
      });
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.submitForm.resetFields();
    },

    // 分页[暂无分页功能]
    handleSizeChange(size) {
      // this.pageParam.pageNum = 1;
      // this.pageParam.pageSize = 50;
    },
    handleCurrentChange(page) {
      // this.pageParam.pageNum = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px;
  padding-bottom: 0;
  .title {
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid #b2b2b2;
    padding-bottom: 10px;
    opacity: 0.8;
  }
  .el-button {
    margin-top: 14px;
  }
}
</style>
