<template>
  <div class="qrCode_dialog">
    <!-- 创建二维码弹窗 -->
    <el-dialog :title="`${dialogTitle}`" :visible.sync="qrcodeData.visible" width="55%" top="4vh" @close="openQrCode(false)">
      <slot name="tips"></slot>

      <el-form ref="qrCodeForm_ref" :model="formData" :rules="qrcodeData.formRules" label-width="130px" size="medium">
        <el-form-item v-if="qrcodeData.dialogType === 'edit' && showItem('preview')" key="preview" prop="preview">
          <el-button type="text" @click="qrcodePreview_dialog = true">查看二维码</el-button>
        </el-form-item>

        <el-form-item v-if="showItem('specifiedPath')" key="specifiedPath" label="指定路径" prop="specifiedPath">
          <el-select v-model="formData.specifiedPath" class="width-260" placeholder="请选择指定路径" @change="changePath">
            <el-option label="首页" :value="1" />
            <el-option label="产品类别" :value="2" />
            <el-option label="产品详情页" :value="3" />
          </el-select>
          <div v-show="formData.specifiedPath !== 1" style="margin-top: 10px">
            <!-- 产品类别 -->
            <el-cascader
              v-show="formData.specifiedPath === 2"
              ref="productCategory_cascader"
              v-model="productCategoryIdVal"
              class="width-260"
              clearable
              :props="productTypeProps"
              :options="productTypeList"
              @change="changeCategory"
            />
            <!-- 产品详情页-选择产品 -->
            <div v-show="formData.specifiedPath === 3">
              <el-button type="primary" @click="chooseProduct(true, 'path')">选择产品</el-button>
              <TableList
                v-if="productData.previewList.length"
                :table-header="productData.tableHeader"
                :table-data="productData.previewList"
                :is-show-page="false"
                :is-show-index="false"
                :is-fix-height="false"
              >
                <template #productCategoryName="{data}">
                  {{ data.productCategoryName || data.categoryName || '-' }}
                </template>
                <template #productStatus="{data}">
                  {{ $api.getValue($api.productStatus, data.productStatus, 'name') }}
                </template>
              </TableList>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="qrcodeData.dialogType === 'add' && showItem('isEffect')" key="isEffect" label="是否立即生效" prop="isEffect">
          <el-radio-group v-model="formData.isEffect" class="width-110">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
          <span class="tips_text">若选择“是”，则二维码生成后，用户即可扫码领取红包。</span>
        </el-form-item>

        <el-form-item v-if="showItem('redPacketAmount')" key="redPacketAmount" label="红包金额" prop="redPacketAmount">
          <el-input
            v-model.trim="formData.redPacketAmount"
            :disabled="disabledEdit('redPacketAmount')"
            class="width-110"
            clearable
            placeholder="红包金额"
            maxlength="6"
          />
          <span class="tips_text">金额需在0.1~200元之间，保留2位小数。</span>
        </el-form-item>

        <el-form-item v-if="qrcodeData.dialogType === 'add' && showItem('createNum')" key="createNum" label="数量" prop="createNum">
          <el-input v-model.trim="formData.createNum" class="width-260" clearable placeholder="生成二维码数量" />
        </el-form-item>

        <el-form-item v-if="showItem('linkBatch')" key="linkBatch" :label="batchTxt" prop="linkBatch">
          <el-button type="primary" :disabled="disabledEdit('linkBatch')" @click="chooseProduct(true, 'batch')">选择产品</el-button>
          <!-- 产品预览 -->
          <div v-show="linkBatchProduct.previewList.length || batchRequire">
            <TableList
              :table-header="linkBatchProduct.tableHeader"
              :table-data="linkBatchProduct.previewList"
              :is-show-page="false"
              :is-show-index="false"
              :is-fix-height="false"
            >
              <template #productCategoryName="{data}">
                {{ data.productCategoryName || data.categoryName || '-' }}
              </template>
              <template #productStatus="{data}">
                {{ $api.getValue($api.productStatus, data.productStatus, 'name') }}
              </template>
              <template #operation="{data}">
                <el-button type="text" :disabled="disabledEdit('linkBatch')" @click="deleteBatchProduct(data)">删除</el-button>
              </template>
            </TableList>
            <el-form-item prop="batchNo" hide-required-asterisk>
              <el-input v-model.trim="formData.batchNo" :disabled="disabledEdit('linkBatch')" class="width-260" clearable placeholder="请填写批次" />
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrcodeData.visible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看二维码弹窗 -->
    <el-dialog class="preview_qrcodeImg" width="400px" :title="`查看二维码`" :visible.sync="qrcodePreview_dialog">
      <div class="preview_box">
        <img class="qrcode_img" :src="detailData.qrCodeUrl" alt="" />
      </div>
      <div slot="footer" class="preview_btn">
        <el-button type="primary" @click="downloadSingleQrCode">下载</el-button>
      </div>
    </el-dialog>

    <!-- 选择产品弹窗 -->
    <el-dialog class="select_merchants" title="选择产品" :visible.sync="productData.visible" width="50%" @close="chooseProduct(false)">
      <el-form class="search_box" :inline="true" size="medium">
        <el-form-item label="搜索产品">
          <el-input
            v-model="productQueryData.param.productName"
            placeholder="请输入产品名称"
            clearable
            @keyup.enter.native="updateList('productTable_update')"
          />
        </el-form-item>
        <el-form-item label="产品类别">
          <el-select v-model="productQueryData.param.productClassify" clearable placeholder="请选择产品类别">
            <el-option v-for="item in $api.orderClassify" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="updateList('productTable_update')">查询</el-button>
        </el-form-item>
      </el-form>
      <TableList
        ref="productTable_ref"
        height="300px"
        :loading="productData.loading"
        :table-header="productData.tableHeader"
        :table-data="productData.list"
        :total="productData.total"
        :highlight-current-row="true"
        :set-page.sync="productQueryData.pageNum"
        :set-size.sync="productQueryData.pageSize"
        @setPage="pageChange($event, 'productTable_page')"
        @setSize="sizeChange($event, 'productTable_size')"
        @rowClick="currentRow($event, 'productTable_row')"
      >
        <template #productCategoryName="{data}">
          {{ data.productCategoryName || data.categoryName || '-' }}
        </template>
        <template #productStatus="{data}">
          {{ $api.getValue($api.productStatus, data.productStatus, 'name') }}
        </template>
      </TableList>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseProduct(false)">取 消</el-button>
        <el-button type="primary" @click="confirmSelectProduct">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getForAndOnShelvesProductList, qrCodeDetail } from '@/api/operation.js'

export default {
  components: {},
  props: {
    dialogTitle: {
      type: String,
      default: '创建二维码'
    },
    // 二维码类型【必填】，如 redPacket:红包二维码
    qrCodeType: {
      type: String,
      required: true,
      default: ''
    },
    // 是否开启限制修改
    openLimitModify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 创建二维码表单数据
      formData: {
        specifiedPath: 3, // 指定路径类型 1.首页 2.产品列表 3.产品详情
        specifiedPathId: '', // 跳转路径id
        isEffect: '', // 立即生效 1生效 2不生效
        redPacketAmount: '', // 金额
        createNum: '', // 数量
        assignMerchantTypeList: [], // 指定扫码商家类型id集字段
        batchNo: '', // 红包关联批次
        productId: '' // 关联批次产品id
      },
      // 二维码数据
      qrcodeData: {
        visible: false,
        dialogType: 'add', // add-新增  edit-编辑
        formRules: {
          specifiedPath: [{ required: true, message: '请选择路径', trigger: 'change' }],
          isEffect: this.checkFormValidator('effect_valid'),
          redPacketAmount: this.checkFormValidator('money_valid'),
          createNum: this.checkFormValidator('createNum_valid'),
          batchNo: this.checkFormValidator('batchNo_valid')
        }
      },

      detailData: {}, // 二维码详情数据
      qrcodePreview_dialog: false, // 查看二维码弹窗

      // 产品类别
      productTypeProps: {
        // 产品类别联级选择器配置
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      productCategoryIdVal: '', // 产品类别
      productTypeList: [], // 产品类别数据

      // 选择产品请求数据
      productQueryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          productName: '', // 产品名称
          productClassify: '' // 产品类别
        }
      },
      // 选择产品数据
      productData: {
        visible: false,
        visibleType: '', // 弹窗类型：path-指定路径，batch-红包关联批次，
        loading: false,
        tableHeader: [
          { label: '产品编号', prop: 'productCode' },
          { label: '产品名称', prop: 'productName' },
          { label: '所属类别', type: 'slot', slotName: 'productCategoryName' },
          { label: '状态', type: 'slot', slotName: 'productStatus' }
        ],
        list: [],
        total: 0,

        currentSelect: '', // 当前选中的数据
        previewList: [] // 预览回显的数据
      },

      // 关联批次产品数据
      linkBatchProduct: {
        tableHeader: [
          { label: '产品编号', prop: 'productCode' },
          { label: '产品名称', prop: 'productName' },
          { label: '所属类别', type: 'slot', slotName: 'productCategoryName' },
          { label: '状态', type: 'slot', slotName: 'productStatus' },
          { label: '操作', type: 'slot', slotName: 'operation' }
        ],
        previewList: []
      }
    }
  },
  computed: {
    // 关联批次文本
    batchTxt() {
      const batchMap = { redPacket: '红包', couponQrCode: '优惠券' }
      const batchName = batchMap[this.qrCodeType] || '二维码'
      return `${batchName}关联批次`
    },
    // 批次必填
    batchRequire() {
      const arr = ['redPacketBatch', 'couponBatch']
      return arr.includes(this.qrCodeType)
    }
  },
  watch: {},
  created() {},
  methods: {
    // 控制表单项显示/隐藏
    showItem(val) {
      const formItemMap = {
        redPacket: ['preview', 'specifiedPath', 'isEffect', 'redPacketAmount', 'createNum', 'linkBatch'],
        redPacketBatch: ['linkBatch'], // 红包二维码关联批次
        redPacketAmount: ['redPacketAmount'], // 红包二维码红包金额
        couponQrCode: ['preview', 'specifiedPath', 'createNum', 'linkBatch'], //优惠券二维码
        couponBatch: ['batchCont', 'linkBatch'] //设置优惠券二维码关联批次
      }
      const formItemVisible = formItemMap[this.qrCodeType] || []
      return formItemVisible.includes(val)
    },
    // 控制某一项不可以编辑
    disabledEdit(val) {
      // 注意要开启limitModify
      const formItemMap = {
        redPacket: ['redPacketAmount', 'linkBatch']
      }
      const formItemVisible = formItemMap[this.qrCodeType] || []
      return formItemVisible.includes(val) && this.openLimitModify
    },

    // 校验表单自定义的规则
    checkFormValidator(validType) {
      switch (validType) {
        case 'effect_valid': // 是否立即生效
          return [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.qrcodeData.dialogType === 'add') {
                  if (!value && value !== 0) {
                    return callback('请选择是否立即生效')
                  }
                }
                callback()
              }
            }
          ]

        case 'money_valid': // 红包金额
          return [
            { required: true, message: '请输入红包金额', trigger: ['change', 'blur'] },
            {
              required: true,
              trigger: ['change', 'blur'],
              validator: (rule, value, callback) => {
                const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
                if (!reg.test(value) || value < 0.1 || value > 200) {
                  return callback(new Error('请输入0.1~200之间的数字，保留2位小数'))
                }
                callback()
              }
            }
          ]

        case 'createNum_valid': // 生成数量
          return [
            {
              required: true,
              trigger: ['change', 'blur'],
              validator: (rule, value, callback) => {
                const reg = /^[1-9]\d*$/
                if (!value && value !== 0) {
                  return callback('请输入生成二维码数量')
                }
                if (value <= 0 || !reg.test(value)) {
                  return callback(new Error('请输入大于0的整数'))
                }
                callback()
              }
            }
          ]

        case 'batchNo_valid': // 红包关联批次
          return [
            {
              required: true,
              trigger: ['change', 'blur'],
              validator: (rule, value, callback) => {
                const reg = /^[^\u4e00-\u9fa5]+$/
                if (this.linkBatchProduct.previewList.length) {
                  if (!value && value !== 0) {
                    return callback(new Error('请输入批次号'))
                  }
                  if (!reg.test(value)) {
                    return callback(new Error('请输入数字和英文字母'))
                  }
                  callback()
                }
                callback()
              }
            }
          ]

        default:
          return []
      }
    },
    // 刷新列表
    updateList(type) {
      switch (type) {
        case 'productTable_update': // 产品列表
          this.productQueryData.pageNum = 1
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 切换页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'productTable_page': // 产品列表
          this.productQueryData.pageNum = pageVal
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 切换条目
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'productTable_size': // 产品列表
          this.productQueryData.pageSize = sizeVal
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 选中当前行
    currentRow(row, type) {
      switch (type) {
        case 'productTable_row': // 产品列表
          this.productData.currentSelect = row
          break

        default:
          break
      }
    },
    // 清除校验
    clearFields(refName) {
      this.$refs[refName] && this.$refs[refName].resetFields()
    },

    // 打开/关闭 二维码弹窗
    async openQrCode(isOpen, openType, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      // openType: add-添加 edit-编辑
      // row: 当前项
      if (isOpen) {
        this.qrcodeData.dialogType = openType
        this.clickRowData = row || {}
        // 获取产品类别
        this.getProductType()

        if (openType === 'edit') {
          // 获取详情回显数据
          const res = await qrCodeDetail(row.redPacketQrCodeId || row.id)
          if (res.code === 200) {
            const { data } = res
            // 接收详情参数
            this.detailData = data
            // 处理回显数据
            Object.keys(this.formData).forEach(key1 => {
              Object.keys(data).forEach(key2 => {
                if (key1 === key2) {
                  this.formData[key1] = data[key2]
                }
              })
            })
            // 处理指定路径回显数据
            this.formData.specifiedPath = data.specifiedPath
            if (data.specifiedPath == 2) {
              this.productCategoryIdVal = data.specifiedPathId || ''
            } else if (data.specifiedPath == 3) {
              this.productData.previewList = (data.productQrCodeDetailVo && [data.productQrCodeDetailVo]) || []
            }
            // 处理批次回显数据
            this.linkBatchProduct.previewList = (data.product && [data.product]) || []
          }
        }
      } else {
        // 关闭弹窗，重置数据
        this.clearFields('qrCodeForm_ref')
        this.formData = Object.assign({}, this.$options.data.call(this).formData)
        this.productCategoryIdVal = ''
        this.productData.previewList = []
        this.linkBatchProduct.previewList = []
      }
      this.qrcodeData.visible = isOpen
    },
    // 确认新增/编辑二维码
    submitForm() {
      // 校验数据
      if (this.showItem('specifiedPath') && this.formData.specifiedPath === 3 && !this.productData.previewList.length) {
        return this.$message.warning('请选择产品')
      }
      if (this.formData.specifiedPath === 2 && !this.productCategoryIdVal) {
        return this.$message.warning('请选择产品类别')
      }
      if (this.batchRequire && !this.linkBatchProduct.previewList.length) {
        return this.$message.warning('请选择产品')
      }

      this.$refs.qrCodeForm_ref.validate(valid => {
        if (valid) {
          if (this.formData.specifiedPath === 3) { // 产品详情
            // 有值才赋值
            if (this.productData.previewList.length) {
              this.formData.specifiedPathId = this.productData.previewList[0].productId
            }
          } else if (this.formData.specifiedPath === 2) { // 分类
            this.formData.specifiedPathId = this.productCategoryIdVal || ''
          }

          if (this.linkBatchProduct.previewList.length) { // 关联批次
            this.formData.productId = this.linkBatchProduct.previewList[0].productId
          }
          // 删除多余的属性
          this.formData.linkBatch && delete this.formData.linkBatch
          this.$emit('getValue', this.formData)

          setTimeout(() => {
            this.openQrCode(false)
          }, 100)
        }
      })
    },

    // 改变指定路径
    changePath(val) {
      this.productCategoryIdVal = ''
      this.productData.previewList = []
      if (val === 2) {
        // 获取产品类别
        this.getProductType()
      }
    },
    // 改变产品类别
    changeCategory(val) {
      if (val == 0) {
        // 当点击全部时 折叠下拉选项
        this.$refs.productCategory_cascader.toggleDropDownVisible()
      }
    },

    // 打开/关闭选择产品弹窗
    chooseProduct(isOpen, type) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        this.productData.visibleType = type
        this.getProductPage()
      } else {
        this.productQueryData = Object.assign({}, this.$options.data.call(this).productQueryData)
        this.productData.list = []
        this.productData.currentSelect = ''
      }
      this.productData.visible = isOpen
    },
    // 确定选择产品
    confirmSelectProduct() {
      const { currentSelect } = this.productData
      if (this.productData.visibleType === 'path') {
        // 指定路径选择产品
        this.productData.previewList = currentSelect ? [currentSelect] : this.productData.previewList
      } else {
        // 红包关联批次选择产品
        this.linkBatchProduct.previewList = currentSelect ? [currentSelect] : this.linkBatchProduct.previewList
      }
      this.chooseProduct(false)
    },
    // 删除批次关联的产品
    deleteBatchProduct(data) {
      // this.linkBatchProduct.previewList = this.linkBatchProduct.previewList.filter(val => { return val.productId !== data.productId })
      this.linkBatchProduct.previewList = []
      this.formData.batchNo = ''
    },

    // 获取产品类别
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data
        this.productTypeList.unshift({ categoryId: '0', categoryName: '全部' })
      })
    },
    // 获取产品列表
    async getProductPage() {
      this.productData.loading = true
      const param = { ...this.productQueryData }
      const res = await getForAndOnShelvesProductList(param)
      if (res.code === 200) {
        this.productData.list = res.data.list || []
        this.productData.total = res.data.total || 0
      }
      this.productData.loading = false

      this.$nextTick(() => {
        if (this.productData.currentSelect) {
          // 高亮手动选中的数据
          const item = this.productData.list.find(item => item.productId === this.productData.currentSelect.productId)
          this.$refs.productTable_ref.setCurrentRow(item)
        } else {
          // 高亮默认已选中数据
          if (this.productData.visibleType === 'path') {
            // 指定路径选择产品
            if (this.productData.previewList.length) {
              const item = this.productData.list.find(item => item.productId == this.productData.previewList[0].productId)
              this.$refs.productTable_ref.setCurrentRow(item)
            }
          } else {
            // 红包关联批次选择产品
            if (this.linkBatchProduct.previewList.length) {
              const item = this.productData.list.find(item => item.productId == this.linkBatchProduct.previewList[0].productId)
              this.$refs.productTable_ref.setCurrentRow(item)
            }
          }
        }
      })
    },

    // 下载单个二维码
    downloadSingleQrCode() {
      // 文件下载地址
      const fileUrl = this.detailData.qrCodeUrl
      const myHeaders = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain'
      })

      // 发起 Fetch 请求
      fetch(fileUrl, { method: 'GET', headers: myHeaders, mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const fileName = this.currentClickData.qrCodeSerial
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob) // 将图片的src赋值给a节点的href
          a.download = fileName // 下载的文件名称
          a.click()
          a.remove()
        })
        .catch(error => console.error('下载失败：', error))
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .table {
  width: 100%;
  margin: 10px 0;
}
.tips_text {
  color: #7f7f7f;
  margin-left: 15px;
}
.preview_qrcodeImg {
  .preview_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .qrcode_img {
      width: 250px;
    }
  }
  .preview_btn {
    text-align: center;
  }
}
.batch_num {
  padding: 10px 0 20px;
}
</style>
