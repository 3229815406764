<!-- 平台 & 业务员共享列表 -->
<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="优惠券ID">
          <el-input v-model="query.couponCode" placeholder="请输入优惠券ID" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="优惠券名称">
          <el-input v-model="query.couponName" placeholder="请输入优惠券名称" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="透出时间">
          <div class="flex-box">
            <el-date-picker v-model="query.tcDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
              placeholder="请选择日期范围" @change="queryListInfo" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="query.couponStatus" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.couponStatusOptions" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用人群">
          <el-select v-model="query.couponUsePerson" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.couponUsePerson" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <!-- group -->
        <el-form-item label="定向分组">
          <el-select v-model="query.orientedGroupId" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in groupData" :key="item.id" :label="item.categoryName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="禁用分组">
          <el-select v-model="query.disableGroupId" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in groupData" :key="item.id" :label="item.categoryName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="使用渠道">
          <el-select v-model="query.useChannel" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.useChannel" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryListInfo">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="added">
      <el-button size="small" type="primary" @click="addCoupon">添加优惠券</el-button>
    </div>
    <div class="table">
      <el-main>
        <template>
          <el-table :data="dataSource" v-loading="tableLoading" border height="330px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="couponName" label="优惠券名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="couponCode" label="优惠券ID" min-width="180" show-overflow-tooltip />
            <el-table-column prop="orientedPopulationDesc" label="适用人群" min-width="100">
              <!-- <template slot-scope="scope">
                {{ findEnumName($api.couponUsePerson, scope.row.orientedPopulationDesc) }}
              </template> -->
            </el-table-column>
            <el-table-column prop="preferenceMode" label="优惠方式" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.preferenceMode, scope.row.preferenceMode) }}
              </template>
            </el-table-column>
            <el-table-column prop="useChannelDesc" label="使用渠道" min-width="100" />
            <el-table-column prop="couponStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.couponStatus, scope.row.couponStatus) }}
              </template>
            </el-table-column>
            <el-table-column v-if="couponType === 'salesperson'" prop="qrCodeCount" label="二维码数量" min-width="100" />
<!--            <el-table-column prop="couponSurplus" label="优惠券剩余量" min-width="120" />-->
            <el-table-column v-if="couponTypeShow" prop="" label="定向分组" min-width="180">
              <template slot-scope="scope">
                <el-tooltip placement="top">
                  <div slot="content" class="table-tip-box">
                    {{ scope.row.orientedGroupDesc }}
                  </div>
                  <div class="table-ellipsis">{{ scope.row.orientedGroupDesc }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column v-if="couponTypeShow" prop="" label="禁用分组" min-width="180">
              <template slot-scope="scope">
                <el-tooltip placement="top">
                  <div slot="content" class="table-tip-box">
                    {{ scope.row.disableGroupDesc }}
                  </div>
                  <div class="table-ellipsis">{{ scope.row.disableGroupDesc }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="penetrateTime" label="透出时间" min-width="160" />
            <el-table-column prop="" label="已领取" min-width="110" >
              <template slot-scope="scope">
                <div >
                  {{scope.row.alreadyReceived}}张
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="已使用" min-width="110">
              <template slot-scope="scope">
                <div >
                  {{scope.row.haveBeenUsed}}张
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="180" fixed="right">
              <template slot-scope="scope">
                <div v-if="scope.row.couponStatus !== '4'">
                  <span class="operation-btn" @click="editCoupon(scope.row)">编辑</span>
                  <span class="operation-btn_partition"> / </span>
                  <template v-if="couponType === 'salesperson' || couponType === 'welfare'">
                    <span class="operation-btn" @click="openQrcodeList(scope.row)">二维码列表</span>
                    <span class="operation-btn_partition"> / </span>
                  </template>
                  <span class="operation-btn" @click="endCoupon(scope.row)">结束</span>
                </div>
                <div v-else>
                  <span class="operation-btn" @click="delCoupon(scope.row.couponId)">删除</span>
                  <span class="operation-btn_partition"> / </span>
                  <template v-if="couponType === 'salesperson' || couponType === 'welfare'">
                    <span class="operation-btn" @click="openQrcodeList(scope.row)">二维码列表</span>
                    <span class="operation-btn_partition"> / </span>
                  </template>
                  <span class="operation-btn" @click="editCoupon(scope.row)">查看</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize"
            :total="pageParam.total"
            @size-change="handleSizeChange($event, 'couponTable')"
            @current-change="handleCurrentChange($event, 'couponTable')"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 弹窗相关 -start -->
    <!-- 二维码列表弹窗组件 -->
    <memberQrcodeList ref="memberQrcodeList" :coupon-data="couponData" :is-selection="false" :show-footer-button="false" :coupon-type="couponType" @update="updateMemberQrcodeList('single')">
      <template #searchButton>
        <el-button size="small" type="primary" @click="bindQrcodeOperate(1)">绑定二维码</el-button>
        <el-button size="small" type="primary" @click="bindQrcodeOperate(2)">解绑二维码</el-button>
      </template>
      <template>
        <memberQrcodeList
          ref="bindQrcodeList"
          :title="boundTitle"
          :coupon-data="couponData"
          :bound-type="boundType"
          :show-operate-button="false"
          :is-selection="true"
          :coupon-type="couponType"
          @update="updateMemberQrcodeList"
          />
      </template>
    </memberQrcodeList>
    <!-- 弹窗相关 -end -->
  </div>
</template>

<script>
import memberQrcodeList from './components/memberQrcodeList'
export default {
  name: 'ShareList',
  components: { memberQrcodeList },
  props: {
    // 优惠券类型: platform-平台优惠券 salesperson-业务员优惠券 welfare-福利优惠券
    couponType: {
      type: String,
      default: ''
    }
  },
  name: "ShareList",
  data() {
    return {
      query: {
        // 查询参数
        couponCode: '',
        couponName: '',
        tcDate: '',
        couponStatus: '',
        couponUsePerson: '',
        orientedGroupId: '',
        disableGroupId: '',
        useChannel: '',
      },
      dataSource: [{}], //列表数据源
      groupData: [],
      tableLoading: false,
      pageParam: {    //分页参数
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      getRoute: '',

      boundTitle: '', // 绑定二维码弹窗标题
      boundType: 1, // 1-已绑定列表 2-未绑定列表
      couponData: {}, // 优惠券数据
      couponTypeList: { // 优惠券类型
        'PlatformCoupon': 1, // 平台优惠券
        'SalesmanCoupon': 2, // 业务员优惠券
        'BonusCoupon': 3 // 福利优惠券
      }
    }
  },
  computed: {
    couponTypeShow() {
      console.log(this.couponType !== 'welfare','this.couponType')
      return this.couponType !== 'welfare'
    }
  },
  created() {
    this.getRoute = this.$route.name
    this.queryListInfo();
    this.getGroupList();

    // 获取用户信息
    // const userInfo = getSessionStorage('userInfo') || {}
  },
  methods: {
    // 创建方法处理枚举数组查找对应名称
    // 封装方法(枚举方法)
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue);
      return enumFound ? enumFound.name : '';
    },

    //获取列表数据
    queryListInfo() {
      //共享列表，通过当前路径来筛选不同的数据 PlatformCoupon  SalesmanCoupon
      const { tcDate, couponUsePerson } = this.query
      const time = tcDate && tcDate.length > 0

      let params = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: {
          ...this.query,
          penetrateBeginTime: time ? tcDate[0] + ' 00:00:00' : '',
          penetrateEndTime: time ? tcDate[1] + ' 23:59:59' : '',
          orientedPopulation: couponUsePerson || '',
          couponType: this.couponTypeList[this.getRoute],
        },
      };

      this.tableLoading = true;
      this.$http.requestPost({
        url: "/marketing/queryCouponList",
        loading: false,
        param: params,
      })
        .then((res) => {
          if (res.code === 200) {
            this.dataSource = res.data.list
            this.pageParam.total = res.data.total
            this.tableLoading = false
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },

    //获取分组列表
    async getGroupList(){
      try {
        const params = {pageNum: 1, pageSize:100, categoryName: "" };
        const res = await this.$http.requestPost({ url: '/customer/talent/queryTalentCategoryList', param: params });
        if(res.code === 200){
          const list = res.data.list;
          this.groupData = list || [];
        };
      } catch (error) {
        this.$message.error('获取分组数据失败！');
      }
    },

    //新增优惠券
    addCoupon() {
      this.$router.push('/operation/marketingTool/addCoupon?route=' + this.getRoute)
    },
    //编辑优惠券
    editCoupon(row) {
      this.$router.push({
        path: '/operation/marketingTool/addCoupon',
        query: {
          id: row.couponId,
          route: this.getRoute,
          detailFlag: row.couponStatus == '4' //'4'表示优惠券已结束，则跳转至编辑页不展示确定按钮
        }
      })

    },

    //结束优惠券
    endCoupon(row) {
      const invalid = "优惠券还未生效，是否确认结束?"
      const Claiming = "优惠券正在领取中，未到结束时间，是否确认结束?"
      this.$confirm(row.couponStatus === '1' ? invalid : Claiming, "确认结束", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$http.requestPut({
            url: `/marketing/endCoupon/${row.couponId}`,
            loading: false
          })
            .then((res) => {
              if (res.code === 200) {
                this.queryListInfo();
                this.$message({
                  type: "success",
                  message: "已结束!",
                });
              }
            });
        })
        .catch((err) => { });
    },
    //删除优惠券
    delCoupon(id) {
      this.$confirm("是否确认删除?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$http.requestDel({
            url: "/marketing/removeCoupon/" + id,
            loading: false
          })
            .then((res) => {
              if (res.code === 200) {
                this.queryListInfo();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
        })
        .catch((err) => { });
    },

    //分页事件
    handleSizeChange(size, type) {
      switch (type) {
        case 'couponTable': // 优惠券列表
          this.pageParam.pageNum = 1
          this.pageParam.pageSize = size
          this.queryListInfo()
          break

        default:
          break;
      }
    },
    handleCurrentChange(page, type) {
      switch(type) {
        case 'couponTable': //优惠券列表
          this.pageParam.pageNum = page;
          this.queryListInfo();
          break;

        default:
          break;
      }

    },

    // 打开二维码列表
    openQrcodeList(data) {
      this.couponData = data
      this.$refs.memberQrcodeList.open()
    },
    // 绑定/解绑二维码
    bindQrcodeOperate(type) {
      // type: 1-绑定操作 2-解绑操作
      // boundType: 1-获取已绑定列表 2-获取未绑定列表
      this.boundType = type === 1 ? 2 : 1
      this.boundTitle = type === 1 ? '绑定' : '解绑'
      this.$refs.bindQrcodeList.open()
    },
    // 更新二维码列表数据
    updateMemberQrcodeList(isSingle) {
      if (!isSingle) {
        this.$refs.memberQrcodeList.updateList()
      }
      this.queryListInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.added {
  margin: 20px 0 0 20px;
}
.qrcodeTable_box {
  padding: 0 20px 10px;
  .table_list {
    width: 100%;
  }
}
.table-ellipsis {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  cursor: pointer;
}
</style>
