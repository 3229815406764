var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "增加时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.daterange,
                        callback: function ($$v) {
                          _vm.daterange = $$v
                        },
                        expression: "daterange",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "width-140",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            _vm.selectValue = ""
                          },
                        },
                        model: {
                          value: _vm.selectKey,
                          callback: function ($$v) {
                            _vm.selectKey = $$v
                          },
                          expression: "selectKey",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "医院名称", value: "hospitalName" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "医院编号", value: "hospitalCode" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-180",
                      attrs: { placeholder: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.selectValue,
                        callback: function ($$v) {
                          _vm.selectValue = $$v
                        },
                        expression: "selectValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _vm.userInfo.managerType == "all"
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增医院")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userInfo.managerType == "all"
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_import = true
                    },
                  },
                },
                [_vm._v("\n      导入\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportHospital },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "hospitalCode",
                        label: "医院编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.hospitalCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "hospitalName",
                        label: "医院名称",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "flex-box" }, [
                                scope.row.hospitalAvatar
                                  ? _c("img", {
                                      staticClass: "img-box-show box-m-r",
                                      attrs: {
                                        src: scope.row.hospitalAvatar,
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "hospital-name" }, [
                                  _vm._v(_vm._s(scope.row.hospitalName)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "merchantName",
                        label: "商家数量",
                        "min-width": "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "addressName",
                        label: "所在地区",
                        "min-width": "200",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "详细地址",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "增加时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "260",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.userInfo.managerType == "all"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userInfo.managerType == "all"
                                ? _c(
                                    "span",
                                    { staticClass: "operation-btn_partition" },
                                    [_vm._v("/")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSellDialog(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("销售记录")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShippingAddress(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("配送地址")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.merchantManage(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("商家管理")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleStop(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.enable === 1 ? "停用" : "启用"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.enable === 1
                                ? _c("span", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openContactNumber(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("产品联系电话")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible_1,
            "before-close": _vm.closeDialog_1,
            width: "600px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
            open: _vm.openHospital,
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "医院编号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "医院编号", readonly: "" },
                            model: {
                              value: _vm.submitForm.hospitalCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.submitForm, "hospitalCode", $$v)
                              },
                              expression: "submitForm.hospitalCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地址", prop: "addressId" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          props: _vm.props_add,
                          options: _vm.addressList_add,
                          placeholder: "请选项地址",
                        },
                        on: {
                          change: function ($event) {
                            _vm.submitForm.hospitalName = ""
                          },
                        },
                        model: {
                          value: _vm.submitForm.addressId,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "addressId", $$v)
                          },
                          expression: "submitForm.addressId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "医院名称", prop: "hospitalName" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "inline-input",
                        attrs: {
                          "fetch-suggestions": _vm.querySearch,
                          placeholder: "请输入内容",
                          "value-key": "name",
                          "trigger-on-focus": false,
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.submitForm.hospitalName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "hospitalName", $$v)
                          },
                          expression: "submitForm.hospitalName",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.submitForm.hospitalName &&
                                _vm.submitForm.hospitalName ===
                                  _vm.hospitalInfo.name,
                              expression:
                                "submitForm.hospitalName && submitForm.hospitalName === hospitalInfo.name",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                !!_vm.hospitalInfo.location
                                  ? typeof _vm.hospitalInfo.address === "string"
                                    ? _vm.hospitalInfo.address
                                    : _vm.hospitalInfo.location
                                  : "无效地址，请重选"
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属商家", prop: "merchantIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form-select",
                              attrs: {
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.changeMerchant },
                              model: {
                                value: _vm.submitForm.merchantIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitForm, "merchantIds", $$v)
                                },
                                expression: "submitForm.merchantIds",
                              },
                            },
                            _vm._l(_vm.merchantList, function (item) {
                              return _c("el-option", {
                                key: item.merchantId,
                                attrs: {
                                  label: item.merchantName,
                                  value: item.merchantId,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "详细地址" },
                        model: {
                          value: _vm.submitForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "address", $$v)
                          },
                          expression: "submitForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经纬度", prop: "LogAndLat" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input", {
                            staticStyle: { flex: "1", "margin-right": "20px" },
                            attrs: { type: "number", placeholder: "经度" },
                            model: {
                              value: _vm.submitForm.longitude,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.submitForm,
                                  "longitude",
                                  _vm._n($$v)
                                )
                              },
                              expression: "submitForm.longitude",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { flex: "1" },
                            attrs: { type: "number", placeholder: "维度" },
                            model: {
                              value: _vm.submitForm.latitude,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.submitForm,
                                  "latitude",
                                  _vm._n($$v)
                                )
                              },
                              expression: "submitForm.latitude",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "介绍", prop: "hospitalIntroduction" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "150",
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.submitForm.hospitalIntroduction,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm,
                              "hospitalIntroduction",
                              $$v
                            )
                          },
                          expression: "submitForm.hospitalIntroduction",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "医院图片", prop: "hospitalAvatar" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            "upload-type": "picture-card",
                            "file-list": _vm.fileList,
                            accept: ".gif,.jpeg,.jpg,.png",
                          },
                          on: {
                            uploadSuccess: _vm.uploadSuccess,
                            removeFile: _vm.removeFile_1,
                            cloudFileData: _vm.setCloudFileData,
                          },
                        },
                        [
                          _c("div", { staticClass: "tip-box" }, [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("图片大小：60*60")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "面积" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "面积" },
                        model: {
                          value: _vm.submitForm.hospitalArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "hospitalArea", $$v)
                          },
                          expression: "submitForm.hospitalArea",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开业时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.submitForm.hospitalOpeningTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "hospitalOpeningTime", $$v)
                          },
                          expression: "submitForm.hospitalOpeningTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营业时间" } },
                    [
                      _c("el-time-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          "is-range": "",
                          format: "HH:mm",
                          "value-format": "HH:mm",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          placeholder: "选择时间范围",
                        },
                        model: {
                          value: _vm.businessHours,
                          callback: function ($$v) {
                            _vm.businessHours = $$v
                          },
                          expression: "businessHours",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog_1 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看商家信息",
            visible: _vm.dialogVisible_2,
            "before-close": function () {
              return (_vm.dialogVisible_2 = false)
            },
            width: "600px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
            open: _vm.openInfo,
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm_2",
                  attrs: { "label-width": "100px", model: _vm.submitForm_2 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家名称", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "merchantName", $$v)
                          },
                          expression: "submitForm_2.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家编号", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "merchantCode", $$v)
                          },
                          expression: "submitForm_2.merchantCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属办事处" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          props: _vm.props,
                          options: _vm.addressList,
                          placeholder: "请选项地址",
                          disabled: "",
                        },
                        model: {
                          value: _vm.submitForm_2.agencyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "agencyId", $$v)
                          },
                          expression: "submitForm_2.agencyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "商家对接人" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.shopsMessenger, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "messengerName",
                                label: "对接人",
                                "min-width": "120",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "messengerPhoneNumber",
                                label: "手机号",
                                "min-width": "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规模" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          attrs: { placeholder: "请选择", disabled: "" },
                          model: {
                            value: _vm.submitForm_2.merchantScale,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm_2, "merchantScale", $$v)
                            },
                            expression: "submitForm_2.merchantScale",
                          },
                        },
                        _vm._l(_vm.scaleList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "成立时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          type: "date",
                          disabled: "",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.submitForm_2.merchantEstablishedTime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantEstablishedTime",
                              $$v
                            )
                          },
                          expression: "submitForm_2.merchantEstablishedTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司网址" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "公司网址", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantWebsite,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "merchantWebsite", $$v)
                          },
                          expression: "submitForm_2.merchantWebsite",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人姓名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "联系人姓名", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantContactsName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantContactsName",
                              $$v
                            )
                          },
                          expression: "submitForm_2.merchantContactsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "联系人手机号", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantContactsPhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantContactsPhoneNumber",
                              $$v
                            )
                          },
                          expression:
                            "submitForm_2.merchantContactsPhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "微信", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantContactsWechat,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantContactsWechat",
                              $$v
                            )
                          },
                          expression: "submitForm_2.merchantContactsWechat",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "邮箱", disabled: "" },
                        model: {
                          value: _vm.submitForm_2.merchantContactsEmail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantContactsEmail",
                              $$v
                            )
                          },
                          expression: "submitForm_2.merchantContactsEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          disabled: "",
                          maxlength: "150",
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.submitForm_2.merchantDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm_2,
                              "merchantDescription",
                              $$v
                            )
                          },
                          expression: "submitForm_2.merchantDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.sellTitle + " - 销售记录",
            visible: _vm.dialogVisible_3,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "div",
                { staticClass: "flex-box" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, size: "medium" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "订单编号" },
                            model: {
                              value: _vm.inquire_2.orderCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.inquire_2, "orderCode", $$v)
                              },
                              expression: "inquire_2.orderCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.getSellList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.showSalesRecordsDialog = true
                                },
                              },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_2,
                      expression: "loading_2",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData_2, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderCoId",
                      label: "订单编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "hospitalName",
                      label: "产品名称",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.productNames.join(", ")) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "amountPaid",
                      label: "实付金额",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s("￥" + scope.row.amountPaid) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "payTime",
                      label: "付款时间",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "memberNickName",
                      label: "会员微信昵称",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "140",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_2.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_2.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_2.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_2,
                      "current-change": _vm.handleCurrentChange_2,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible_4,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_4 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c("el-tree", {
                attrs: {
                  "node-key": "shippingId",
                  lazy: "",
                  load: _vm.loadNode,
                  "default-expand-all": false,
                  props: _vm.defaultProps,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_4 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商家管理",
            visible: _vm.merchantsManageData.dialogState,
            top: "5vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.merchantsManageData, "dialogState", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "科室名称" } },
                    [
                      _c("el-input", {
                        staticClass: "width-180",
                        attrs: { placeholder: "请输入科室名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.updateDepartmentList($event)
                          },
                        },
                        model: {
                          value: _vm.merchantsManageData.searchVal,
                          callback: function ($$v) {
                            _vm.$set(_vm.merchantsManageData, "searchVal", $$v)
                          },
                          expression: "merchantsManageData.searchVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.updateDepartmentList },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.openManageMerchant },
                        },
                        [_vm._v("管理商家")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.merchantsManageData.loading,
                      expression: "merchantsManageData.loading",
                    },
                  ],
                  ref: "merchantsManage_ref",
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "400px",
                    border: "",
                    data: _vm.merchantsManageData.list,
                    "row-key": function (row) {
                      return row.hospitalLinkMerchantId
                    },
                  },
                  on: { "selection-change": _vm.selectDepartment },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      "reserve-selection": true,
                      selectable: _vm.disabledSelectDepartment,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      prop: "date",
                      fixed: "",
                      width: "50",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.merchantsManageData.queryData.pageNum -
                                    1) *
                                    _vm.merchantsManageData.queryData.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "departmentName",
                      label: "科室名称",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "merchantCode",
                      label: "商家编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.merchantCode || "-") +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "merchantName",
                      label: "商家名称",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.merchantName || "-") +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "messengerName",
                      label: "商家信息员",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.messengerName || "-") +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      "min-width": "200",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.merchantId
                              ? _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.merchantInfo(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("商家档案")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "operation-btn_partition" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openProductList(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("产品列表")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "operation-btn_partition" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.delMerchant(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pagination",
                  staticStyle: { padding: "20px 0" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.merchantsManageData.queryData.pageNum,
                      "page-size": _vm.merchantsManageData.queryData.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.merchantsManageData.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": function ($event) {
                        return _vm.sizeChange($event, "merchantsManage_size")
                      },
                      "current-change": function ($event) {
                        return _vm.pageChange($event, "merchantsManage_page")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "管理商家",
            visible: _vm.manageMerchantData.dialogState,
            top: "5vh",
            width: "65%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.manageMerchantData, "dialogState", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true, size: "medium" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-140",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          _vm.manageMerchantData.searchVal = ""
                        },
                      },
                      model: {
                        value: _vm.manageMerchantData.searchKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.manageMerchantData, "searchKey", $$v)
                        },
                        expression: "manageMerchantData.searchKey",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "商家名称", value: "merchantName" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "商家编号", value: "merchantCode" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "商家信息员", value: "messengerName" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "width-180",
                    attrs: { placeholder: "", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateManageMerchantList($event)
                      },
                    },
                    model: {
                      value: _vm.manageMerchantData.searchVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.manageMerchantData, "searchVal", $$v)
                      },
                      expression: "manageMerchantData.searchVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.updateManageMerchantList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.manageMerchantData.loading,
                expression: "manageMerchantData.loading",
              },
            ],
            ref: "manageMerchantTable_ref",
            staticClass: "popup_table",
            attrs: {
              "table-header": _vm.manageMerchantData.tableHeader,
              "table-data": _vm.manageMerchantData.list,
              total: _vm.manageMerchantData.total,
              "highlight-current-row": true,
            },
            on: {
              rowClick: _vm.selectCurrentMerchant,
              setPage: function ($event) {
                return _vm.pageChange($event, "manageMerchant_page")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "manageMerchant_size")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "businessCoverage",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          data.businessCoverage && data.businessCoverage.length
                            ? data.businessCoverage.join(" ")
                            : "-"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "messengerName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(data.messengerName || "-") +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.merchantInfo(data)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.manageMerchantData.dialogState = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitChangeMerchant },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("shops-details", {
        ref: "shopsDetails",
        attrs: {
          "dialog-show": _vm.shopsDetailsDialog,
          "dialog-visible": _vm.shopsDetailsDialog,
          disable: true,
          title: _vm.dialogTitle,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.shopsDetailsDialog = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.shopsDetailsDialog = $event
          },
        },
      }),
      _vm._v(" "),
      _c("product-table", {
        ref: "productTable",
        attrs: {
          "dialog-show": _vm.productTableDialog,
          "dialog-visible": _vm.productTableDialog,
          title: _vm.dialogTitle,
          operation: false,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.productTableDialog = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.productTableDialog = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "产品联系电话",
            visible: _vm.contactNumberData.dialogState,
            top: "5vh",
            width: "65%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.contactNumberData, "dialogState", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true, size: "medium" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-140",
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          _vm.contactNumberData.searchVal = ""
                        },
                      },
                      model: {
                        value: _vm.contactNumberData.searchKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.contactNumberData, "searchKey", $$v)
                        },
                        expression: "contactNumberData.searchKey",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "产品名称", value: "productName" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "产品编号", value: "productCode" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "width-180",
                    attrs: { placeholder: "", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateContactNumberList($event)
                      },
                    },
                    model: {
                      value: _vm.contactNumberData.searchVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.contactNumberData, "searchVal", $$v)
                      },
                      expression: "contactNumberData.searchVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.updateContactNumberList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.contactNumberData.loading,
                expression: "contactNumberData.loading",
              },
            ],
            ref: "contactNumberTable_ref",
            staticClass: "popup_table",
            attrs: {
              "table-header": _vm.contactNumberData.tableHeader,
              "table-data": _vm.contactNumberData.list,
              total: _vm.contactNumberData.total,
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event, "contactNumber_page")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "contactNumber_size")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "phoneNumber",
                fn: function (ref) {
                  var data = ref.data
                  return [_c("span", [_vm._v(_vm._s(data.phoneNumber || "-"))])]
                },
              },
              {
                key: "merchantNameList",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.merchantNameList && data.merchantNameList.length
                            ? data.merchantNameList.join("、")
                            : "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editContactNumber(data)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "产品联系电话",
            visible: _vm.contactNumberData.editDialogState,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.contactNumberData, "editDialogState", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "contactNumberEditForm_ref",
              attrs: {
                model: _vm.contactNumberEditForm,
                "hide-required-asterisk": "",
                rules: _vm.contactNumberData.editRules,
                size: "medium",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品联系电话", prop: "phoneNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品联系电话", clearable: "" },
                    model: {
                      value: _vm.contactNumberEditForm.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.contactNumberEditForm,
                          "phoneNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "contactNumberEditForm.phoneNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.contactNumberData.editDialogState = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitContactNumberEditForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_import,
            title: "导入",
            width: "650",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_import = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                { ref: "submitForm", attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "导入模板" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.downloadTemplate },
                        },
                        [_vm._v("下载批量导入模板文件")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "批量导入" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            action: "/hospital/medical/importHospitals",
                            "show-file-list": false,
                            "file-list": _vm.fileList,
                            accept: ".xls,.xlsx",
                          },
                          on: {
                            beforeUpload: _vm.beforeUpload,
                            uploadSuccess: _vm.importFile,
                            uploadError: _vm.uploadError,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.importStatus === 1
                          ? _c("p", { staticClass: "progress color_success" }, [
                              _vm._v(
                                "\n              导入成功！\n            "
                              ),
                            ])
                          : _vm.importStatus === 2
                          ? _c("p", { staticClass: "progress color_red" }, [
                              _vm._v(
                                "\n              导入医院失败，请检查导入模版是否正确！\n            "
                              ),
                            ])
                          : _vm.importStatus === 3
                          ? _c("p", { staticClass: "progress" }, [
                              _vm._v(
                                "\n              正在导入中...\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_import = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("hospital-export", {
        ref: "isProductExport",
        attrs: {
          "show-dialog": _vm.showExportDialog,
          close: _vm.showExportDialog,
          "product-type": "hospital",
          title: "医院导出",
        },
        on: {
          "update:close": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
      _vm._v(" "),
      _c("d-export2", {
        attrs: {
          "show-dialog": _vm.showSalesRecordsDialog,
          "show-region": false,
          "show-order-type": false,
          "product-order-type": "medicine",
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.showSalesRecordsDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.showSalesRecordsDialog = $event
          },
          exportFiles: _vm.exportSalesRecords,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }