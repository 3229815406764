var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "类别名称" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "类别名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.categoryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "categoryName", $$v)
                        },
                        expression: "inquire.categoryName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addClass },
            },
            [_vm._v("添加类别")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      "row-key": "categoryId",
                      "tree-props": {
                        children: "childes",
                        hasChildren: "hasChildren",
                      },
                      height: "400px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "", label: "", "min-width": "60" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryName",
                        label: "类别名称",
                        "min-width": "160",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.categoryName))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryComment",
                        label: "备注",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryOrder",
                        label: "排序",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "添加时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryStatus",
                        label: "显示状态",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getValue(
                                      _vm.showState,
                                      scope.row.categoryStatus,
                                      "name"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "200",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel == 1,
                                      expression:
                                        "scope.row.categoryLevel == 1",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addChilClass(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("添加子类")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel == 1,
                                      expression:
                                        "scope.row.categoryLevel == 1",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.categoryStatus === "display",
                                      expression:
                                        "scope.row.categoryStatus === 'display'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setState(scope.row, "h")
                                    },
                                  },
                                },
                                [_vm._v("隐藏")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.categoryStatus === "hide",
                                      expression:
                                        "scope.row.categoryStatus === 'hide'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setState(scope.row, "s")
                                    },
                                  },
                                },
                                [_vm._v("显示 ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteProduct(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.addChil ? "父类名称" : "类别名称",
                        prop: "categoryName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.addChil ? "父类名称" : "类别名称",
                          readonly: _vm.addChil,
                        },
                        model: {
                          value: _vm.submitForm.categoryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryName", $$v)
                          },
                          expression: "submitForm.categoryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.addChil
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "子类名称",
                            prop: _vm.addChil ? "categoryName2" : "",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "子类名称" },
                            model: {
                              value: _vm.submitForm.categoryName2,
                              callback: function ($$v) {
                                _vm.$set(_vm.submitForm, "categoryName2", $$v)
                              },
                              expression: "submitForm.categoryName2",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "封面图片",
                        prop:
                          _vm.addChil || _vm.categoryLevel == 2
                            ? ""
                            : "coverImg",
                      },
                    },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            "upload-type": "picture-card",
                            "file-list": _vm.primaryImagesList,
                            accept: ".gif,.jpeg,.jpg,.png",
                          },
                          on: {
                            uploadSuccess: _vm.uploadSuccess,
                            removeFile: _vm.removeFile,
                            cloudFileData: _vm.setCloudFileData,
                          },
                        },
                        [
                          _c("div", { staticClass: "tip-box" }, [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("建议360px*360px")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "150",
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.submitForm.categoryComment,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryComment", $$v)
                          },
                          expression: "submitForm.categoryComment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "categoryOrder" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "排序" },
                        model: {
                          value: _vm.submitForm.categoryOrder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm,
                              "categoryOrder",
                              _vm._n($$v)
                            )
                          },
                          expression: "submitForm.categoryOrder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitClass } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }