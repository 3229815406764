<template>
  <div class="main-box">
    <!-- <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item>
          <el-button type="primary" size="small" @click="getTableList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>  -->

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="iconCategory" label="产品类型" min-width="140" />
            <el-table-column prop="iconImg" label="导航图标(100*20)" min-width="140">
              <template slot-scope="scope">
                <div v-if="scope.row.iconImg" class="table-view-box">
                  <img :src="scope.row.iconImg" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog title="编辑产品类型图标" :visible.sync="dialogVisible" :before-close="closeDialog" width="650px" top="5vh">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="120px" :model="submitForm" :rules="submitRules">
          <el-form-item label="产品类型" prop="iconCategory">
            <el-input v-model="submitForm.iconCategory" placeholder="产品类型" disabled />
          </el-form-item>
          <el-form-item label="图标" prop="iconImg">
            <d-upload
              :file-list="fileList"
              upload-type="picture-card"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccess"
              @removeFile="removeFile"
              @cloudFileData="setCloudFileData"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">图片大小：100*20</span>
              </div>
            </d-upload>
          </el-form-item>
          <!-- <el-form-item label="是否显示" prop="iconStatus">
              <el-radio v-model="submitForm.iconStatus" label="display">是</el-radio>
              <el-radio v-model="submitForm.iconStatus" label="hide">否</el-radio>
            </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import { extractHttpLinks, _delOssFile } from '@/utils/public'

export default {
  components: {
    dUpload
  },
  data() {
    return {
      imgType: ['.gif', '.jpeg', '.jpg', '.png'],
      videoType: ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v'],

      loading: false,
      // 表格数据
      tableData: [],

      // 编辑
      dialogVisible: false, // 编辑弹窗
      submitForm: {
        iconCategory: '',
        iconStatus: 'display',
        iconImg: ''
      },
      submitRules: {
        iconCategory: [{ required: true, message: '请输入产品类型', trigger: 'change' }],
        iconImg: [{ required: true, message: '请选择图标', trigger: 'blur' }]
      },
      attacheTable: [],
      editRow: {},
      fileList: [], // 文件列表

      cloudFileData: [] // 云文件列表
    }
  },
  watch: {
    submitForm: {
      handler(news, old) {
        this.$nextTick(() => {
          var upEl = document.getElementsByClassName('el-upload')[0]
          if (news.iconImg) {
            upEl.style.display = 'none'
          } else {
            upEl.style.display = 'block'
          }
        })
      },
      deep: true
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 图片上传成功
    uploadSuccess(res, file, fileList) {
      this.submitForm.iconImg = res.data
    },
    // 删除文件
    removeFile(file, fileList) {
      this.submitForm.iconImg = ''
      this.fileList = []
    },

    // 获取数据列表
    getTableList() {
      // return
      const data = {
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/operation/product/category/icon/list',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // 修改
    handleEdit(row) {
      this.fileList = []
      this.submitForm = {
        iconCategory: row.iconCategory,
        iconStatus: row.iconStatus,
        iconImg: row.iconImg
      }
      if (row.iconImg) {
        const arr = row.iconImg.split('/')
        this.fileList.push({
          name: arr[arr.length - 1],
          url: row.iconImg
        })
      }

      this.dialogVisible = true
      this.editRow = row
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            iconCategory: this.submitForm.iconCategory,
            iconStatus: this.submitForm.iconStatus,
            iconImg: this.submitForm.iconImg,
            iconCode: this.editRow.iconCode
          }

          this.$http
            .requestPost({
              url: '/operation/product/category/icon/save',
              param: param
            })
            .then(res => {
              this.getTableList()
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.closeDialog()
            })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      // 删除无用oss
      this.delCloudFile(this.submitForm)

      this.$refs.submitForm && this.$refs.submitForm.resetFields()
      this.dialogVisible = false
      this.submitForm = {
        iconCategory: '',
        iconStatus: 'display'
      }
      this.fileList = []
    },

    // 存储云文件数据
    setCloudFileData(data) {
      this.cloudFileData.push(data)
      console.log(this.cloudFileData, '图片组')
    },
    // 删除无用的图片
    delCloudFile(data) {
      const delOssList = extractHttpLinks(data, this.cloudFileData)
      console.log(delOssList, 'delOssList')
      _delOssFile(delOssList)
    }
  }
}
</script>

<style lang="scss" scoped>
.address-hierarchy {
  flex-wrap: wrap;
}

.table-view-box {
  width: 100px;
  height: 20px;
  margin: auto;
  & > img {
    width: 100%;
    height: 100%;
  }

  & > video {
    width: 100%;
    height: 100%;
  }
}
</style>
