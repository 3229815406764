<template>
  <div class="main-box redPacket_list">
    <!-- 搜索 -->
    <div class="head-search-row search_box">
      <el-form inline size="small" :model="queryData">
        <el-form-item label="红包二维码编号">
          <el-input
            v-model="queryData.qrCodeSerial"
            clearable
            placeholder="请输入红包二维码编号"
            @keyup.enter.native="updateList('mainTable_update')"
          />
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="queryData.productName" clearable placeholder="请输入产品名称" @keyup.enter.native="updateList('mainTable_update')" />
        </el-form-item>
        <el-form-item label="批次">
          <el-input v-model="queryData.batchNo" clearable placeholder="请输入批次" @keyup.enter.native="updateList('mainTable_update')" />
        </el-form-item>
        <el-form-item label="红包金额">
          <span class="money_form">
            <el-input
              v-model="queryData.redPacketMinAmount"
              :class="['width-110', { 'red_input': showMoneyTips }]"
              clearable
              placeholder="最小金额"
              @change="checkMoneyVal"
            />
            <span style="margin: 0 3px;">-</span>
            <el-input
              v-model="queryData.redPacketMaxAmount"
              :class="['width-110', { 'red_input': showMoneyTips }]"
              clearable
              placeholder="最大金额"
              @change="checkMoneyVal"
            />
          </span>
          <span v-show="showMoneyTips" class="money_tips">请输入正确的数值范围</span>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="dateSelectVal"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
            @change="changeDatePicker"
          />
        </el-form-item>
        <el-form-item label="红包二维码状态">
          <el-select v-model="queryData.isEffect" class="width-110" clearable placeholder="请选择">
            <el-option label="已生效" :value="1" />
            <el-option label="未生效" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="listType === 1" label="转账状态">
          <el-select v-model="queryData.transferStatus" class="width-110" clearable placeholder="请选择">
            <el-option label="成功" :value="1" />
            <el-option label="失败" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 20px">
        <el-button size="small" type="primary" @click="updateList('mainTable_update')">查询</el-button>
        <template v-if="listType === 2">
          <el-button size="small" type="primary" @click="redPacketQrCodeDialog(true, 'add')">创建红包二维码</el-button>
          <el-button size="small" type="primary" @click="liveRedPacketQrCode">立即生效</el-button>
          <el-button size="small" type="primary" @click="relatedMulBatch(true)">设置关联批次</el-button>
          <el-button size="small" type="primary" @click="changeMoneyDialog(true)">修改红包金额</el-button>
        </template>
        <el-button size="small" type="primary" @click="exportQrcode">导出</el-button>
        <el-button size="small" type="primary" @click="getDownloadQrCode">下载二维码图片</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-main>
      <TableList
        ref="mainTable_ref"
        class="mainTable_container"
        :table-header="tableData.tableHeader"
        :is-selection="true"
        row-key-name="redPacketQrCodeId"
        :table-data="tableData.list"
        :total="tableData.total"
        @setPage="pageChange($event, 'mainTable_page')"
        @setSize="sizeChange($event, 'mainTable_size')"
        @delXuan="selectionChange"
      >
        <template #redPacketAmount="{data}">
          <span>{{ data.redPacketAmount ? '￥' + data.redPacketAmount : 0 }}</span>
        </template>
        <template #salesPrice="{data}">
          <span>{{ data.salesPrice ? '￥' + data.salesPrice : 0 }}</span>
        </template>
        <template #transferStatus="{data}">
          <span>{{ getTransferStatusText(data.transferStatus) }}</span>
        </template>
        <template #isEffect="{data}">
          <span>{{ data.isEffect === 1 ? '已生效' : '未生效' }}</span>
        </template>
        <template #operation="{data}">
          <el-button class="operate_btn" type="text" @click="redPacketQrCodeDialog(true, 'edit', data)">二维码</el-button>
          <el-button class="operate_btn" type="text" @click="salesRecordDialog(true, data)">销售记录</el-button>
          <el-button class="operate_btn" type="text" @click="memberListDialog(true, data)">会员列表</el-button>
          <el-button v-if="listType === 1 && data.transferStatus === 2" class="operate_btn" type="text" @click="reTransferDialog(true, data)">
            重新转账
          </el-button>
        </template>
      </TableList>
    </el-main>

    <!-- ——————————————————————————弹窗相关 start—————————————————————————— -->
    <!-- 销售记录弹窗 -->
    <el-dialog
      :title="`${clickRowData.qrCodeSerial} 销售记录`"
      :visible.sync="salesRecordsData.visible"
      width="50%"
      @close="salesRecordDialog(false)"
    >
      <TableList
        height="400px"
        class="pb_10"
        :loading="salesRecordsData.loading"
        :table-header="salesRecordsData.tableHeader"
        :table-data="salesRecordsData.list"
        :is-show-page="false"
      >
        <template #userName="{data}">
          <span>{{ `${data.memberNickName} / ${data.recipient}` }}</span>
        </template>
        <template #orderTotalPrice="{data}">
          <span>{{ data.orderTotalPrice ? '￥' + data.orderTotalPrice : '￥0.00' }}</span>
        </template>
        <template #orderStatus="{data}">
          <span>{{ $api.getValue($api.EcommerceOrderState, data.orderStatus, 'name') }}</span>
        </template>
        <template #operation="{data}">
          <el-button class="btn" type="text" @click="operateButton('salesRecords_detail', data)">订单详情</el-button>
        </template>
      </TableList>
    </el-dialog>

    <!-- 会员列表弹窗 -->
    <el-dialog :title="`${clickRowData.qrCodeSerial} 会员列表`" :visible.sync="memberListData.visible" width="50%" @close="memberListDialog(false)">
      <el-form ref="memberList_ref" :model="memberQueryData" inline size="small">
        <el-form-item label="微信昵称" prop="memberNickname">
          <el-input v-model="memberQueryData.memberNickname" clearable placeholder="请输入微信昵称" />
        </el-form-item>
        <el-form-item label="手机号" prop="memberTelephone">
          <el-input v-model.trim="memberQueryData.memberTelephone" clearable maxlength="11" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="会员编号" prop="memberCode">
          <el-input v-model="memberQueryData.memberCode" clearable placeholder="请输入会员编号" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateList('memberTable_update')">查询</el-button>
        </el-form-item>
      </el-form>
      <TableList
        height="400px"
        class="pb_10"
        :loading="memberListData.loading"
        :table-header="memberListData.tableHeader"
        :table-data="memberListData.list"
        :is-show-page="false"
      >
        <template #cumulativeConsumption="{data}">
          <span>{{ data.cumulativeConsumption ? '￥' + data.cumulativeConsumption : '-' }}</span>
        </template>
      </TableList>
    </el-dialog>

    <!-- 重新转账弹窗 -->
    <el-dialog title="确认重新转账" :visible.sync="transferData.visible" width="650px">
      <el-form ref="transferAccount_ref" label-width="220px" size="medium">
        <el-form-item label="收款用户openId：">{{ transferData.previewData.memberOpenId }}</el-form-item>
        <el-form-item label="收款用户注册手机号：">{{ transferData.previewData.memberPhoneNumber }}</el-form-item>
        <el-form-item label="转账金额：">￥{{ transferData.previewData.redPacketAmount }}</el-form-item>
        <el-form-item label="是否确认以上信息无误，并重新转账？" label-width="300px" />
      </el-form>
      <span slot="footer" style="display: flex; justify-content: center;">
        <el-button type="primary" @click="confirmReTransfer">确 定</el-button>
        <el-button @click="transferData.visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- ——————————————————————————弹窗相关 end--—————————————————————————— -->

    <!-- 创建二维码弹窗组件 -->
    <qrCodeDialog
      ref="qrCodeDialog_ref"
      :qr-code-type="qrCodeType"
      :open-limit-modify="listType === 1"
      :dialog-title="getQrCodeDialogTitle(qrcodeDialogType)"
      @getValue="getQrCodeData"
    >
      <template #tips>
        <p v-if="qrCodeType === 'redPacketBatch'" style="padding: 20px;">
          已选中{{ selectData.length }}个红包二维码，设置后，二维码红包关联批次将以本次设置的批次为准。
        </p>
        <p v-if="qrCodeType === 'redPacketAmount'" style="padding: 20px;">
          已选中{{ selectData.length }}个红包二维码，设置后，二维码红包金额将以本次设置为准。
        </p>
      </template>
    </qrCodeDialog>
  </div>
</template>

<script>
import {
  redPacketQrCodeList,
  createQrCode,
  modifyQrCode,
  batchUpdateQrCode,
  batchEditRedPacketAmount,
  batchEffect,
  qrCodeMemberList,
  qrCodeSaleRecord,
  afreshTransfer
} from '@/api/operation.js'

import qrCodeDialog from './qrCodeDialog'
export default {
  components: { qrCodeDialog },
  props: {
    // 列表类型 1-已领取 2-未领取
    listType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // 二维码类型
      qrCodeType: '', // redPacket-创建/编辑红包二维码, redPacketBatch-红包关联批次, redPacketAmount-红包金额
      dateSelectVal: '', // 日期值
      pickerOptions: { // 日期选择器配置
        disabledDate(time) {
          // 不给选明天的日期
          var endOfToday = new Date();
          endOfToday.setHours(23, 59, 59, 999)
          return time.getTime() > endOfToday;
        }
      },
      queryData: {
        pageNum: 1,
        pageSize: 10,
        qrCodeSerial: '', // 二维码编号
        productName: '',
        batchNo: '',
        redPacketMinAmount: '',
        redPacketMaxAmount: '',
        createBeginTime: '',
        createEndTime: '',
        isEffect: '', // 红包二维码状态 1生效 2不生效
        transferStatus: '' // 红包转账状态 1.成功 2.失败 3.转账中
      },
      tableData: {
        tableHeader: [
          { label: '二维码编号', prop: 'qrCodeSerial', width: '160px' },
          { label: '产品名称', prop: 'productName', width: '160px' },
          { label: '批次', prop: 'batchNo', width: '160px', defaultValue: '-' },
          { label: '红包金额（元）', type: 'slot', slotName: 'redPacketAmount', width: '160px' },
          { label: '销售额', type: 'slot', slotName: 'salesPrice', width: '160px' },
          { label: '用户总数', prop: 'memberNum', width: '160px', defaultValue: 0 },
          { label: '扫码次数', prop: 'scanNum', width: '160px', defaultValue: 0 },
          { label: '红包领取时间', prop: 'receiveTime', width: '160px', isHide: this.listType === 2, defaultValue: '-' },
          { label: '领取红包会员名称', prop: 'memberNickName', width: '160px', isHide: this.listType === 2, defaultValue: '-' },
          { label: '会员注册手机号', prop: 'memberPhoneNumber', width: '160px', isHide: this.listType === 2, defaultValue: '-' },
          { label: '转账状态', type: 'slot', slotName: 'transferStatus', width: '160px', isHide: this.listType === 2 },
          { label: '创建时间', prop: 'createTime', width: '160px' },
          { label: '红包二维码状态', type: 'slot', slotName: 'isEffect', width: '160px', isHide: this.listType === 1 },
          { label: '生效时间', prop: 'effectTime', width: '160px' },
          { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', width: '260px' }
        ],
        total: 0,
        list: []
      },
      showMoneyTips: false, // 是否展示金额输入错误提示
      selectData: [], // 当前多选的项
      clickRowData: {}, // 当前点击的项

      qrcodeDialogType: '', // 二维码弹窗类型 add创建 edit编辑 batch

      // 销售记录请求数据
      salesRecordsQueryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          merchantId: '',
          orderCode: ''
        }
      },
      // 销售数据
      salesRecordsData: {
        visible: false,
        loading: true,
        tableHeader: [
          { label: '订单编号', prop: 'orderCode' },
          { label: '卖家/收货人', type: 'slot', slotName: 'userName' },
          { label: '实付金额', type: 'slot', slotName: 'orderTotalPrice' },
          { label: '下单时间', prop: 'orderTime' },
          { label: '订单状态', type: 'slot', slotName: 'orderStatus' },
          { label: '操作', type: 'slot', slotName: 'operation' }
        ],
        list: []
      },

      // 会员列表请求数据
      memberQueryData: {
        qrCodeId: '', // 二维码id
        memberNickname: '', // 微信昵称
        memberCode: '', // 会员编号
        memberTelephone: '' // 手机号
      },
      // 会员列表数据
      memberListData: {
        visible: false,
        loading: false,
        tableHeader: [
          { label: '微信昵称', prop: 'memberNickname' },
          { label: '会员编号', prop: 'memberCode' },
          { label: '注册手机号', prop: 'memberTelephone' },
          { label: '累计消费', type: 'slot', slotName: 'cumulativeConsumption' },
          { label: '消费次数', prop: 'consumptionFrequency' },
          { label: '注册时间', prop: 'registerTime' }
        ],
        list: []
      },

      // 重新转账弹窗
      transferData: {
        visible: false,
        previewData: {
          transferId: '',
          memberOpenId: '',
          memberPhoneNumber: '',
          redPacketAmount: ''
        }
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.queryData.redPacketQrCodeStatus = this.listType
    this.getQueryQrCodeList()
  },
  methods: {
    // 获取红包转账状态
    getTransferStatusText(status) {
      const statusText = {
        1: '成功',
        2: '失败',
        3: '转账中'
      }
      return statusText[status] || ''
    },
    // 校验红包金额的数值
    checkMoneyVal(val) {
      const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
      const minVal = this.queryData.redPacketMinAmount
      const maxVal = this.queryData.redPacketMaxAmount
      if (minVal || maxVal) {
        if (!reg.test(val)) {
          return (this.showMoneyTips = true)
        }
      }
      if ((!minVal && maxVal) || (minVal && !maxVal)) {
        return (this.showMoneyTips = true)
      }
      if (minVal && maxVal) {
        if (minVal > maxVal) {
          return (this.showMoneyTips = true)
        }
      }
      this.showMoneyTips = false
    },
    // 获取弹窗标题
    getQrCodeDialogTitle(type) {
      const typeText = {
        add: '新增二维码',
        edit: '编辑二维码',
        batch: '设置二维码关联批次',
        money: '设置二维码红包金额'
      }
      return typeText[type] || '新增二维码'
    },
    // 切换筛选日期
    changeDatePicker(val) {
      this.queryData.createBeginTime = val ? val[0] : ''
      this.queryData.createEndTime = val ? val[1] : ''
    },
    // 清除校验
    clearFields(refName) {
      this.$refs[refName] && this.$refs[refName].resetFields()
    },

    // 切换页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'mainTable_page':
          this.queryData.pageNum = pageVal
          this.getQueryQrCodeList()
          break

        default:
          break
      }
    },
    // 切换条目
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'mainTable_size':
          this.queryData.pageSize = sizeVal
          this.getQueryQrCodeList()
          break

        default:
          break
      }
    },
    // 多选
    selectionChange(rows) {
      this.selectData = rows
    },
    // 选中当前行
    currentRow(row, type) {
      switch (type) {
        case '':
          break

        default:
          break
      }
    },
    // 刷新列表
    updateList(type) {
      switch (type) {
        case 'mainTable_update':
          if (this.showMoneyTips) {
            return false
          }
          this.queryData.pageNum = 1
          this.getQueryQrCodeList()
          break

        case 'memberTable_update': // 会员列表
          this.getQrCodeMemberList()
          break

        default:
          break
      }
    },

    // 获取二维码列表
    async getQueryQrCodeList() {
      const params = { ...this.queryData }
      const res = await redPacketQrCodeList(params)
      // console.log('res===>>>>', res)
      if (res.code === 200) {
        this.tableData.list = res.data.list
        this.tableData.total = res.data.total
      }
    },

    // 获取二维码数据
    getQrCodeData(val) {
      // console.log('val===>>>>', { ...val })
      // 创建/编辑红包二维码
      if (this.qrCodeType === 'redPacket') {
        this.confirmAdd(val)
      }
      // 设置关联批次
      if (this.qrCodeType === 'redPacketBatch') {
        this.confirmRelatedMulBatch(val)
      }

      // 修改红包金额
      if (this.qrCodeType === 'redPacketAmount') {
        this.confirmChangeMoney(val)
      }
    },

    // 打开/关闭 红包二维码弹窗
    redPacketQrCodeDialog(isOpen, openType, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      // openType: add-添加 edit-编辑
      // row: 当前项
      this.qrCodeType = 'redPacket' // redPacket-创建/编辑红包二维码, redPacketBatch-红包关联批次, redPacketAmount-红包金额
      this.qrcodeDialogType = openType
      this.$refs.qrCodeDialog_ref.openQrCode(isOpen, openType, row)
      if (isOpen) {
        this.clickRowData = row || {}
        openType === 'add' && this.$refs.mainTable_ref.clearSelection()
      }
    },

    // 创建/编辑红包二维码
    async confirmAdd(val) {
      let res = {}
      if (this.qrcodeDialogType === 'add') {
        // 新增
        const paramData = val
        // 二维码所属类型 1：商家二维码  2：经销品个人码 3：电商品个人码 4.宝滋泉商家码 5.宝滋泉个人码 6.红包二维码 7.优惠券二维码
        paramData.type = 6
        res = await createQrCode(paramData)
      } else if (this.qrcodeDialogType === 'edit') {
        // 编辑
        const paramData = {
          qrCodeId: this.clickRowData.redPacketQrCodeId,
          specifiedPath: val.specifiedPath,
          specifiedPathId: val.specifiedPathId,
          productId: val.productId,
          batchNo: val.batchNo
        }
        res = await modifyQrCode(paramData)
      }
      if (res.code === 200) {
        this.$message.success(`${this.qrcodeDialogType === 'add' ? '创建' : '编辑'}二维码成功`)
        this.getQueryQrCodeList()
      } else {
        this.$message.error(`${this.qrcodeDialogType === 'add' ? '创建' : '编辑'}二维码失败`)
      }
    },

    // 批量立即生效
    async liveRedPacketQrCode() {
      if (!this.selectData.length) {
        return this.$message.warning('请选择一个或多个二维码')
      }
      const hasEffect = this.selectData.some(item => item.isEffect === 1)
      if (hasEffect) {
        return this.$message.warning('当前勾选存在已生效的二维码，请重新勾选')
      }
      const msgText = `已选中 ${this.selectData.length} 个红包二维码，生效后，用户即可扫码领取红包。是否立即生效？`
      this.$confirm(msgText, '红包二维码生效确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          // eslint-disable-next-line prefer-const
          let qrCodeIdList = []
          this.selectData.forEach(item => {
            qrCodeIdList.push(item.redPacketQrCodeId)
          })
          const res = await batchEffect(qrCodeIdList)
          if (res.code === 200) {
            this.$message.success('操作成功')
            this.getQueryQrCodeList()
            this.$refs.mainTable_ref.clearSelection()
          } else {
            console.log('批量设置红包生效失败:', res)
          }
        })
        .catch(() => {})
    },

    // 批量设置关联批次弹窗
    relatedMulBatch(isOpen) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        if (!this.selectData.length) {
          return this.$message.warning('请至少选择一个二维码')
        }
        this.qrCodeType = 'redPacketBatch' // redPacket-创建/编辑红包二维码, redPacketBatch-红包关联批次, redPacketAmount-红包金额
        this.qrcodeDialogType = 'batch'
      }
      this.$refs.qrCodeDialog_ref.openQrCode(isOpen)
    },
    // 确定批量设置关联批次
    async confirmRelatedMulBatch(val) {
      // eslint-disable-next-line prefer-const
      let qrCodeIdList = []
      this.selectData.forEach(item => {
        qrCodeIdList.push(item.redPacketQrCodeId)
      })
      const paramData = {
        qrCodeIdList,
        productId: val.productId,
        batchNo: val.batchNo
      }
      const res = await batchUpdateQrCode(paramData)
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.getQueryQrCodeList()
        this.$refs.mainTable_ref.clearSelection()
      } else {
        console.log('批量设置红包关联批次失败:', res)
      }
    },

    // 批量修改金额弹窗
    changeMoneyDialog(isOpen) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        if (!this.selectData.length) {
          return this.$message.warning('请至少选择一个二维码')
        }
        this.qrCodeType = 'redPacketAmount' // redPacket-创建/编辑红包二维码, redPacketBatch-红包关联批次, redPacketAmount-红包金额
        this.qrcodeDialogType = 'money'
      }
      this.$refs.qrCodeDialog_ref.openQrCode(isOpen)
    },
    // 确定批量修改金额
    async confirmChangeMoney(val) {
      // eslint-disable-next-line prefer-const
      let qrCodeIdList = []
      this.selectData.forEach(item => {
        qrCodeIdList.push(item.redPacketQrCodeId)
      })
      const paramData = {
        qrCodeIdList,
        redPacketAmount: val.redPacketAmount
      }
      const res = await batchEditRedPacketAmount(paramData)
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.getQueryQrCodeList()
        this.$refs.mainTable_ref.clearSelection()
      } else {
        console.log('批量设置红包金额失败:', res)
      }
    },

    // 二维码管理导出
    async exportQrcode() {
      const requestUrl = '/marketing/exportRedPacketQrCodeList'
      const fileName = `红包二维码导出_${new Date().getTime()}`
      this.$http
        .download({
          url: requestUrl,
          param: { ...this.queryData },
          method: 'POST'
        })
        .then(res => {
          this.$public.downloadFile(res, fileName)
        })
        .catch(err => {
          this.$message.error(err)
          console.error('导出失败：', err)
        })
    },

    // 批量下载二维码
    async getDownloadQrCode() {
      const requestUrl = '/marketing/downloadRedPacketQrCode'
      const fileName = `log_${new Date().getTime()}` // 下载的文件名称
      this.$http
        .download({
          url: requestUrl,
          param: { ...this.queryData },
          method: 'POST'
        })
        .then(res => {
          this.$public.downloadFile(res, fileName)
        })
        .catch(err => {
          this.$message.error(err)
          console.error('下载失败：', err)
        })
    },

    // 打开/关闭 销售记录弹窗
    async salesRecordDialog(isOpen, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        this.clickRowData = row
        // 获取销售记录
        const res = await qrCodeSaleRecord(row.redPacketQrCodeId)
        if (res.code === 200) {
          this.salesRecordsData.list = res.data
        }
        this.salesRecordsData.loading = false
      }
      this.salesRecordsData.visible = isOpen
    },

    // 打开/关闭 会员列表弹窗
    memberListDialog(isOpen, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        this.clickRowData = row
        this.getQrCodeMemberList()
      } else {
        this.clearFields('memberList_ref')
      }
      this.memberListData.visible = isOpen
    },
    // 获取会员列表接口
    async getQrCodeMemberList() {
      this.memberListData.loading = true
      const params = { ...this.memberQueryData }
      params.qrCodeId = this.clickRowData.redPacketQrCodeId
      const res = await qrCodeMemberList(params)
      if (res.code === 200) {
        this.memberListData.list = res.data
      }
      this.memberListData.loading = false
    },

    // 打开/关闭 重新转账弹窗
    reTransferDialog(isOpen, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        this.clickRowData = row || {}
        Object.keys(this.transferData.previewData).forEach(key1 => {
          Object.keys(row).forEach(key2 => {
            if (key1 === key2) {
              this.transferData.previewData[key1] = row[key2]
            }
          })
        })
      }
      this.transferData.visible = isOpen
    },
    // 重新转账
    async confirmReTransfer() {
      const { transferId } = this.transferData.previewData
      const res = await afreshTransfer(transferId)
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.getQueryQrCodeList()
      } else {
        console.log('重新转账失败===>>>>', res)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pb_10 {
  padding-bottom: 10px;
}
.redPacket_list {
  background-color: #fff;
  /deep/ .table {
    width: 100%;
    margin: 10px 0;
  }
  .mainTable_container {
    margin: 0;
  }
  .search_box {
    .money_form {
      position: relative;
      .red_input {
        /deep/ .el-input__inner {
          border-color: #f56c6c;
        }
      }
    }
    .money_tips {
      color: #f56c6c;
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: -24px;
    }
  }
  .tips_text {
    color: #7f7f7f;
    margin-left: 15px;
  }
  /deep/ .operate_btn {
    padding: 5px 0;
    margin: 0;
    &::before {
      content: '/';
      font-size: 12px;
      color: #4e9aef;
      vertical-align: top;
      margin-right: 5px;
    }
    &:first-child::before {
      content: '';
      margin: 0;
    }
  }
}
</style>
