<template>
  <!-- 知识库-新增/编辑文章 -->
  <div class="box">
    <el-form ref="submitForm" class="formData" :model="formData" size="small" label-width="160px" :rules="rules">
      <el-form-item label="是否调用营养学社文章">
        <el-radio-group v-model="articleRadio" :disabled="editDisabled">
          <el-radio :label="1">否</el-radio>
          <el-radio :label="2">是</el-radio>
        </el-radio-group>
        <el-button v-show="articleRadio == 2" :disabled="editDisabled" style="margin-left:20px" type="primary" size="mini" @click="openArticleDialog">
          选择文章
        </el-button>
      </el-form-item>
      <el-form-item label="标题" prop="articleTitle">
        <el-input
          v-model="formData.articleTitle"
          class="input_rPadding"
          show-word-limit
          clearable
          maxlength="50"
          :disabled="editDisabled"
          placeholder="请输入标题"
          style="width: 350px;"
        />
      </el-form-item>
      <el-form-item label="类别" prop="articleCategoryId">
        <el-select v-model="formData.articleCategoryId" placeholder="请选择" style="width: 350px;">
          <el-option v-for="(item, index) in queryCategoryList" :key="index" :label="item.categoryName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="作者" prop="articleAuthor">
        <el-input
          v-model="formData.articleAuthor"
          class="input_rPadding"
          show-word-limit
          clearable
          maxlength="50"
          :disabled="editDisabled"
          placeholder="请输入作者"
          style="width: 350px;"
        />
      </el-form-item>
      <el-form-item label="认证信息" prop="organization">
        <el-input
          v-model="formData.organization"
          class="input_rPadding"
          show-word-limit
          clearable
          maxlength="50"
          :disabled="editDisabled"
          placeholder="请输入认证信息"
          style="width: 350px;"
        />
      </el-form-item>
      <el-form-item label="封面图片" prop="coverUrl">
        <d-upload
          upload-type="picture-card"
          :file-list="coverList"
          :limit="1"
          accept=".gif,.jpeg,.jpg,.png"
          :disabled="editDisabled"
          @uploadSuccess="uploadSuccess(arguments, 'coverImg')"
          @removeFile="removeFile(arguments, 'coverImg')"
          @cloudFileData="setCloudFileData"
        >
          <div class="tip-box">
            <i class="el-icon-plus"></i>
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <el-form-item label="内容" prop="articleContent">
        <div v-if="!editDisabled" class="editor">
          <mavon-editor
            ref="mavon_editor"
            v-model="formData.articleContent"
            :toolbars="toolbars"
            :autofocus="false"
            @imgAdd="handleEditorImgAdd"
            @imgDel="handleEditorImgDel"
            @change="editorChange"
          />
        </div>
        <!-- 显示只读内容或占位符 -->
        <div v-else class="editor-readonly">
          <div v-html="formData.articleContent.replace(/\n/gm, '<br>')"></div>
        </div>
      </el-form-item>
      <el-form-item label="是否添加提示" prop="promptRadio">
        <el-radio-group v-model="promptRadio" @change="$forceUpdate()">
          <el-radio :label="1">否</el-radio>
          <el-radio :label="2">是</el-radio>
        </el-radio-group>
        <el-input
          v-show="promptRadio == 2"
          v-model="formData.articlePrompt"
          class="input_rPadding"
          show-word-limit
          clearable
          maxlength="50"
          placeholder="请输入提示语"
          style="width: 350px; margin-left:20px"
        />
      </el-form-item>
      <el-form-item label="顶部视频" style="height:70px; margin-top:30px">
        <d-upload
          :size="500"
          :auto-upload="false"
          video-height="400px"
          :file-list="topVideoList"
          :action="actionUrl"
          accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
          @uploadSuccess="uploadSuccess(arguments, 'topVideo')"
          @handleChange="fileChange(arguments, 'topVideo')"
          @removeFile="removeFile(arguments, 'topVideo')"
          @cloudFileData="setCloudFileData"
        >
          <div class="tip-box">
            <el-button size="small" type="primary" :disabled="editDisabled">上传视频</el-button>
          </div>
        </d-upload>
        <el-progress v-if="progressFlag1" :percentage="percentage1" />
      </el-form-item>
      <el-form-item label="底部视频" style="height: 70px;">
        <d-upload
          :size="500"
          :auto-upload="false"
          video-height="400px"
          :file-list="bottomVideoList"
          :action="actionUrl"
          accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
          @uploadSuccess="uploadSuccess(arguments, 'bottomVideo')"
          @handleChange="fileChange(arguments, 'bottomVideo')"
          @removeFile="removeFile(arguments, 'bottomVideo')"
          @cloudFileData="setCloudFileData"
        >
          <div class="tip-box">
            <el-button size="small" type="primary" :disabled="editDisabled">上传视频</el-button>
          </div>
        </d-upload>
        <el-progress v-if="progressFlag2" :percentage="percentage2" />
      </el-form-item>
    </el-form>

    <!-- 提交按钮 -->
    <div class="btns">
      <div class="confirm" @click="submitForm">完成</div>
      <div class="cancel" @click="cancelSubmit">取消</div>
    </div>

    <!-- 选择文章弹窗 -->
    <el-dialog title="选择文章" :visible.sync="articleDialogVisible" append-to-body @close="articleDialogVisible = false">
      <div class="content">
        <el-form :model="articleQueryData" size="small" :inline="true" style="margin-top: 20px;">
          <el-form-item label="标题">
            <el-input v-model="articleQueryData.title" placeholder="请输入文章标题" clearable @keyup.enter.native="searchArticle" />
          </el-form-item>
          <el-form-item label="作者">
            <el-input v-model="articleQueryData.author" placeholder="请输入作者名称" clearable @keyup.enter.native="searchArticle" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="searchArticle">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="tablelist"
          height="400"
          border
          highlight-current-row
          style="margin-top: 30px;"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
          :data="articleList"
          @current-change="articleCurrentChange"
        >
          <el-table-column label="序号" width="60" align="center" type="index" />
          <el-table-column label="标题" align="center" prop="title" />
          <el-table-column label="作者" align="center" prop="author" />
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="articleQueryData.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="articleQueryData.pageSize"
            :total="total"
            layout=" total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmArticle">确 定</el-button>
        <el-button @click="articleDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 视频在线预览弹框 -->
    <el-dialog destroy-on-close :visible.sync="videoDialogVisible" width="60%" append-to-body @close="closeVideoDialog">
      <video v-if="videoDialogVisible" ref="video" class="video" controls preload="auto" playsinline="true">
        <source :src="vedioUrl" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>
<script>
import dUpload from '@/components/d-upload/index'
import axios from 'axios'
import { extractHttpLinks, _delOssFile } from '@/utils/public'

// const baseURL = process.env.VUE_APP_BASE_API
function getSignature() {
  return axios.post('/common/file/upload/getQCloudSignature', JSON.stringify({ action: 'GetUgcUploadSign' })).then(function(response) {
    return response.data.data
  })
}
export default {
  // name:'新增文章',
  components: { dUpload },
  data() {
    return {
      editDisabled: false, // 是否禁止编辑
      modifyStatus: 0, // 0-新增 1-编辑

      // -----------文件上传相关 start-----------------
      actionUrl: '/common/file/upload/video', // 上传地址
      percentage1: 0, // 顶部视频上传进度条
      progressFlag1: false, // 顶部视频上传进度条显示状态
      uploader1: '', // 顶部视频上传盒子

      percentage2: 0, // 底部视频上传进度条
      progressFlag2: false, // 底部视频上传进度条显示状态
      uploader2: '', //  底部视频上传盒子
      // -----------文件上传相关 end-----------------

      // 富文本配置
      toolbars: {
        // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      },
      mavonEditorValue: '',

      coverList: [], // 封面
      topVideoList: [], // 顶部视频
      bottomVideoList: [], // 底部视频
      articleRadio: 1, // 是否调用文章radio
      promptRadio: null, // 是否添加提示radio

      articleDialogVisible: false, // 选择文章弹窗
      articleList: [], // 选择文章表格数据
      articleQueryData: {
        // 文章列表请求数据
        pageNum: 1,
        pageSize: 10,
        title: '',
        author: ''
      },
      total: 0, // 文章列表总条数
      selectArticleData: {}, // 当前选择的文章

      queryCategoryList: [], // 类别

      // 表单数据
      formData: {
        id: '', // 知识库文章主键id【为空新增 不为空编辑】
        articleSource: null, // 文章来源，1.638医品，2.营养学社
        articleTitle: '', // 文章标题
        articleCategoryId: '',
        articleAuthor: '', // 文章作者
        authorAvatar: '', // 头像（从营养师选择的文章需要传 否则不需要传）
        organization: '', // 认证信息
        coverUrl: '', // 封面图片
        articleContent: '', // 文章内容
        pureContent: '', // 文章纯文本内容
        articlePrompt: '', // 提示语
        topVideo: '', // 顶部视频
        buttonVideo: '' // 底部视频
      },
      // 表单验证规则
      rules: {
        articleTitle: [{ required: true, message: '请输入标题', trigger: ['change', 'blur'] }],
        articleAuthor: [{ required: true, message: '请输入作者名称', trigger: ['change', 'blur'] }],
        articleCategoryId: [{ required: true, message: '请选择类别', trigger: ['change', 'blur'] }],
        coverUrl: [{ required: true, message: '请上传封面', trigger: ['change', 'blur'] }],
        articleContent: [{ required: true, message: '请填写内容', trigger: ['change', 'blur'] }],
        promptRadio: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (this.promptRadio) {
                return callback()
              } else {
                callback(new Error('请选择是否添加提示'))
              }
            }
          }
        ]
      },

      videoDialogVisible: false, // 视频弹窗
      vedioUrl: '', // 视频地址
      contentId: '', // 关联文章id

      cloudFileData: [] // 云文件列表
    }
  },
  computed: {},
  created() {
    this.tcVod = new this.$TcVod({
      getSignature: getSignature
    })
    // 获取类别
    this.getList()
    const id = this.$route.query.id
    if (id) {
      this.modifyStatus = 1 // 0-新增 1-编辑
      this.getDetail(id)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.id) {
      to.meta.title = '编辑文章'
    } else {
      to.meta.title = '新增文章'
    }
    next()
  },
  beforeDestroy() {
    this.delCloudFile()
  },

  methods: {
    // 获取详情
    getDetail(id) {
      this.$http
        .requestGet({
          url: '/knowledge/knowledgeArticleDetail/' + id
        })
        .then(res => {
          if (res.code === 200) {
            // console.log("==>",{...res.data});
            const data = { ...res.data }
            Object.keys(this.formData).forEach((key, index) => {
              this.formData[key] = data[key]
            })
            if (this.formData.articleSource != 2) {
              this.formData.articleContent = this.$public.parseHTML_ForMarkdown(this.formData.articleContent)
            }
            this.coverList = this.formData.coverUrl ? [{ name: 'coverImg', url: this.formData.coverUrl }] : []
            this.topVideoList = this.formData.topVideo ? [{ name: '顶部视频.mp4', url: this.formData.topVideo }] : []
            this.bottomVideoList = this.formData.buttonVideo ? [{ name: '底部视频.mp4', url: this.formData.buttonVideo }] : []
            this.articleRadio = this.formData.articleSource
            this.promptRadio = this.formData.articlePrompt ? 2 : 1
            this.editDisabled = this.formData.articleSource == 2
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 获取类别
    getList() {
      this.$http
        .requestPost({
          url: '/knowledge/queryKnowledgeCategoryList',
          param: {
            pageNum: 1,
            pageSize: 1000,
            param: {
              categoryName: '', // 知识库分类名称
              categoryStatus: 1
            }
          },
          loading: false
        })
        .then(res => {
          if (res.code === 200) {
            this.queryCategoryList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ------------------选择文章相关内容 start ------------------------
    // 打开选择文章弹窗
    openArticleDialog() {
      this.articleDialogVisible = true
      this.getArticleList()
    },
    // 选择文章查找
    searchArticle() {
      this.articleQueryData.pageNum = 1
      this.getArticleList()
    },
    // 改变页码
    handleSizeChange(size) {
      this.articleQueryData.pageNum = 1
      this.articleQueryData.pageSize = size
      this.getArticleList()
    },
    // 改变页数
    handlePageChange(page) {
      this.articleQueryData.pageNum = page
      this.getArticleList()
    },
    // 获取选择文章列表
    getArticleList() {
      this.$http
        .requestPost({
          url: '/knowledge/queryServerContent',
          param: this.articleQueryData
        })
        .then(res => {
          if (res.code === 200) {
            this.articleList = res.data.list
            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 选择文章
    articleCurrentChange(row) {
      this.selectArticleData = row
      // console.log("选中文章==>",row);
    },
    // 选择文章确定
    confirmArticle() {
      // 清空上传文件相关内容
      Object.keys(this.formData).forEach((key1, index1) => {
        Object.keys(this.selectArticleData).forEach((key2, index2) => {
          if (key1 === key2) {
            this.formData[key1] = this.selectArticleData[key2]
          }
        })
      })
      this.formData.articleTitle = this.selectArticleData.title
      this.formData.articleAuthor = this.selectArticleData.author
      this.formData.articleContent = this.selectArticleData.content
      this.formData.articleCategoryId = ''

      this.coverList = this.selectArticleData.coverUrl ? [{ name: 'coverImg', url: this.selectArticleData.coverUrl }] : []
      this.topVideoList = this.selectArticleData.topVideo ? [{ name: '顶部视频.mp4', url: this.selectArticleData.topVideo }] : []
      this.bottomVideoList = this.selectArticleData.buttonVideo ? [{ name: '底部视频.mp4', url: this.selectArticleData.buttonVideo }] : []
      this.editDisabled = true
      this.articleDialogVisible = false

      this.$message({
        type: 'success',
        message: '操作成功!'
      })
      // console.log(this.formData, "this.formData");
    },
    // ------------------选择文章相关内容 end ------------------------

    // ------------------文件上传相关 start ------------------------
    // 关闭视频在线预览弹框
    closeVideoDialog() {
      this.vedioUrl = ''
    },
    // 文件上传成功时的钩子
    uploadSuccess(argumentList, type) {
      const res = argumentList[0]
      // const file = argumentList[1]
      // const fileList = argumentList[2]
      if (type === 'coverImg') {
        // 封面图
        this.formData.coverUrl = res.data
        this.coverList = [{ name: res.originalFilename, url: res.data }]
      } else if (type === 'topVideo') {
        // 顶部视频
        this.formData.topVideo = res.data
        this.topVideoList = [{ name: res.originalFilename, url: res.data }]
      } else if (type === 'bottomVideo') {
        // 底部视频
        this.formData.buttonVideo = res.data
        this.bottomVideoList = [{ name: res.originalFilename, url: res.data }]
      } else {
        console.log('type===>>>>', type)
      }
    },
    // 文件上传成功后，将fileId，fileUrl传给后台
    uploaderDoneFn(objName, fileList, uploader) {
      const that = this
      uploader
        .done()
        .then(function(doneResult) {
          const videosObj = [
            {
              url: doneResult.video.url,
              name: fileList[0].name
            }
          ]
          that.$http
            .requestPostQs({
              url: '/common/file/upload/saveQCloud',
              param: {
                fileId: doneResult.fileId,
                fileUrl: doneResult.video.url
              }
            })
            .then(res => {
              console.log('res==>', res)
              if (objName == 'topVideo') {
                that.formData.topVideo = videosObj[0].url
              }
              if (objName == 'bottomVideo') {
                that.formData.buttonVideo = videosObj[0].url
              }
            })
            .catch(err => {
              console.log('上传失败==>', err)
            })
        })
        .catch(function(err) {
          // deal with error
        })
    },
    // 视频上传进度
    uploaderMediaProgress(objName, uploader) {
      const that = this
      uploader.on('media_progress', function(info) {
        const percentage = parseInt(info.percent * 100)
        if (objName == 'topVideo') {
          that.progressFlag1 = true // 显示进度条
          that.percentage1 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }
        if (objName == 'bottomVideo') {
          that.progressFlag2 = true // 显示进度条
          that.percentage2 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }

        if (percentage >= 100) {
          setTimeout(() => {
            if (objName == 'topVideo') {
              that.progressFlag1 = false
            }
            if (objName == 'bottomVideo') {
              that.progressFlag2 = false
            }
          }, 1000) // 一秒后关闭进度条
        }
      })
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    fileChange(argumentList, type) {
      const file = argumentList[0]
      const fileList = argumentList[1]
      if (type === 'topVideo') {
        // 基本信息配置
        this.uploader1 = this.tcVod.upload({
          mediaFile: file.raw // 媒体文件
        })
        // 进度
        this.uploaderMediaProgress('topVideo', this.uploader1)
        // 视频上传后 处理
        this.uploaderDoneFn('topVideo', fileList, this.uploader1)
      } else if (type === 'bottomVideo') {
        // 基本信息配置
        this.uploader2 = this.tcVod.upload({
          mediaFile: file.raw // 媒体文件
        })
        // 进度
        this.uploaderMediaProgress('bottomVideo', this.uploader2)
        // 视频上传后 处理
        this.uploaderDoneFn('bottomVideo', fileList, this.uploader2)
      } else {
        console.log('type===>>>>', type)
      }
    },
    // 文件列表移除文件时的钩子
    removeFile(argumentList, type) {
      // const file = argumentList[0]
      const fileList = argumentList[1]

      if (type === 'topVideo') {
        this.formData.topVideo = ''
        this.topVideoList = []
        this.progressFlag1 = false
        this.percentage1 = 0
        if (this.uploader1) {
          this.uploader1.cancel()
          this.uploader1 = ''
        }
      } else if (type === 'bottomVideo') {
        this.formData.buttonVideo = ''
        this.bottomVideoList = fileList
        this.progressFlag2 = false
        this.percentage2 = 0
        if (this.uploader2) {
          this.uploader2.cancel()
          this.uploader2 = ''
        }
      } else if (type === 'coverImg') {
        this.coverList = []
        this.formData.coverUrl = ''
      }
    },
    // ------------------文件上传相关 end ------------------------

    // 提交表单
    submitForm() {
      if (this.articleRadio == 2 && JSON.stringify(this.selectArticleData) === '{}' && !this.modifyStatus) {
        return this.$message({
          message: '请选择文章',
          type: 'warning'
        })
      }
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          if (this.promptRadio == 2 && !this.formData.articlePrompt) {
            this.formData.articlePrompt = '无'
          } else if (this.promptRadio == 1) {
            this.formData.articlePrompt = ''
          } else {
            this.formData.articlePrompt = this.formData.articlePrompt
          }
          this.formData.articleSource = this.articleRadio
          this.formData.articleContent = this.formData.articleSource == 2 ? this.formData.articleContent : this.mavonEditorValue
          if (this.formData.articleContent) {
            // 将标签文本转为纯文本
            const parser = new DOMParser()
            const doc = parser.parseFromString(this.formData.articleContent, 'text/html')
            const img = doc.getElementsByTagName('img')[0] // 获取图片
            let text = doc.body.innerText
            // text = text.replace(/\n/g, ' ')// 将\n转为空格
            // let imgReg = /<img(?:(?!\/>).|\n)*?\/>/gm //匹配img
            // 将图片转为md格式保证和后端处理的格式一样
            text = img && `${text} ![${img.alt}](${img.src})`
            this.formData.pureContent = text
          } else {
            this.formData.pureContent = ''
          }

          // 提交前删除无用oss
          this.delCloudFile(this.formData)

          this.$http
            .requestPut({
              url: '/knowledge/addOrEditKnowledgeArticle',
              param: this.formData,
              loading: false
            })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                // 返回上一页
                setTimeout(() => {
                  this.$router.go(-1)
                })
              }
            })
            .catch(err => {})
        } else {
          return false
        }
      })
    },
    // 取消提交
    cancelSubmit() {
      this.$router.go(-1)
    },

    // ------------------富文本相关 start ------------------------
    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
      const mavonEditor = new FormData()
      mavonEditor.append('file', file)
      this.$http
        .requestPost({
          url: '/common/file/upload',
          param: mavonEditor
        })
        .then(res => {
          this.$refs.mavon_editor.$img2Url(pos, res.data)
        })
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) {
      console.log(pos)
    },
    // 富文本改变后的内容
    editorChange(val, render) {
      this.mavonEditorValue = render
    },
    // ------------------富文本相关 end ------------------------

    // 存储云文件数据
    setCloudFileData(data) {
      this.cloudFileData.push(data)
      console.log(this.cloudFileData, '图片组')
    },
    // 删除无用的图片
    delCloudFile(data) {
      const delOssList = extractHttpLinks(data, this.cloudFileData)
      console.log(delOssList, 'delOssList')
      _delOssFile(delOssList)
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  background-color: #fff;
  padding: 20px;
  .input_rPadding {
    /deep/.el-input__inner {
      padding-right: 66px;
    }
  }
}
.editor {
  max-width: 1000px;
  max-height: 420px;
  .v-note-wrapper.shadow {
    height: 420px;
  }
}
.btns {
  border-top: 1px solid #f3f3f3;
  padding: 20px 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  .confirm {
    cursor: pointer;
    padding: 15px 40px;
    margin-right: 50px;
    color: #fff;
    background: #4e9aef;
    border-radius: 10px;
  }
  .cancel {
    cursor: pointer;
    padding: 15px 40px;
    color: #7f7f7f;
    border: 1px solid #797979;
    border-radius: 10px;
  }
}
.tip-box {
  position: relative;
  .tip {
    position: absolute;
    top: 26px;
    left: 34%;
    font-size: 12px;
    color: #ccc;
  }
}

.vedio {
  .el-icon-close {
    display: none;
  }
  &:hover {
    .el-icon-close {
      display: inline-block;
    }
    span {
      color: #4e9aef !important;
    }
  }
}
.video {
  width: 100%;
  height: 500px;
}
</style>
