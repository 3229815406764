var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "最近消费时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                      },
                      model: {
                        value: _vm.lastConsumeTime,
                        callback: function ($$v) {
                          _vm.lastConsumeTime = $$v
                        },
                        expression: "lastConsumeTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "累计消费" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.consumeSumBegin,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "consumeSumBegin", _vm._n($$v))
                        },
                        expression: "inquire.consumeSumBegin",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "span-link" }, [_vm._v("-")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.consumeSumEnd,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "consumeSumEnd", _vm._n($$v))
                        },
                        expression: "inquire.consumeSumEnd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "消费次数" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.consumeCountBegin,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.inquire,
                            "consumeCountBegin",
                            _vm._n($$v)
                          )
                        },
                        expression: "inquire.consumeCountBegin",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "span-link" }, [_vm._v("-")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.consumeCountEnd,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "consumeCountEnd", _vm._n($$v))
                        },
                        expression: "inquire.consumeCountEnd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "注册时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                      },
                      model: {
                        value: _vm.registerTime,
                        callback: function ($$v) {
                          _vm.registerTime = $$v
                        },
                        expression: "registerTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "width-140",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            _vm.selectValue = ""
                          },
                        },
                        model: {
                          value: _vm.selectKey,
                          callback: function ($$v) {
                            _vm.selectKey = $$v
                          },
                          expression: "selectKey",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "微信昵称", value: "nickname" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: {
                            label: "手机号",
                            value: "memberPhoneNumber",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "会员编号", value: "memberCode" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-180",
                      attrs: { placeholder: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.selectValue,
                        callback: function ($$v) {
                          _vm.selectValue = $$v
                        },
                        expression: "selectValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "来源" } },
                [
                  _c("el-input", {
                    staticClass: "width-110",
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.inquire.sourceWx,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "sourceWx", $$v)
                      },
                      expression: "inquire.sourceWx",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员等级" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select width-110",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.inquire.vipLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "vipLevel", $$v)
                        },
                        expression: "inquire.vipLevel",
                      },
                    },
                    _vm._l(_vm.$api.userLevel, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分组" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select width-110",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.inquire.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "categoryId", $$v)
                        },
                        expression: "inquire.categoryId",
                      },
                    },
                    _vm._l(_vm.groupData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.categoryName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd(1)
                },
              },
            },
            [_vm._v("设置所属专员")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd(2)
                },
              },
            },
            [_vm._v("设置营养师")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.tableData, border: "", height: "400" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", prop: "date", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "微信昵称",
                      "min-width": "200",
                      prop: "memberNickname",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex-box flex-center" }, [
                              scope.row.memberAvatar
                                ? _c("img", {
                                    staticClass: "img-box-show box-m-r",
                                    attrs: {
                                      src: scope.row.memberAvatar,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(scope.row.memberNickname)),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "会员编号",
                      "min-width": "200",
                      prop: "memberCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "最近消费时间",
                      "min-width": "160",
                      prop: "lastConsumeTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "累计消费",
                      "min-width": "120",
                      prop: "consumeSum",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s("￥" + scope.row.consumeSum.toFixed(2)) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "消费次数",
                      "min-width": "120",
                      prop: "consumeCount",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册方式",
                      "min-width": "120",
                      prop: "source",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.source == 1
                              ? _c("div", [_vm._v("APP")])
                              : scope.row.source == 2
                              ? _c("div", [_vm._v("小程序")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册手机号",
                      "min-width": "120",
                      prop: "memberTelephone",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册时间",
                      "min-width": "140",
                      prop: "registerTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册时长（天）",
                      "min-width": "120",
                      prop: "registerDuration",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "最近登录时间",
                      "min-width": "160",
                      prop: "lastLoginTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属专员",
                      "min-width": "120",
                      prop: "attacheName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属营养师",
                      "min-width": "120",
                      prop: "dietitianName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "会员等级",
                      "min-width": "120",
                      prop: "vipLevel",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.findEnumName(
                                    _vm.$api.userLevel,
                                    scope.row.vipLevel
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属业务员",
                      "min-width": "120",
                      prop: "intendantName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "业务员编号",
                      "min-width": "200",
                      prop: "intendantCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "二维码编号",
                      "min-width": "200",
                      prop: "qrCodeSerial",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "电商品业务员",
                      "min-width": "120",
                      prop: "salesmanName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "电商品二维码编号",
                      "min-width": "200",
                      prop: "electronicQrCodeSerial",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "福利二维码编号",
                      "min-width": "120",
                      prop: "bonusQrCodeId",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "福利二维码类型",
                      "min-width": "200",
                      prop: "bonusType",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.bonusType === 1
                              ? _c("div", [_vm._v("红包")])
                              : scope.row.bonusType === 2
                              ? _c("div", [_vm._v("优惠券")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "姓名", "min-width": "120", prop: "name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "身份证号码",
                      "min-width": "200",
                      prop: "idCardNumber",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属分组",
                      "min-width": "120",
                      prop: "categoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "来源",
                      "min-width": "120",
                      prop: "sourceWx",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      "min-width": "240",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.openSellDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("消费记录")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "operation-btn_partition" },
                              [_vm._v("/")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.openSiteDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("浏览记录")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog_1,
            title: _vm.dialogTitle + " - 会员档案",
            visible: _vm.dialogVisible_1,
            top: "5vh",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "微信昵称" } }, [
                    _c("div", { staticClass: "flex-box" }, [
                      _vm.submitForm.memberAvatar
                        ? _c("img", {
                            staticClass: "img-box-show box-m-r",
                            attrs: {
                              src: _vm.submitForm.memberAvatar,
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.submitForm.memberNickname)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "会员编号" },
                        model: {
                          value: _vm.submitForm.memberCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "memberCode", $$v)
                          },
                          expression: "submitForm.memberCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "姓名" },
                        model: {
                          value: _vm.submitForm.memberName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "memberName", $$v)
                          },
                          expression: "submitForm.memberName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "boy" },
                          model: {
                            value: _vm.submitForm.memberGender,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "memberGender", $$v)
                            },
                            expression: "submitForm.memberGender",
                          },
                        },
                        [_vm._v("男")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "girl" },
                          model: {
                            value: _vm.submitForm.memberGender,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "memberGender", $$v)
                            },
                            expression: "submitForm.memberGender",
                          },
                        },
                        [_vm._v("女")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.submitForm.overseaItem && _vm.submitForm.overseaItem.recipient
                ? _c(
                    "div",
                    { staticClass: "ht-info" },
                    [
                      _c("div", { staticClass: "ht-info-title" }, [
                        _vm._v("海淘信息"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "submitForm",
                          attrs: {
                            model: _vm.submitForm,
                            rules: _vm.submitRules,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c("el-form-item", { attrs: { label: "收件人" } }, [
                            _c("div", { staticClass: "flex-box" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.submitForm.overseaItem.recipient)
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-form-item", { attrs: { label: "联系方式" } }, [
                            _c("div", { staticClass: "flex-box" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.submitForm.overseaItem
                                      .recipientPhoneNumber
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box ht-info-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.submitForm.overseaItem.idCardFrontUrl,
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.submitForm.overseaItem.idCardBackUrl,
                          },
                        }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog_1 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeCPM,
            visible: _vm.dialogVisible_2,
            title: _vm.dialogName1,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _vm.dialogName1 == "设置所属专员"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "Popout_content" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "submitForm_2",
                        attrs: {
                          model: _vm.submitForm_2,
                          rules: _vm.submitRules_2,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "已选会员" } }, [
                          _c(
                            "div",
                            { staticClass: "flex-box user-list" },
                            _vm._l(_vm.selectList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "flex-box" },
                                [
                                  item.memberAvatar
                                    ? _c("img", {
                                        attrs: {
                                          src: item.memberAvatar,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(item.memberNickname)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属专员", prop: "attacheId" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openAttacheList(1)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.attacheTable,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "attacheCode",
                                        label: "专员编号",
                                        "min-width": "160",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "attacheName",
                                        label: "专员姓名",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "attachePhoneNumber",
                                        label: "手机号",
                                        "min-width": "120",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "end",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("el-button", { on: { click: _vm.closeCPM } }, [
                      _vm._v("取 消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitEdit },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "Popout_content" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "submitForm_3",
                        attrs: {
                          model: _vm.submitForm_3,
                          rules: _vm.submitRules_3,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "已选会员" } }, [
                          _c(
                            "div",
                            { staticClass: "flex-box user-list" },
                            _vm._l(_vm.selectList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "flex-box" },
                                [
                                  item.memberAvatar
                                    ? _c("img", {
                                        attrs: {
                                          src: item.memberAvatar,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(item.memberNickname)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "所属营养师", prop: "dietitianId" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openAttacheList(2)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.nutritionTable,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "dietitianCode",
                                        label: "营养师编号",
                                        "min-width": "160",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "dietitianName",
                                        label: "营养师",
                                        "min-width": "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "phoneNumber",
                                        label: "手机号",
                                        "min-width": "120",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "end",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("el-button", { on: { click: _vm.closeCPM } }, [
                      _vm._v("取 消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitNut },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogName2,
            visible: _vm.dialogVisible_5,
            "before-close": function () {
              return (_vm.dialogVisible_5 = false)
            },
            width: "750px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_5 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称" },
                        model: {
                          value: _vm.inquire_4.attacheName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_4, "attacheName", $$v)
                          },
                          expression: "inquire_4.attacheName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "编号" },
                        model: {
                          value: _vm.inquire_4.attacheCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_4, "attacheCode", $$v)
                          },
                          expression: "inquire_4.attacheCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "手机号" },
                        model: {
                          value: _vm.inquire_4.attachePhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_4, "attachePhoneNumber", $$v)
                          },
                          expression: "inquire_4.attachePhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getAttacheList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_4,
                      expression: "loading_4",
                    },
                  ],
                  ref: "attacheTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData_4,
                    border: "",
                    height: "400px",
                    "highlight-current-row": "",
                  },
                  on: { "current-change": _vm.handleSelectChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "attacheCode",
                      label: "专员编号",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "attacheName",
                      label: "专员姓名",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "attachePhoneNumber",
                      label: "手机号",
                      "min-width": "120",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_4.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_4.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_4.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_4,
                      "current-change": _vm.handleCurrentChange_4,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_5 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAttache },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogName2,
            visible: _vm.dialogVisible_6,
            "before-close": function () {
              return (_vm.dialogVisible_6 = false)
            },
            width: "750px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_6 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称" },
                        model: {
                          value: _vm.inquire_6.attacheName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_6, "attacheName", $$v)
                          },
                          expression: "inquire_6.attacheName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "编号" },
                        model: {
                          value: _vm.inquire_6.attacheCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_6, "attacheCode", $$v)
                          },
                          expression: "inquire_6.attacheCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getNutritionList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_6,
                      expression: "loading_6",
                    },
                  ],
                  ref: "nutritionTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData_6,
                    border: "",
                    height: "400px",
                    "highlight-current-row": "",
                  },
                  on: { "current-change": _vm.handleNutritionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dietitianCode",
                      label: "营养师编号",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dietitianName",
                      label: "营养师",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phoneNumber",
                      label: "手机号",
                      "min-width": "120",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_6.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_6.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_6.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_6,
                      "current-change": _vm.handleCurrentChange_6,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_6 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmNutrition },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.sellTitle + " - 消费记录",
            visible: _vm.dialogVisible_3,
            "before-close": function () {
              return (_vm.dialogVisible_3 = false)
            },
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "div",
                { staticClass: "flex-box" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, size: "medium" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "订单编号" },
                            model: {
                              value: _vm.inquire_2.orderCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.inquire_2, "orderCode", $$v)
                              },
                              expression: "inquire_2.orderCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.getSellList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_2,
                      expression: "loading_2",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData_2, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: scope.row.items, border: "" },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品信息",
                                    "min-width": "200",
                                    prop: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                scope.row.productImg
                                                  ? _c("img", {
                                                      staticClass: "img-box",
                                                      attrs: {
                                                        src: scope.row
                                                          .productImg,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "hospital-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.productName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品编号",
                                    "min-width": "160",
                                    prop: "productCode",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品规格",
                                    "min-width": "120",
                                    prop: "productNorms",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.productNorms || "/"
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单价",
                                    "min-width": "120",
                                    prop: "itemPrice",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "￥" + scope.row.itemPrice
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数量",
                                    "min-width": "120",
                                    prop: "itemNum",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "购买总价",
                                    "min-width": "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "￥" +
                                                    (
                                                      parseInt(
                                                        scope.row.itemNum
                                                      ) * scope.row.itemPrice
                                                    ).toFixed(2)
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单编号",
                      "min-width": "200",
                      prop: "orderCode",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleParticulars(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.orderCode))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "买家/收货人",
                      "min-width": "160",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.memberNickName +
                                    "/" +
                                    scope.row.recipient
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "总金额", "min-width": "120", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  "￥" + scope.row.orderTotalPrice.toFixed(2)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "实付金额", "min-width": "120", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  "￥" + scope.row.paidInAmount.toFixed(2)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "运费", "min-width": "120", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  "￥" + scope.row.shippingFee.toFixed(2)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下单时间",
                      "min-width": "160",
                      prop: "orderTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单状态",
                      "min-width": "120",
                      prop: "orderStatus",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$api.getValue(
                                    _vm.$api.EcommerceOrderState,
                                    scope.row.orderStatus,
                                    "name"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属专员",
                      "min-width": "120",
                      prop: "attacheName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货地址",
                      "min-width": "200",
                      prop: "recipientAddressDetail",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "140",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleParticulars(scope.row)
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.siteTitle + " - 浏览记录",
            visible: _vm.dialogVisible_4,
            "before-close": function () {
              return (_vm.dialogVisible_4 = false)
            },
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_4 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "浏览时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.daterange,
                          callback: function ($$v) {
                            _vm.daterange = $$v
                          },
                          expression: "daterange",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getSiteList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_3,
                      expression: "loading_3",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData_3, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "lastBrowseTime",
                      label: "最新浏览时间",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex-box" }, [
                              scope.row.productImg
                                ? _c("img", {
                                    staticClass: "img-box",
                                    attrs: {
                                      src: scope.row.productImg,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "hospital-name" }, [
                                _vm._v(_vm._s(scope.row.productName)),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "browseCount",
                      label: "浏览次数",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCategoryName",
                      label: "所属类别",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productClassify",
                      label: "所属类型",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$api.getValue(
                                    _vm.$api.orderClassify,
                                    scope.row.productClassify,
                                    "name"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_3.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_3.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_3.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_3,
                      "current-change": _vm.handleCurrentChange_3,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }