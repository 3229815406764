<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="是否显示">
          <el-select v-model="inquire.navStatus" placeholder="请选择" class="form-select" clearable>
            <el-option label="显示" value="display" />
            <el-option label="隐藏" value="hide" />
          </el-select>
        </el-form-item>

        <el-form-item label="导航名称">
          <el-input v-model="inquire.navName" placeholder="导航名称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增导航</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            v-loading="loading"
            :data="tableData"
            border
            row-key="navId"
            :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
            height="400px"
            style="width: 100%"
          >
            <el-table-column prop="" label="" min-width="60" />
            <el-table-column prop="navName" label="导航名称" min-width="120" />
            <el-table-column prop="navImg" label="导航图标(90*90)" min-width="140">
              <template slot-scope="scope">
                <div v-if="scope.row.navImg" class="table-view-box">
                  <img :src="scope.row.navImg" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="categoryId" label="关联分类" min-width="160" />
            <el-table-column prop="navOrder" label="排序" min-width="100" />
            <el-table-column prop="navStatus" label="是否显示" min-width="100">
              <template slot-scope="scope">
                {{ scope.row.navStatus === 'display' ? '显示' : '隐藏' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition">/</span>
                <span v-if="scope.row.navLevel == 1" class="operation-btn" @click="handleIcon(scope.row)">增加区域icon</span>
                <span v-if="scope.row.navLevel == 1" class="operation-btn_partition">/</span>
                <span v-if="scope.row.navLevel == 2" class="operation-btn" @click="handleRun(scope.row)">运营范围</span>
                <span v-if="scope.row.navLevel == 2" class="operation-btn_partition">/</span>
                <span v-show="scope.row.navStatus !== 'display'" class="operation-btn" @click="handleHide(scope.row, 'display')">显示</span>
                <span v-show="scope.row.navStatus === 'display'" class="operation-btn" @click="handleHide(scope.row, 'hide')">隐藏</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-main>
    </div>
    <!-- 运营城市 -->
    <navigationCity v-if="showCity" :show-city="showCity" @setCityValue="getCityValue" />

    <!-- 添加弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="closeDialog" width="650px" top="5vh">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="120px" :model="submitForm" :rules="submitRules">
          <el-form-item v-if="dialogTitle == '添加区域Icon'" label="父类名称" :required="true">
            <el-input v-model="submitForm.fuName" maxlength="12" placeholder="父类名称" disabled />
          </el-form-item>
          <el-form-item label="导航名称" prop="navName">
            <el-input v-model="submitForm.navName" maxlength="12" placeholder="导航名称" />
          </el-form-item>
          <el-form-item label="导航图标" prop="navImg">
            <d-upload
              upload-type="picture-card"
              :file-list="fileList"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccess"
              @removeFile="removeFile"
              @cloudFileData="setCloudFileData"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">图片大小：90*90</span>
              </div>
            </d-upload>
          </el-form-item>
          <el-form-item label="关联分类" prop="categoryId">
            <el-cascader v-model="submitForm.categoryId" :options="productCategoryList" :props="cascaderProps" class="width-inherit" />
          </el-form-item>
          <el-form-item v-if="dialogIcon" label="选择区域">
            <el-button type="primary" @click="handleRun">运营范围</el-button>
          </el-form-item>
          <el-form-item label="排序" prop="navOrder">
            <el-input v-model.number="submitForm.navOrder" placeholder="排序" />
          </el-form-item>
          <el-form-item label="是否显示" prop="navStatus">
            <el-radio v-model="submitForm.navStatus" label="display">是</el-radio>
            <el-radio v-model="submitForm.navStatus" label="hide">否</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import { extractHttpLinks, _delOssFile } from '@/utils/public'

import navigationCity from '@/components/navigationCity/index.vue'
export default {
  components: {
    dUpload,
    navigationCity
  },
  data() {
    return {
      imgType: ['.gif', '.jpeg', '.jpg', '.png'],
      videoType: ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v'],

      // 查询参数
      inquire: {
        navStatus: '',
        navName: ''
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加区域地址
      dialogTitle: '添加导航', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        fuName: '',
        navName: '',
        categoryId: '',
        navImg: '',
        navOrder: '',
        navStatus: 'display'
      },
      submitRules: {
        // fuName: [{required: true, message: '请输入父类名称', trigger: 'change'}],
        navName: [{ required: true, message: '请输入导航名称', trigger: 'change' }],
        categoryId: [{ required: true, message: '请选择关联分类', trigger: 'blur' }],
        navImg: [{ required: true, message: '请选择导航图标', trigger: 'blur' }],
        navOrder: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        navStatus: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      attacheTable: [],
      isAdd: true, // 是否为添加
      editRow: {},
      fileList: [], // 文件列表

      productCategoryList: [], // 分类列表
      cascaderProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      iconId: '',
      dialogIcon: false,
      showCity: false,

      cloudFileData: [] // 云文件列表
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
    // 获取产品分类列表数据
    this.getProductCategoryList()
  },
  methods: {
    // 刷新
    searchList() {
      this.getTableList()
    },
    // 图片上传成功
    uploadSuccess(res, file, fileList) {
      this.submitForm.navImg = res.data
    },
    // 删除文件
    removeFile(file, fileList) {
      this.submitForm.navImg = ''
    },
    // 获取产品分类列表数据
    getProductCategoryList() {
      this.$http.requestGet({ url: '/product/category/tree', loading: false }).then(res => {
        const firstAll = {
          categoryId: '0',
          categoryName: '全部'
        }
        res.data.unshift(firstAll)
        this.productCategoryList = res.data
        console.log(this.productCategoryList, 'this.productCategoryList')
      })
    },

    // 获取数据列表
    getTableList() {
      this.loading = true
      this.$http
        .requestPost({
          url: '/operation/home/nav/list',
          param: this.inquire,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // 添加
    addition() {
      this.dialogTitle = '添加导航'
      this.dialogIcon = false
      this.dialogVisible = true
      this.isAdd = true
      this.fileList = []
    },
    // 修改
    handleEdit(row) {
      this.fileList = []
      this.dialogTitle = '编辑导航'
      this.dialogIcon = false
      this.isAdd = false
      this.submitForm = {
        navName: row.navName,
        categoryId: row.categoryId,
        navImg: row.navImg,
        navOrder: row.navOrder,
        navStatus: row.navStatus
      }
      if (row.navImg) {
        const arr = row.navImg.split('/')
        this.fileList.push({
          name: arr[arr.length - 1],
          url: row.navImg
        })
      }

      this.dialogVisible = true
      this.editRow = row
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            navName: this.submitForm.navName,
            categoryId: this.submitForm.categoryId,
            navImg: this.submitForm.navImg,
            navOrder: this.submitForm.navOrder,
            navStatus: this.submitForm.navStatus
          }
          if (!this.isAdd) {
            param.navId = this.editRow.navId
          }
          if (this.dialogTitle == '添加区域Icon') {
            param.navLevel = 2
            param.navParentId = this.iconId
          }
          this.$http
            .requestPost({
              url: '/operation/home/nav/save',
              param: param
            })
            .then(res => {
              this.getTableList()
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.closeDialog()
            })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      // 删除无用oss
      this.delCloudFile(this.submitForm)

      this.$refs.submitForm && this.$refs.submitForm.resetFields()
      this.dialogVisible = false
      this.submitForm = {
        navName: '',
        categoryId: '',
        navImg: '',
        navOrder: '',
        navStatus: 'display'
      }
      this.fileList = []
    },

    // 隐藏
    handleHide(row, type) {
      const name = type === 'display' ? '显示' : '隐藏'
      this.$confirm('确定' + name + '该导航按钮?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const param = {
            navId: row.navId,
            navStatus: type
          }
          this.$http.requestPost({ url: '/operation/home/nav/save', param: param }).then(res => {
            this.getTableList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(err => {})
    },
    handleIcon(row) {
      this.submitForm.fuName = row.navName
      this.dialogTitle = '添加区域Icon'
      this.iconId = row.navId
      this.dialogIcon = false
      this.dialogVisible = true
      this.isAdd = true
      this.fileList = []
    },
    handleRun(row) {
      this.showCity = !this.showCity
      sessionStorage.setItem('cityId', JSON.stringify(row.navId))
    },
    getCityValue(val) {
      this.showCity = val
    },

    // 存储云文件数据
    setCloudFileData(data) {
      this.cloudFileData.push(data)
      console.log(this.cloudFileData, '图片组')
    },
    // 删除无用的图片
    delCloudFile(data) {
      const delOssList = extractHttpLinks(data, this.cloudFileData)
      console.log(delOssList, 'delOssList')
      _delOssFile(delOssList)
    }
  }
}
</script>

<style lang="scss" scoped>
.address-hierarchy {
  flex-wrap: wrap;
}

.table-view-box {
  width: 90px;
  height: 90px;
  margin: auto;
  & > img {
    width: 100%;
    height: 100%;
  }

  & > video {
    width: 100%;
    height: 100%;
  }
}
</style>
