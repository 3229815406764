<template>
  <!-- 已发货货订单 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" @change="selectValue = ''" placeholder="请选择">
              <el-option label="订单编号" value="orderCode"></el-option>
              <el-option label="收货人姓名" value="recipient"></el-option>
              <el-option label="专员姓名" value="attacheName"></el-option>
            </el-select>

            <el-input v-model="selectValue" placeholder="请输入" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="申请时间">
          <el-date-picker v-model="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" end-placeholder="结束日期" start-placeholder="开始日期" type="daterange">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="inquire.productName" placeholder="产品名称" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px;display: flex;align-items: center;">
      <el-button size="small" type="primary" @click="fnExportOrderList">导出</el-button>
      <div style="margin-left: 20px;">本月已完成开票：</div>
      <div style="font-size: 18px;">{{ybTotal}}</div>
      <div style="margin-left: 10px;">张</div>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table ref="tables" v-loading="loading" :data="tableData" :expand-row-keys="expands" border height="400px" row-key="invoiceId" style="width: 100%" @expand-change="expandChange">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                        <div class="hospital-name">{{ scope.row.productName }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip></el-table-column>
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum"></el-table-column>
                  <el-table-column label="购买总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.recipient ? (scope.row.customerOrder.memberNickName + '/' + scope.row.customerOrder.recipient) : scope.row.customerOrder.memberNickName + '（自提）' }}
              </template>
            </el-table-column>
            <el-table-column label="开票金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + (scope.row.customerOrder.invoiceAmount ? scope.row.customerOrder.invoiceAmount.toFixed(2) : '0.00')}}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + (scope.row.customerOrder.shippingFee ? scope.row.customerOrder.shippingFee.toFixed(2) : '0.00' ) }}
              </template>
            </el-table-column>
            <el-table-column label="开票申请时间" min-width="160" prop="createTime"></el-table-column>
            <el-table-column label="订单状态" min-width="120" prop="orderStatus">
              <template slot-scope="scope">
                {{ $api.getValue($api.EcommerceOrderState, scope.row.customerOrder.orderStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column label="所属专员" min-width="120" prop="attacheName">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.attacheName }}
              </template>
            </el-table-column>
            <el-table-column label="大区" min-width="120" prop="regionName">
              <template slot-scope="scope">
                {{ scope.row.regionName }}
              </template>
            </el-table-column>
            <el-table-column label="片区" min-width="120" prop="districtName">
              <template slot-scope="scope">
                {{ scope.row.districtName }}
              </template>
            </el-table-column>
            <el-table-column label="办事处" min-width="120" prop="agencyName">
              <template slot-scope="scope">
                {{ scope.row.agencyName }}
              </template>
            </el-table-column>
            <el-table-column label="医院" min-width="120" prop="hospitalName">
              <template slot-scope="scope">
                {{ scope.row.hospitalName }}
              </template>
            </el-table-column>
            <el-table-column label="商家" min-width="120" prop="merchantName">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.merchantName }}
              </template>
            </el-table-column>
            <el-table-column label="发票抬头" min-width="120" prop="invoiceName">
              <template slot-scope="scope">
                {{ scope.row.invoiceName }}
              </template>
            </el-table-column>
            <el-table-column label="开票状态" min-width="120" prop="invoiceStatus">
              <template slot-scope="scope">
                {{ scope.row.invoiceStatus }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" prop="remark">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleBH(scope.row)">驳回原因</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination :current-page="pageParam.pageNum" :page-size="pageParam.pageSize" :page-sizes="$api.pageSizes" :total="pageParam.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 开票 -->
    <el-dialog :visible.sync="dialogVisible" title="开票" width="500px" :before-close="closeDialog">
      <div class="Popout_content">
        <div class="product_wrapper">
          <div>产品信息：</div>
          <div class="product_item_box">
            <div class="product_item" v-for="(item,itemsIndex) in waitInfo.items" :key="itemsIndex">
              <img :src="item.productImg" alt="">
              <div>
                <div>{{item.productName}}</div>
                <div>数量：{{item.itemNum}}</div>
                <div>单价：{{item.itemPrice}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="wait-info">
          <ul>
            <li class="wait-li flex-box">
              <div class="lable">订单编号</div>
              <div class="content">{{ waitInfo.orderCode }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">开票金额</div>
              <div class="content">{{ '￥' + waitInfo.invoicePrice }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">抬头类型</div>
              <div class="content">{{ waitInfo.invoiceType == 1 ? '企业' : '个人' }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">发票抬头</div>
              <div class="content">{{ waitInfo.invoiceName }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">税号</div>
              <div class="content">{{ waitInfo.taxNumber }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户银行</div>
              <div class="content">{{ waitInfo.openBankName || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户账号</div>
              <div class="content">{{ waitInfo.openBankNnumber || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">企业地址</div>
              <div class="content">{{ waitInfo.enterpriseAddress || '无' }}</div>
            </li>
          </ul>
        </div>

        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px" size="medium">
          <el-form-item label="发票图片" prop="invoiceUrl">
            <d-upload upload-type="picture-card" :file-list="fileList" accept=".gif,.jpeg,.jpg,.png" @removeFile="removeFile" @uploadSuccess="uploadSuccess">
              <div class="tip-box">
                <i class="el-icon-plus" />
                <span class="tip" slot="tip">图片大小：210*297</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="submitForm.remark" :rows="3" maxlength="150" placeholder="请输入内容" type="textarea">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 驳回原因 -->
    <el-dialog :visible.sync="dialogBH" title="驳回原因" width="500px" :before-close="closeDialogBH">
      <div class="Popout_content">
        <div class="product_wrapper">
          <div>产品信息：</div>
          <div class="product_item_box">
            <div class="product_item" v-for="(item,itemsIndex) in waitInfo.items" :key="itemsIndex">
              <img :src="item.productImg" alt="">
              <div>
                <div>{{item.productName}}</div>
                <div>数量：{{item.itemNum}}</div>
                <div>单价：{{item.itemPrice}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="wait-info">
          <ul>
            <li class="wait-li flex-box">
              <div class="lable">订单编号：</div>
              <div class="content">{{ waitInfo.orderCode }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">开票金额：</div>
              <div class="content">{{ '￥' + waitInfo.invoicePrice }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">抬头类型：</div>
              <div class="content">{{ waitInfo.invoiceType == 1 ? '企业' : '个人' }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">发票抬头：</div>
              <div class="content">{{ waitInfo.invoiceName }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">税号：</div>
              <div class="content">{{ waitInfo.taxNumber }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户银行：</div>
              <div class="content">{{ waitInfo.openBankName || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户账号：</div>
              <div class="content">{{ waitInfo.openBankNnumber || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">企业地址：</div>
              <div class="content">{{ waitInfo.enterpriseAddress || '无' }}</div>
            </li>
          </ul>
        </div>
        <el-form ref="submitForm" :model="submitForm" label-width="100px" size="medium">
          <el-form-item label="备注：">
            <div>{{ waitInfo.remark || '无' }}</div>
          </el-form-item>
        </el-form>
        <el-form label-width="140px">
          <el-form-item label="诺税通驳回原因：">
            <div>{{ waitInfo.failCause || '无' }}</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialogBH">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { exportInvoice } from "@/api/order";
export default {
  components: {
    dUpload,
  },
  data() {
    return {
      inquire: {
        invoiceStatus: 2,
        createTimeBegin: "",
        createTimeEnd: "",
      },
      daterange: [],
      selectKey: "orderCode",
      selectValue: "",

      // 表格数据
      tableData: [],
      expands: [], // 当前展示行

      // 分页
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      // 开票
      waitInfo: {}, // 开票信息
      dialogVisible: false,
      submitForm: {
        invoiceUrl: "",
        remark: "",
      },
      submitRules: {
        invoiceUrl: [
          { required: true, message: "请选择发票图片", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      fileList: [],
      editRow: {},
      // 审核
      dialogBH: false,
      ybTotal: "",
    };
  },
  created() {
    if (sessionStorage.getItem("cacheParamF")) {
      this.inquire = JSON.parse(sessionStorage.getItem("cacheParamF"));
      this.daterange[0] = this.inquire.createTimeBegin || "";
      this.daterange[1] = this.inquire.createTimeEnd || "";
    }
    this.getTableList();
    this.handleTotal();
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList();
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1;
      this.getTableList();
    },
    // 添加医院-添加图片文件
    uploadSuccess(res, file, fileList) {
      this.submitForm.invoiceUrl = file.response.data;
    },
    // 添加医院-删除图片文件
    removeFile(file, fileList) {
      this.submitForm.invoiceUrl = "";
    },
    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = []);
      }
      this.expands = [news.invoiceId];
    },
    // 导出
    fnExportOrderList() {
      let this_ = this;
      if (!this_.daterange) this.daterange = [];
      this_.inquire.createTimeBegin =
        this_.daterange.length > 0 ? this_.daterange[0] : "";
      this_.inquire.createTimeEnd =
        this_.daterange.length > 0 ? this_.daterange[1] : "";

      let urlParam =
        this_.pageParam.pageNum + "&pageSize=" + this_.pageParam.pageSize;
      var param = JSON.parse(JSON.stringify(this_.inquire));
      if (this_.selectValue) {
        param[this_.selectKey] = this_.selectValue;
      }
      exportInvoice(param).then((res) => {
        this.$public.downloadFile(res);
        this.getTableList();
      });
    },
    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin =
        this.daterange.length > 0 ? this.daterange[0] : "";
      this.inquire.createTimeEnd =
        this.daterange.length > 0 ? this.daterange[1] : "";

      let urlParam =
        this.pageParam.pageNum + "&pageSize=" + this.pageParam.pageSize;
      var param = JSON.parse(JSON.stringify(this.inquire));
      if (this.selectValue) {
        param[this.selectKey] = this.selectValue;
      }

      this.loading = true;
      this.$http
        .requestPost({
          url: "/customer/invoice/orderList?pageNum=" + urlParam,
          param,
          loading: false,
        })
        .then((res) => {
          if (res.code == 200) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.pageParam.total = res.data.data.total;
            sessionStorage.removeItem("cacheParamF");
          }
        })
        .catch((err) => {
          this.loading = false;
          sessionStorage.removeItem("cacheParamF");
          console.log("error", err);
        });
    },

    // 开票
    handleWait(row) {
      this.$http
        .requestGet({ url: "/customer/invoice/get/" + row.invoiceId })
        .then((res) => {
          this.editRow = res.data;
          this.waitInfo = res.data;
          this.waitInfo.orderTotalPrice = row.customerOrder.orderTotalPrice;
          this.dialogVisible = true;
        });
    },

    // 确认
    confirm() {
      this.$refs.submitForm.validate((valid) => {
        if (valid) {
          let param = {
            invoiceUrl: this.submitForm.invoiceUrl,
            remark: this.submitForm.remark,
            invoiceId: this.editRow.invoiceId,
            invoiceStatus: 1,
          };
          this.$http
            .requestPost({ url: "/customer/invoice/save", param })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("操作成功！");
                setTimeout(() => {
                  this.closeDialog();
                  this.getTableList();
                }, 1000);
              }
            });
        } else {
          return false;
        }
      });
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && this.$refs.submitForm.resetFields();
      this.dialogVisible = false;
      this.submitForm = {
        invoiceUrl: "",
        remark: "",
      };
      this.fileList = [];
    },

    // 点击审核弹框
    handleBH(row) {
      this.$http
        .requestGet({ url: "/customer/invoice/get/" + row.invoiceId })
        .then((res) => {
          this.waitInfo = res.data;
          this.dialogBH = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 驳回-确认
    closeDialogBH() {
      this.dialogBH = false;
    },

    // 订单详情
    handleParticulars(row) {
      let path = "",
        index = 0;
      sessionStorage.setItem("cacheParamF", JSON.stringify(this.inquire));
      switch (row.customerOrder.orderClassify) {
        case "electronic":
          path = "/order/commerce/particulars";
          index = this.$api.EcommerceOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        case "medicine":
          path = "/order/medical/hospitalDelivery/particulars";
          index =
            this.$api.orderState.findIndex(
              (item) => item.value === row.customerOrder.orderStatus
            ) + 1;
          break;
        case "lease":
          path = "/order/lease/particulars";
          index = this.$api.leaseOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        case "nutrition":
          path = "/order/NutritionOrder/particulars";
          index = this.$api.nutritionOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        default:
          return;
      }
      this.$router.push({
        path,
        query: {
          type: index,
          id: row.customerOrder.orderId,
        },
      });
    },

    // 统计-总的发票数量
    handleTotal() {
      this.$http
        .requestPost({
          url: "/customer/invoice/getCurrentMonthInvoice",
        })
        .then((res) => {
          if (res.code == 200) {
            this.ybTotal = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wait-info {
  padding-bottom: 20px;
}
.wait-li {
  padding-bottom: 10px;
  .lable {
    font-size: 14px;
    font-weight: bold;
    padding-right: 10px;
    width: 100px;
    text-align: right;
  }
  .content {
    flex: 1;
  }
}
.product_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 20px;
  font-weight: bold;
  .product_item_box {
    flex: 1;
  }
  .product_item_box,
  .product_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .product_item {
    margin-right: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}
</style>
