var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium", "label-suffix": ":" },
            },
            [
              _c("el-form-item", { attrs: { label: "人员姓名" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入人员姓名" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.memberNickname,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "memberNickname", $$v)
                        },
                        expression: "inquire.memberNickname",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "手机号" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入手机号" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.memberTelephone,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "memberTelephone", $$v)
                        },
                        expression: "inquire.memberTelephone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "员工工号" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入员工工号" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.qyUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "qyUserId", $$v)
                        },
                        expression: "inquire.qyUserId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "所属分组" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "width-180",
                        attrs: { placeholder: "请选择所属分组", clearable: "" },
                        model: {
                          value: _vm.inquire.talentCategoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "talentCategoryId", $$v)
                          },
                          expression: "inquire.talentCategoryId",
                        },
                      },
                      _vm._l(_vm.groupManagementList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.categoryName, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "会员编号" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入会员编号" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.memberCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "memberCode", $$v)
                        },
                        expression: "inquire.memberCode",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "增加时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      model: {
                        value: _vm.inquire.times,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "times", $$v)
                        },
                        expression: "inquire.times",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "二维码编号" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入二维码编号" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.qrCodeSerial,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "qrCodeSerial", $$v)
                        },
                        expression: "inquire.qrCodeSerial",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleAddPerson },
            },
            [_vm._v("\n      新增人员\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.uploadDialogVisible = true
                },
              },
            },
            [_vm._v("\n      导入\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportPersonsRecords },
            },
            [_vm._v("\n      导出\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.createQrCode },
            },
            [
              _vm._v(
                "\n      生成二维码（" +
                  _vm._s(_vm.selectionList.length || 0) +
                  "）\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "personListTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      height: "400px",
                      "row-key": "memberId",
                    },
                    on: { "selection-change": _vm.handlePersonSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        fixed: "",
                        "reserve-selection": true,
                        selectable: _vm.isSelectAbleRow,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "date",
                        label: "序号",
                        width: "60",
                        fixed: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberNickname",
                        label: "人员姓名",
                        "min-width": "200",
                        fixed: "",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberTelephone",
                        label: "手机号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "qyUserId",
                        label: "员工工号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberCode",
                        label: "会员编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryName",
                        label: "所属分组",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属大区",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(row.regionName || "无")),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属片区",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(row.districtName || "无")),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属办事处",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(row.agencyName || "无")),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(">\n          "),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryCreateTime",
                        label: "增加时间",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "qrCodeSerial",
                        label: "睿长太二维码编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "electronicQrCodeSerial",
                        label: "电商品二维码编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "260",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDeletePerson(row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                              _vm._v(" "),
                              _vm.handleIsEmployee(row)
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.createRowQrCode(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  生成二维码\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("EditPersonnelDialog", {
        attrs: {
          "group-management-list": _vm.groupManagementList,
          "edit-form": _vm.editPersonRow,
        },
        on: { "edit-done": _vm.searchList },
        model: {
          value: _vm.personDialogVisible,
          callback: function ($$v) {
            _vm.personDialogVisible = $$v
          },
          expression: "personDialogVisible",
        },
      }),
      _vm._v(" "),
      _c("UploadBatchPersonDialog", {
        on: { "edit-done": _vm.searchList },
        model: {
          value: _vm.uploadDialogVisible,
          callback: function ($$v) {
            _vm.uploadDialogVisible = $$v
          },
          expression: "uploadDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }