import { OSSURL } from '@/utils/api'

const ossUrlObj = {
  public: {
    proportionHospitalIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionAccessIcon.png`,
    proportionRiderIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionRiderIcon.png`,
    proportionUserIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionUserIcon.png`,
    proportionOrderIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionOrderIcon.png`,
    proportionAccessIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionAccessIcon.png`,
    proportionOrderAccessIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionOrderAccessIcon.png`,
    proportionAddIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionAddIcon.png`,
    proportionConversionIcon: `${OSSURL}/projectFile/middlePlatform/public/proportionConversionIcon.png`,
    proportionBg1Img: `${OSSURL}/projectFile/middlePlatform/public/proportionBg1Img.png`,
    proportionBg2Img: `${OSSURL}/projectFile/middlePlatform/public/proportionBg2Img.png`,
    proportionBg3Img: `${OSSURL}/projectFile/middlePlatform/public/proportionBg3Img.png`,
    proportionBg4Img: `${OSSURL}/projectFile/middlePlatform/public/proportionBg4Img.png`
  }
}

// 筛选重复项
function findDuplicates() {
  // console.log(ossUrlObj);
  let data = []
  for (const arrayKey in ossUrlObj) {
    for (const arrayKey1 in ossUrlObj[arrayKey]) {
      data.push(ossUrlObj[arrayKey][arrayKey1])
      // const elink = document.createElement('a')
      // elink.href = ossUrlObj[arrayKey][arrayKey1]
      // elink.download = arrayKey1 //file.name
      // elink.style.display = 'none'
      // elink.target = '_blank'
      // elink.click()
    }
  }

  let result = data.filter((item, index, self) => {
    return self.indexOf(item) !== index
  })
  if (result.length > 0) {
    console.log(result, '出现重复数据')
  }
}

findDuplicates(ossUrlObj)

export default ossUrlObj
