var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box", attrs: { id: "main-scroll" } },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-bar bg_white" },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "a",
            {
              key: item.id,
              staticClass: "nav_item",
              class: { active: index === _vm.currentNavIndex },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.skipTo(index)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_1" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "common",
                attrs: {
                  size: "medium",
                  "label-width": "120px",
                  model: _vm.common,
                  rules: _vm.commonRules,
                },
              },
              [
                !_vm.isAdd
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "产品编号", readonly: "" },
                          model: {
                            value: _vm.common.productCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.common, "productCode", $$v)
                            },
                            expression: "common.productCode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品名称", prop: "productName" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "产品名称" },
                      model: {
                        value: _vm.common.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productName", $$v)
                        },
                        expression: "common.productName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "通用名称", prop: "productCommonName" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "通用名称" },
                      model: {
                        value: _vm.common.productCommonName,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productCommonName", $$v)
                        },
                        expression: "common.productCommonName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "所有类别", prop: "productCategoryId" } },
                  [
                    _c("el-cascader", {
                      staticClass: "width-inherit",
                      attrs: { options: _vm.productTypeList, props: _vm.props },
                      model: {
                        value: _vm.common.productCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productCategoryId", $$v)
                        },
                        expression: "common.productCategoryId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品亮点", prop: "productHighlight" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "产品亮点" },
                      model: {
                        value: _vm.common.productHighlight,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productHighlight", $$v)
                        },
                        expression: "common.productHighlight",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isAdd
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "总库存" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "总库存", disabled: "" },
                          model: {
                            value: _vm.common.productInventoryTotal,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.common,
                                "productInventoryTotal",
                                _vm._n($$v)
                              )
                            },
                            expression: "common.productInventoryTotal",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产厂商", prop: "productManufacturer" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "50", placeholder: "生产厂商" },
                      model: {
                        value: _vm.common.productManufacturer,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productManufacturer", $$v)
                        },
                        expression: "common.productManufacturer",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "收款商户", prop: "wechatMerchantId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.common.wechatMerchantId,
                          callback: function ($$v) {
                            _vm.$set(_vm.common, "wechatMerchantId", $$v)
                          },
                          expression: "common.wechatMerchantId",
                        },
                      },
                      _vm._l(_vm.weChatMerchantInfo, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label: item.mchName,
                            value: String(item.id),
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "排序", prop: "productOrder" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "5", placeholder: "排序" },
                      model: {
                        value: _vm.common.productOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productOrder", _vm._n($$v))
                        },
                        expression: "common.productOrder",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_2" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "sales",
                attrs: {
                  size: "medium",
                  "label-width": "140px",
                  model: _vm.sales,
                  rules: _vm.salesRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "required", attrs: { label: "添加规格" } },
                  [
                    _c("div", { staticClass: "size_box flex-box box-m-b" }, [
                      _c("label", [_vm._v("规格名称")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-box" }, [
                        _c(
                          "div",
                          { staticClass: "size-name" },
                          _vm._l(_vm.norms, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: tag.id,
                                staticClass: "box-m-r",
                                attrs: { closable: "", effect: "dark" },
                                on: {
                                  click: function ($event) {
                                    return _vm.amendTag("father", [index])
                                  },
                                  close: function ($event) {
                                    return _vm.removeSizeName(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(tag.normName) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "add-size flex-box" },
                          [
                            _c("el-input", {
                              staticClass: "width-110 box-m-r",
                              attrs: {
                                placeholder: "规格名称",
                                disabled: _vm.norms.length > 0 && _vm.isGifts,
                                maxlength: "10",
                              },
                              model: {
                                value: _vm.normName,
                                callback: function ($$v) {
                                  _vm.normName = $$v
                                },
                                expression: "normName",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  disabled: _vm.norms.length > 0 && _vm.isGifts,
                                },
                                on: { click: _vm.addSizeName },
                              },
                              [_vm._v("添加新规格")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.norms, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "size_box" },
                        [
                          item.normName
                            ? _c("div", { staticClass: "flex-box box-m-b" }, [
                                _c("label", { staticClass: "font-normal" }, [
                                  _vm._v(_vm._s(item.normName) + ":"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      staticClass: "width-110 box-m-r",
                                      attrs: {
                                        placeholder: "设置属性",
                                        maxlength: "10",
                                      },
                                      model: {
                                        value: item.attrName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "attrName", $$v)
                                        },
                                        expression: "item.attrName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          disabled:
                                            item.normValues.length > 0 &&
                                            _vm.isGifts,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addAttr(index)
                                          },
                                        },
                                      },
                                      [_vm._v("添加")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box specs" }, [
                            _c(
                              "div",
                              { staticClass: "size-name " },
                              _vm._l(item.normValues, function (tag, index2) {
                                return _c(
                                  "div",
                                  {
                                    key: tag.valueName + index2,
                                    staticClass: "size-item",
                                  },
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        staticClass: "tag",
                                        attrs: { closable: "", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.amendTag("child", [
                                              index,
                                              index2,
                                            ])
                                          },
                                          close: function ($event) {
                                            return _vm.closeAttr(index, index2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(tag.valueName) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    tag.isSell === 0
                                      ? _c(
                                          "div",
                                          { staticClass: "sell" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: !tag.valueId,
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleIsSell(tag)
                                                  },
                                                },
                                                model: {
                                                  value: tag.isSell,
                                                  callback: function ($$v) {
                                                    _vm.$set(tag, "isSell", $$v)
                                                  },
                                                  expression: "tag.isSell",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      发售\n                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "sell" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: !tag.valueId,
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleNoSell(tag)
                                                  },
                                                },
                                                model: {
                                                  value: tag.isSell,
                                                  callback: function ($$v) {
                                                    _vm.$set(tag, "isSell", $$v)
                                                  },
                                                  expression: "tag.isSell",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      停售\n                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.sizeTypeList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "one_" + index,
                          staticClass: "size_box flex-box box-m-b",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "font-normal" },
                            [
                              _vm._l(item.normValues, function (item2, index2) {
                                return _c(
                                  "span",
                                  { key: "one_" + index + index2 },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          index2 === item.normValues.length - 1
                                            ? item2
                                            : item2 + "+"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              _vm._v("\n              :\n            "),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-box" },
                            [
                              _c("el-input", {
                                staticClass: "width-110 box-m-r",
                                attrs: {
                                  maxlength: "7",
                                  placeholder: "销售价（选填）",
                                },
                                on: {
                                  blur: function () {
                                    return _vm.$validate.verifyMoney(
                                      item.inventorySalesPrice
                                    )
                                      ? (item.inventorySalesPrice = Number(
                                          item.inventorySalesPrice
                                        ))
                                      : (item.inventorySalesPrice = "")
                                  },
                                  change: _vm.salesEdit,
                                },
                                model: {
                                  value: item.inventorySalesPrice,
                                  callback: function ($$v) {
                                    _vm.$set(item, "inventorySalesPrice", $$v)
                                  },
                                  expression: "item.inventorySalesPrice",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "width-110 box-m-r",
                                attrs: {
                                  maxlength: "7",
                                  placeholder: "成本价",
                                },
                                on: {
                                  blur: function () {
                                    return _vm.$validate.verifyMoney(
                                      item.inventoryCostPrice
                                    )
                                      ? (item.inventoryCostPrice = Number(
                                          item.inventoryCostPrice
                                        ))
                                      : (item.inventoryCostPrice = "")
                                  },
                                  change: _vm.salesEdit,
                                },
                                model: {
                                  value: item.inventoryCostPrice,
                                  callback: function ($$v) {
                                    _vm.$set(item, "inventoryCostPrice", $$v)
                                  },
                                  expression: "item.inventoryCostPrice",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "width-110 box-m-r",
                                attrs: {
                                  maxlength: "7",
                                  placeholder: "市场价",
                                },
                                on: {
                                  blur: function () {
                                    return _vm.$validate.verifyMoney(
                                      item.inventoryMarketPrice
                                    )
                                      ? (item.inventoryMarketPrice = Number(
                                          item.inventoryMarketPrice
                                        ))
                                      : (item.inventoryMarketPrice = "")
                                  },
                                  change: _vm.salesEdit,
                                },
                                model: {
                                  value: item.inventoryMarketPrice,
                                  callback: function ($$v) {
                                    _vm.$set(item, "inventoryMarketPrice", $$v)
                                  },
                                  expression: "item.inventoryMarketPrice",
                                },
                              }),
                              _vm._v(" "),
                              index === 0
                                ? _c(
                                    "span",
                                    { staticClass: "color_light-grey box-m-l" },
                                    [_vm._v("价格保留两位小数")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "初始销量", prop: "initialSales" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("el-input", {
                          staticClass: "width-110",
                          attrs: { maxlength: "10", placeholder: "初始销量" },
                          on: { change: _vm.salesEdit },
                          model: {
                            value: _vm.sales.initialSales,
                            callback: function ($$v) {
                              _vm.$set(_vm.sales, "initialSales", _vm._n($$v))
                            },
                            expression: "sales.initialSales",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-size-12 box-m-l color_light-black",
                          },
                          [
                            _vm._v(
                              "\n              设置后，您的用户看到的销量=初始销量+最近30天真实销量\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.productType !== "cross_border"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否为海淘产品",
                            prop: "uploadPrescription",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.common.isOversea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.common, "isOversea", $$v)
                                },
                                expression: "common.isOversea",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.common.isOversea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.common, "isOversea", $$v)
                                },
                                expression: "common.isOversea",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否需要购物凭证",
                            prop: "uploadPrescription",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              on: { change: _vm.salesEdit },
                              model: {
                                value: _vm.sales.uploadPrescription,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sales, "uploadPrescription", $$v)
                                },
                                expression: "sales.uploadPrescription",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              on: { change: _vm.salesEdit },
                              model: {
                                value: _vm.sales.uploadPrescription,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sales, "uploadPrescription", $$v)
                                },
                                expression: "sales.uploadPrescription",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商家默认提成",
                            prop: "defaultMerchantBrokerage",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-140",
                            attrs: { placeholder: "商家默认提成" },
                            on: {
                              change: _vm.salesEdit,
                              blur: function ($event) {
                                _vm.sales.defaultMerchantBrokerage =
                                  _vm.sales.defaultMerchantBrokerage.replace(
                                    /[^0-9.]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.sales.defaultMerchantBrokerage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sales,
                                  "defaultMerchantBrokerage",
                                  $$v
                                )
                              },
                              expression: "sales.defaultMerchantBrokerage",
                            },
                          }),
                          _vm._v("\n            %\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商家默认首单提成",
                            prop: "firstMerchantBrokerage",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-140",
                            attrs: { placeholder: "商家默认首单提成" },
                            on: {
                              change: _vm.salesEdit,
                              blur: function ($event) {
                                _vm.sales.firstMerchantBrokerage =
                                  _vm.sales.firstMerchantBrokerage.replace(
                                    /[^0-9.]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.sales.firstMerchantBrokerage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sales,
                                  "firstMerchantBrokerage",
                                  $$v
                                )
                              },
                              expression: "sales.firstMerchantBrokerage",
                            },
                          }),
                          _vm._v("\n            %\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "专员默认提成",
                            prop: "defaultAttacheBrokerage",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-140",
                            attrs: { placeholder: "专员默认提成" },
                            on: {
                              change: _vm.salesEdit,
                              blur: function ($event) {
                                _vm.sales.defaultAttacheBrokerage =
                                  _vm.sales.defaultAttacheBrokerage.replace(
                                    /[^0-9.]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.sales.defaultAttacheBrokerage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sales,
                                  "defaultAttacheBrokerage",
                                  $$v
                                )
                              },
                              expression: "sales.defaultAttacheBrokerage",
                            },
                          }),
                          _vm._v("\n            %\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "专员默认首单提成",
                            prop: "firstAttacheBrokerage",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-140",
                            attrs: { placeholder: "专员默认首单提成" },
                            on: {
                              change: _vm.salesEdit,
                              blur: function ($event) {
                                _vm.sales.firstAttacheBrokerage =
                                  _vm.sales.firstAttacheBrokerage.replace(
                                    /[^0-9.]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.sales.firstAttacheBrokerage,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sales,
                                  "firstAttacheBrokerage",
                                  $$v
                                )
                              },
                              expression: "sales.firstAttacheBrokerage",
                            },
                          }),
                          _vm._v("\n            %\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否为第三方产品",
                            prop: "uploadPrescription",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.common.isFranchisedProduct,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.common,
                                    "isFranchisedProduct",
                                    $$v
                                  )
                                },
                                expression: "common.isFranchisedProduct",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.common.isFranchisedProduct,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.common,
                                    "isFranchisedProduct",
                                    $$v
                                  )
                                },
                                expression: "common.isFranchisedProduct",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.common.isFranchisedProduct
              ? _c(
                  "div",
                  { staticClass: "add-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.addProduct },
                      },
                      [_vm._v("选择")]
                    ),
                    _vm._v(" "),
                    !_vm.productStandards.length && _vm.flag
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("请选择需要加盟的产品"),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.common.isFranchisedProduct
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: {
                      width: "50%",
                      "margin-left": "45px",
                      margin: "20px 0",
                    },
                    attrs: {
                      data: _vm.productStandards,
                      border: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2749146133
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属平台",
                        "min-width": "60",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.findEnumName(
                                        _vm.$api.platformOptions,
                                        scope.row.productBrand
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        58458892
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCode",
                        label: "产品编号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isGifts
              ? _c(
                  "el-form",
                  {
                    ref: "quota",
                    attrs: {
                      size: "medium",
                      "label-width": "140px",
                      model: _vm.sales,
                      rules: _vm.salesRules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否限购", prop: "buyingQuota" } },
                      [
                        _c("div", { staticClass: "buyingQuota_content" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.formData.buyingQuota,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "buyingQuota", $$v)
                                    },
                                    expression: "formData.buyingQuota",
                                  },
                                },
                                [_vm._v("是")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 0 },
                                  model: {
                                    value: _vm.formData.buyingQuota,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "buyingQuota", $$v)
                                    },
                                    expression: "formData.buyingQuota",
                                  },
                                },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.buyingQuota,
                                  expression: "formData.buyingQuota",
                                },
                              ],
                              staticClass: "buyingQuota_input",
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    prop: "buyingQuotaTotal",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "width-110",
                                    attrs: {
                                      placeholder: "限购数量",
                                      maxlength: "3",
                                    },
                                    model: {
                                      value: _vm.formData.buyingQuotaTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "buyingQuotaTotal",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "formData.buyingQuotaTotal",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "color_light-grey box-m-l",
                                  staticStyle: { "margin-right": "15px" },
                                },
                                [
                                  _vm._v(
                                    "设置后，用户的购买数量不能超过限购数量"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "", prop: "buyingQuotaTime" },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      format: "yyyy-MM-dd HH:mm",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": "00:00:00",
                                      placeholder: "请选择限购开始时间",
                                    },
                                    model: {
                                      value: _vm.formData.buyingQuotaTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "buyingQuotaTime",
                                          $$v
                                        )
                                      },
                                      expression: "formData.buyingQuotaTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.buyingQuota,
                    expression: "formData.buyingQuota",
                  },
                ],
                staticClass: "quote_link_product",
              },
              [
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.openQuoteRelateDialog },
                      },
                      [_vm._v("设置关联产品")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("TableList", {
                  staticClass: "tableList",
                  attrs: {
                    "is-fix-height": false,
                    "max-height": "450px",
                    "is-show-page": false,
                    "table-header": _vm.quoteTableHead,
                    "table-data": _vm.selectQuoteProducts,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "sellingPice",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            "\n            ￥" +
                              _vm._s(data.productPriceMin) +
                              " ~ ￥" +
                              _vm._s(data.productPriceMax) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                    {
                      key: "productStatus",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                data.productStatus == "on_shelves"
                                  ? "在售"
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.delSelectQuoteRelate(
                                    scope.data,
                                    scope.index
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.productType === "cross_border"
        ? _c(
            "div",
            {
              staticClass: "row_box bg_white link_box",
              attrs: { id: "row_link" },
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content link_content" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "linkForm",
                      attrs: {
                        size: "medium",
                        "label-width": "140px",
                        model: _vm.linkFormData,
                        rules: _vm.linkFormRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "link_item",
                          attrs: { label: "产品链接", required: "" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "设置链接：",
                                "label-width": "100px",
                                prop: "crossBorderUrl",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "item_input",
                                attrs: {
                                  placeholder: "请输入产品链接",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.linkFormData.crossBorderUrl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.linkFormData,
                                      "crossBorderUrl",
                                      $$v
                                    )
                                  },
                                  expression: "linkFormData.crossBorderUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addCouponLink },
                            },
                            [_vm._v("添加优惠券")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.linkFormData.couponData,
                        function (item, index) {
                          return _c(
                            "el-form-item",
                            { key: index, staticClass: "link_item" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "linkItem_coupon",
                                  attrs: {
                                    label: "优惠券ID：",
                                    "label-width": "100px",
                                    prop:
                                      "couponData." + index + ".couponLinkId",
                                    rules: _vm.linkFormRules.couponLinkIdRule,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "item_input",
                                    attrs: {
                                      placeholder: "请输入优惠券ID",
                                      clearable: "",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.focusCouponLink(
                                          $event,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.couponLinkId,
                                      callback: function ($$v) {
                                        _vm.$set(item, "couponLinkId", $$v)
                                      },
                                      expression: "item.couponLinkId",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteCouponLink(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "linkItem_uploadImg",
                                  attrs: {
                                    label: "链接图片：",
                                    "label-width": "100px",
                                    prop: "couponData." + index + ".couponImg",
                                    rules: _vm.linkFormRules.couponImgRule,
                                  },
                                },
                                [
                                  _c(
                                    "d-upload",
                                    {
                                      class: "couponLinkIdUpload" + index,
                                      attrs: {
                                        "upload-type": "picture-card",
                                        limit: 1,
                                        "file-list": item.couponImg,
                                        accept: ".gif,.jpeg,.jpg,.png",
                                      },
                                      on: {
                                        uploadSuccess: function ($event) {
                                          return _vm.uploadSuccessFun(
                                            arguments,
                                            "upload_linkImg",
                                            index
                                          )
                                        },
                                        removeFile: function ($event) {
                                          return _vm.removeFileFun(
                                            arguments,
                                            "remove_linkImg",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "tip-box" }, [
                                        _c("i", {
                                          staticClass: "el-icon-plus",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip",
                                          },
                                          [_vm._v("建议750px*357px")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.linkFormData.couponData.length
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "link_item",
                              attrs: {
                                label: "客服二维码",
                                prop: "serverQrcodeImg",
                              },
                            },
                            [
                              _c(
                                "d-upload",
                                {
                                  staticClass: "server_uploadImg",
                                  attrs: {
                                    "upload-type": "picture-card",
                                    limit: 1,
                                    "file-list":
                                      _vm.linkFormData.serverQrcodeImg,
                                    accept: ".gif,.jpeg,.jpg,.png",
                                  },
                                  on: {
                                    uploadSuccess: function ($event) {
                                      return _vm.uploadSuccessFun(
                                        arguments,
                                        "upload_serverQrcode"
                                      )
                                    },
                                    removeFile: function ($event) {
                                      return _vm.removeFileFun(
                                        arguments,
                                        "remove_serverQrcode"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "tip-box" }, [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip",
                                      },
                                      [_vm._v("建议330px*330px")]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_3" } }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "graphic",
                attrs: {
                  size: "medium",
                  "label-width": "140px",
                  model: _vm.graphic,
                  rules: _vm.graphicRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "产品图片(主图)", prop: "primaryImages" } },
                  [
                    _c(
                      "d-upload",
                      {
                        staticClass: "graphicImg_upload",
                        attrs: {
                          "upload-type": "picture-card",
                          "file-list": _vm.primaryImagesList,
                          accept: ".gif,.jpeg,.jpg,.png",
                        },
                        on: {
                          uploadSuccess: function ($event) {
                            return _vm.uploadSuccessFun(
                              arguments,
                              "upload_mainPicture"
                            )
                          },
                          removeFile: function ($event) {
                            return _vm.removeFileFun(
                              arguments,
                              "remove_mainPicture"
                            )
                          },
                          cloudFileData: _vm.setCloudFileData,
                        },
                      },
                      [
                        _c("div", { staticClass: "tip-box" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("建议360px*360px")]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品图片(次图)", prop: "images" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          "upload-type": "picture-card",
                          "file-list": _vm.imagesList,
                          limit: 9,
                          multiple: "",
                          accept: ".gif,.jpeg,.jpg,.png",
                        },
                        on: {
                          uploadSuccess: function ($event) {
                            return _vm.uploadSuccessFun(
                              arguments,
                              "upload_vicePicture"
                            )
                          },
                          removeFile: function ($event) {
                            return _vm.removeFileFun(
                              arguments,
                              "remove_vicePicture"
                            )
                          },
                          cloudFileData: _vm.setCloudFileData,
                        },
                      },
                      [
                        _c("div", { staticClass: "tip-box" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("建议360px*360px")]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主图视频(默认)" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 100,
                          "file-list": _vm.primaryVideosList,
                          "auto-upload": _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          handleChange: _vm.handleChange_2,
                          uploadSuccess: function ($event) {
                            return _vm.uploadSuccessFun(
                              arguments,
                              "upload_defaultVideo"
                            )
                          },
                          removeFile: function ($event) {
                            return _vm.removeFileFun(
                              arguments,
                              "remove_defaultVideo"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag2
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage2 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.primaryVideosList.length > 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频封面", prop: "videoImage1" } },
                      [
                        _c(
                          "d-upload",
                          {
                            attrs: {
                              "upload-type": "picture-card",
                              "file-list": _vm.graphic.videoImage1,
                              limit: 1,
                              accept: ".gif,.jpeg,.jpg,.png",
                            },
                            on: {
                              uploadSuccess: function ($event) {
                                return _vm.uploadSuccessFun(
                                  arguments,
                                  "upload_defaultVideoCover"
                                )
                              },
                              removeFile: function ($event) {
                                return _vm.removeFileFun(
                                  arguments,
                                  "remove_defaultVideoCover"
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "tip-box" }, [
                              _c("i", { staticClass: "el-icon-plus" }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主图视频(医院)" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.openVideos },
                      },
                      [_vm._v("视频上传")]
                    ),
                    _vm._v(" "),
                    _c("d-upload", {
                      attrs: {
                        size: 100,
                        "file-list": _vm.hospitalFileList,
                        action: _vm.actionUrl,
                        accept:
                          ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                      },
                      on: {
                        uploadSuccess: function ($event) {
                          return _vm.uploadSuccessFun(
                            arguments,
                            "upload_hospitalVideo"
                          )
                        },
                        removeFile: function ($event) {
                          return _vm.removeFileFun(
                            arguments,
                            "remove_hospitalVideo"
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情顶部视频" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 500,
                          "file-list": _vm.graphic.topVideo,
                          "auto-upload": _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          handleChange: _vm.handleChange_5,
                          uploadSuccess: function ($event) {
                            return _vm.uploadSuccessFun(
                              arguments,
                              "upload_topVideo"
                            )
                          },
                          removeFile: function ($event) {
                            return _vm.removeFileFun(
                              arguments,
                              "remove_topVideo"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag5
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage5 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.graphic.topVideo.length > 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频封面", prop: "videoImage2" } },
                      [
                        _c(
                          "d-upload",
                          {
                            attrs: {
                              "upload-type": "picture-card",
                              "file-list": _vm.graphic.videoImage2,
                              limit: 1,
                              accept: ".gif,.jpeg,.jpg,.png",
                            },
                            on: {
                              uploadSuccess: function ($event) {
                                return _vm.uploadSuccessFun(
                                  arguments,
                                  "upload_topVideoCover"
                                )
                              },
                              removeFile: function ($event) {
                                return _vm.removeFileFun(
                                  arguments,
                                  "remove_topVideoCover"
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "tip-box" }, [
                              _c("i", { staticClass: "el-icon-plus" }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情底部视频" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 500,
                          "file-list": _vm.graphic.bottomVideo,
                          "auto-upload": _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          handleChange: _vm.handleChange_6,
                          uploadSuccess: function ($event) {
                            return _vm.uploadSuccessFun(
                              arguments,
                              "upload_bottomVideo"
                            )
                          },
                          removeFile: function ($event) {
                            return _vm.removeFileFun(
                              arguments,
                              "remove_bottomVideo"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag6
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage6 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.graphic.bottomVideo.length > 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频封面", prop: "videoImage3" } },
                      [
                        _c(
                          "d-upload",
                          {
                            attrs: {
                              "upload-type": "picture-card",
                              "file-list": _vm.graphic.videoImage3,
                              limit: 1,
                              accept: ".gif,.jpeg,.jpg,.png",
                            },
                            on: {
                              uploadSuccess: function ($event) {
                                return _vm.uploadSuccessFun(
                                  arguments,
                                  "upload_bottomVideoCover"
                                )
                              },
                              removeFile: function ($event) {
                                return _vm.removeFileFun(
                                  arguments,
                                  "remove_bottomVideoCover"
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "tip-box" }, [
                              _c("i", { staticClass: "el-icon-plus" }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品详情", prop: "detail" } },
                  [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("mavon-editor", {
                          ref: "md",
                          attrs: { toolbars: _vm.toolbars },
                          on: {
                            imgAdd: _vm.handleEditorImgAdd,
                            imgDel: _vm.handleEditorImgDel,
                            change: _vm.editorChange,
                          },
                          model: {
                            value: _vm.editor,
                            callback: function ($$v) {
                              _vm.editor = $$v
                            },
                            expression: "editor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "产品参数" } }, [
                  _c(
                    "div",
                    { staticClass: "form-list flex-box" },
                    [
                      _vm._l(_vm.graphic.param, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "form-item flex-box" }, [
                            _c(
                              "label",
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    placeholder: "参数名称",
                                  },
                                  model: {
                                    value: item.paramKey,
                                    callback: function ($$v) {
                                      _vm.$set(item, "paramKey", $$v)
                                    },
                                    expression: "item.paramKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-item-content" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    placeholder: "参数值",
                                  },
                                  model: {
                                    value: item.paramValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "paramValue", $$v)
                                    },
                                    expression: "item.paramValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "close_ben",
                                on: {
                                  click: function ($event) {
                                    return _vm.delectParam(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-circle-close" })]
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item flex-box" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.addParam },
                              },
                              [_vm._v("添加参数行")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_4" } }, [
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _vm._l(_vm.formData.tags, function (tag, index) {
                return _c(
                  "el-tag",
                  {
                    key: tag.tagId ? tag.tagId : tag.tagName,
                    attrs: { closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.closeLabel(index)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(tag.tagName) + "\n        ")]
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addLabel },
                },
                [_vm._v("添加")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_5" } }, [
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _vm._l(_vm.formData.services, function (tag, index) {
                return _c(
                  "el-tag",
                  {
                    key: tag.serviceId ? tag.serviceId : tag.serviceName,
                    attrs: { closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.closeServe(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(tag.serviceName) + "\n        "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addServe },
                },
                [_vm._v("添加")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_6" } }, [
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              { attrs: { size: "medium", "label-width": "80px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "上架" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "on_shelves" },
                        model: {
                          value: _vm.formData.productStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productStatus", $$v)
                          },
                          expression: "formData.productStatus",
                        },
                      },
                      [_vm._v("上架出售")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "for_sale" },
                        model: {
                          value: _vm.formData.productStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productStatus", $$v)
                          },
                          expression: "formData.productStatus",
                        },
                      },
                      [_vm._v("放入待出售")]
                    ),
                    _vm._v(" "),
                    !_vm.isAdd
                      ? _c(
                          "el-radio",
                          {
                            attrs: { label: "off_shelves" },
                            model: {
                              value: _vm.formData.productStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "productStatus", $$v)
                              },
                              expression: "formData.productStatus",
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-box submit-btn bg_white" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                  [_vm._v("确 定")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加" + _vm.labelTitle,
            visible: _vm.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    size: "medium",
                    "label-width": "80px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.labelTitle, prop: "labels" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "12", placeholder: _vm.labelTitle },
                        model: {
                          value: _vm.submitForm.labels,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "labels", $$v)
                          },
                          expression: "submitForm.labels",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传视频",
            visible: _vm.dialogVisible_1,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
            close: _vm.closeHospitalVideoDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitVideosRef",
                  attrs: {
                    size: "medium",
                    "label-width": "80px",
                    model: _vm.submitVideos,
                    rules: _vm.submitVideosRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择视频", prop: "videos" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            size: 100,
                            "file-list": _vm.hospitalVideosList,
                            "auto-upload": _vm.autoUpload,
                            action: _vm.actionUrl,
                            accept:
                              ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                          },
                          on: {
                            handleChange: _vm.handleChange_4,
                            uploadSuccess: function ($event) {
                              return _vm.uploadSuccessFun(
                                arguments,
                                "upload_hospitalVideoDialog"
                              )
                            },
                            removeFile: function ($event) {
                              return _vm.removeFileFun(
                                arguments,
                                "remove_hospitalVideoDialog"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tip-box" },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("视频上传")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.progressFlag4
                        ? _c("el-progress", {
                            attrs: { percentage: _vm.percentage4 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "绑定医院", prop: "hospitalId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-inherit",
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.submitVideos.hospitalId,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitVideos, "hospitalId", $$v)
                            },
                            expression: "submitVideos.hospitalId",
                          },
                        },
                        _vm._l(_vm.hospitalList, function (item) {
                          return _c("el-option", {
                            key: item.hospitalId,
                            attrs: {
                              label: item.hospitalName,
                              value: item.hospitalId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeHospitalVideoDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.videosConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "产品列表",
                "before-close": _vm.closeDialog_1,
                visible: _vm.dialogShow,
                top: "12vh",
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "Popout_content" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "proDialog",
                      staticClass: "demo-form-inline",
                      attrs: {
                        inline: true,
                        size: "medium",
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属平台" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form-select",
                              attrs: { placeholder: "请选择" },
                              on: {
                                change: function () {
                                  return _vm.getProductList()
                                },
                              },
                              model: {
                                value: _vm.inquire.productBrand,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inquire, "productBrand", $$v)
                                },
                                expression: "inquire.productBrand",
                              },
                            },
                            _vm._l(_vm.$api.platformOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索产品" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入产品名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchList($event)
                              },
                            },
                            model: {
                              value: _vm.inquire.productName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inquire,
                                  "productName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "inquire.productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.searchList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading_1,
                          expression: "loading_1",
                        },
                      ],
                      ref: "msgTabel",
                      staticClass: "single-select-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.productList,
                        border: "",
                        height: "400px",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", prop: "date", width: "60" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        (_vm.pageParam_1.pageNum - 1) *
                                          _vm.pageParam_1.pageSize +
                                          (scope.$index + 1)
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1856937175
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属平台",
                          "min-width": "60",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.findEnumName(
                                          _vm.$api.platformOptions,
                                          scope.row.productBrand
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          58458892
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编号",
                          "min-width": "160",
                          prop: "productCode",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          "min-width": "100",
                          prop: "productName",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.pageParam_1.pageNum,
                          "page-size": _vm.pageParam_1.pageSize,
                          "page-sizes": _vm.$api.pageSizes,
                          total: _vm.pageParam_1.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange_1,
                          "current-change": _vm.handleCurrentChange_1,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-box" }, [
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c("el-button", { on: { click: _vm.closeDialog_1 } }, [
                          _vm._v("取 消"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submitAdd_1 },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "padding-bottom": "10px" } }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("quotaChooseProduct", {
        ref: "quotaChooseProduct",
        attrs: { "link-product-ids": _vm.selectQuoteProducts },
        on: { confirmSelect: _vm.confirmSelectQuoteRelate },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("销售信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("链接专区")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("图文描述")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("搜索标签")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("服务保障")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("发布设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }