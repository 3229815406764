var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "developers_box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("上传资源到云")]),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "submitForm", attrs: { "label-position": "left" } },
        [
          _c("el-form-item", { attrs: { label: "归属", prop: "extremity" } }, [
            _c(
              "div",
              { staticClass: "select_box" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择图片归属" },
                    model: {
                      value: _vm.extremity,
                      callback: function ($$v) {
                        _vm.extremity = $$v
                      },
                      expression: "extremity",
                    },
                  },
                  _vm._l(_vm.extremityList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.title, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "文件夹名称",
                prop: "folderName",
                "label-width": "90px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "input_box" },
                [
                  _c("el-input", {
                    attrs: {
                      width: "200px",
                      placeholder: "请输入文件夹名",
                      required: "",
                    },
                    model: {
                      value: _vm.folderName,
                      callback: function ($$v) {
                        _vm.folderName = $$v
                      },
                      expression: "folderName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.$http.requestUrl + "/common/file/specifiedPath/upload",
            "list-type": "picture",
            multiple: true,
            "file-list": _vm.fileList,
            headers: _vm.headers,
            data: _vm.dataObj,
            "on-preview": _vm.onPreview,
            "on-success": _vm.onSuccess,
            "on-change": _vm.onChange,
            "before-upload": _vm.beforeUpload,
            "auto-upload": false,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "small", type: "primary" },
              slot: "trigger",
            },
            [_vm._v("选取文件")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { size: "small", type: "success" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v("上传到oss云")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tip", attrs: { slot: "tip" }, slot: "tip" },
            [
              _c("i", { staticClass: "el-icon-warning" }),
              _vm._v("点击图片可复制图片地址"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片预览",
            visible: _vm.dialogVisible_1,
            modal: false,
            width: "60%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _vm.fileType === "image"
              ? _c("div", { staticClass: "image-box" }, [
                  _c("img", { attrs: { src: _vm.preview, alt: "" } }),
                ])
              : _c("div", [
                  _c(
                    "video",
                    {
                      attrs: {
                        id: "video",
                        autoplay: "",
                        src: _vm.preview,
                        controls: "controls",
                        width: "100%",
                        height: "100%",
                      },
                    },
                    [_vm._v("视频描述")]
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }