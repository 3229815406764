<template>
  <div id="interview">
    <div class="title">
      <div class="txt_box">
        <div class="text">{{ title }}</div>
        <img src="@/assets/image/top.png" alt="" class="icon" />
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="" />
          <div ref="poup" class="poup">
            <div class="txt">
              统计最近七天的历史访问数据(不含当日)
              <br />
              日访问次数：平台的单日访问总次数
              <br />
              日新增用户：单日新注册的用户总数
              <br />
              日访问人数：单日授权登录的用户总数
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="chart" ></div>
  </div>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'
export default {
  data() {
    return {
      title: '历史访问数据分析'
    }
  },
  computed: {},
  created() {},
  methods: {
    //  获取日访问量数据图表
    getDataBottom(dataTime, dailyVisitors, newMember, dailyVisits) {
      // 获取到dom
      var chartDom = document.getElementById('chart')
      var myChart = echarts.init(chartDom)
      window.addEventListener('resize', function() {
        myChart.resize()
      })
      const colors = ['rgba(96, 111, 255, 0.8)', '#FD7222', '#4B9CFE']
      var option
      option = {
        color: colors,
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'cross',
          //   label: {
          //   backgroundColor: '#6a7985'
          // },
          // }
        },
        grid: {
          right: '0',
          left: '30',
          top: '80',
          bottom: '20'
        },
        legend: {
          top: '10',
          right: 'center',
          itemWidth: 10, // 图例标记的图形宽度
          itemHeight: 10, // 图例标记的图形高度 在修改图例标记图形的时候可以用到。比如矩形变成直线
          itemGap: 26, // 间距
          data: [
            {
              name: '日访问次数',
              icon: 'circle'
            },
            {
              name: '日新增用户',
              icon: 'rect'
            },
            {
              name: '日访问人数',
              icon: 'rect'
            }
          ],
          textStyle: {
            fontSize: 12, // 文字大小
            padding: [0, 5, -2, 0], // 文字与图形之间的左右间距
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              // x轴文字的配置
              show: true,
              interval: 0, // 使x轴文字显示全
              textStyle: {
                color: '#B0F9FD', // 更改坐标轴文字颜色
                fontSize: 14 // 文字大小
              }
            },
            axisTick: {
              show: false
            },
            // 设置坐标轴字体颜色和宽度
            axisLine: {
              show: false
            },
            // prettier-ignore
            data: dataTime
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            position: 'left',
            // alignTicks: true,
            // offset: 80,
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(101, 165, 221, 0.08)'],
                width: 1,
                type: 'solid'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            // 设置坐标轴字体颜色
            axisLabel: {
              textStyle: {
                color: '#65A5DD',
                fontSize: 12 // 文字大小
              },
              formatter: function(value) {
                // 判断数据是否过千，过千显示*K
                let data
                if (value >= 1000) {
                  data = value / 1000 + 'k'
                } else if (value < 1000) {
                  data = value
                }
                return data
              }
            }
          },
          {
            type: 'value',
            name: '',
            position: 'left',
            // alignTicks: true,
            // offset: 80,
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#17385f'],
                width: 1,
                type: 'dashed'
              }
            },
            // 设置坐标轴字体颜色和宽度
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            }
          },
          {
            type: 'value',
            name: '',
            position: 'left',
            // alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#17385f'],
                width: 1,
                type: 'dashed'
              }
            },
            // 设置坐标轴字体颜色和宽度
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '日访问次数',
            type: 'line',
            // data: [8, 9, 81, 111, 711, 1112, 11111],
            data: dailyVisits,
            symbol: 'none',
            smooth: true
          },
          {
            name: '日新增用户',
            type: 'bar',
            barWidth: 5, // 柱图宽度
            // data: [2,3,1,5,7,5,2],
            data: newMember,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FD7222'
                },
                {
                  offset: 1,
                  color: 'rgba(1, 7, 48, 0.1)'
                }
              ])
            }
          },
          {
            name: '日访问人数',
            type: 'bar',
            barWidth: 5, // 柱图宽度
            // data: [1,4,2,7,2,3,5],
            data: dailyVisitors,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#4B9CFE'
                },
                {
                  offset: 1,
                  color: 'rgba(1, 7, 48, 0.1)'
                }
              ])
            }
          }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>
<style lang="scss" scoped>
#interview {
  // width: 100%;
  // height: 100%;
  width: 615px;
  height: 535px;
  padding: 20px;

  background: url('https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/06b8703a-2603-4c83-8c15-d6a8493cc44f.png') no-repeat center 0px;
  background-size: cover;
  margin-top: 18px;
  .title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 8px 28px 0;
    .text {
      background-image: linear-gradient(0deg, #56feff, #e2f3fb);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 16px;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
    .txt_box {
      display: flex;
      align-self: center;
      align-items: center;
      align-content: center;
      &:hover {
        .tip {
          .poup {
            display: flex;
          }
        }
      }
    }
  }
}

#chart {
  height: 420px;
  width: 100%;
}

.tip {
  width: 28px;
  height: 28px;
  position: relative;
  margin-left: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.poup {
  background: url('../../assets/image/tip.png') no-repeat center center;
  padding: 20px;
  text-align: left;
  right: 10px;
  z-index: 100;
  position: absolute;
  width: 302px;
  // height: 71px;
  color: #c0e2ff;
  font-weight: 400;
  font-size: 12px;
  font-face: Microsoft YaHei;
  line-height: 20px;
  display: none;
}
</style>
