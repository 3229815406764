<template>
  <!-- 医品 - 医院配送 - 退货订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <!-- <div class="steps-box bg_white">
          <el-steps :active="active">
            <el-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
              :description="item.time"
            ></el-step>
          </el-steps>
        </div> -->

    <div class="row_2 bg_white flex-box">
      <div class="row_left">
        <h4>退货单信息</h4>
        <p class="color_light-black">
          退货单状态：{{ $api.getValue($api.returnOrderState, pageData.orderStatus, 'name') }} {{ pageData.afterSalesRequestReport ? '(售后)' : '' }}
        </p>
        <p class="color_light-black">申请时间：{{ pageData.applyReturnTime }}</p>
        <p v-if="pageData.orderStatus != 'auditing'" class="color_light-black">审核时间：{{ pageData.auditTime }}</p>
        <p v-if="pageData.orderStatus == 'returned'" class="color_light-black">签收时间：{{ pageData.signTime || '无' }}</p>
        <p v-if="pageData.orderStatus == 'returned'" class="color_light-black">退款时间：{{ pageData.returnTime }}</p>
        <p v-if="pageData.orderStatus == 'returned' || pageData.orderStatus == 'pending_sign'" class="color_light-black">
          退货物流单号：{{ pageData.returnLogisticsCode || '无' }}
        </p>
        <p v-if="pageData.orderStatus == 'returned' || pageData.orderStatus == 'pending_sign'" class="color_light-black">
          退货地址：{{ pageData.returnAddressDetail || '无' }}
        </p>
        <p v-if="pageData.orderStatus == 'unaudited'" class="color_light-black">不通过原因：{{ pageData.rejectReason || '无' }}</p>
        <p class="color_light-black">配送方式：{{ $api.getValue($api.deliveryMethod, pageData.deliveryMethod, 'name') }}</p>
        <p class="color_light-black">收货人姓名：{{ pageData.recipient }}</p>
        <p class="color_light-black">收货人手机号：{{ pageData.recipientPhoneNumber }}</p>
        <p class="color_light-black">
          收货人地址：
          <span>{{ pageData.recipientAddressDetail }}</span>
        </p>
        <p style="height: 24px"></p>
        <div class="color_light-black flex-box">
          <span class="row-title">购物凭证：</span>
          <div v-show="imageUrl">
            <d-upload upload-type="picture-card" :file-list="fileList" accept=".gif,.jpeg,.jpg,.png">
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </div>
          <span v-show="!imageUrl">无</span>
        </div>
        <div class="color_light-black flex-box">
          <span class="row-title">退货凭证：</span>
          <div v-show="returnProof" class="image_wrapper">
            <div v-for="(item, index) in returnProof" :key="index" class="image_box" @click="perviewImg(item)">
              <img :src="item" alt="" />
            </div>
          </div>
          <span v-show="!returnProof">无</span>
        </div>

        <p class="color_light-black">备注信息：{{ pageData.orderRemark || '无' }}</p>
        <p v-if="pageData.orderStatus == 'returned'" class="color_light-black">请示报告号：{{ pageData.afterSalesRequestReport || '无' }}</p>
      </div>
    </div>

    <div class="row_3 bg_white">
      <h4>商品信息</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available">
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ '￥' + scope.row.productPrice || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="小计" min-width="100" prop="itemAmount">
            <template slot-scope="scope">
              {{ `${scope.row.subtotalCoupon ? '￥' + scope.row.subtotalCoupon : 0}` }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ `${scope.row.couponDiscountAmount ? '￥' + scope.row.couponDiscountAmount : 0}` }}
            </template>
          </el-table-column>
          <el-table-column label="实收金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ `${scope.row.paidInAmountCoupon ? '￥' + scope.row.paidInAmountCoupon : 0}` }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="提成比例" min-width="100" prop="brokerageRate" />
          <el-table-column label="提成金额" min-width="100" prop="brokerageAmount" /> -->
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥ {{ pageData.orderTotalPrice || 0 }}</span>
          </li>
          <li class="line-box"></li>
          <li class="bold">
            退款金额：
            <span class="block_span color_red">￥ {{ pageData.paidInAmount || 0 }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="pageData.merchantName" class="row_4 bg_white">
      <h4>商家/医院信息</h4>
      <p class="color_light-black">
        <span class="title-box">商家名称：</span>
        {{ pageData.merchantName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家编号：</span>
        {{ pageData.merchantCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院名称：</span>
        {{ pageData.hospitalName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院编号：</span>
        {{ pageData.hospitalCode }}
      </p>
    </div>

    <div class="row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black">
        <span class="title-box">订单类型：</span>
        {{ $api.getValue($api.orderClassify, pageData.orderClassify, 'name') }}
      </p>
      <p class="color_light-black">
        <span class="title-box">订单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">下单时间：</span>
        {{ pageData.orderTime }}
      </p>
      <p class="color_light-black">
        <span class="title-box">付款时间：</span>
        {{ pageData.paymentTime }}
      </p>
      <p v-if="pageData.arriveTime" class="color_light-black">
        <span class="title-box">送达时间：</span>
        {{ pageData.arriveTime }}
      </p>
      <p v-if="pageData.afterSalesRequestReport" class="color_light-black">
        <span class="title-box">确认收货时间：</span>
        {{ pageData.receiptTime }}
      </p>
    </div>

    <div class="row_5 row_4 bg_white">
      <h4>出库批次信息</h4>
      <div v-for="(item, index) in pageData.items" :key="index" style="margin-bottom:10px">
        <div v-if="item.batch.length >= 1">
          <div>商品名称：{{ item.productName }}</div>
          <div v-for="(batchItem, batchIndex) in item.batch" :key="batchIndex">
            <p class="color_light-black">
              <span class="title-box">批次号：</span>
              {{ batchItem.batchNo }}
            </p>
            <p class="color_light-black">
              <span class="title-box">发货数量：</span>
              {{ batchItem.deliverCount }}
            </p>
            <p class="color_light-black">
              <span class="title-box">有效期：</span>
              {{ batchItem.produceTime }} ~ {{ batchItem.expireTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>退货批次信息</h4>
      <div v-for="(item, index) in pageData.items" :key="index" style="margin-bottom:10px">
        <div v-if="item.returnBatch.length >= 1">
          <div>商品名称：{{ item.productName }}</div>
          <div v-for="(batchItem, batchIndex) in item.returnBatch" :key="batchIndex">
            <p class="color_light-black">
              <span class="title-box">批次号：</span>
              {{ batchItem.batchNo }}
            </p>
            <p class="color_light-black">
              <span class="title-box">发货数量：</span>
              {{ batchItem.deliverCount }}
            </p>
            <p class="color_light-black">
              <span class="title-box">有效期：</span>
              {{ batchItem.produceTime }} ~ {{ batchItem.expireTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>专员信息</h4>
      <p class="color_light-black">
        <span class="title-box">专员姓名：</span>
        {{ pageData.attacheName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">专员编号：</span>
        {{ pageData.attacheCode || '无' }}
      </p>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>业务员信息</h4>
      <p class="color_light-black">
        <span class="title-box">业务员姓名：</span>
        {{ pageData.intendantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">业务员编号：</span>
        {{ pageData.intendantCode || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">二维码编号：</span>
        {{ pageData.qrCodeSerial || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端名称：</span>
        {{ pageData.qrMerchantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端编号：</span>
        {{ pageData.qrMerchantCode || '无' }}
      </p>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>福利二维码信息</h4>
      <p class="color_light-black">
        <span class="title-box">二维码编号：</span>
        {{ pageData.intendantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">二维码类别：</span>
        {{ pageData.intendantCode || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">关联产品：</span>
        {{ pageData.qrCodeSerial || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">关联批次：</span>
        {{ pageData.qrMerchantName || '无' }}
      </p>
    </div>

    <!-- 优惠明细-start -->
    <div class="row_6 row_4 bg_white">
      <h4>优惠信息</h4>
      <p class="color_light-black" style="margin:0 0 10px 50px;">
        <span style="margin-right:50px">优惠券名称：{{ pageData.couponName || '-' }}</span>
        <span style="margin-right:50px">
          优惠券ID：{{ pageData.customerCoupon && pageData.customerCoupon.couponCode ? pageData.customerCoupon.couponCode : '-' }}
        </span>
        <span style="margin-right:50px">会员等级：{{ $public.getVipLevel(pageData.vipLevel) }}</span>
      </p>
      <p v-if="pageData.couponIntendantVo" class="color_light-black" style="margin:0 0 10px 50px">
        <span style="margin-right:50px">业务员编号：{{ pageData.couponIntendantVo.intendantCode || '无' }}</span>
        <span style="margin-right:50px">业务员姓名：{{ pageData.couponIntendantVo.intendantName || '无' }}</span>
        <span style="margin-right:50px">二维码编号：{{ pageData.couponIntendantVo.qrCodeSerial || '无' }}</span>
      </p>
      <div class="table">
        <el-table :data="pageData.items" :border="true" style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box" style="align-items:center">
                <img v-if="scope.row.productImg" class="img-box" :src="scope.row.productImg" alt="" />
                <span style="margin-left:5px">{{ scope.row.productName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="100" prop="productNorms" />
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ `￥${scope.row.productPrice}` }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="优惠方式" min-width="100" prop="couponMode">
            <template slot-scope="scope">
              {{ scope.row.couponMode || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.amountCouponInformation ? `￥${scope.row.amountCouponInformation}` : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="优惠价" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.priceCouponInformation ? `￥${scope.row.priceCouponInformation}` : 0 }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 优惠明细-end -->

    <div v-if="pageData.orderStatus == 'auditing'" class="flex-box bottom-btn">
      <el-button type="primary" @click="pass">通过</el-button>
      <el-button @click="reject">驳回</el-button>
    </div>

    <!-- 驳回原因 -->
    <el-dialog :before-close="close" :visible.sync="dialogVisible_1" title="驳回原因" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item label="驳回原因" prop="rejectReason">
            <el-input v-model="submitForm.rejectReason" placeholder="请输入" rows="6" type="textarea" maxlength="150" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 退款审核通过 - 选择批次 -->
    <el-dialog title="通过" :visible.sync="dialogVisible_2" width="70%">
      <div class="Popout_content">
        <el-form class="demo-form-inline" size="medium" label-position="top">
          <div v-for="(item, index) in detailInfo.items" :key="index">
            <el-form-item label="产品信息">
              <el-table :data="[detailInfo.items[index]]" border style="width:842px;">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                      <div class="hospital-name">{{ scope.row.productName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="productPrice" />
                <el-table-column label="数量" width="120" prop="itemNum" />
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ `${scope.row.subtotalCoupon ? '￥' + scope.row.subtotalCoupon : 0}` }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ `${scope.row.paidInAmountCoupon ? '￥' + scope.row.paidInAmountCoupon : 0}` }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template v-if="batchShow">
                    <span class="operation-btn" @click="openSelectBatch(index)">选择批次</span>
                  </template>
                  <template v-else>
                    —
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>
          <el-form-item v-if="batchShow" label="退货信息">
            <div v-for="(item, index) in detailInfo.items" :key="index">
              <div style="width:100%;">{{ item.productName }}</div>
              <el-table :data="detailInfo.items[index].batchList" border style="width: 872px">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="批次" width="150" prop="batchNo" />
                <el-table-column label="有效期（天）" width="120" prop="termOfValidity" />
                <el-table-column label="出库数量" width="120" prop="deliverCount" />
                <el-table-column label="商品编号" width="140" prop="produceTime" />
                <el-table-column label="添加时间" width="140" prop="expireTime" />
                <el-table-column label="数量" width="160" prop="">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.num" @blur="verifyInt(scope.row.num, index, scope.$index)" />
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="deleteBatch(index, scope.row.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmRefund">确 认</el-button>
      </span>
    </el-dialog>

    <!-- 选择批次弹窗 -->
    <el-dialog title="选择批次" :visible.sync="dialogVisible_batch" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="批次">
            <el-input v-model="inquire_batch.batchNo" placeholder="批次" clearable />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList_batch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="batchTable" v-loading="loading" :data="batchTable" border height="400px" @selection-change="handleBatchChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam_batch.pageNum - 1) * pageParam_batch.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="批次" min-width="160" prop="batchNo" />
          <el-table-column label="库存" min-width="120" prop="count" />
          <el-table-column label="生产日期" min-width="160" prop="produceTime" />
          <el-table-column label="到期日期" min-width="160" prop="expireTime" />
          <el-table-column label="有效期（天）" min-width="120" prop="termOfValidity" />
          <el-table-column label="入库时间" min-width="160" prop="createTime" />
          <el-table-column label="操作人" min-width="160" prop="operatorName" />
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_batch.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_batch.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_batch.total"
            @size-change="handleSizeChange_batch"
            @current-change="handleCurrentChange_batch"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_batch = false">取 消</el-button>
        <el-button type="primary" @click="confirmBatchSelect">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图片预览 -->
    <d-preview :show-dialog="showDialog" :file-url="fileUrl" :close.sync="showDialog" />
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import dPreview from '@/components/d-preview'

export default {
  components: {
    dUpload,
    dPreview
  },
  data() {
    return {
      // 步骤条
      steps: [],
      active: 0,

      Id_: null, // 主键id
      // 商品信息
      pageData: [],
      goodsMoney: 0,

      imageUrl: [],
      fileList: [],

      // 退货凭证
      returnProof: [],
      //  ============= 审核 =============
      dialogVisible_1: false,
      submitForm: {
        rejectReason: ''
      },
      submitRules: {
        rejectReason: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }]
      },
      logisticsCompanyList: [], // 物流公司列表
      auditState: 1, // 1 通过， 2 驳回
      fileUrl: '', // 图片预览
      showDialog: false, // 是否显示

      // 审核通过 - 选择退货批次弹窗
      dialogVisible_2: false,
      detailInfo: {
        items: []
      },
      currentIndex: 0, // 选择批次的产品下标

      // 选择批次弹窗
      dialogVisible_batch: false,
      inquire_batch: {
        inventoryId: '',
        merchantId: '',
        batchNo: '',
        batchIds: []
      },
      pageParam_batch: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      batchTable: [],
      selectBatch: [], // 选择的批次
      loading: false
    }
  },
  computed: {
    // 待审核退货单、待审核售后单都需要显示选择批次
    batchShow() {
      if (
        (this.detailInfo.orderClassify === 'medicine' &&
          this.detailInfo.deliveryMethod === 'hospital' &&
          this.detailInfo.normalStatus === 'pending_receipt') ||
        this.detailInfo.normalStatus === 'completed'
      ) {
        return true
      }
      return false
    }
  },
  created() {
    this.Id_ = this.$route.query.id

    this.getOrderInfo()
  },
  mounted() {},
  methods: {
    // 获取详情数据
    getOrderInfo() {
      this.$http.requestGet({ url: '/customer/order/get/' + this.Id_ + '/' + true }).then(res => {
        this.pageData = res.data
        this._statisticsOrderCode(this.pageData.orderCode)
        this.imageUrl = res.data.prescriptionUrl ? res.data.prescriptionUrl.split(',') : ''
        if (this.imageUrl.length > 0) {
          this.fileList = []
          this.imageUrl.forEach(e => {
            this.fileList.push({
              name: e.slice(e.lastIndexOf('/')),
              url: e
            })
          })
        }
        // 退货凭证
        this.returnProof = res.data.returnProof ? res.data.returnProof.split(',') : ''

        const money = this.pageData.items.reduce((num, cur) => {
          return (num += cur.productPrice * cur.itemNum)
        }, 0)
        this.goodsMoney = money
      })
    },

    //  ========================== 待审核 ==========================
    // 打开通过弹窗
    pass() {
      this.detailInfo = JSON.parse(JSON.stringify(this.pageData))
      this.detailInfo.items.map(v => {
        v.batchList = []
      })
      this.dialogVisible_2 = true

      this.inquire_batch.inventoryId = this.detailInfo.items[0].inventoryId
      this.inquire_batch.merchantId = this.detailInfo.deliveryMethod === 'hospital' ? this.detailInfo.merchantId : ''

      // this.$confirm('是否确认通过?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消'
      // }).then(() => {
      this.auditState = 1
      //     this.submitAudit()
      // }).catch(() => {
      // })
    },
    // 打开选择批次弹窗
    openSelectBatch(index) {
      this.currentIndex = index
      this.dialogVisible_batch = true
      this.searchList_batch()
    },
    // 删除出库批次
    deleteBatch(index1, index2) {
      this.detailInfo.items[index1].batchList.splice(index2, 1)
    },
    // 出库数量输入值检测
    verifyInt(v, i1, i2) {
      // 判断是否为正整数
      if (!this.$validate.verifyInt(v)) {
        this.detailInfo.items[i1].batchList[i2].num = ''
        return
      }

      // 判断当前出库数量是否大于实际购买数量
      const itemNum = this.detailInfo.items[i1].itemNum
      const returnNum = this.detailInfo.items[i1].returnNum || 0
      const num = itemNum - returnNum
      const totalNum = this.detailInfo.items[i1].batchList.reduce((t, n) => t + parseInt(n.num || 0), 0)
      if (totalNum > num) {
        this.detailInfo.items[i1].batchList[i2].num = ''
        return
      }

      // 将类似 '01' 的字符串转换为 1
      this.detailInfo.items[i1].batchList[i2].num = parseInt(v)
    },
    // 选择批次 - 退货审核通过
    confirmRefund() {
      const items = this.detailInfo.items
      let require = true
      const batch = []
      let deliver = false

      // 1，当 whetherDirectReturn 不为 1 时，需传批次 , 等于 1 时，仅退款
      // 2，当订单属于医品，医院配送，已发货状态 需传批次
      if (this.batchShow) {
        for (let i = 0; i < items.length; i++) {
          const itemNum = items[i].itemNum - parseInt(items[i].returnNum || 0)
          items[i].batchList.forEach((t, v) => {
            if (t.deliverCount && parseInt(t.num) > parseInt(t.deliverCount)) {
              t.num = ''
              deliver = true
            }
          })
          const outboundNum = items[i].batchList.reduce((t, v) => {
            if (parseInt(v.num) > 0) {
              const curBatch = {
                customerOrderId: this.detailInfo.orderId,
                batchId: v.batchId,
                deliverCount: v.num,
                customerOrderItemId: items[i].itemId
              }
              batch.push(curBatch)
            }

            return t + parseInt(v.num)
          }, 0)
          if (deliver == true) {
            this.$message.warning(`不能超出当前批次的出库数量`)
            require = false
            break
          }
          if (outboundNum < itemNum) {
            this.$message.warning(`"${items[i].productName}" 产品的批次出库数量不足`)
            require = false
            break
          } else if (outboundNum > itemNum) {
            this.$message.warning(`"${items[i].productName}" 产品的批次出库数量超出`)
            require = false
            break
          }
        }
      }

      if (require) {
        if (batch.length <= 0 && this.batchShow) {
          return this.$message.warning(`请填写出库数量`)
        }
        this.submitAudit(batch)
      }
    },

    // ================ 选择批次 ================
    // 页容量改变
    handleSizeChange_batch(size) {
      this.pageParam_batch.pageNum = 1
      this.pageParam_batch.pageSize = size
      // 刷新(数据)
      this.getBatchList()
    },
    // 页码改变
    handleCurrentChange_batch(page) {
      this.pageParam_batch.pageNum = page
      // 刷新(页码)
      this.getBatchList()
    },
    // 批次列表查询
    searchList_batch() {
      this.pageParam_batch.pageNum = 1
      this.getBatchList()
    },
    // 获取批次列表
    getBatchList() {
      const data = {
        pageNum: this.pageParam_batch.pageNum,
        pageSize: this.pageParam_batch.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire_batch))
      }

      // 获取要筛选掉的批次id
      const len = this.detailInfo.items[this.currentIndex].batchList.length
      if (len > 0) {
        const batchList = this.detailInfo.items[this.currentIndex].batchList
        data.param.batchIds = batchList.map(v => v.batchId)
      }
      data.param.itemId = this.detailInfo.items[this.currentIndex].itemId

      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/order/pageStockBatch',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          const { list, total } = res.data
          list.map(v => {
            const d = new Date(v.expireTime)
            const timeStamp = d - new Date(v.produceTime)
            const termOfValidity = timeStamp / (24 * 60 * 60 * 1000)

            v.termOfValidity = termOfValidity.toFixed(0)
          })
          this.batchTable = list
          this.pageParam_batch.total = total
        })
        .catch(err => {
          this.loading = false
        })
    },
    // 选择批次
    handleBatchChange(e) {
      this.selectBatch = e
    },
    // 确定批次的选择
    confirmBatchSelect() {
      const arr = JSON.parse(JSON.stringify(this.selectBatch))
      arr.map(v => (v.num = 0))
      this.detailInfo.items[this.currentIndex].batchList.push(...arr)
      this.$refs.batchTable && this.$refs.batchTable.toggleRowSelection()
      this.dialogVisible_batch = false
    },

    // ================ 审核 - 驳回 ================
    reject() {
      this.auditState = 2
      this.dialogVisible_1 = true
    },
    // 审核驳回确定
    confirm() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          this.submitAudit()
        } else {
          return false
        }
      })
    },
    // 提交审核信息
    submitAudit(batch) {
      var url = '/customer/order/return/audit/'
      var param = {}
      if (this.auditState === 1) {
        param = batch
      }
      // 驳回
      else if (this.auditState === 2) {
        url = '/customer/order/return/reject/'
        param = { rejectReason: this.submitForm.rejectReason }
      }
      this.$http.requestPost({ url: url + this.Id_, param }).then(res => {
        this.$message.success('操作成功')
        this.$router.go(-1)
      })
    },
    // 关闭弹窗
    close() {
      this.$refs.submitForm.resetFields()
      this.dialogVisible_1 = false
    },
    // 预览凭证图片
    perviewImg(url) {
      this.showDialog = true
      this.fileUrl = url
    },

    // 埋点
    _statisticsOrderCode(id) {
      this.$http
        .requestPost({
          url: `/listenerLog/orderDetails?orderCode=${id}`,
          loading: false
        })
        .then(res => {})
        .catch(err => {
          console.log('【埋点失败】', err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  & >>> .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 50%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 50%;

      & p {
        padding: 5px 0;
      }
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }
}

.title-box {
  display: inline-block;
  width: 116px;
  text-align: right;
}

.show-money {
  padding: 10px 0;

  ul {
    li {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      font-size: 16px;
    }

    .line-box {
      margin-left: 70%;
      padding-top: 20px;
      border-bottom: 1px solid #ccc;
    }

    .bold {
      font-weight: bolder;
    }
  }
}

.bottom-btn {
  background-color: #fff;
  padding: 20px 0;
  justify-content: center;
}
.image_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .image_box {
    width: 120px;
    height: 120px;
    margin: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}
</style>
