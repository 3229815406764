<template>
  <div class="top-nav">
    <navbar v-if="windowWidth1260" />
    <div class="log">638 管理中台</div>

    <el-menu
      style="min-width: 850px"
      id="zoom-in"
      class="el-menu-box"
      :default-active="activeMenu"
      mode="horizontal"
      @select="handleSelect"
      v-if="routes.length > 0"
      v-show="!windowWidth1260"
    >
          <div v-for="item in routes" :key="item.path" class="nav-item" >
            <app-link :to="resolvePath(item)">
              <el-menu-item v-if="!item.hidden" :index="item.path">
                <!-- {{ item.meta ? item.meta.title : item.children[0].meta.title }} -->
                {{ item.meta.title }}
              </el-menu-item>
            </app-link>
          </div>
    </el-menu>

    <el-menu
      style="min-width: 126px"
      id="zoom-out"
      class="el-menu-box"
      :default-active="activeMenu"
      mode="horizontal"
      @select="handleSelect"
      v-if="routes.length > 0"
      v-show="windowWidth1260"
    >
      <el-submenu index="0">
        <template slot="title">{{ menuTitle ? menuTitle : '选择模块' }}</template>
          <div v-for="item in routes" :key="item.path" class="nav-item">
            <app-link :to="resolvePath(item)">
              <el-menu-item v-if="!item.hidden" :index="item.path">
                <!-- {{ item.meta ? item.meta.title : item.children[0].meta.title }} -->
                {{ item.meta.title }}
              </el-menu-item>
            </app-link>
          </div>
      </el-submenu>
    </el-menu>

    <div style="position: absolute; right: 0%; display: flex;height: 56px;line-height: 56px;" class="head_user_btn">
      <!-- <div style="cursor: pointer;padding: 0 12px;">
            <span style="display: block; font-size: 12px !important; color: #409eff" >
              <el-badge :value="12" class="item">退出账号</el-badge>
            </span>
      </div> -->
      <div style="padding: 0 12px;">
        <el-dropdown  @command="handleCommand" :trigger="windowWidth1260 ? 'click' : 'hover'">
          <span class="el-dropdown-link">
            <!-- 登录人的名字 -->
            <el-badge is-dot class="item" :hidden="$store.state.user.msgNum <= 0" style="display: initial;">{{ userInfo.managerName || 'admin' }}</el-badge>
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personData" >个人资料</el-dropdown-item>
            <el-dropdown-item command="msgNum" divided v-if="isPermission('msgCenter')">
              <router-link to="/system/msgCenter">
                消息中心
                <el-badge class="mark" :hidden="$store.state.user.msgNum <= 0" :value="$store.state.user.msgNum" />
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item command="modifyPwd" divided>修改密码</el-dropdown-item>
            <el-dropdown-item command="logout" divided>退出账号</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 退出登录 -->
      <div style="cursor: pointer;padding: 0 12px;" class="exit_btn" @click="logout">
          <span
            style="display: block; font-size: 12px !important; color: #409eff"
          >
            退出账号
          </span>
      </div>
    </div>

    <!-- <div class="slogan" style="z-index: 9">
      <div class="copyright">
        <span
          >Copyright 1985 - 2020 深圳健安医药有限公司（www.chinaja.com）All
          RightsReserved 粤ICP备09063742号-58 当前版本：{{ $api.versionsCode || '1.0.1' }}</span>
      </div>
    </div> -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="windowWidth768 ? '90%' : '30%'"
      :modal="false"
    >
      <span>您确定要退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutc">取 消</el-button>
        <el-button type="primary" @click="logoutx">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="个人档案"
      :visible.sync="dialogVisible_1"
      :width="windowWidth768 ? '90%' : '30%'"
      :modal="false"
    >
      <div class="Popout_content">
        <el-form size="medium" label-width="80px">
          <el-form-item label="账号">
            <div>{{ userInfo.managerPhoneNumber }}</div>
          </el-form-item>

          <el-form-item label="姓名">
            <div>{{ userInfo.managerName }}</div>
          </el-form-item>
          
          <el-form-item label="权限组">
            <div>{{ userInfo.rightsGroupNames.join(',') }}</div>
          </el-form-item>

          <el-form-item label="区域权限">
            <div>{{ getManageType }}</div>
          </el-form-item>

          <el-form-item label="手机">
            <div>{{ userInfo.managerPhoneNumber }}</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible_2"
      :width="windowWidth768 ? '90%' : '30%'"
      :modal="false"
    >
      <div class="Popout_content">
        <el-form size="medium" label-width="100px" ref="userForm" :model="userForm" :rules="userRules">
            <el-form-item label="用户名">
              {{ userInfo.managerName }}
            </el-form-item>

            <el-form-item label="原密码" prop="oldPwd">
              <el-input type="password" v-model="userForm.oldPwd" placeholder="原密码"></el-input>
            </el-form-item>

            <el-form-item label="新密码" prop="newPwd">
              <el-input type="password" v-model="userForm.newPwd" placeholder="新密码"></el-input>
            </el-form-item>

            <el-form-item label="重复新密码" prop="newPwds">
              <el-input type="password" v-model="userForm.newPwds" placeholder="重复新密码"></el-input>
            </el-form-item>
            
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="commitChanges">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLink from "./Sidebar/Link";
import { constantRoutes } from "@/router";
import variables from "@/styles/variables.scss";
import {getSessionStorage,clearStorage} from '@/utils/auth.js'
import {Navbar} from "@/layout/components/index";
import store from "@/store";

export default {
  name: "Topbar",
  components: {
    Navbar,
    AppLink,
  },
  data() {
    var checkPwd = [
      { required: true, message: '密码不能少于6位', trigger: 'blur' },
      { min: 6, max: 8, message: '长度在6到8个字符', trigger: 'blur' },
    ]
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userForm.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // 菜单
      routes: [],
      dialogVisible: false,
      windWidth: false,

      menuTitle: '', // 菜单标题

      // 个人档案
      dialogVisible_1: false,
      userInfo: {},
      managerTypeList: [
        { name: '所有权限', id: 'all' },
        { name: '大区权限', id: 'region' },
        { name: '片区权限', id: 'district' },
        { name: '办事处权限', id: 'agency' },
      ],

      // 修改密码
      dialogVisible_2: false,
      userForm: {
        oldPwd: '',
        newPwd: '',
        newPwds: ''
      },
      userRules: {
        oldPwd: checkPwd,
        newPwd: checkPwd,
        newPwds: [ {required: true, validator: validatePass, trigger: 'blur'} ],
      },

      

      // 消息通知定时器
      getCountUnread: null,
    };
  },
  computed: {
    store() {
      return store
    },
    // 实时获取屏幕宽度
    windowWidth1260() {
      return this.$store.state.settings.windowWidth1260
    },
    windowWidth768() {
      return this.$store.state.settings.windowWidth768
    },
    activeMenu() {
      const { meta, path } = this.$route;

      // 如果是首页，首页高亮
      if (path === "/home") {
        return "/home";
      }

      // 如果不是首页，高亮一级菜单
      const activeMenu = "/" + path.split("/")[1];
      return activeMenu;
    },
    variables() {
      return variables;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    getManageType() {
      let ind = this.managerTypeList.findIndex(e => e.id == this.userInfo.managerType)
      if (ind == 0) return this.managerTypeList[ind].name
      let html = ''
      if (this.userInfo.areas.length > 0) {
        this.userInfo.areas.forEach((e,i) => {
          if (i == 0) html += e.regionName
          if (i == 1) html += ' - ' + e.districtName 
          if (i == 2) html += ' - ' + e.agencyName
        });
      }
      return html
    },
    ...mapGetters(["avatar"]),
  },
  created() {
    this.userInfo = getSessionStorage('userInfo')
    
    // 服务器返回的菜单（路由）列表
    this.routes = this.$store.state.user.menuList
    // 本地的菜单（路由）列表
    // this.routes = constantRoutes
    console.log('route',this.routes);

    if (this.routes.length === 0) {
      this.getMenuTree()
    } else {
      this.initCurrentRoutes()
    }
  },

  mounted() {
    this.windWidth = window.innerWidth > 1260 ? true : false;
    this.$store.dispatch('settings/setWindowWidth', window.innerWidth)
    window.addEventListener('resize', this.showMenu);
  },
  beforeMount() {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.showMenu)
    clearTimeout(this.getCountUnread)
  },
  methods: {
    // 获取用户菜单
    getMenuTree() {
      this.$http.requestGet({ url:'/login/getMenuTree/' + this.userInfo.managerId, loading:false }).then(res => {
        this.routes = res.data
        this.$store.commit('user/SET_MENU_LIST', res.data)
        this.initCurrentRoutes();
      })
    },

    // 通过当前路径找到二级菜单对应项，存到store，用来渲染左侧菜单
    initCurrentRoutes() {
      const { path } = this.$route;
      let route = this.routes.find(
        (item) => item.path === "/" + path.split("/")[1]
      );
      // 如果找不到这个路由，说明是首页
      if (!route) {
        route = this.routes.find((item) => item.path === "/");
        // this.$router.push(route.path + route.children[0].path)
      }
      console.log(this.routes,route,'this.routes')
      this.$store.commit("permission/SET_CURRENT_ROUTES", route);
      this.$store.commit("permission/SET_ALLROUTES", this.routes);
      this.setSidebarHide(route);

      if (route.meta) {
        this.menuTitle = route.meta.title
      } else {
        this.menuTitle = route.children[0].meta.title
      }
    },
    // 判断该路由是否只有一个子项或者没有子项，如果是，则在一级菜单添加跳转路由
    isOnlyOneChild(item) {
      if (item.children && item.children.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(item) {
      // 如果是个完成的url直接返回
      if (this.$validate.isExternal(item.path)) {
        return item.path;
      }
      // 如果是首页，就返回重定向路由
      if (item.path === "/") {
        // const path = item.redirect;
        let path = '/';
        if (item.children && item.children.length > 0) {
          path += item.children[0].path
        }
        return path;
      }

      // 如果有子项，默认跳转第一个子项路由
      let path = "";
      /**
       * item 路由子项
       * parent 路由父项
       */
      const getDefaultPath = (item, parent) => {
        // 如果path是个外部链接（不建议），直接返回链接，存在个问题：如果是外部链接点击跳转后当前页内容还是上一个路由内容
        if (this.$validate.isExternal(item.path)) {
          path = item.path;
          return;
        }
        // 第一次需要父项路由拼接，所以只是第一个传parent
        if (parent) {
          path += parent.path + "/" + item.path;
        } else {
          path += "/" + item.path;
        }
        // 如果还有子项，继续递归
        if (item.children && item.children.length > 0) {
          getDefaultPath(item.children[0]);
        }
      };

      if (item.children && item.children.length > 0) {
        getDefaultPath(item.children[0], item);
        return path;
      }

      return item.path;
    },
    handleSelect(key, keyPath) {
      console.log(this.routes)
      console.log(key)
      // 把选中路由的子路由保存store
      const route = this.routes.find((item) => item.path === key)
      this.$store.commit("permission/SET_CURRENT_ROUTES", route)
      this.$store.commit("permission/SET_ALLROUTES", this.routes)
      this.setSidebarHide(route)

      // 修改当前
      if (route.meta) {
        this.menuTitle = route.meta.title
      } else {
        this.menuTitle = route.children[0].meta.title
      }
      // 当选择菜单：用户
      if(key === "/user"){
        this.$store.dispatch('user/getMerchantTypeList');  //获取商家类型
      }
    },
    // 设置侧边栏的显示和隐藏
    setSidebarHide(route) {
      if (!route.children || route.children.length === 1) {
        this.$store.dispatch("app/toggleSideBarHide", false);
      } else {
        this.$store.dispatch("app/toggleSideBarHide", false);
      }
    },
    logout() {
      this.dialogVisible = true;
      // await this.$store.dispatch("user/logout");
      // this.$router.push(`/?redirect=${this.$route.fullPath}`);
    },
    logoutc() {
      this.dialogVisible = false;
    },
    // 退出登录
    logoutx() {
      clearStorage()
      this.$store.commit('user/SET_MENU_LIST', [])
      this.$router.push("/");
    },
    // 显示隐藏头部导航菜单
    showMenu(e) {
      this.$store.dispatch('settings/setWindowWidth', e.target.innerWidth)
      this.windWidth = e.target.innerWidth > 1260
    },
    // 个人信息下拉框
    handleCommand(command) {
      if (command == 'msgNum') return
      this[command]()
    },
    // 个人资料
    personData() {
      this.dialogVisible_1 = true;
    },
    // 打开修改密码弹窗
    modifyPwd() {
      this.dialogVisible_2 = true;
    },
    // 提交密码修改
    commitChanges() {
      let Base64 = require('js-base64').Base64;
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          const param = {
            managerPhoneNumber: this.userInfo.managerPhoneNumber,
            oldPassword: Base64.encode(this.userForm.oldPwd),
            newPassword: Base64.encode(this.userForm.newPwd)
          }

          this.$http.requestPut({ url: '/system/manager/changePassword', param }).then(res => {
            this.$message.success('操作成功！')
            this.dialogVisible_2 = false
          })
        } else {
          return false
        }
      })
    },
    isPermission(path) {
      if (this.routes.length > 0) {
        let item = this.routes.find(item => item.path == "/system")
        if (item && item.children.length > 0) {
          let index = item.children.findIndex(item => item.path == path)
          if (index !== -1) {
            // 轮询查找消息
            this.getCountUnread = setTimeout(() => {
              this.$store.dispatch('user/getCount4Unread')
            }, 1000 * 60)
            return true
          }
        }
      }
      return false
    }
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.slogan {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 35px;
  background-color: #f9f9f9;
  .copyright {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
    transform: translate(28%, 20%);

    span {
      font-size: 12px;
      margin-top: 10px;
      color: #000;
      transform: scale(1, 0.85);
    }
  }
}

</style>
