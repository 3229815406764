var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box redPacket_list" },
    [
      _c(
        "div",
        { staticClass: "head-search-row search_box" },
        [
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", model: _vm.queryData } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "红包二维码编号" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入红包二维码编号",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("mainTable_update")
                      },
                    },
                    model: {
                      value: _vm.queryData.qrCodeSerial,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "qrCodeSerial", $$v)
                      },
                      expression: "queryData.qrCodeSerial",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入产品名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("mainTable_update")
                      },
                    },
                    model: {
                      value: _vm.queryData.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "productName", $$v)
                      },
                      expression: "queryData.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入批次" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList("mainTable_update")
                      },
                    },
                    model: {
                      value: _vm.queryData.batchNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "batchNo", $$v)
                      },
                      expression: "queryData.batchNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "红包金额" } }, [
                _c(
                  "span",
                  { staticClass: "money_form" },
                  [
                    _c("el-input", {
                      class: ["width-110", { red_input: _vm.showMoneyTips }],
                      attrs: { clearable: "", placeholder: "最小金额" },
                      on: { change: _vm.checkMoneyVal },
                      model: {
                        value: _vm.queryData.redPacketMinAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryData, "redPacketMinAmount", $$v)
                        },
                        expression: "queryData.redPacketMinAmount",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { margin: "0 3px" } }, [
                      _vm._v("-"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      class: ["width-110", { red_input: _vm.showMoneyTips }],
                      attrs: { clearable: "", placeholder: "最大金额" },
                      on: { change: _vm.checkMoneyVal },
                      model: {
                        value: _vm.queryData.redPacketMaxAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryData, "redPacketMaxAmount", $$v)
                        },
                        expression: "queryData.redPacketMaxAmount",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMoneyTips,
                        expression: "showMoneyTips",
                      },
                    ],
                    staticClass: "money_tips",
                  },
                  [_vm._v("请输入正确的数值范围")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                      "picker-options": _vm.pickerOptions,
                    },
                    on: { change: _vm.changeDatePicker },
                    model: {
                      value: _vm.dateSelectVal,
                      callback: function ($$v) {
                        _vm.dateSelectVal = $$v
                      },
                      expression: "dateSelectVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "红包二维码状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-110",
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryData.isEffect,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryData, "isEffect", $$v)
                        },
                        expression: "queryData.isEffect",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "已生效", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "未生效", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.listType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "转账状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-110",
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.queryData.transferStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "transferStatus", $$v)
                            },
                            expression: "queryData.transferStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "成功", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "失败", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updateList("mainTable_update")
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _vm.listType === 2
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.redPacketQrCodeDialog(true, "add")
                          },
                        },
                      },
                      [_vm._v("创建红包二维码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.liveRedPacketQrCode },
                      },
                      [_vm._v("立即生效")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.relatedMulBatch(true)
                          },
                        },
                      },
                      [_vm._v("设置关联批次")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.changeMoneyDialog(true)
                          },
                        },
                      },
                      [_vm._v("修改红包金额")]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportQrcode },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.getDownloadQrCode },
                },
                [_vm._v("下载二维码图片")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c("TableList", {
            ref: "mainTable_ref",
            staticClass: "mainTable_container",
            attrs: {
              "table-header": _vm.tableData.tableHeader,
              "is-selection": true,
              "row-key-name": "redPacketQrCodeId",
              "table-data": _vm.tableData.list,
              total: _vm.tableData.total,
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event, "mainTable_page")
              },
              setSize: function ($event) {
                return _vm.sizeChange($event, "mainTable_size")
              },
              delXuan: _vm.selectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "redPacketAmount",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.redPacketAmount ? "￥" + data.redPacketAmount : 0
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "salesPrice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(data.salesPrice ? "￥" + data.salesPrice : 0)
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "transferStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getTransferStatusText(data.transferStatus))
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "isEffect",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(_vm._s(data.isEffect === 1 ? "已生效" : "未生效")),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.redPacketQrCodeDialog(true, "edit", data)
                          },
                        },
                      },
                      [_vm._v("二维码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.salesRecordDialog(true, data)
                          },
                        },
                      },
                      [_vm._v("销售记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "operate_btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.memberListDialog(true, data)
                          },
                        },
                      },
                      [_vm._v("会员列表")]
                    ),
                    _vm._v(" "),
                    _vm.listType === 1 && data.transferStatus === 2
                      ? _c(
                          "el-button",
                          {
                            staticClass: "operate_btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.reTransferDialog(true, data)
                              },
                            },
                          },
                          [_vm._v("\n          重新转账\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickRowData.qrCodeSerial + " 销售记录",
            visible: _vm.salesRecordsData.visible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.salesRecordsData, "visible", $event)
            },
            close: function ($event) {
              return _vm.salesRecordDialog(false)
            },
          },
        },
        [
          _c("TableList", {
            staticClass: "pb_10",
            attrs: {
              height: "400px",
              loading: _vm.salesRecordsData.loading,
              "table-header": _vm.salesRecordsData.tableHeader,
              "table-data": _vm.salesRecordsData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "userName",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(data.memberNickName + " / " + data.recipient)
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderTotalPrice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.orderTotalPrice
                            ? "￥" + data.orderTotalPrice
                            : "￥0.00"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "orderStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$api.getValue(
                            _vm.$api.EcommerceOrderState,
                            data.orderStatus,
                            "name"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.operateButton(
                              "salesRecords_detail",
                              data
                            )
                          },
                        },
                      },
                      [_vm._v("订单详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickRowData.qrCodeSerial + " 会员列表",
            visible: _vm.memberListData.visible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.memberListData, "visible", $event)
            },
            close: function ($event) {
              return _vm.memberListDialog(false)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "memberList_ref",
              attrs: { model: _vm.memberQueryData, inline: "", size: "small" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信昵称", prop: "memberNickname" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入微信昵称" },
                    model: {
                      value: _vm.memberQueryData.memberNickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberQueryData, "memberNickname", $$v)
                      },
                      expression: "memberQueryData.memberNickname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "memberTelephone" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      maxlength: "11",
                      placeholder: "请输入手机号",
                    },
                    model: {
                      value: _vm.memberQueryData.memberTelephone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.memberQueryData,
                          "memberTelephone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "memberQueryData.memberTelephone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员编号", prop: "memberCode" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入会员编号" },
                    model: {
                      value: _vm.memberQueryData.memberCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.memberQueryData, "memberCode", $$v)
                      },
                      expression: "memberQueryData.memberCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateList("memberTable_update")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableList", {
            staticClass: "pb_10",
            attrs: {
              height: "400px",
              loading: _vm.memberListData.loading,
              "table-header": _vm.memberListData.tableHeader,
              "table-data": _vm.memberListData.list,
              "is-show-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "cumulativeConsumption",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          data.cumulativeConsumption
                            ? "￥" + data.cumulativeConsumption
                            : "-"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认重新转账",
            visible: _vm.transferData.visible,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.transferData, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "transferAccount_ref",
              attrs: { "label-width": "220px", size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "收款用户openId：" } }, [
                _vm._v(_vm._s(_vm.transferData.previewData.memberOpenId)),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收款用户注册手机号：" } }, [
                _vm._v(_vm._s(_vm.transferData.previewData.memberPhoneNumber)),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "转账金额：" } }, [
                _vm._v(
                  "￥" + _vm._s(_vm.transferData.previewData.redPacketAmount)
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", {
                attrs: {
                  label: "是否确认以上信息无误，并重新转账？",
                  "label-width": "300px",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReTransfer },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.transferData.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("qrCodeDialog", {
        ref: "qrCodeDialog_ref",
        attrs: {
          "qr-code-type": _vm.qrCodeType,
          "open-limit-modify": _vm.listType === 1,
          "dialog-title": _vm.getQrCodeDialogTitle(_vm.qrcodeDialogType),
        },
        on: { getValue: _vm.getQrCodeData },
        scopedSlots: _vm._u([
          {
            key: "tips",
            fn: function () {
              return [
                _vm.qrCodeType === "redPacketBatch"
                  ? _c("p", { staticStyle: { padding: "20px" } }, [
                      _vm._v(
                        "\n        已选中" +
                          _vm._s(_vm.selectData.length) +
                          "个红包二维码，设置后，二维码红包关联批次将以本次设置的批次为准。\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.qrCodeType === "redPacketAmount"
                  ? _c("p", { staticStyle: { padding: "20px" } }, [
                      _vm._v(
                        "\n        已选中" +
                          _vm._s(_vm.selectData.length) +
                          "个红包二维码，设置后，二维码红包金额将以本次设置为准。\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }