<template>
  <div class="developers_box">
    <div class="title">上传资源到云</div>
    <el-form ref="submitForm" label-position="left">
      <el-form-item label="归属" prop="extremity">
        <div class="select_box">
          <el-select v-model="extremity" placeholder="请选择图片归属">
            <el-option v-for="item in extremityList" :key="item.value" :label="item.title" :value="item.value"/>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="文件夹名称" prop="folderName" label-width="90px">
        <div class="input_box">
          <el-input width="200px" v-model="folderName" placeholder="请输入文件夹名" required/>
        </div>
      </el-form-item>
    </el-form>
    <el-upload
        ref="upload"
        class="upload-demo"
        :action="$http.requestUrl + '/common/file/specifiedPath/upload'"
        list-type="picture"
        :multiple="true"
        :file-list="fileList"
        :headers="headers"
        :data="dataObj"
        :on-preview="onPreview"
        :on-success="onSuccess"
        :on-change="onChange"
        :before-upload="beforeUpload"
        :auto-upload="false"
    >
      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
      <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到oss云</el-button>
      <div slot="tip" class="tip"><i class="el-icon-warning"></i>点击图片可复制图片地址</div>
    </el-upload>
    <!-- 弹框 -->
    <el-dialog title="图片预览" :visible.sync="dialogVisible_1" :modal="false" width="60%" top="5vh">
      <div class="Popout_content">
        <div v-if="fileType === 'image'" class="image-box">
          <img :src="preview" alt="">
        </div>
        <div v-else>
          <video id="video" autoplay :src="preview" controls="controls" width="100%" height="100%">视频描述</video>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getSessionStorage } from '@/utils/auth.js'

export default {
  data() {
    return {
      extremity: '', // 归属
      extremityList: [ // 归属列表
        {
          title: '638中台',
          value: 1
        },
        {
          title: '638医品',
          value: 2
        },
        {
          title: '医品骑手',
          value: 3
        }
      ],
      fileList: [],
      folderName: '',
      preview: '', // 预览图片路径
      fileType: '', // 文件类型
      dialogVisible_1: false, // 预览弹窗
      headers: {
        Authorization: getSessionStorage('userToken')
      },
      successFileList: [],
      dataObj: {
        fileName: 'test',
        filePath: 'middlePlatform'
      }
    }
  },
  methods: {
    submitUpload() {
      if (this.extremity) {
        switch (this.extremity) {
          case 1:
            this.dataObj.filePath = 'projectFile/middlePlatform'
            break
          case 2:
            this.dataObj.filePath = 'projectFile/medicalUser'
            break
          case 3:
            this.dataObj.filePath = 'projectFile/medicalMerchants'
            break
          default:
            this.dataObj.filePath = 'projectFile/middlePlatform'
        }
      } else {
        return this.$message({
          message: '请选择图片归属',
          type: 'error'
        })
      }
      if (this.folderName) {
        this.dataObj.filePath = `${this.dataObj.filePath}/${this.folderName}`
        this.$refs.upload.submit()
      } else {
        this.$message({
          message: '请填写文件夹名称',
          type: 'error'
        })
      }
    },
    // 点击图片
    onPreview(file) {
      console.log(file, 'file')
      let that = this
      if (file.response) {
        this.$copyText(file.response.data).then(
            function(e) {
              console.log('copy arguments e:', e)
              that.$message({
                message: '复制成功',
                type: 'success'
              })
            },
            function(e) {
              console.log('copy arguments e:', e)
              alert('复制失败!')
            }
        )
      } else {
        this.$message({
          message: '该图片还未上传，请先上传',
          type: 'error'
        })
      }
      // if (file.raw) {
      //   console.log('file.raw', file.raw)
      //   const type = file.raw.type.split('/')
      //   this.fileType = type[0]
      //
      //   const reader = new FileReader()
      //   reader.readAsDataURL(file.raw)
      //   reader.onload = e => {
      //     console.log(e)
      //     // _base64
      //     this.preview = reader.result
      //     console.log('reader.result', reader.result)
      //   }
      // } else if (file.url) {
      //   const imgT = ['.gif', '.jpeg', '.jpg', '.png', '.PNG']
      //   const videoT = ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v']
      //   const ind = file.url.lastIndexOf('.')
      //   const type = file.url.slice(ind)
      //   if (imgT.indexOf(type) !== -1) {
      //     this.fileType = 'image'
      //   } else if (videoT.indexOf(type) !== -1) {
      //     this.fileType = 'video'
      //   }
      //   this.preview = file.url
      // }

      // this.dialogVisible_1 = true
    },

    onSuccess(res, file, fileList) {
      console.log('成功')
      // this.successFileList.push({ ossUrl: res.data, fileName: file.name })
    },
    onChange(file, fileList) {
      console.log(file, fileList, '成功')
      // this.successFileList.push({ ossUrl: res.data, fileName: file.name })
    },
    // 上传前
    beforeUpload(file) {
      this.dataObj.fileName = file.name
    }
  }
}
</script>

<style scoped lang="scss">
.developers_box {
  .title {
    margin-bottom: 20px;
  }

  padding: 15px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(40, 49, 29, 0.09);
}

.el-input {
  width: 200px;
}

.image-box {
  img {
    width: 100%;
    height: 100%;
  }
}

.tip {
  margin: 20px 0;
  color: #f56c6c;
  font-size: 14px;
}
</style>
