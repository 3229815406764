var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "content_box" },
      [
        _c("h4", { staticStyle: { "margin-bottom": "20px" } }, [
          _vm._v("经销产品出库配置"),
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "formRef",
            attrs: {
              model: _vm.formData,
              "label-width": "100px",
              size: "small",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "产品名称", prop: "productName" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.formData.productName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "productName", $$v)
                    },
                    expression: "formData.productName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "产品规格", prop: "skuInfo" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.formData.skuInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "skuInfo", $$v)
                    },
                    expression: "formData.skuInfo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "发货方式", prop: "deliveryWay" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择发货方式",
                      disabled: !_vm.isEdit,
                    },
                    model: {
                      value: _vm.formData.wechatDeliveryMethod,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "wechatDeliveryMethod", $$v)
                      },
                      expression: "formData.wechatDeliveryMethod",
                    },
                  },
                  _vm._l(_vm.deliveryWayList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "发货批次", prop: "batch" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择发货批次",
                      disabled: !_vm.isEdit,
                    },
                    model: {
                      value: _vm.formData.batch,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "batch", $$v)
                      },
                      expression: "formData.batch",
                    },
                  },
                  _vm._l(_vm.batchList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.batchNo, value: item.batchNo },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "" } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitSave } },
                  [_vm._v(_vm._s(_vm.isEdit ? "保存" : "编辑"))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }