<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="标题">
          <el-input v-model="inquire.contentTitle" placeholder="标题" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="类别">
          <el-cascader v-model="inquire.contentCategoryId" :props="props" :options="categoryTree" clearable placeholder="请选项地址"/>
        </el-form-item>

        <el-form-item label="添加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增内容</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="contentTitle" label="标题" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="contentCategoryDesc" label="所属类别" min-width="160" show-overflow-tooltip/>
            <el-table-column prop="contentOrder" label="排序" min-width="100"/>
            <el-table-column prop="contentHits" label="浏览量" min-width="100"/>
            <el-table-column prop="contentStatus" label="是否显示" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.contentStatus === 'display' ? '显示' : '隐藏' }}
              </template>
            </el-table-column>
            <el-table-column prop="contentTime" label="发布时间" min-width="160"/>
            <el-table-column prop="createTime" label="添加时间" min-width="160"/>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="delData(scope.row)">删除</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleRun(scope.row)">运营范围</span>
                <span class="operation-btn_partition">/</span>
                <span v-show="scope.row.contentStatus === 'hide'" class="operation-btn" @click="handleHide(scope.row, 's')">显示</span>
                <span v-show="scope.row.contentStatus === 'display'" class="operation-btn" @click="handleHide(scope.row, 'h')">隐藏</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog :title="dialogTitle + '内容'" :visible.sync="dialogVisible" :before-close="closeDialog" width="70%" top="5vh">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="所属类别" prop="contentCategoryId">
            <el-cascader
              v-model="submitForm.contentCategoryId"
              :props="props_add"
              :options="categoryTree"
              placeholder="请选择"
              class="width-inherit"
            />
          </el-form-item>
          <el-form-item label="标题" prop="contentTitle">
            <el-input v-model="submitForm.contentTitle" maxlength="30" placeholder="标题"/>
          </el-form-item>
          <el-form-item label="详情顶部视频" prop="topVideo">
            <d-upload
              :size="500"
              :file-list="submitForm.topVideo"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_1"
              @handleChange="handleChange_1"
              @removeFile="removeFile_1"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag1" :percentage="percentage1"/>
          </el-form-item>

          <el-form-item v-if="submitForm.topVideo !== undefined && submitForm.topVideo.length > 0" label="顶部视频封面" prop="messageTopCover">
            <d-upload
              upload-type="picture-card"
              :file-list="graphic.messageTopCover"
              :limit="1"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessCover_1"
              @removeFile="removeCover_1"
              @cloudFileData="setCloudFileData"
            >
              <div class="tip-box">
                <i class="el-icon-plus" ></i>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="详情底部视频" prop="bottomVideo">
            <d-upload
              :size="500"
              :file-list="submitForm.bottomVideo"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_2"
              @handleChange="handleChange_2"
              @removeFile="removeFile_2"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag2" :percentage="percentage2"/>
          </el-form-item>

          <el-form-item
            v-if="submitForm.bottomVideo !== undefined && submitForm.bottomVideo.length > 0"
            label="底部视频封面"
            prop="messageBottomCover"
          >
            <d-upload
              upload-type="picture-card"
              :file-list="graphic.messageBottomCover"
              :limit="1"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessCover_2"
              @removeFile="removeCover_2"
              @cloudFileData="setCloudFileData"
            >
              <div class="tip-box">
                <i class="el-icon-plus" ></i>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="内容" prop="content">
            <div class="editor">
              <mavon-editor
                ref="md"
                v-model="editor"
                :toolbars="toolbars"
                @imgAdd="handleEditorImgAdd"
                @imgDel="handleEditorImgDel"
                @change="editorChange"
              />
            </div>
          </el-form-item>
          <el-form-item label="推荐销售产品" prop="productId">
            <div>
              <el-button type="primary" size="small" @click="openProductList">选择</el-button>
            </div>
            <div>
              <el-table :data="productTable" border style="width: 100%">
                <el-table-column prop="productName" label="产品名称" min-width="100"/>
                <el-table-column prop="" label="销售价" min-width="120">
                  <template slot-scope="scope">
                    {{
                      (scope.row.productPriceMin ? scope.row.productPriceMin : '') +
                        ' ~ ' +
                        (scope.row.productPriceMax ? scope.row.productPriceMax : '')
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" min-width="120">
                  <template slot-scope="scope">
                    <span v-show="submitForm.isAdvertisement == 0" class="operation-btn" @click="handleAdvShow">展示广告标签</span>
                    <span v-show="submitForm.isAdvertisement == 1" class="operation-btn" @click="handleAdvHide">取消展示广告标签</span>
                    <span class="operation-btn" @click="handleDel(scope.row)">/ 删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="排序" prop="contentOrder">
            <el-input v-model="submitForm.contentOrder" placeholder="排序"/>
          </el-form-item>
          <el-form-item label="显示时间" prop="contentTime">
            <el-date-picker
              v-model="submitForm.contentTime"
              class="width-inherit"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择日期时间"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
    <contentCity v-if="showCity" :show-city="showCity" @setCityValue="getCityValue" />

    <!-- 产品列表弹框 -->
    <el-dialog title="选择产品" :visible.sync="dialogVisible_2" :before-close="() => (dialogVisible_2 = false)" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="产品名称">
            <el-input v-model="inquire_2.productName" placeholder=""/>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getProductList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          v-loading="loading_2"
          :data="tableData_2"
          border
          height="400px"
          style="width: 100%"
          highlight-current-row
          @current-change="currentChange"
        >
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="120"/>
          <el-table-column prop="productCode" label="产品编号" min-width="160"/>
          <el-table-column prop="productCategoryName" label="所属类别" min-width="160"/>
          <el-table-column prop="" label="销售价" min-width="160">
            <template slot-scope="scope">
              {{ scope.row.productPriceMin + ' ~ ' + scope.row.productPriceMax }}
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerchant">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import { parse } from 'path-to-regexp'
import axios from 'axios'
import contentCity from '@/components/contentCity/index.vue'
import { extractHttpLinks, _delOssFile } from '@/utils/public'

function getSignature() {
  return axios.post('/common/file/upload/getQCloudSignature', JSON.stringify({ action: 'GetUgcUploadSign' })).then(function(response) {
    return response.data.data
  })
}
export default {
  components: {
    dUpload,
    contentCity
  },
  data() {
    return {
      actionUrl: '/common/file/upload/video',
      // 类别
      categoryTree: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      props_add: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'childes',
        emitPath: false
      },
      // 查询参数
      inquire: {
        contentTitle: '',
        contentCategoryId: '',
        productName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加区域地址
      dialogTitle: '添加', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        contentCategoryId: '',
        contentTitle: '',
        contentOrder: '',
        content: '',
        productId: '',
        contentTime: '',
        topVideo: [], // 顶部详情视频
        bottomVideo: [], // 底部详情视频
        messageTopCover: '', // 顶部详情视频封面
        messageBottomCover: '', // 底部详情视频封面
        isAdvertisement: 0
      },
      submitRules: {
        contentCategoryId: [{ required: true, message: '请选择类别', trigger: 'change' }],
        contentTitle: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        contentOrder: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          {
            pattern: /^[+]{0,1}(\d+)$/,
            message: '请输入正整数',
            trigger: 'blur'
          }
        ],
        productId: [{ required: false, message: '请选择推荐销售产品', trigger: 'blur' }],
        contentTime: [{ required: true, message: '请选择显示时间', trigger: 'change' }],
        messageTopCover: [{ required: true, message: '请添加顶部视频封面', trigger: 'blur' }],
        messageBottomCover: [{ required: true, message: '请添加底部视频封面', trigger: 'blur' }]
      },
      isAdd: true, // 是否为添加
      editRow: {},

      toolbars: {
        // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      },
      editor: '',

      selectRow: [], // 选择当前行
      // ==== 产品弹框 ====
      dialogVisible_2: false,
      inquire_2: {
        productName: ''
      },
      loading_2: false,
      tableData_2: [],
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      productTable: [],
      // 手动上传视频
      autoUpload: false, // 是否自动上传
      percentage1: 0,
      progressFlag1: false,

      percentage2: 0,
      progressFlag2: false,
      uploader1: '', // 顶部视频上传盒子
      uploader2: '', //  底部视频上传盒子
      contentIdNew: '',
      showCity: false,

      graphic: {
        messageTopCover: [],
        messageBottomCover: []
      },

      graphicRules: {
        messageTopCover: [{ required: true, message: '请添加顶部视频封面', trigger: 'blur' }],
        messageBottomCover: [{ required: true, message: '请添加底部视频封面', trigger: 'blur' }]
      },

      primaryVideosList: [], // 默认视屏

      cloudFileData: [] // 云文件列表
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
    this.getCategoryTree()
    this.tcVod = new this.$TcVod({
      getSignature: getSignature
    })
  },
  methods: {
    handleDel(row) {
      this.productTable = []
      this.submitForm.productId = ''
    },
    // 广告展示
    handleAdvShow() {
      this.submitForm.isAdvertisement = 1
    },
    // 广告隐藏
    handleAdvHide() {
      this.submitForm.isAdvertisement = 0
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
      const formdata = new FormData()
      formdata.append('file', file)
      this.$http
        .requestPost({
          url: '/common/file/upload',
          param: formdata
        })
        .then(res => {
          this.$refs.md.$img2Url(pos, res.data)
          this.setCloudFileData(res.data)
        })
      this.clearUploadStatus()
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) {
      console.log(pos)
    },
    // 富文本改变后的内容
    editorChange(val, render) {
      this.submitForm.content = render
    },
    // 获取分类列表
    getCategoryTree() {
      this.$http.requestGet({ url: '/operation/message/category/tree', loading: false }).then(res => {
        this.categoryTree = res.data
      })
    },
    // 如果分类列表里没有对应的详情id 则清空分类id
    selectTree(arr) {
      const contentCategoryId = this.submitForm.contentCategoryId
      let flag = false
      arr.forEach(item => {
        let hasContentCategoryId = ''
        if (item.childes) {
          hasContentCategoryId = item.childes.filter(v => {
            return contentCategoryId == v.categoryId
          })
          if (hasContentCategoryId.length >= 1) {
            flag = true
            return false
          }
        }
      })
      if (flag == false) {
        this.submitForm.contentCategoryId = ''
      }
    },

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/operation/message/content/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // 添加
    addition() {
      this.dialogTitle = '添加'
      this.dialogVisible = true
      this.isAdd = true
      // 清空视频上传状态盒子
      this.clearUploadStatus()
    },
    // 修改
    handleEdit(row) {
      if (row.contentId) {
        this.contentIdNew = row.contentId
      } else {
        this.contentIdNew = ''
      }
      this.dialogTitle = '编辑'
      this.isAdd = false
      this.clearUploadStatus()
      this.$http.requestGet({ url: '/operation/message/content/get/' + row.contentId }).then(res => {
        const data = res.data
        this.submitForm = {
          contentCategoryId: data.contentCategoryId,
          contentTitle: data.contentTitle,
          contentOrder: data.contentOrder,
          content: data.content,
          contentTime: data.contentTime,
          productId: data.productId,
          isAdvertisement: data.isAdvertisement,
          bottomVideo: [],
          topVideo: [],
          messageTopCover: '', // 顶部详情视频封面
          messageBottomCover: '' // 底部详情视频封面
        }
        // 如果没有对应类别id 则清空所属的类别
        if (this.categoryTree.length >= 1) {
          this.selectTree(this.categoryTree)
        }
        if (data.messageBottomCover) {
          // console.log(data,"data")
          this.graphic.messageBottomCover.push({ url: data.messageBottomCover })
          // console.log(this.graphic,"this.graphic.messageBottomCover")
          this.submitForm.messageBottomCover = data.messageBottomCover
        }
        if (data.messageTopCover) {
          this.graphic.messageTopCover.push({ url: data.messageTopCover })
          this.submitForm.messageTopCover = data.messageTopCover
        }
        if (data.topVideo && data.topVideo.length > 0) {
          const videosObj = {
            url: data.topVideo,
            name: data.topVideo.substring(data.topVideo.lastIndexOf('/') + 1)
          }
          this.submitForm.topVideo.push(videosObj)
        }
        if (data.bottomVideo && data.bottomVideo.length > 0) {
          const videosObj = {
            url: data.bottomVideo,
            name: data.bottomVideo.substring(data.bottomVideo.lastIndexOf('/') + 1)
          }

          this.submitForm.bottomVideo.push(videosObj)
        }
        this.editor = this.$public.parseHTML_ForMarkdown(data.content)
        if (data.productId && data.productId.length > 0) {
          this.productTable = [
            {
              productName: data.productName,
              productPriceMax: data.productPriceMax,
              productPriceMin: data.productPriceMin,
              productId: data.productId
            }
          ]
        } else {
          this.productTable = []
        }
      })

      this.dialogVisible = true
      this.editRow = row

      this.clearUploadStatus()
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            contentCategoryId: this.submitForm.contentCategoryId,
            contentTitle: this.submitForm.contentTitle,
            contentOrder: this.submitForm.contentOrder,
            content: this.submitForm.content,
            productId: this.submitForm.productId,
            contentTime: this.submitForm.contentTime,
            isAdvertisement: this.submitForm.isAdvertisement,
            topVideo: '',
            // topVideo:[],
            bottomVideo: '',
            // bottomVideo:[],
            messageTopCover: this.submitForm.messageTopCover,
            messageBottomCover: this.submitForm.messageBottomCover
          }
          // 详情顶部视频
          if (this.submitForm.topVideo && this.submitForm.topVideo.length > 0) {
            const videos = this.submitForm.topVideo[0]
            console.log(videos)
            if (!videos.response) {
              param.topVideo = videos.url
            } else {
              param.topVideo = videos.response.data
            }
          }

          // 详情底部视频
          if (this.submitForm.bottomVideo && this.submitForm.bottomVideo.length > 0) {
            const videos = this.submitForm.bottomVideo[0]
            if (!videos.response) {
              param.bottomVideo = videos.url
            } else {
              param.bottomVideo = videos.response.data
            }
          }
          if (!this.isAdd) {
            param.contentId = this.editRow.contentId
          }
          this.$http
            .requestPost({
              url: '/operation/message/content/save',
              param: param
            })
            .then(res => {
              this.getTableList()
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.closeDialog()
            })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      // 删除无用oss
      this.delCloudFile(this.submitForm)
      this.cloudFileData = []

      this.$refs.submitForm && this.$refs.submitForm.resetFields()
      this.dialogVisible = false
      this.submitForm = {
        contentCategoryId: '',
        contentTitle: '',
        contentOrder: '',
        content: '',
        contentTime: '',
        productId: '',
        isAdvertisement: 0,
        messageTopCover: '', // 顶部详情视频封面
        messageBottomCover: '' // 底部详情视频封面
      }
      this.graphic = {
        messageTopCover: [],
        messageBottomCover: []
      }
      this.editor = ''
      this.productTable = []
    },

    // 删除
    delData(row) {
      this.$confirm('确定删除该内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
            this.pageParam.pageNum--
          }
          this.$http
            .requestDel({
              url: '/operation/message/content/del/' + row.contentId
            })
            .then(res => {
              this.getTableList()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            })
        })
        .catch(err => {})
    },

    // 是否隐藏
    handleHide(row, type) {
      const name = type === 's' ? '显示' : '隐藏'
      this.$confirm('确定' + name + '该内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/operation/message/content/change/status/' + row.contentId
            })
            .then(res => {
              this.getTableList()
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            })
        })
        .catch(err => {})
    },

    // ======== 产品 ========
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1
      this.pageParam_2.pageSize = size
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page
      // 刷新(页码)
      this.getProductList()
    },

    // 打开产品列表
    openProductList() {
      this.submitForm.isAdvertisement = 0
      this.dialogVisible_2 = true
      this.getProductList()
    },

    // 查询产品列表
    getProductList() {
      const data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: this.inquire_2
      }

      this.loading_2 = true
      this.$http
        .requestPost({
          url: '/operation/message/content/choose/product',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading_2 = false
          this.tableData_2 = res.data.list
          this.pageParam_2.total = res.data.total
          if (this.productTable.length > 0) {
            const index = this.tableData_2.findIndex(item => item.productId === this.productTable[0].productId)
            this.$refs.multipleTable.setCurrentRow(this.tableData_2[index])
          }
        })
        .catch(err => {
          this.loading_2 = false
          console.log(err)
        })
    },

    // 确定负责人的选择
    confirmMerchant() {
      this.productTable = [this.selectRow]
      this.submitForm.productId = this.selectRow.productId
      this.dialogVisible_2 = false
    },

    // 产品列表的选择
    currentChange(news, old) {
      this.selectRow = news
    },

    // 上传视频-详情顶部视频
    uploadSuccess_1(res, file, fileList) {
      console.log(fileList)
      this.submitForm.topVideo = fileList
    },
    // 上传视频封面-详情顶部视频封面
    uploadSuccessCover_1(res, file, fileList) {
      // console.log("test!",fileList[0].response.data);
      this.graphic.messageTopCover = fileList
      this.submitForm.messageTopCover = fileList[0].response.data
    },
    removeFile_1(file, fileList) {
      this.submitForm.topVideo = fileList
      this.progressFlag1 = false
      this.percentage1 = 0
      if (this.uploader1) {
        this.uploader1.cancel()
        this.uploader2 = ''
      }
      console.log('remove1---', fileList)
      this.graphic.messageTopCover = []
      this.submitForm.messageTopCover = ''
    },
    // 上传视频-详情底部视频
    uploadSuccess_2(res, file, fileList) {
      this.submitForm.bottomVideo = fileList
    },
    // 上传视频封面-详情底部视频封面
    uploadSuccessCover_2(res, file, fileList) {
      this.graphic.messageBottomCover = fileList
      this.submitForm.messageBottomCover = fileList[0].response.data
    },
    // 删除底部视频
    removeFile_2(file, fileList) {
      this.submitForm.bottomVideo = fileList
      this.progressFlag2 = false
      this.percentage2 = 0
      if (this.uploader2) {
        this.uploader2.cancel()
        this.uploader2 = ''
      }
      console.log('remove2---', fileList)
      this.graphic.messageBottomCover = []
      this.submitForm.messageBottomCover = ''
    },
    // 删除视频封面
    removeCover_1(file, fileList) {
      this.graphic.messageTopCover = fileList
      this.submitForm.messageTopCover = ''
    },
    removeCover_2(file, fileList) {
      this.graphic.messageBottomCover = fileList
      this.submitForm.messageBottomCover = ''
    },
    // 详情顶部视频
    handleChange_1(file, fileList) {
      // 基本信息配置
      this.uploader1 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('topVideo', this.uploader1)
      // 视频上传后 处理
      this.uploaderDoneFn('topVideo', fileList, this.uploader1)
    },
    // 详情底部视频
    handleChange_2(file, fileList) {
      // 基本信息配置
      this.uploader2 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('bottomVideo', this.uploader2)
      // 视频上传后 处理
      this.uploaderDoneFn('bottomVideo', fileList, this.uploader2)
    },
    // 视频上传成功后，将fileId，fileUrl传给后台
    uploaderDoneFn(objName, fileList, uploader) {
      const that = this
      uploader
        .done()
        .then(function(doneResult) {
          const videosObj = [
            {
              url: doneResult.video.url,
              name: fileList[0].name
            }
          ]
          that.$http
            .requestPostQs({
              url: '/common/file/upload/saveQCloud',
              param: {
                fileId: doneResult.fileId,
                fileUrl: doneResult.video.url
              }
            })
            .then(res => {
              console.log(res)
            })

          if (objName == 'topVideo') {
            that.submitForm.topVideo = videosObj
          }
          if (objName == 'bottomVideo') {
            that.submitForm.bottomVideo = videosObj
          }
        })
        .catch(function(err) {
          // deal with error
        })
    },
    // 视频上传进度
    uploaderMediaProgress(objName, uploader) {
      const that = this
      uploader.on('media_progress', function(info) {
        const percentage = parseInt(info.percent * 100)
        if (objName == 'topVideo') {
          console.log(objName)
          that.progressFlag1 = true // 显示进度条
          console.log('progressFlag1', that.progressFlag1)
          that.percentage1 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
          console.log(percentage)
        }
        if (objName == 'bottomVideo') {
          that.progressFlag2 = true // 显示进度条
          that.percentage2 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }

        if (percentage >= 100) {
          setTimeout(() => {
            if (objName == 'topVideo') {
              that.progressFlag1 = false
            }
            if (objName == 'bottomVideo') {
              that.progressFlag2 = false
            }
          }, 1000) // 一秒后关闭进度条
        }
      })
    },
    // 清空上传视频数据
    clearUploadStatus() {
      this.uploader1 = ''
      this.uploader2 = ''
    },
    handleRun(val) {
      this.showCity = !this.showCity
      const cityId = val.contentId
      sessionStorage.setItem('cityId', JSON.stringify(cityId))
    },
    getCityValue(val) {
      this.showCity = val
    },

    // 存储云文件数据
    setCloudFileData(data) {
      this.cloudFileData.push(data)
      console.log(this.cloudFileData, '图片组')
    },
    // 删除无用的图片
    delCloudFile(data) {
      const delOssList = extractHttpLinks(data, this.cloudFileData)
      console.log(delOssList, 'delOssList')
      _delOssFile(delOssList)
    }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  max-width: 1000px;
  max-height: 420px;
  .v-note-wrapper.shadow {
    height: 420px;
  }
}
</style>
