<template>
  <!-- 产品类别列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="类别名称">
          <div class="flex-box">
            <el-input v-model="inquire.categoryName" placeholder="类别名称" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addClass">添加类别</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border row-key="categoryId"
            :tree-props="{ children: 'childes', hasChildren: 'hasChildren' }" height="400px" style="width: 100%">
            <el-table-column prop="" label="" min-width="60" />
            <el-table-column prop="categoryName" label="类别名称" min-width="160">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">{{ scope.row.categoryName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="categoryComment" label="备注" min-width="160" show-overflow-tooltip />
            <el-table-column prop="categoryOrder" label="排序" min-width="100" />
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="categoryStatus" label="显示状态" min-width="100">
              <template slot-scope="scope">
                {{ $api.getValue(showState, scope.row.categoryStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span v-show="scope.row.categoryLevel == 1" class="operation-btn"
                  @click="addChilClass(scope.row)">添加子类</span>
                <span v-show="scope.row.categoryLevel == 1" class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span v-show="scope.row.categoryStatus === 'display'" class="operation-btn"
                  @click="setState(scope.row, 'h')">隐藏</span>
                <span v-show="scope.row.categoryStatus === 'hide'" class="operation-btn"
                  @click="setState(scope.row, 's')">显示 </span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteProduct(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="closeDialog" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="80px" :model="submitForm" :rules="submitRules">
          <el-form-item :label="addChil ? '父类名称' : '类别名称'" prop="categoryName">
            <el-input v-model="submitForm.categoryName" :placeholder="addChil ? '父类名称' : '类别名称'" :readonly="addChil" />
          </el-form-item>
          <el-form-item v-if="addChil" label="子类名称" :prop="addChil ? 'categoryName2' : ''">
            <el-input v-model="submitForm.categoryName2" placeholder="子类名称" />
          </el-form-item>
          <el-form-item label="封面图片" :prop="(addChil || categoryLevel == 2) ? '' : 'coverImg'">
            <d-upload
                upload-type="picture-card"
                :file-list="primaryImagesList"
                accept=".gif,.jpeg,.jpg,.png"
                @uploadSuccess="uploadSuccess"
                @removeFile="removeFile"
                @cloudFileData="setCloudFileData"
            >
              <div class="tip-box">
                <i class="el-icon-plus" />
                <span slot="tip" class="tip">建议360px*360px</span>
              </div>
            </d-upload>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="submitForm.categoryComment" type="textarea" :rows="3" maxlength="150"
              placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="排序" prop="categoryOrder">
            <el-input v-model.number="submitForm.categoryOrder" placeholder="排序" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitClass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { _delOssFile, extractHttpLinks } from '@/utils/public'
export default {
  components: {
    dUpload
  },
  data() {
    return {
      // 查询参数
      inquire: {
        categoryName: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      showState: [
        { name: '显示', value: 'display' },
        { name: '隐藏', value: 'hide' }
      ],

      dialogTitle: '添加类别', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      addChil: false, // 判断是否添加子类
      submitForm: {
        categoryName: '',
        categoryName2: '',
        categoryComment: '',
        categoryOrder: 0,
        coverImg: ""
      },
      submitRules: {
        coverImg: [{ required: true, message: "请上传封面图片", trigger: "change" }],
        categoryName: [{ required: true, message: '请输入类别名称', trigger: 'blur' }],
        categoryName2: [{ required: true, message: '请输入子类名称', trigger: 'blur' }],
        categoryOrder: [{ required: true, message: '请输入排序', trigger: 'blur' },
        { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ]
      },
      isAddClass: true, // 是否为添加
      editRow: {},
      primaryImagesList: [],
      categoryLevel: 0,
      cloudFileData: [] // 云文件列表
    }
  },
  created() {
    // 获取产品列表
    this.getProductList()
  },
  beforeDestroy() {
    this.delCloudFile([],'all')
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getProductList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      const categoryName = this.inquire.categoryName !== ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize
      }
      categoryName && (data.param = this.inquire)

      this.loading = true
      this.$http.requestPost({
        url: '/product/category/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加产品类别
    addClass() {
      this.dialogTitle = '添加类别'
      this.addChil = false
      this.dialogVisible = true
      this.isAddClass = true
    },
    // 打开添加子类弹窗
    addChilClass(row) {
      this.dialogTitle = '添加子类'
      this.addChil = true
      this.categoryLevel = 2
      this.dialogVisible = true
      this.isAddClass = true
      this.editRow = row
      this.submitForm.categoryName = row.categoryName
    },
    // 修改类别
    handleEdit(row) {
      this.categoryLevel = row.categoryLevel;
      this.dialogTitle = '编辑类别'
      this.addChil = false
      this.isAddClass = false
      this.dialogVisible = true
      this.editRow = row
      this.primaryImagesList = [];
      this.submitForm = {
        coverImg: '',
        categoryName: row.categoryName,
        categoryComment: row.categoryComment,
        categoryOrder: row.categoryOrder
      };
      if (row.coverImg && row.coverImg.length > 0) {
        let videosObj = {
          url: row.coverImg,
          name: row.coverImg.substring(row.coverImg.lastIndexOf("/") + 1)
        };
        this.primaryImagesList.push(videosObj);
        console.log(this.primaryImagesList);
      }
    },
    // 提交类别添加
    submitClass() {
      if (this.primaryImagesList.length > 0) {
        const videos = this.primaryImagesList[0];
        console.log(videos);
        if (!videos.response) {
          this.submitForm.coverImg = videos.url;
        } else {
          this.submitForm.coverImg = videos.response.data;
        }
      }
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            categoryName: this.submitForm.categoryName,
            categoryComment: this.submitForm.categoryComment,
            categoryOrder: this.submitForm.categoryOrder,
            coverImg: this.submitForm.coverImg
          }
          if (!this.isAddClass) {
            param.categoryId = this.editRow.categoryId
          }
          if (this.addChil) {
            param.categoryParentId = this.editRow.categoryId
            param.categoryName = this.submitForm.categoryName2
          }
          this.delCloudFile(param)
          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 提交
    submit(param) {
      this.$http.requestPost({
        url: '/product/category/save',
        param: param
      }).then(res => {
        this.getProductList()
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.cloudFileData = []
        this.closeDialog()
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        categoryName: '',
        categoryName2: '',
        categoryComment: '',
        categoryOrder: '',
        coverImg: ''
      };
      this.primaryImagesList = [];
      this.delCloudFile([],'all')
    },

    // 修改状态
    setState(row, type) {
      const name = type === 'h' ? '隐藏' : '显示'
      this.$confirm('是否确定' + name + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          categoryId: row.categoryId,
          categoryStatus: type === 'h' ? 'hide' : 'display'
        }
        this.submit(param)
      }).catch(err => { })
    },

    // 删除
    deleteProduct(row) {
      this.$confirm('确定删除该类别?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({ url: '/product/category/del/' + row.categoryId, param: '' }).then(res => {
          this.getProductList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    // 上传封面图
    uploadSuccess(res, file, fileList) {
      console.log(fileList);
      this.primaryImagesList = fileList;
    },
    removeFile(file, fileList) {
      this.primaryImagesList = fileList;
    },
    // 存储云文件数据
    setCloudFileData(data) {
      this.cloudFileData.push(data)
      console.log(this.cloudFileData, '图片组')
    },

    // 删除无用的图片 type='all' 全部 type='part' 部分
    delCloudFile(data, type = 'part') {
      let delOssList = []
      if (type === 'part') {
        delOssList = extractHttpLinks(data, this.cloudFileData)
      } else {
        delOssList = this.cloudFileData
      }
      _delOssFile(delOssList)
      this.cloudFileData = []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
