<!-- 未领取红包二维码列表 -->
<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="优惠券二维码编号">
          <el-input v-model="inquire.couponQrCode" placeholder="请输入优惠券二维码编号" @keyup.enter.native="getTableList" />
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="inquire.productName" placeholder="请输入产品名称" @keyup.enter.native="getTableList" />
        </el-form-item>
        <el-form-item label="批次">
          <el-input v-model="inquire.productBatch" placeholder="请输入产品批次" />
        </el-form-item>
        <el-form-item label="优惠券ID">
          <el-input v-model="inquire.couponId" placeholder="请输入优惠券ID" />
        </el-form-item>
        <el-form-item label="创建时间">
          <div class="flex-box">
            <el-date-picker
              v-model="inquire.daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getTableList">查询</el-button>
          <el-button size="small" @click="resetInquire">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="addBtn">
      <template v-for="item in operateButton">
        <el-button size="small" type="primary" @click="operateBtnClick(item.id)">{{ item.buttonName }}</el-button>
      </template>
    </div>
    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            v-loading="loading"
            border
            height="400px"
            style="width: 100%"
            row-key="id"
            @selection-change="selectionChange"
          >
            <el-table-column type="selection" width="60" fixed :reserve-selection="true" />
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="qrCodeSerial" label="优惠券二维码编号" min-width="180" show-overflow-tooltip />
            <el-table-column prop="productName" label="产品名称" min-width="140" show-overflow-tooltip />
            <el-table-column prop="batchNo" label="批次" min-width="140" show-overflow-tooltip />
            <el-table-column prop="couponQuantity" label="优惠券数量" min-width="140" show-overflow-tooltip />
            <el-table-column prop="salesPrice" label="销售额" min-width="120" show-overflow-tooltip />
            <el-table-column prop="userNum" label="用户总数" min-width="120" show-overflow-tooltip />
            <el-table-column prop="scanNum" label="扫码次数" min-width="120" show-overflow-tooltip />
            <el-table-column prop="createTime" label="创建时间" min-width="200" show-overflow-tooltip />
            <el-table-column prop="" label="操作" min-width="280" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="tableHandle('edit', scope.row)">二维码</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="tableHandle('sales', scope.row)">销售记录</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="tableHandle('user', scope.row)">会员列表</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="tableHandle('coupon', scope.row)">优惠券列表</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 通用 QrCodeDialog组件【创建、编辑、关联批次】 -->
    <QrCodeDialog ref="qrCodeDialog_ref" :qr-code-type="qrCodeType" :dialogTitle="dialogTitle" @getValue="getQrCodeData">
      <template #tips>
        <p v-if="qrCodeType === 'couponBatch'" style="padding: 20px;">
          已选中 {{ selectionTableData.length }} 个优惠券二维码，设置后，优惠券二维码关联批次将以本次设置的批次为准。
        </p>
      </template>
    </QrCodeDialog>

    <!-- 销售记录弹窗 -->
    <el-dialog
      :title="`${clickRowData.qrCodeSerial} 销售记录`"
      :visible.sync="salesRecordsData.dialog"
      width="60%"
      @close="salesRecordsData.dialog = false"
    >
      <TableList
        height="400px"
        :loading="salesRecordsData.loading"
        :table-header="salesRecordsData.tableHeader"
        :table-data="salesRecordsData.list"
        :is-show-page="false"
      >
        <template #userName="{data}">
          <span>{{ `${data.memberNickName} / ${data.recipient}` }}</span>
        </template>
        <template #orderTotalPrice="{data}">
          <span>{{ data.orderTotalPrice ? '￥' + data.orderTotalPrice : '￥0.00' }}</span>
        </template>
        <template #orderStatus="{data}">
          <span>{{ $api.getValue($api.EcommerceOrderState, data.orderStatus, 'name') }}</span>
        </template>
        <template #operation="{data}">
          <el-button class="btn" type="text" @click="salesRecordsDetail(data)">订单详情</el-button>
        </template>
      </TableList>
    </el-dialog>

    <!-- 会员列表弹窗 -->
    <el-dialog :title="`${clickRowData.qrCodeSerial} 会员列表`" :visible.sync="memberListData.dialog" width="60%" @close="memberListDialog(false)">
      <el-form ref="memberList_ref" :model="memberQueryData" inline size="small">
        <el-form-item label="微信昵称" prop="memberNickname">
          <el-input v-model="memberQueryData.memberNickname" clearable placeholder="请输入微信昵称" />
        </el-form-item>
        <el-form-item label="手机号" prop="memberTelephone">
          <el-input v-model.trim="memberQueryData.memberTelephone" clearable maxlength="11" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="会员编号" prop="memberCode">
          <el-input v-model="memberQueryData.memberCode" clearable placeholder="请输入会员编号" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getQrCodeMemberList">查询</el-button>
        </el-form-item>
      </el-form>
      <TableList
        height="400px"
        :loading="memberListData.loading"
        :table-header="memberListData.tableHeader"
        :table-data="memberListData.list"
        :is-show-page="false"
      >
        <template #cumulativeConsumption="{data}">
          <span>{{ data.cumulativeConsumption ? '￥' + data.cumulativeConsumption : '-' }}</span>
        </template>
      </TableList>
    </el-dialog>

    <!-- 优惠券列表弹窗 -->
    <el-dialog
      :title="`${clickRowData.qrCodeSerial} 优惠券列表`"
      :visible.sync="couponListData.dialog"
      width="60%"
      @close="couponListData.dialog = false"
    >
      <TableList
        height="400px"
        :loading="couponListData.loading"
        :table-header="couponListData.tableHeader"
        :table-data="couponListData.list"
        :is-show-page="false"
      >
        <template #couponStatus="{data}">
          <span>{{ $api.getValue($api.couponStatus, data.couponStatus, 'name') }}</span>
        </template>
      </TableList>
    </el-dialog>
  </div>
</template>

<script>
import {
  batchUpdateQrCode,
  couponQrCodeList,
  queryCouponByQrCodeId,
  queryCouponQrCodeByCouponId
} from '@/api/operation'
import { qrCodeSaleRecord, qrCodeMemberList, createQrCode, modifyQrCode } from '@/api/user.js'
import QrCodeDialog from './components/qrCodeDialog'

export default {
  components: { QrCodeDialog },
  data() {
    return {
      // 查询参数
      inquire: {
        couponQrCode: '',
        productName: '',
        productBatch: '',
        couponId: '',
        daterange: [],
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],
      loading: false,
      selectionTableData: [], // 选中的数据
      editFormData: '', // 编辑时获取列表数据

      // 弹窗集合
      clickRowData: {}, // 当前点击的项
      // 会员列表请求数据
      memberQueryData: {
        qrCodeId: '', // 二维码id
        memberNickname: '', // 微信昵称
        memberCode: '', // 会员编号
        memberTelephone: '', // 手机号
      },
      // 会员列表数据
      memberListData: {
        dialog: false,
        loading: false,
        tableHeader: [
          { label: '微信昵称', prop: 'memberNickname' },
          { label: '会员编号', prop: 'memberCode' },
          { label: '注册手机号', prop: 'memberTelephone' },
          { label: '累计消费', type: 'slot', slotName: 'cumulativeConsumption' },
          { label: '消费次数', prop: 'consumptionFrequency' },
          { label: '注册时间', prop: 'registerTime' },
        ],
        list: [],
      },
      // 销售数据
      salesRecordsData: {
        dialog: false,
        loading: true,
        tableHeader: [
          { label: '订单编号', prop: 'orderCode' },
          { label: '卖家/收货人', type: 'slot', slotName: 'userName' },
          { label: '实付金额', type: 'slot', slotName: 'orderTotalPrice' },
          { label: '下单时间', prop: 'orderTime' },
          { label: '订单状态', type: 'slot', slotName: 'orderStatus' },
          { label: '操作', type: 'slot', slotName: 'operation' },
        ],
        list: [],
      },
      // 优惠券列表数据
      couponListData: {
        dialog: false,
        loading: false,
        tableHeader: [
          { label: '优惠券名称', prop: 'couponName' },
          { label: '优惠券ID', prop: 'couponCode' },
          { label: '状态', type: 'slot', slotName: 'couponStatus' },
          { label: '透出时间', prop: 'penetrateTime' },
        ],
        list: [],
      },

      // 外部组件
      qrCodeType: '',
      dialogTitle: '',
    }
  },
  created() {
    this.getTableList()
  },
  computed: {
    // 操作按钮配置
    operateButton() {
      const config = [
        { id: 1, buttonName: '创建优惠券二维码' },
        { id: 2, buttonName: '导出' },
        { id: 3, buttonName: '设置优惠券关联批次' },
        { id: 4, buttonName: '下载二维码图片' },
      ]
      return config
    },
  },
  methods: {
    // 获取数据列表
    async getTableList() {
      try {
        if (!this.inquire.daterange) this.inquire.daterange = []
        const data = {
          pageNum: this.pageParam.pageNum,
          pageSize: this.pageParam.pageSize,
          param: {
            qrCodeSerial: this.inquire.couponQrCode,
            productName: this.inquire.productName,
            batchNo: this.inquire.productBatch,
            couponCode: this.inquire.couponId,
            createBeginTime: this.inquire.daterange[0] || '',
            createEndTime: this.inquire.daterange[1] || '',
          },
        }
        this.loading = true
        const res = await couponQrCodeList(data)
        if (res.code === 200) {
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        }
      } catch (error) {
        console.error('获取列表失败：', error)
      } finally {
        this.loading = false
      }
    },
    // 操作栏事件
    tableHandle(type, row) {
      switch (type) {
        case 'edit': // 二维码
          // 打开弹窗true、创建edit
          this.qrCodeType = 'couponQrCode'
          this.dialogTitle = '编辑优惠券二维码'
          this.editFormData = row //编辑时获取列表数据，从数据中获取优惠券id为编辑确认时使用
          this.$refs.qrCodeDialog_ref.openQrCode(true, 'edit', row,'welfare')
          break
        case 'sales': // 销售记录
          this.salesRecordDialog(row)
          break
        case 'user': // 会员列表
          this.memberListDialog(true, row)
          break
        case 'coupon': // 优惠券列表
          this.couponListData.dialog = true
          this.clickRowData = row
          this.getQrCodeCouponList(row)
          break
      }
    },
    // 打开/关闭 销售记录弹窗 获取列表接口
    async salesRecordDialog(row) {
      try {
        this.clickRowData = row
        this.salesRecordsData.dialog = true
        const res = await qrCodeSaleRecord(row.id)
        if (res.code === 200) {
          this.salesRecordsData.list = res.data
        }
      } catch (error) {
        console.error('获取销售记录失败：', error)
      } finally {
        this.salesRecordsData.loading = false
      }
    },
    // 销售记录详情
    salesRecordsDetail(item) {
      // TODO这里需要根据订单类型跳转对应详情页
      // this.$router.push({
      //   path: "/order/ExternalProductOrder/particulars",
      //   query: { id: data.orderId },
      // });
    },
    // 打开/关闭 会员列表弹窗
    memberListDialog(isOpen, row) {
      // isOpen：true-打开弹窗 false-关闭弹窗
      if (isOpen) {
        this.clickRowData = row
        this.getQrCodeMemberList()
      } else {
        this.clearFields('memberList_ref')
      }
      this.memberListData.dialog = isOpen
    },
    // 获取会员列表接口
    async getQrCodeMemberList() {
      try {
        this.memberListData.loading = true
        const params = { ...this.memberQueryData, qrCodeId: this.clickRowData.id }
        const res = await qrCodeMemberList(params)
        if (res.code === 200) {
          this.memberListData.list = res.data
        }
      } catch (error) {
        console.error('获取会员列表失败：', error)
      } finally {
        this.memberListData.loading = false
      }
    },
    // 获取优惠券列表接口
    async getQrCodeCouponList(row) {
      try {
        this.couponListData.loading = true
        const res = await queryCouponByQrCodeId(row.id)
        if (res.code === 200) {
          this.couponListData.list = res.data
        }
      } catch (error) {
        console.error('获取优惠券列表失败：', error)
      } finally {
        this.couponListData.loading = false
      }
    },

    // 按钮
    operateBtnClick(event) {
      switch (event) {
        case 1: //创建优惠券二维码
          // 打开弹窗true、创建add
          this.qrCodeType = 'couponQrCode'
          this.dialogTitle = '创建优惠券二维码'
          this.$refs.qrCodeDialog_ref.openQrCode(true, 'add')
          break
        case 2: //导出
          // TODO 无弹窗，根据筛选条件导出
          console.log('导出参数===》', this.inquire)
          break
        case 3: //设置优惠券关联批次
          if (this.selectionTableData.length) {
            this.qrCodeType = 'couponBatch'
            this.dialogTitle = '设置二维码关联批次'
            this.$refs.qrCodeDialog_ref.openQrCode(true, 'batch1')
          } else {
            this.$message.warning('请选择优惠券二维码')
          }
          break
        case 4: //下载二维码图片
          this.getDownloadQrCode()
          break
      }
    },
    // 获取二维码数据【创建、关联批次、编辑】
    async getQrCodeData(val) {
      const valObj = { ...val }
      let data = {
        specifiedPath: valObj.specifiedPath, //跳转路径 1.首页 2.产品列表 3.产品详情
        specifiedPathId: valObj.specifiedPathId,
        createNum: valObj.createNum, //创建数量
        batchNo: valObj.batchNo, //批次
        productId: valObj.productId, //批次关联产品
        type: 7, // 固定参数：7优惠券二维码
      }
      // console.log('data  --- createQrCode', data);
      if (this.dialogTitle === '创建优惠券二维码') {
        const createRes = await createQrCode(data)
        if (createRes.code === 200) {
          this.$message.success('新增成功')
        }
      } else if (this.dialogTitle === '编辑优惠券二维码') {
        data.qrCodeId = this.editFormData.id
        delete data.createNum
        delete data.type
        const editRes = await modifyQrCode(data)
        if (editRes.code === 200) {
          this.$message.success('编辑成功')
        }
      } else if (this.dialogTitle === '设置二维码关联批次') {
        if (!valObj.batchNo) {
          return this.$message.warning('请选择关联产品再确认')
        }
        const batchData = {
          batchNo: valObj.batchNo, //批次
          productId: valObj.productId || '',
          qrCodeIdList: this.selectionTableData.map((item) => item.id),
        }
        const batchRes = await batchUpdateQrCode(batchData)
        if (batchRes.code === 200) {
          this.$refs.multipleTable.clearSelection()
          this.$message.success('批量操作成功')
        }
      }
      await this.getTableList()
    },
    // 下载二维码图片
    async getDownloadQrCode() {
      if (!this.inquire.daterange) this.inquire.daterange = []
      const param = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        qrCode: this.inquire.couponQrCode,
        productName: this.inquire.productName,
        batchNo: this.inquire.productBatch,
        couponCode: this.inquire.couponId,
        createBeginTime: this.inquire.daterange[0] || '',
        createEndTime: this.inquire.daterange[1] || '',
        type: 7,
      }
      const fileName = `log_${new Date().getTime()}` // 下载的文件名称
      this.$http
        .download({
          url: '/customer/merchant/downloadQrCode',
          param,
          method: 'POST',
        })
        .then((res) => {
          this.$public.downloadFile(res, fileName)
        })
        .catch((err) => {
          this.$message.error(err)
          console.error('下载失败：', err)
        })
    },
    // 多选
    selectionChange(data) {
      // console.log('=选择===', data)
      this.selectionTableData = data
    },
    // 重置查询
    resetInquire() {
      this.inquire = {
        couponQrCode: '',
        productName: '',
        productBatch: '',
        couponId: '',
        daterange: [],
      }
      this.getTableList()
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getTableList()
    },
  },
}
</script>

<style lang="scss" scoped>
.addBtn {
  margin: 20px 0 0 20px;
}
</style>
