<template>
  <el-dialog :visible.sync="visible" :title="personDialogTitle" :close-on-click-modal="false"
             width="550px" top="10vh" @close="personCloseDialog"
  >
    <div class="Popout_content">
      <el-form ref="submitFormRef" label-width="100px" :model="submitForm" :rules="ruleFormRules">
        <el-form-item label="人员姓名" prop="memberNickname">
          <el-input v-model="submitForm.memberNickname" placeholder="请输入人员姓名"/>
        </el-form-item>
        <el-form-item label="员工工号">
          <el-input v-model="submitForm.qyUserId" placeholder="请输入员工工号"/>
        </el-form-item>
        <el-form-item label="手机号" prop="memberTelephone">
          <el-input v-model="submitForm.memberTelephone" :disabled="!!submitForm.memberId" placeholder="请输入手机号"
                    maxlength="11"
          />
        </el-form-item>
        <el-form-item label="所属分组" prop="talentCategoryIds">
          <el-select v-model="submitForm.talentCategoryIds" placeholder="请选择所属分组" class="form-select"
                     multiple @change="changeTalentCategoryIds"
          >
            <el-option v-for="item in groupManagementList" :key="item.id" :label="item.categoryName"
                       :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属区域" prop="areaId">
          <el-cascader ref="cascaderAreaIdRef" v-model="submitForm.areaId" :options="areaList" :props="areaProps"
                       clearable :disabled="!IsEmployeeGroup" style="width: 100%;"
          />
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
        <el-button :loading="personButtonLoading" @click="visible = false">取 消</el-button>
        <el-button :loading="personButtonLoading" type="primary" @click="personSubmitAddOrEdit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveOrUpdateTalentItem } from '@/api/user'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 表单编辑数据
    editForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 所属分组
    groupManagementList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      personButtonLoading: false,
      IsEmployeeGroup: false, // 判断当前选择所属分组是否是员工
      submitForm: {},
      personDialogTitle: '编辑人员',
      areaList: [],
      areaProps: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    // form表单规则
    ruleFormRules() {
      return {
        memberNickname: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
        memberTelephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        talentCategoryIds: [{ required: true, message: '请选择所属分组', trigger: 'change' }],
        areaId: [{ required: this.IsEmployeeGroup, message: '请选择所属区域', trigger: ['change', 'blur'] }]
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$refs.submitFormRef && this.$refs.submitFormRef.resetFields()
        this.personDialogTitle = this.editForm.memberId ? '编辑人员' : '新增人员'
        const talentCategoryIds = this.editForm.categoryId ? this.editForm.categoryId.split('/') : [] // 切分分组id
        this.submitForm = { ...this.editForm, talentCategoryIds }

        this.$nextTick(() => {
          // 判断当前选中区域
          if (this.editForm.agencyId) {
            this.$set(this.submitForm, 'areaId', this.editForm.agencyId)
          } else if (this.editForm.districtId) {
            this.$set(this.submitForm, 'areaId', this.editForm.districtId)
          } else {
            this.$set(this.submitForm, 'areaId', this.editForm.regionId)
          }
          // 判断当前选择所属分组是否是员工
          const currentGroup = this.groupManagementList.find(item => item.categoryName === '员工')
          talentCategoryIds.forEach(item => {
            if (item === currentGroup.id) {
              this.IsEmployeeGroup = true
            }
          })
        })
      }
    }
  },

  created() {
    // 获取区域
    this.getDistrictIdList()
  },

  methods: {
    // 关闭弹窗-重置
    personCloseDialog() {
      this.personDialogTitle = '新增人员'
      this.submitForm = {
        memberNickname: '',
        qyUserId: '',
        memberTelephone: '',
        talentCategoryIds: [],
        areaId: ''
      }
      this.IsEmployeeGroup = false
      this.$refs.submitFormRef && this.$refs.submitFormRef.resetFields()
    },
    // 新增或编辑人员
    personSubmitAddOrEdit() {
      this.$refs.submitFormRef.validate(async(valid) => {
        if (!valid) return false
        try {
          const param = {
            memberNickname: this.submitForm.memberNickname,
            qyUserId: this.submitForm.qyUserId,
            memberTelephone: this.submitForm.memberTelephone,
            talentCategoryId: this.submitForm.talentCategoryIds.join(',')
          }
          // 判断是否是员工 且 选中了区域
          if (this.IsEmployeeGroup && this.submitForm.areaId) {
            const chooseNodes = this.$refs.cascaderAreaIdRef.getCheckedNodes()[0]
            const { path, pathLabels } = chooseNodes
            // 选中的节点=办事处
            if (path.length === 3) {
              param.regionId = path[0]
              param.regionName = pathLabels[0]
              param.districtId = path[1]
              param.districtName = pathLabels[1]
              param.agencyId = path[2]
              param.agencyName = pathLabels[2]
            }
            // 选中的节点=片区
            if (path.length === 2) {
              param.regionId = path[0]
              param.regionName = pathLabels[0]
              param.districtId = path[1]
              param.districtName = pathLabels[1]
            }
            // 选中的节点=大区
            if (path.length === 1) {
              param.regionId = path[0]
              param.regionName = pathLabels[0]
            }
          }
          // 编辑必传memberId
          if (this.submitForm.memberId) {
            param.memberId = this.submitForm.memberId
          }
          this.personButtonLoading = true
          const res = await saveOrUpdateTalentItem(param)
          this.personButtonLoading = false
          if (res.code !== 200) {
            return
          }
          this.$message.success('操作成功!')
          this.visible = false
          this.$emit('edit-done')
        } catch (e) {
          console.log(e)
          this.personButtonLoading = false
        }
      })
    },
    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.areaList = res.data
      })
    },
    // 选择所属分组
    changeTalentCategoryIds(ids) {
      const employeeItem = this.groupManagementList.find(item => item.categoryName === '员工')
      this.IsEmployeeGroup = employeeItem && (ids || []).includes(employeeItem.id)
      // 判断是否是员工分组 否=>清空区域id
      if (!this.IsEmployeeGroup) {
        this.submitForm.areaId = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
