<template>
  <screenBox id="largeScreen" :width="'1920'" :height="'1080'">
    <!-- <div id="largeScreen"> -->
    <div ref="largeScreen" class="large-box">
      <div class="chart-box">
        <!-- 标题 -->
        <div class="title-img">
          <!-- 标题内容 -->
          <!-- <div class="title-left"></div> -->
          <div class="title-content" />
          <!-- <div class="title-right"></div> -->
          <div class="title-bottom" />
          <div class="sales-title">
            <el-select
                v-model="inquire.dayOrMonth"
                class="selectDate"
                :popper-append-to-body="false"
                @change="disposeTimeShow"
            >
              <el-option label="按日统计" :value="1" />
              <el-option label="按月统计" :value="2" />
            </el-select>
            <el-date-picker
                v-show="inquire.dayOrMonth === 1"
                ref="datePicker"
                v-model="daterange"
                class="picker-date"
                :append-to-body="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                prefix-icon="el-icon-date"
                :picker-options="pickerOptions"
                @change="changeDate"
            />
            <el-date-picker
                v-show="inquire.dayOrMonth === 2"
                v-model="daterange"
                class="picker-month"
                :append-to-body="false"
                value-format="yyyy-MM-dd"
                type="monthrange"
                range-separator="-"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                @change="changeDate"
            />
          </div>
        </div>
        <!--左侧整体 -->
        <!-- <div class="left">
        <div class="schedule" :key="Math.random()">
        <scheduleChart ref="scheduleChart" :current="currentData" :selectQ.sync="selectQ" :selectM.sync="selectM" :progressList="progressList" @getActive="getActive" @getTableType="getTableType"></scheduleChart>
      </div >
      <div class="l-bottom">
        <brokenLineChart ref="saleChart"></brokenLineChart>
      </div >
      </div> -->
        <!--中间整体 -->
        <div class="center-box">
          <div class="content">
            <!-- 分为上下两部分 -->
            <div class="center-top">
              <!-- 医院总数以及骑手总数用户 -->
              <div id="total-data">
                <div class="tip">
                  <img src="../../assets/image/wenhao.png" alt="">
                  <div ref="poup" class="poup">
                    <div class="txt1">
                      医院总数：累计至今正在使用中的医院数量<br>
                      骑手总数：累计至今的商家端数量<br>
                      用户总数：累计至今已注册的用户数量<br>
                      下单用户总数：累计至今已购买过的用户数量
                    </div>
                  </div>
                </div>
                <div class="date" />
                <div class="data-box">
                  <div class="data-item">
                    <div class="data-left">
                      <img
                          :src="ossUrlObj.public.proportionHospitalIcon"
                          alt=""
                      >
                    </div>
                    <div class="data-txt">
                      <div class="data">{{ platformBasicData.hospitalTotality }}</div>
                      <div class="data-title">医院总数</div>
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-left">
                      <img
                          :src="ossUrlObj.public.proportionRiderIcon"
                          alt=""
                      >
                    </div>
                    <div class="data-txt">
                      <div class="data">{{ platformBasicData.riderTotality }}</div>
                      <div class="data-title">骑手总数</div>
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-left">
                      <img
                          :src="ossUrlObj.public.proportionUserIcon"
                          alt=""
                      >
                    </div>
                    <div class="data-txt">
                      <div class="data">{{ platformBasicData.userTotality }}</div>
                      <div class="data-title">用户总数</div>
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-left">
                      <img
                          :src="ossUrlObj.public.proportionOrderIcon"
                          alt=""
                      >
                    </div>
                    <div class="data-txt">
                      <div class="data">{{ platformBasicData.orderUserTotality }}</div>
                      <div class="data-title">下单用户总数</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 日访问量数据展示 -->
              <div id="data-top">
                <div class="tip">
                  <img src="../../assets/image/wenhao.png" alt="">
                  <div ref="poup" class="poup">
                    <div class="txt1">
                      访问人数：所选时间段内访问638医品的用户总数<br>
                      下单人数：所选时间段内已下单的用户总数<br>
                      新增用户：所选时间段内首次注册登录的用户总数<br>
                      转化率=下单人数/访问人数<br><br>
                    </div>
                    <div class="txt2">
                      注：
                      <div class="txt">
                        无法查看2023-01-01之前的访问次数、访问人数、<br>新增用户、转化率的数据
                      </div>
                    </div>
                  </div>
                </div>
                <div class="data-box">
                  <div class="data-item">
                    <div class="data-txt">
                      <div class="data">{{ getDailyActive.accessSum }}</div>
                      <div class="data-title">访问人数</div>
                    </div>
                    <div class="data-right">
                      <img
                          :src="ossUrlObj.public.proportionAccessIcon"
                          alt=""
                      >
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-txt">
                      <div class="data">{{ getDailyActive.dailyOrderingUsersSum }}</div>
                      <div class="data-title">下单人数</div>
                    </div>
                    <div class="data-right">
                      <img
                          :src="ossUrlObj.public.proportionOrderAccessIcon"
                          alt=""
                      >
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-txt">
                      <div class="data">{{ getDailyActive.dailyNewUsers }}</div>
                      <div class="data-title">新增用户</div>
                    </div>
                    <div class="data-right">
                      <img
                          :src="ossUrlObj.public.proportionAddIcon"
                          alt=""
                      >
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="data-txt">
                      <div class="data">{{ getDailyActive.averageConversionRate }}</div>
                      <div class="data-title">转化率</div>
                    </div>
                    <div class="data-right">
                      <img
                          :src="ossUrlObj.public.proportionConversionIcon"
                          alt=""
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="center-bottom">
              <div class="left pub-flex-column-between">
                <!-- 平台业务数据 -->
                <div id="business-data">
                  <div class="business-title">
                    <div class="title">
                      <div class="tip">
                        <img src="../../assets/image/wenhao.png" alt="">
                        <div ref="poup" class="poup">
                          <div class="txt1">
                            统计所选时间段内大区/片区/办事处/个人/产品的销售数据
                          </div>
                        </div>
                      </div>
                      <div class="icon"><img src="../../assets/image/pt.png" alt=""></div>
                      <div class="business">平台业务数据</div>
                    </div>
                    <div v-if="managerType == 'all'" class="nav-list">
                      <div
                          v-for="(item,index) in list"
                          :key="item.value"
                          class="item"
                          :class="current == index ? 'active' : ''"
                          @click="getSelect(index)"
                      >{{ item.name }}
                      </div>
                    </div>
                    <div v-if="managerType == 'region'" class="nav-list">
                      <div
                          v-for="(item,index) in list1"
                          :key="item.value"
                          class="item"
                          :class="current == index ? 'active' : ''"
                          @click="getSelect(index)"
                      >{{ item.name }}
                      </div>
                    </div>
                    <div v-if="managerType == 'district'" class="nav-list">
                      <div
                          v-for="(item,index) in list2"
                          :key="item.value"
                          class="item"
                          :class="current== index ? 'active' : ''"
                          @click="getSelect(index)"
                      >{{ item.name }}
                      </div>
                    </div>
                  </div>
                  <dv-scroll-board ref="scrollBoard" class="carouselList" :config="config_1" />
                </div>
                <!-- 产品浏览数据饼图 -->
                <div class="pie-box">
                  <pieChart
                      ref="pieChart"
                      v-model="ratioCurrent"
                      :manager-type="managerType"
                      @selectRadio="selectRadio"
                  />
                </div>
              </div>
              <!-- 中间图表数据 -->
              <div class="center">
                <div class="brange">
                  <div v-show="barrage" class="scrolling">
                    <div class="first">{{ attacheName }}</div>
                    <div class="one">{{ barrage }}</div>
                    <div class="two">&nbsp;{{ product }}</div>
                    <div class="three">{{ price }}</div>
                  </div>
                </div>
                <!-- 总销售额和总订单量数据展示 -->
                <div id="sales-data">
                  <div class="data-content">
                    <div class="top-module">
                      <div class="name1">总销售额</div>
                      <div class="name2">总订单量</div>
                    </div>
                    <!-- <dv-digital-flop :config="saleConfig" class="flop1"/> -->
                    <div class="bottom-module">
                      <module :value="marketing.totalPrice" :time="2" />
                      <module class="order-module" :value="marketing.totalOrder" :time="2" />
                      <!-- <dv-digital-flop :config="orderConfig"  class="flop"/> -->
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <!-- 上方弹幕体 -->
                  <!-- 地图 -->
                  <mapChart ref="mapChart" class="map-chart" />
                </div>
              </div>
              <!-- 右侧图表 -->
              <div class="right pub-flex-column-between">
                <!-- 历史访问 -->
                <div class="border1">
                  <interviewChart ref="interview" />
                </div>
                <!-- 订单数量趋势分析 -->
                <div class="order-box">
                  <brokenLineChart ref="orderChart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--右侧整体 -->
        <!-- <div class="right">
          <div class="top">
            <div class="conversion-box">
              <conversionChart ref="conversion"></conversionChart>
            </div>
          </div>
          <div class="bottom">
            <div class="circle-box">
              <circleChart ref="circle" :list="purchasingPowerAnalysis"></circleChart>
            </div>
          </div>
        </div> -->

      </div>
    </div>
    <!-- </div> -->
  </screenBox>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'

import { initjson } from '@/components/largeScreen/json/json.js'
import dayjs from 'dayjs'
// 引入socket
import mapChart from '@/components/largeScreen/mapChart.vue'
import scheduleChart from '@/components/largeScreen/scheduleChart.vue'
import brokenLineChart from '@/components/largeScreen/brokenLineChart.vue'
import conversionChart from '@/components/largeScreen/conversionChart.vue'
import circleChart from '@/components/largeScreen/circleChart.vue'
import pieChart from '@/components/largeScreen/pieChart2.vue'
import interviewChart from '@/components/largeScreen/interviewChart2.vue'
import module from '@/components/largeScreen/numScroll.vue'
import screenBox from '@/components/largeScreen/screenBox.vue'
import ossUrlObj from '@/utils/ossURL'

export default {
  computed: {
    ossUrlObj() {
      return ossUrlObj
    }
  },
  components: {
    scheduleChart,
    brokenLineChart,
    conversionChart,
    circleChart,
    pieChart,
    interviewChart,
    mapChart,
    module,
    screenBox
  },
  data() {
    return {
      list: [
        { name: '大区', value: 1 },
        { name: '片区', value: 2 },
        { name: '办事处', value: 3 },
        { name: '个人', value: 4 },
        { name: '产品', value: 5 }
      ],
      list1: [
        { name: '片区', value: 1 },
        { name: '办事处', value: 2 },
        { name: '个人', value: 3 },
        { name: '产品', value: 4 }
      ],
      list2: [
        { name: '办事处', value: 1 },
        { name: '个人', value: 2 },
        { name: '产品', value: 3 }
      ],
      currentData: 0, // 年度切换
      current: 0, // 大区切换
      config_1: {
        header: [],
        // indexHeader:'排名',
        data: [],
        rowNum: 10, // 表格行数
        headerHeight: 40, // 表头高度
        headerBGC: 'linear-gradient(to right,#000e45, #184a94);', // 表头背景色
        oddRowBGC: '', // 奇数行
        evenRowBGC: '', // 偶数行
        // index: true,
        columnWidth: [100],
        align: ['center', 'center', 'center', 'center'],
        waitTime: 6000,
        carousel: 'page'
      },
      managerType: 'all', // 权限
      regionName: '', // 拿到对应大区的名字
      config_2: {
        header: ['所属大区', '所属片区', '所属办事处', '所属专员', '产品', '总金额', '下单时间'],
        headerBGC: '', // 表头背景色
        data: [],
        rowNum: 5, // 表格行数
        headerHeight: 40, // 表头高度
        oddRowBGC: '', // 奇数行
        evenRowBGC: '#1e4cc3', // 偶数行
        columnWidth: [80, 80, 80, 80, 120, 60, 140],
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center'],
        waitTime: 300
      },
      getDailyActive: {
        accessSum: 0, // 访问人数
        averageConversionRate: '', // 转化率
        dailyNewUsers: 0, // 新增用户
        dailyVisits: 0, // 访问次数
        dailyOrderingUsersSum: 0// 下单人数
      }, // 访问数值显示
      platformBasicData: {
        hospitalTotality: 0, // 医院总数
        orderUserTotality: 0, // 下单用户总数
        riderTotality: 0, // 骑手总数
        userTotality: 0// 用户总数
      },
      purchasingPowerAnalysis: {}, // 用户购买力分析
      // 营销情况
      inquire: {
        dayOrMonth: 1,
        startTime: '',
        endTime: ''
      },
      marketing: {
        totalOrder: 0, // 总订单量
        totalPrice: 0 // 总销售额
      }, // 营销情况
      daterange: [], // 日期选择的
      pickerMinDate: '', // 获取开始选择时间
      pickerMaxDate: '', // 获取结束选择时间
      pickerOptions: {
        // 删除或注释掉shortcuts属性
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMaxDate = maxDate.getTime()
          }
          console.log('onPick', maxDate, minDate)
        },
        disabledDate: (time) => {
          // 限制只能选择31天且不能大于当前时间，往前往后都只有31天的日期范围
          const day31 = (31 - 1) * 24 * 3600 * 1000 + 24 * 3600 * 1000 - 1000
          if (this.pickerMinDate !== '' && !this.pickerMaxDate) {
            let maxTime = this.pickerMinDate + day31
            if (maxTime > new Date()) {
              maxTime = new Date()
            }
            return time.getTime() > maxTime || time.getTime() > Date.now()
          }
          if (this.pickerMaxDate !== '' && this.pickerMinDate !== '') {
            const minTime = this.pickerMaxDate - day31
            let maxTime = this.pickerMinDate + day31 // 这是为了保证往后限制只有31天显示可选
            if (maxTime >= new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)) {
              maxTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
            }
            return time.getTime() < minTime || time.getTime() > maxTime
          }
          return time.getTime() > Date.now()
        }
      },
      orderConfig: {
        number: [0],
        formatter: (number) => {
          const numbers = number.toString().split('').reverse()
          const segs = []
          while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
          return segs.join(',').split('').reverse().join('')
        },
        style: {
          fontSize: 60,
          fill: '#47a6fd'
        }
      },
      saleConfig: {
        number: [0],
        formatter: (number) => {
          const numbers = number.toString().split('').reverse()
          const segs = []
          while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
          return segs.join(',').split('').reverse().join('')
        },
        style: {
          fontSize: 60,
          fill: '#f95159'
        }
      },
      productBrowseOrSales: [], // 产品饼图数据
      pieR: null, // 产品浏览占比定时器
      ratioTime: null, // 饼图切换定时器
      ratioCurrent: 0, // 产品饼图当前tab
      platformServiceData: [], // 平台业务数据
      platTime: null, // 平台数据下方数组定时器
      tabTimer: null, // tab切换
      performanceCompliance: {}, // 业绩达标
      progressList: [], // 左侧进度条数据
      progressTimer: null, // 左侧进度条定时器
      selectQ: '', // 季度选择的值
      selectM: '', // 月度选择的值
      barrage: '', // 弹幕
      price: '', // 价格
      attacheName: '',
      product: '', // 中间产品名称
      rec: null,
      socket: null,
      socket1: null,
      socket2: null,
      socket3: null,
      socket4: null,
      socket5: null,
      timeout: 30000, // 30秒一次心跳
      timeoutObj: null, // 心跳心跳倒计时
      serverTimeoutObj: null, // 心跳倒计时
      clientWidth: '',
      clientHeight: '',
      timerW: null,
      timerH: null,
      areaId: '', // 区域权限id
      resetTime: 0// 尝试重连时间
    }
  },
  watch: {
    clientWidth(val) {
      if (!this.timerW) {
        this.clientWidth = val
        this.timerW = true
        const _this = this
        setTimeout(function() {
          _this.timerW = false
        }, 500)
      }
      // 这里可以添加修改时的方法
      this.windowWidth(val)
    }
    //  clientHeight(val) {
    //  if(!this.timerH) {
    //     this.clientHeight = val
    //     this.timerH = true
    //     let _this = this
    //   setTimeout(function () {
    //     _this.timerH = false
    //    }, 500)
    //  }
    //   // 这里可以添加修改时的方法
    //  this.windowHeight(val);
    //  },
  },
  async created() {
    try {
      this.setJurisdiction()
      console.log('进入到created')
      this.windowWidth(document.documentElement.clientWidth)
      this.windowHeight(document.documentElement.clientHeight)
      this.$nextTick(() => {
        const de = document.getElementById('largeScreen')
        document.querySelector('body').style.backgroundColor = '#000'
        if (de) {
          this.fullScreen()
        }
      })
      this.daterange = [this.$public.getDate(), this.$public.gettime()]
      this.daterange[0] = this.daterange[0] + ' 00:00:00'
      this.daterange[1] = this.daterange[1] + ' 23:59:59'
      this.daterange = [...this.daterange]
      this.inquire.startTime = this.daterange[0]
      this.inquire.endTime = this.daterange[1]
      this.getData()
      this.selectQ = this.getCurrentQuarter()
      this.selectM = this.getCurrentMonth()
      this.setScroll(this.current)
      //  await this.getPerformance()
      //  this.performScroll(this.currentData)
    } catch (error) {

    }
  },
  async mounted() {
    // 初始化自适应  ----在刚显示的时候就开始适配一次
    // this.handleScreenAuto();
    // 绑定自适应函数   ---防止浏览器栏变化后不再适配
    // window.onresize = () => this.handleScreenAuto();
    // 渲染地图
    this.$refs.mapChart.initChart(initjson('initChart'))
    await this.createSocket()// 建立连接
    //  开启心跳
    this.start()
    this.pieOutlineFunc()
    this.getTimeOrders()
  },
  beforeDestroy() {
    window.onresize = null
    this.oncloseWs()
    this.socket = null
    this.socket1 = null
    this.socket2 = null
    this.socket3 = null
    this.socket4 = null
    this.socket5 = null
    this.clear()
    this.cancelFullscreen()
    document.removeEventListener('fullscreenchange', () => {
      return
    })
    window.removeEventListener('resize', () => {
      return
    })
  },
  methods: {
    windowWidth(value) {
      this.clientWidth = value
      console.log('value: ', value)
    },
    windowHeight(value) {
      this.clienHeight = value
      console.log('value: ', value)
    },
    // 处理权限
    setJurisdiction() {
      this.managerType = this.$route.query.managerType
      if (this.managerType == 'all') {
        this.regionName = 'china'
      } else {
        this.regionName = this.$route.query.regionName
      }
    },
    // 获取数据
    async getData() {
      this.areaId = this.$route.query.areaId
      const managerType = this.$route.query.managerType
      const param = {
        orderNumAnalyze: this.inquire.dayOrMonth,
        marketingBeginTime: this.inquire.startTime, // 营销情况开始时间
        marketingEndTime: this.inquire.endTime, // 营销情况结束时间
        areaId: this.areaId || '',
        managerType: managerType
      }
      this.$http.requestPost({
        url: '/statistics/getBigScreenData',
        param: param,
        loading: false
      }).then(res => {
        const data = res.data
        this.getDailyActive = data.getDailyActive
        this.platformBasicData = data.platformBasicData
        // 历史访问数量图表数据
        this.setDaily(data.historicalAccessData)
        // 订单24小时趋势分析
        this.setOrders(data.orderQuantityAnalysis.orderQuantityAnalysisByDay)
        // // 用户购买力分析
        // if(data.purchasingPowerAnalysis){
        //   this.setBuy(data.purchasingPowerAnalysis)
        // }else{
        //   this.setBuy({})
        // }
        // 销售额趋势额分析
        // this.setSales(data.salesTrendAnalysis)
        // 产品浏览量占比
        this.productBrowseOrSales = data.productBrowseOrSales
        this.setRatio(data.productBrowseOrSales)
        if (this.managerType == 'all') {
          this.ratioTime = setInterval(() => {
            this.setRatio(this.productBrowseOrSales)
          }, 60000)
        }
        // 转化率趋势分析
        // this.setConversion(data.conversionRateTrendAnalysis)
        // 中间营销情况
        this.setMarket(data.marketing)
        // 处理平台业务数据
        this.platformServiceData = data.platformServiceData
        this.setPlatForm(data.platformServiceData)
      }).catch(err => {

      })
    },
    // 订单数据接口
    async getTimeOrders() {
      const areaId = this.$route.query.areaId
      const managerType = this.$route.query.managerType
      const param = {
        orderNumAnalyze: this.inquire.dayOrMonth,
        marketingBeginTime: this.inquire.startTime, // 营销情况开始时间
        marketingEndTime: this.inquire.endTime, // 营销情况结束时间
        areaId: areaId || '',
        managerType: managerType
      }
      this.$http.requestPost({
        url: '/statistics/getRealTimeOrders',
        param: param,
        loading: false
      }).then(res => {
        // 处理地图的高亮，只要最新的第一条订单
        const order = res.data.realTimeOrder
        //  按照权限展示
        // let order = {
        //   orderClassify:'medicine',
        //   productNameAndQuantityList:[
        //     {productName:'舒复派（红茶味）',itemNum:1}
        //   ],
        //   attacheName:'易烊千玺（',
        //   regionName:'华南大区',
        //   districtName:'什么片区片',
        //   agencyName:'么什么办事处',
        //   orderTotalPrice:'￥0.02',
        //   province:'湖北省',
        //   city:'武汉市'
        // }
        console.log('order', order)
        const data = []
        if (this.managerType == 'all') {
          if (order.orderClassify !== 'electronic') {
            if (order.province) {
              let list = []
              list = order.productNameAndQuantityList
              this.product = ''
              this.attacheName = ''
              this.barrage = ''
              this.price = ''
              if (list.length) {
                list.forEach(item => {
                  if (item.productName) {
                    this.product += item.productName + ' *' + item.itemNum + '、'
                  }
                })
              }
              this.product = this.product.slice(0, -1)
              this.price = '（' + order.orderTotalPrice + '元）'
              const obj = {
                name: this.removeSpecialWords(order.province),
                title: '专员',
                attachedName: order.attacheName || '无专员',
                region: order.regionName + ' / ' + order.districtName + ' / ' + order.agencyName,
                product: this.product,
                amount: order.orderTotalPrice
              }
              if (!order.regionName) {
                obj.region = order.province + ' / ' + order.city
              }
              this.barrage = '（' + obj.region + '）' + '—'
              data.push(obj)
              if (order.attacheName) {
                this.attacheName = order.attacheName
              } else {
                this.attacheName = '无专员'
              }
            }
          } else {
            if (order.orderCode) {
              this.product = ''
              this.barrage = ''
              this.price = ''
              this.attacheName = ''
              let list = []
              list = order.productNameAndQuantityList
              if (list.length) {
                list.forEach(item => {
                  if (item.productName) {
                    this.product += item.productName + ' *' + item.itemNum + '、'
                  }
                })
              }
              this.product = this.product.slice(0, -1)
              this.attacheName = '电商品'
              this.barrage = '(' + order.province + ' / ' + order.city + ')' + '—'
              this.price = '（' + order.orderTotalPrice + '元）'
              const obj = {
                name: this.removeSpecialWords(order.province),
                title: '类型',
                attachedName: '电商品',
                region: order.province + ' / ' + order.city,
                product: this.product,
                amount: order.orderTotalPrice
              }
              data.push(obj)
            }
          }
          if (order.province) {
            const province = this.removeSpecialWords(order.province)
            if (province) {
              this.$refs.mapChart.setMap(initjson('china'), province, data)
            }
          }
        } else {
          if ((this.managerType == 'region' && this.areaId == order.regionId) || (this.managerType == 'district' && this.areaId == order.districtId)) {
            console.log('meiswdhjsdhb')
            if (order.orderClassify !== 'electronic') {
              if (order.province) {
                this.product = ''
                this.attacheName = ''
                this.barrage = ''
                this.price = ''
                let list = []
                list = order.productNameAndQuantityList
                this.product = ''
                if (list.length) {
                  list.forEach(item => {
                    if (item.productName) {
                      this.product += item.productName + ' *' + item.itemNum + '、'
                    }
                  })
                }
                this.product = this.product.slice(0, -1)
                this.price = '（' + order.orderTotalPrice + '元）'
                const obj = {
                  name: this.removeSpecialWords(order.province),
                  title: '专员',
                  attachedName: order.attacheName || '无专员',
                  region: order.regionName + ' / ' + order.districtName + ' / ' + order.agencyName,
                  product: this.product,
                  amount: order.orderTotalPrice
                }
                if (!order.regionName) {
                  obj.region = order.province + ' / ' + order.city
                }
                this.barrage = '（' + obj.region + '）' + '—'
                data.push(obj)
                if (order.attacheName) {
                  this.attacheName = order.attacheName
                } else {
                  this.attacheName = '无专员'
                }
              }
            } else {
              if (order.orderCode) {
                this.product = ''
                this.barrage = ''
                this.attacheName = ''
                this.price = ''
                let list = []
                list = order.productNameAndQuantityList
                if (list.length) {
                  list.forEach(item => {
                    if (item.productName) {
                      this.product += item.productName + ' *' + item.itemNum + '、'
                    }
                  })
                }
                this.product = this.product.slice(0, -1)
                this.attacheName = '电商品'
                this.barrage = '(' + order.province + ' / ' + order.city + ')' + '—'
                this.price = '（' + order.orderTotalPrice + '元）'
                const obj = {
                  name: this.removeSpecialWords(order.province),
                  title: '类型',
                  attachedName: '电商品',
                  region: order.province + ' / ' + order.city,
                  product: this.product,
                  amount: order.orderTotalPrice
                }
                data.push(obj)
              }
            }
            if (order.province) {
              const province = this.removeSpecialWords(order.province)
              if (province) {
                this.$refs.mapChart.setMap(initjson('china'), province, data)
              }
            }
          }
        }
      })
    },

    disposeTimeShow(e) {
      console.log('this.daterange', this.daterange)
      this.daterange = [this.$public.getDate(), this.$public.gettime()]
      if (this.inquire.dayOrMonth == 1) {
        //  if(this.daterange[0].includes('00:00:00')){
        //   this.inquire.startTime = this.daterange[0]
        //   this.inquire.endTime = this.daterange[1]
        //  }else{
        //   this.inquire.startTime = this.daterange[0] = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
        //   this.inquire.endTime = this.daterange[1] = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
        //   this.daterange = [...this.daterange]
        //  }
        this.daterange[0] = this.daterange[0] + ' 00:00:00'
        this.daterange[1] = this.daterange[1] + ' 23:59:59'
        this.daterange = [...this.daterange]
        this.inquire.startTime = this.daterange[0]
        this.inquire.endTime = this.daterange[1]
        this.clear()
        this.getData()
        this.setScroll(this.current)
      } else if (this.inquire.dayOrMonth == 2) {
        // if(!this.inquire.startTime.includes('00:00:00')){
        //   this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
        //   this.inquire.endTime = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
        // }
        this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00' : ''
        this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] + ' 23:59:59' : ''
        this.clear()
        this.getData()
        this.setScroll(this.current)
      }
    },
    // 获取左侧业绩达标情况数据
    async getPerformance() {
      // 默认进来是本年度、本季度、本月
      const param = {
        quarter: this.selectQ,
        month: this.selectM
      }
      this.$http.requestPost({
        url: '/statistics/getSalesTarget',
        param: param,
        loading: false
      }).then(res => {
        console.log('res', res)
        // 处理业绩数据
        this.performanceCompliance = res.data.performanceCompliance
        this.setPerformance(res.data.performanceCompliance)
      })
    },
    // 选择对应的季度或者月度
    getTableType(val) {
      if (this.currentData == 1) {
        this.selectQ = val
        clearTimeout(this.progressTimer)
        this.getPerformance()
        this.performScroll(this.currentData)
      } else if (this.currentData == 2) {
        this.selectM = val
        clearTimeout(this.progressTimer)
        this.getPerformance()
        this.performScroll(this.currentData)
      }
      console.log('this.selectQ', this.selectQ, this.selectM)
    },
    setPerformance(res) {
      // 处理业绩达标数据
      if (this.currentData == 0) {
        const list = res.regionByYear
        list.map(item => {
          delete item.targetSales
          delete item.regionId
        })
        this.progressList = list
      } else if (this.currentData == 1) {
        const list = res.regionByQuarter
        list.map(item => {
          delete item.targetSales
          delete item.regionId
        })
        this.progressList = list
      } else if (this.currentData == 2) {
        const list = res.regionByMonth
        list.map(item => {
          delete item.targetSales
          delete item.regionId
        })
        this.progressList = list
      }
    },
    performScroll(current) {
      // 处理业绩达标情况轮播
      switch (current) {
        case 0:
          this.progressTimer = setTimeout(() => {
            this.currentData = 1
            this.performScroll(this.currentData)
            this.setPerformance(this.performanceCompliance)
          }, 60000)
          break
        case 1:
          this.progressTimer = setTimeout(() => {
            this.currentData = 2
            this.performScroll(this.currentData)
            this.setPerformance(this.performanceCompliance)
          }, 60000)
          break
        case 2:
          this.progressTimer = setTimeout(() => {
            this.currentData = 0
            this.performScroll(this.currentData)
            this.setPerformance(this.performanceCompliance)
          }, 60000)
          break
        default:
          break
      }
    },
    // 点击切换业绩达标情况tab栏
    getActive(index) {
      this.currentData = index
      // 清除定时器
      clearTimeout(this.progressTimer)
      this.performScroll(this.currentData)
      this.setPerformance(this.performanceCompliance)
    },
    getCurrentMonth() {
      const now = new Date()
      const month = now.getMonth() + 1
      return month
    },
    getCurrentQuarter() {
      const now = new Date()
      const quarterNum = Math.floor((now.getMonth() + 3) / 3) // 获取当前月份所在季度数
      return quarterNum
    },
    
    // 处理平台业务数据
    async setPlatForm(res) {
      // 定义对象，剔除个人列表current
      const platformMap = {
        all: [  //大区
          { current: 0, dataKey: 'region', header: ['排名', '大区', '销售额'] },
          { current: 1, dataKey: 'district', header: ['排名', '片区', '销售额'] },
          { current: 2, dataKey: 'agency', header: ['排名', '办事处', '销售额'] },
          { current: 4, dataKey: 'product', header: ['排名', '产品名称', '在售医院数量', '销售额'] },
        ],
        region: [  //片区
          { current: 0, dataKey: 'district', header: ['排名', '片区', '销售额'] },
          { current: 1, dataKey: 'agency', header: ['排名', '办事处', '销售额'] },
          { current: 3, dataKey: 'product', header: ['排名', '产品名称', '在售医院数量', '销售额'] },
        ],
        district: [  //办事处
          { current: 0, dataKey: 'agency', header: ['排名', '办事处', '销售额'] },
          { current: 2, dataKey: 'product', header: ['排名', '产品名称', '在售医院数量', '销售额'] },
        ],
      };
      // 根据 managerType权限获取对应数组
      const dataSource = platformMap[this.managerType] || [];
      for (const item of dataSource) {
        if (item.current === this.current) {
          // 大区列表
          if (item.dataKey === 'region') {
            const arr = res[item.dataKey];
            const header = item.header;
            this.getNext(arr, 0, header);
          } else {
            this.processTopTenData(res, item.dataKey, item.header);
          }
          return;
        }
      }
      // 当current没有匹配，则展示个人列表
      this.processNextArray();
    },
    // 封装统一处理【平台业务数据】
    processTopTenData(res, dataKey, header) {
      // 固定命名规则
      const topTen1 = res[dataKey][dataKey + 'TopTen1'];
      const topTen2 = res[dataKey][dataKey + 'TopTen2'];
      // console.log('========》topTen1', topTen1);
      // console.log('--------> topTen2', topTen2);
      
      if (topTen2 && topTen2.length) {
        this.getNext(topTen1, 0, header);
        this.platTime = setTimeout(() => {
          this.getNext(topTen2, 10, header);
        }, 3000);
      } else {
        this.getNext(topTen1, 0, header);
      }
    },
    // 平台业务数据【个人列表 -- 循坏方法】
    processNextArray() {
      const mock1 = [
        {name:'电商品1',attache:'深圳1',price:12},
        {name:'电商品2',attache:'深圳2',price:14},
        {name:'电商品3',attache:'深圳3',price:16},
      ]
      const mock2 = [
        {name:'电商品4',attache:'深圳1',price:22},
        {name:'电商品5',attache:'深圳2',price:66},
        {name:'电商品6',attache:'深圳3',price:88},
      ]
      const mock3 = [
        {name:'经销品1',attache:'深圳1',price:12},
        {name:'经销品2',attache:'深圳2',price:14},
        {name:'经销品3',attache:'深圳3',price:16},
      ]
      const mock4 = [
        {name:'经销品4',attache:'深圳1',price:22},
        {name:'经销品5',attache:'深圳2',price:66},
        {name:'经销品6',attache:'深圳3',price:88},
      ]
      const mock5 = [
        {name:'医品1',attache:'深圳1',price:12},
        {name:'医品2',attache:'深圳2',price:14},
        {name:'医品3',attache:'深圳3',price:16},
      ]
      const mock6 = [
        {name:'医品4',attache:'深圳1',price:22},
        {name:'医品5',attache:'深圳2',price:66},
        {name:'医品6',attache:'深圳3',price:88},
      ]
      // mock数据
      const attache = {
        electronicAttacheTopTen1: mock1,
        electronicAttacheTopTen2:mock2,
        externalAttacheTopTen1:mock3,
        externalAttacheTopTen2:mock4,
        medicineAttacheTopTen1: mock5,
        medicineAttacheTopTen2: mock6
      }
      const header = ['排名', '信息员', '所属办事处', '销售额']
      const resObject = this.platformServiceData.attache;
      // const resObject = attache;  
      // console.log('resObject=======', resObject);
      const keys = Object.keys(resObject).sort();
      let index = 0;
      
      // 循环处理函数
      const processArray = () => {
        if (index >= keys.length) {
          console.log('所有都执行完毕！！！');
          clearTimeout(this.platTime);
          return;
        }
        const mapKey = keys[index];
        const isShownArr = resObject[mapKey];
        if (isShownArr.length) {
          const isEven = mapKey.endsWith('1');   //判断当前字符是否指定某个子字符结尾
          const sort = isEven ? 0 : 10;
          this.getNext(isShownArr, sort, header);
        }
        // 增加索引并设置定时器
        index++;
        this.platTime = setTimeout(processArray, 30000); 
      };
      processArray();
    },
    // 执行顺序
    async getNext(data, num, header) {
      // 判断数据数组是否为空
      if (data.length) {
        // 转换数据为目标格式
        data = this.$public.transData(data)
        // 遍历数据数组
        for (let i = 0; i < data.length; i++) {
          let color, backgroundColor
          if (i === 0 && num == 0) { // 第一名使用 white 文字和橙色背景
            color = '#FFFFFF'
            backgroundColor = `url(${ossUrlObj.public.proportionBg1Img})no-repeat center center;`
          } else if (i === 1 && num == 0) { // 第二名使用 white 文字和深绿色背景
            color = '#FFFFFF'
            backgroundColor = `url(${ossUrlObj.public.proportionBg2Img})no-repeat center center;`
          } else if (i === 2 && num == 0) { // 第三名使用 white 文字和蓝色背景
            color = '#FFFFFF'
            backgroundColor = `url(${ossUrlObj.public.proportionBg3Img})no-repeat center center;`
          } else { // 其他名次使用默认颜色和背景
            color = '#1D89B1'
            backgroundColor = `url(${ossUrlObj.public.proportionBg4Img})no-repeat center center;`
          }
          // 定义用于添加到数组前面的 HTML 字符串，其中包含一个圆形图标和一个数字
          const arr = '<span style="height:100%;display:inline-block;border-radius:50%;background:' + backgroundColor + ';width:25px;height:25px;font-size:15px;color:' + color + ';font-family:Arial;font-weight:700;line-height: 25px;">' +
              Number(num + i + 1) + '</span>'
          // 将 HTML 字符串添加到当前项的数组前面
          // 判断是否为空数据
          if (data[i][0]) {
            data[i].unshift(arr)
          }
          // 金额有可能为0需要加入判断
          if (data[i][data[i].length - 1] || data[i][data[i].length - 1] === 0) {
            // 获取最后一个金额字段，将其包裹在 span 标签中，并设置样式属性 color 为白色
            const lastField = '<span style="color: #B0F9FD;font-size: 16px;font-weight: 700;box-shadow: 0 2px 10px 0 #0000004d;">' + data[i][data[i].length - 1] + '</span>'
            // 替换原始的数组元素，以便显示样式化的金额字段
            data[i][data[i].length - 1] = lastField
          }
        }
        // 更新配置信息
        this.config_1.data = data
        this.config_1.header = header
        this.config_1 = { ...this.config_1 }
        console.log(data, '处理后数据')
      }
    },

    // 处理平台的tab数据滚动
    setScroll(current) {
      switch (current) {
        case 0:
          this.tabTimer = setTimeout(() => {
            this.current = 1
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
          }, 60000)
          break
        case 1:
          this.tabTimer = setTimeout(() => {
            if (this.managerType == 'district') {
              this.current = 0
            } else {
              this.current = 2
            }
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
          }, 60000)
          break
        case 2:
          this.tabTimer = setTimeout(() => {
            if (this.managerType == 'region') {
              this.current = 0
            } else {
              this.current = 3
            }
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
          }, 60000)
          break
        case 3:
          console.log('点击了')
          this.tabTimer = setTimeout(() => {
            this.current = 0
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
          }, 60000)
          break
        case 4:
          this.tabTimer = setTimeout(() => {
            this.current = 4
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
          }, 60000)
          break
        default:
          break
      }
    },
    // 点击大区切换
    getSelect(index) {
      this.current = index
      // 清除定时器
      clearTimeout(this.tabTimer)
      clearTimeout(this.platTime)
      this.setScroll(this.current)
      this.setPlatForm(this.platformServiceData)
    },
    // 修改日历时确认选择
   async changeDate(e) {
      console.log('e', e, this.daterange)
      if (e) {
        if (this.inquire.dayOrMonth == 2) {
          this.daterange[1] = this.$public.getAssignMonth(this.daterange[1])[1]
          this.daterange = [...this.daterange]
          this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00' : ''
          this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] + ' 23:59:59' : ''
          // 发起请求
          await this.clear()
          await this.getData()
          this.setScroll(this.current)
        } else if (this.inquire.dayOrMonth == 1) {
          this.inquire.startTime = this.daterange[0]
          this.inquire.endTime = this.daterange[1]
          // 发起请求
          await this.clear()
          await this.getData()
          this.setScroll(this.current)
        }
      } else {
        this.pickerMinDate = ''// 获取开始选择时间
        this.pickerMaxDate = ''// 获取结束选择时间
        this.$refs.datePicker.pickerVisible = true
        this.daterange = []
      }
    },
    selectRadio(current) {
      this.ratioCurrent = current
      clearTimeout(this.pieR)
      clearInterval(this.ratioTime)
      // this.ratioScroll(current)
      // console.log('this.ratioCurrent',this.ratioCurrent);
      this.setRatio(this.productBrowseOrSales)
      this.ratioTime = setInterval(() => {
        this.setRatio(this.productBrowseOrSales)
      }, 60000)
    },
    // 处理产品浏览占比以及产品销量占比
    setRatio(res) {
      // 一分钟之后修改数据
      if (this.managerType == 'all') {
        if (this.ratioCurrent == 0) {
          const title = '产品销量占比'
          const arr = res.salesRatio
          const data = []
          const titleList = []
          arr.forEach(item => {
            const obj = {
              name: item.productName,
              value: item.productSales
            }
            data.push(obj)
            if (item.productName) {
              titleList.push(item.productName)
            }
          })
          this.$refs.pieChart && this.$refs.pieChart.getPieChart(title, titleList, data)
          this.pieR = setTimeout(() => {
            const title = '产品浏览量占比'
            const arr = res.browseRatio
            const data = []
            const titleList = []
            arr.forEach(item => {
              const obj = {
                name: item.productName,
                value: item.browseCount
              }
              data.push(obj)
              if (item.productName) {
                titleList.push(item.productName)
              }
            })
            this.$refs.pieChart && this.$refs.pieChart.getPieChart(title, titleList, data)
            this.ratioCurrent = 1
          }, 60000)
        } else if (this.ratioCurrent == 1) {
          const title = '产品浏览量占比'
          const arr = res.browseRatio
          const data = []
          const titleList = []
          arr.forEach(item => {
            const obj = {
              name: item.productName,
              value: item.browseCount
            }
            data.push(obj)
            if (item.productName) {
              titleList.push(item.productName)
            }
          })
          this.$refs.pieChart && this.$refs.pieChart.getPieChart(title, titleList, data)
          this.pieR = setTimeout(() => {
            const title = '产品销量占比'
            const arr = res.salesRatio
            const data = []
            const titleList = []
            arr.forEach(item => {
              const obj = {
                name: item.productName,
                value: item.productSales
              }
              data.push(obj)
              if (item.productName) {
                titleList.push(item.productName)
              }
            })
            this.$refs.pieChart && this.$refs.pieChart.getPieChart(title, titleList, data)
            this.ratioCurrent = 0
          }, 60000)
        }
      } else {
        const title = '产品销量占比'
        const arr = res.salesRatio
        const data = []
        const titleList = []
        arr.forEach(item => {
          const obj = {
            name: item.productName,
            value: item.productSales
          }
          data.push(obj)
          if (item.productName) {
            titleList.push(item.productName)
          }
        })
        this.$refs.pieChart && this.$refs.pieChart.getPieChart(title, titleList, data)
      }
    },
    // 处理营销情况
    setMarket(res) {
      this.marketing = res
      this.saleConfig.number[0] = this.marketing.totalPrice
      this.saleConfig = { ...this.saleConfig }
      this.orderConfig.number[0] = this.marketing.totalOrder
      this.orderConfig = { ...this.orderConfig }
    },
    // 处理日访问数据
    setDaily(res) {
      const dataTime = []
      const dailyVisitors = []
      const newMember = []
      const dailyVisits = []
      res.dailyVisitors.forEach(item => {
        dailyVisitors.push(item.peopleNum)
        dataTime.push(item.time)
      })
      res.newMember.forEach(item => {
        newMember.push(item.peopleNum)
      })
      res.dailyVisits.forEach(item => {
        dailyVisits.push(item.peopleNum)
      })
      this.$refs.interview.getDataBottom(dataTime, dailyVisitors, newMember, dailyVisits)
    },
    // 处理销售额趋势分析数据
    setSales(res) {
      const title = '销售额趋势分析'
      const current = []
      const hourList = []
      const header = []
      res.forEach(item => {
        current.push(item.salesRevenue)
        hourList.push(item.stringTime)
      })
      this.$refs.saleChart.getDataOrder(title, hourList, current, header)
    },
    // 处理订单数量24小时趋势分析
    setOrders(res) {
      const title = '订单24小时趋势分析'
      const current = []
      const hourList = []
      const header = []
      res.forEach(item => {
        current.push(item.orderCount)
        hourList.push(item.hour)
      })
      this.$refs.orderChart.getDataOrder(title, hourList, current, header)
    },
    // 用户购买力分析
    setBuy(res) {
      for (const key in res) {
        res[key] = Number(res[key])
      }
      this.purchasingPowerAnalysis = res
    },
    // 处理转化率趋势分析
    setConversion(res) {
      const conversionRate = []
      const time = []
      const averageConversionRate = []
      res.conversionRate.forEach(item => {
        conversionRate.push(Number(item.conversionRate))
        time.push(item.stringTime)
      })
      // 处理平均值
      time.forEach(item => {
        averageConversionRate.push(Number(res.averageConversionRate))
      })
      // console.log('averageConversionRate',time,conversionRate,averageConversionRate);
      this.$refs.conversion.getDataChart(time, averageConversionRate, conversionRate)
    },
    // 清除所有定时器
    async clear() {
      this.isConnect = false
      await clearTimeout(this.pieR)
      await clearInterval(this.ratioTime)
      await clearTimeout(this.tabTimer)
      await clearTimeout(this.platTime)
    },
    // socket 通信
    async createSocket() {
      // 环境的配置
      let baseURL = ''
      let baseURL1 = ''
      let baseURL2 = ''
      let baseURL3 = ''
      let baseURL4 = ''
      let baseURL5 = ''
      if (process.env.NODE_ENV == 'development') {
        // baseURL = 'ws://192.168.3.58:8003/customer/ws/realTime/PB9ys6rhwV0_m4uA';
        // baseURL1 = 'ws://192.168.3.58:8002/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA';
        // baseURL2 = 'ws://192.168.3.58:8001/management/ws/realTime/PB9ys6rhwV0_m4uA';
        // baseURL3 = 'ws://192.168.3.58:8003/customer/wss/realTime/PB9ys6rhwV0_m4uA';
        // baseURL4 = 'ws://192.168.3.58:8002/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA';
        // baseURL5 = 'ws://192.168.3.58:8001/management/wss/realTime/PB9ys6rhwV0_m4uA';
        baseURL = 'ws://test-user-api.638yipin.uyaoku.com/customer/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL1 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL2 = 'ws://test-manager-api.638yipin.uyaoku.com/management/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL3 = 'ws://test-user-api.638yipin.uyaoku.com/customer/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL4 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL5 = 'ws://test-manager-api.638yipin.uyaoku.com/management/wss/realTime/PB9ys6rhwV0_m4uA'
      } else if (process.env.NODE_ENV == 'staging') {
        baseURL = 'ws://test-user-api.638yipin.uyaoku.com/customer/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL1 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL2 = 'ws://test-manager-api.638yipin.uyaoku.com/management/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL3 = 'ws://test-user-api.638yipin.uyaoku.com/customer/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL4 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL5 = 'ws://test-manager-api.638yipin.uyaoku.com/management/wss/realTime/PB9ys6rhwV0_m4uA'
      } else if (process.env.NODE_ENV == 'production') {
        baseURL = 'wss://user-api.' + $hostname + '.com/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL1 = 'wss://merchant-api.' + $hostname + '.com/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL2 = 'wss://manager-api.' + $hostname + '.com/ws/realTime/PB9ys6rhwV0_m4uA'
        baseURL3 = 'wss://user-api.' + $hostname + '.com/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL4 = 'wss://merchant-api.' + $hostname + '.com/wss/realTime/PB9ys6rhwV0_m4uA'
        baseURL5 = 'wss://manager-api.' + $hostname + '.com/wss/realTime/PB9ys6rhwV0_m4uA'
      }
      let socket
      socket = new WebSocket(baseURL)
      this.socket = socket
      socket.onopen = this.onopenWs
      socket.onclose = this.oncloseWs
      socket.onerror = this.onerrorWs
      socket.onmessage = this.onmessageWs
      let socket1
      socket1 = new WebSocket(baseURL1)
      this.socket1 = socket1
      socket1.onopen = this.onopenWs
      socket1.onclose = this.oncloseWs
      socket1.onerror = this.onerrorWs
      socket1.onmessage = this.onmessageWs
      let socket2
      socket2 = new WebSocket(baseURL2)
      this.socket2 = socket2
      socket2.onopen = this.onopenWs
      socket2.onclose = this.oncloseWs
      socket2.onerror = this.onerrorWs
      socket2.onmessage = this.onmessageWs
      let socket3
      socket3 = new WebSocket(baseURL3)
      this.socket3 = socket3
      socket3.onopen = this.onopenWs
      socket3.onclose = this.oncloseWs
      socket3.onerror = this.onerrorWs
      socket3.onmessage = this.onmessageWs
      let socket4
      socket4 = new WebSocket(baseURL4)
      this.socket4 = socket4
      socket4.onopen = this.onopenWs
      socket4.onclose = this.oncloseWs
      socket4.onerror = this.onerrorWs
      socket4.onmessage = this.onmessageWs
      let socket5
      socket5 = new WebSocket(baseURL5)
      this.socket5 = socket5
      socket5.onopen = this.onopenWs
      socket5.onclose = this.oncloseWs
      socket5.onerror = this.onerrorWs
      socket5.onmessage = this.onmessageWs
      this.$router.afterEach(() => {
        socket.close()
        socket1.close()
        socket2.close()
        socket3.close()
        socket4.close()
        socket5.close()
        clearTimeout(this.timeoutObj)
        clearTimeout(this.serverTimeoutObj)
        clearTimeout(this.rec)
      })
    },
    reConnect() {
      this.oncloseWs()
      console.log('尝试重新连接')
      // 如果已经连上就不在重连了
      if (this.isConnect) return
      this.rec && clearTimeout(this.rec)
      // 延迟5秒重连  避免过多次过频繁请求重连
      const _this = this
      if (this.resetTime * 5 > 600) {
        this.resetTime = 0
        this.$message({ message: '尝试重连超时，请重新登录', type: 'error' })
        this.$router.push('/largeScreenLogin')
      } else {
        this.rec = setTimeout(() => {
          this.resetTime++
          _this.createSocket()
        }, 5000)
      }
    },
    onopenWs(event) {
      console.log('链接', event)
      // 连接建立后修改标识
      this.isConnect = true
      this.resetTime = 0
      //  this.socket.send("websocket client connect testss");
    },
    oncloseWs(event) {
      console.log('断开链接', event)
      // 连接断开后修改标识
      this.isConnect = false
      // 进行重连
      //  setTimeout(()=>{
      //     this.createSocket()
      //    },5000);
    },

    onerrorWs(event) {
      console.log('出现错误', event)
      this.isConnect = false
      // 连接错误 需要重连
      this.reConnect()
      setTimeout(() => {
        this.createSocket()
      }, 5000)
    },
    onmessageWs(event) {
      console.log('收到消息了----', event)
      const msg = JSON.parse(event.data)
      console.log('msg', msg)
      if (msg.pong) {
        return this.reset()
      }
      this.getMsg(msg)
      window.dispatchEvent(new CustomEvent('onmessageWS', {
        detail: {
          data: event
        }
      }))
    },
    reset() { // 重置心跳
      // 清除时间
      clearTimeout(this.timeoutObj)
      clearTimeout(this.serverTimeoutObj)
      // 重启心跳
      this.start()
    },
    websocketsend(messsage) {
      this.socket.send(messsage)
      this.socket1.send(messsage)
      this.socket2.send(messsage)
      this.socket3.send(messsage)
      this.socket4.send(messsage)
      this.socket5.send(messsage)
    },
    start() { // 开启心跳
      this.timeoutObj && clearTimeout(this.timeoutObj)
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
      this.timeoutObj = setTimeout(() => {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (this.socket && this.socket.readyState == 1 && this.socket1 && this.socket1.readyState == 1 && this.socket2 && this.socket2.readyState == 1 && this.socket3 && this.socket3.readyState == 1 && this.socket4 && this.socket4.readyState == 1 && this.socket5 && this.socket5.readyState == 1) { // 如果连接正常
          const actions = 'ping'
          console.log('actions: ', actions)
          this.websocketsend(actions)
        } else { // 否则重连
          this.socket && this.socket1 && this.socket2 && this.socket3 && this.socket4 && this.socket5 && this.reConnect()
        }
        // this.serverTimeoutObj = setTimeout(() => {
        //   //超时关闭
        // this.socket.close()
        // this.socket1.close()
        // this.socket2.close()
        // },this.timeout);
      }, this.timeout)
    },
    // 处理收到的消息
    // 处理收到的消息
    async getMsg(msg) {
      try {
        // 处理中间区域以及下方订单
        if (msg.order) {
          //  订单为医品
          const data = []
          // 如果为全国权限或者登录的当前账号权限是管理当前订单区域的，则对应高亮
          // 判断是大区账号还是片区账号，与websocket返回的数据对应比对
          if (this.managerType == 'all') {
            if (msg.order.orderClassify !== 'electronic') {
              if (msg.order.province) {
                let list = []
                list = msg.order.productNameAndQuantityList
                this.product = ''
                this.barrage = ''
                this.attacheName = ''
                this.price = ''
                if (list.length) {
                  list.forEach(item => {
                    if (item.productName) {
                      this.product += item.productName + ' *' + item.itemNum + '、'
                    }
                  })
                }
                this.product = this.product.slice(0, -1)
                this.price = '（' + msg.order.orderTotalPrice + '元）'
                const obj = {
                  name: this.removeSpecialWords(msg.order.province),
                  title: '专员',
                  attachedName: msg.order.attacheName || '无专员',
                  region: msg.order.regionName + ' / ' + msg.order.districtName + ' / ' + msg.order.agencyName,
                  product: this.product,
                  amount: msg.order.orderTotalPrice
                }
                if (!msg.order.regionName) {
                  obj.region = msg.order.province + ' / ' + msg.order.city
                }
                this.barrage = '（' + obj.region + '）' + '—'
                data.push(obj)
                if (msg.order.attacheName) {
                  this.attacheName = msg.order.attacheName
                } else {
                  this.attacheName = '无专员'
                }
              }
            } else {
              if (msg.order.orderCode) {
                this.product = ''
                this.barrage = ''
                this.attacheName = ''
                this.price = ''
                let list = []
                list = msg.order.productNameAndQuantityList
                if (list.length) {
                  list.forEach(item => {
                    if (item.productName) {
                      this.product += item.productName + ' *' + item.itemNum + '、'
                    }
                  })
                }
                this.product = this.product.slice(0, -1)
                this.attacheName = '电商品'
                this.barrage = '(' + msg.order.province + ' / ' + msg.order.city + ')' + '—'
                this.price = '（' + msg.order.orderTotalPrice + '元）'
                const obj = {
                  name: this.removeSpecialWords(msg.order.province),
                  title: '类型',
                  attachedName: '电商品',
                  region: msg.order.province + ' / ' + msg.order.city,
                  product: this.product,
                  amount: msg.order.orderTotalPrice
                }
                data.push(obj)
              }
            }
            if (msg.order.province) {
              const province = this.removeSpecialWords(msg.order.province)
              if (province) {
                this.$refs.mapChart.setMap(initjson('china'), province, data)
              }
            }
          } else {
            if ((this.managerType == 'region' && this.areaId == msg.order.regionId) || (this.managerType == 'district' && this.areaId == msg.order.districtId)) {
              if (msg.order.orderClassify !== 'electronic') {
                if (msg.order.province) {
                  this.product = ''
                  this.barrage = ''
                  this.attacheName = ''
                  this.price = ''
                  let list = []
                  list = msg.order.productNameAndQuantityList
                  if (list.length) {
                    list.forEach(item => {
                      if (item.productName) {
                        this.product += item.productName + ' *' + item.itemNum + '、'
                      }
                    })
                  }
                  this.product = this.product.slice(0, -1)
                  this.price = '（' + msg.order.orderTotalPrice + '元）'
                  const obj = {
                    name: this.removeSpecialWords(msg.order.province),
                    title: '专员',
                    attachedName: msg.order.attacheName || '无专员',
                    region: msg.order.regionName + ' / ' + msg.order.districtName + ' / ' + msg.order.agencyName,
                    product: this.product,
                    amount: msg.order.orderTotalPrice
                  }

                  if (!msg.order.regionName) {
                    obj.region = msg.order.province + ' / ' + msg.order.city
                  }
                  this.barrage = '（' + obj.region + '）' + '—'
                  data.push(obj)
                  if (msg.order.attacheName) {
                    this.attacheName = msg.order.attacheName
                  } else {
                    this.attacheName = '无专员'
                  }
                }
              } else {
                if (msg.order.orderCode) {
                  this.product = ''
                  this.barrage = ''
                  this.attacheName = ''
                  this.price = ''
                  let list = []
                  list = msg.order.productNameAndQuantityList
                  if (list.length) {
                    list.forEach(item => {
                      if (item.productName) {
                        this.product += item.productName + ' *' + item.itemNum + '、'
                      }
                    })
                  }
                  this.product = this.product.slice(0, -1)
                  this.attacheName = '电商品'
                  this.barrage = '(' + msg.order.province + ' / ' + msg.order.city + ')' + '—'
                  this.price = '（' + msg.order.orderTotalPrice + '元）'
                  const obj = {
                    name: this.removeSpecialWords(msg.order.province),
                    title: '类型',
                    attachedName: '电商品',
                    region: msg.order.province + ' / ' + msg.order.city,
                    product: this.product,
                    amount: msg.order.orderTotalPrice
                  }
                  data.push(obj)
                }
              }
              if (msg.order.province) {
                const province = this.removeSpecialWords(msg.order.province)
                if (province) {
                  this.$refs.mapChart.setMap(initjson('china'), province, data)
                }
              }
            }
          }
        }
        setTimeout(async() => {
          this.clear()
          await this.getData()
          this.setScroll(this.current)
        }, 500)
      } catch (error) {

      }
    },
    removeSpecialWords(str) {
      const pattern = /省|自治区|特别行政区|壮族|回族|市/g
      return str.replace(pattern, '')
    },
    removeCityWords(str) {
      const pattern = /城区/g
      return str.replace(pattern, '市')
    },
    pieOutlineFunc() {
      var _this = this
      window.addEventListener('resize', function(e) {
        if (!_this.isFullScreen()) {
          // 非全屏状态
          // 业务逻辑
          try {
            document.removeEventListener('fullscreenchange', () => {
              return
            })
            window.removeEventListener('resize', this.resize)
            _this.$router.push('/largeScreenLogin')
          } catch (error) {

          }
        }
      })
    },
    // 退出全屏
    cancelFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    // 判断是否全屏
    isFullScreen() {
      try {
        return !!(document.webkitIsFullScreen || this.fullScreen())
      } catch (error) {

      }
    },
    fullScreen() {
      // let de = document.querySelector('body');
      try {
        const de = document.documentElement
        if (de && de.requestFullscreen) {
          de.requestFullscreen()
        } else if (de && de.mozRequestFullScreen) {
          de.mozRequestFullScreen()
        } else if (de && de.webkitRequestFullScreen) {
          de.webkitRequestFullScreen()
        }
      } catch (error) {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/styles/largeScreen/flexProportion.scss';
</style>
