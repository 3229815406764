<template>
  <!-- 经销产品出库配置 -->
  <div class="main-box">
    <div class="content_box">
      <h4 style="margin-bottom: 20px;">经销产品出库配置</h4>
      <el-form ref="formRef" :model="formData" label-width="100px" size="small">
        <el-form-item label="产品名称" prop="productName">
          <el-input v-model="formData.productName" disabled />
        </el-form-item>
        <el-form-item label="产品规格" prop="skuInfo">
          <el-input v-model="formData.skuInfo" disabled />
        </el-form-item>
        <el-form-item label="发货方式" prop="deliveryWay">
          <el-select v-model="formData.wechatDeliveryMethod" placeholder="请选择发货方式" :disabled="!isEdit">
            <el-option v-for="(item, index) in deliveryWayList" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="发货批次" prop="batch">
          <el-select v-model="formData.batch" placeholder="请选择发货批次" :disabled="!isEdit">
            <el-option v-for="(item, index) in batchList" :key="index" :label="item.batchNo" :value="item.batchNo" />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitSave">{{ isEdit ? '保存' : '编辑' }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getExternalOutboundConfigure, updateExternalOutboundConfigure } from '@/api/api.js'
export default {
  components: {},
  data() {
    return {
      isEdit: false, // 编辑状态
      formData: {
        productName: '', // 产品名称
        productNorms: '', // 产品规格
        wechatDeliveryMethod: '', // 发货方式
        batch: '' // 发货批次
      },
      deliveryWayList: [
        { id: 1, name: '物流配送' },
        { id: 2, name: '同城配送' },
        { id: 3, name: '虚拟发货' }
      ],
      // 批次列表
      batchList: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getExternalOutboundConfigure()
  },
  methods: {
    // 获取经销品配置数据
    getExternalOutboundConfigure() {
      getExternalOutboundConfigure().then(res => {
        const data = res.data || {}
        this.formData = data
        this.getBatchList(data.inventoryId)
      }).catch(err => {
        console.log('err===>>>>', err)
      })
    },
    // 获取批次数据
    getBatchList(inventoryId) {
      const data = {
        pageNum: 1,
        pageSize: 1000,
        param: {
          inventoryId
        }
      }
      this.$http
        .requestPost({
          url: '/customer/order/pageMerchantBatch',
          param: data,
          loading: false
        })
        .then(res => {
          const data = res.data || {}
          this.batchList = data.list || []
        })
        .catch(err => {})
    },
    submitSave() {
      if (!this.isEdit) {
        this.isEdit = true
      } else {
        const { wechatDeliveryMethod, batch } = this.formData
        if (!wechatDeliveryMethod) {
          return this.$message.warning('请选择发货方式')
        }
        if (!batch) {
          return this.$message.warning('请选择发货批次')
        }
        const deliveryItem = this.deliveryWayList.filter(val => { return val.id === wechatDeliveryMethod })
        const deliveryWay = deliveryItem[0].name
        this.$confirm(`确定提交修改后的 “发货方式：${deliveryWay}, 发货批次：${batch}” ？`, '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            updateExternalOutboundConfigure(this.formData).then(res => {
              this.isEdit = false
              this.$message.success('操作成功')
            }).catch(err => {
              this.isEdit = false
              this.$message.error('操作失败')
            })
          })
          .catch(err => {})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  width: 600px;
  padding: 20px 20px 5px;
}
/deep/ .el-select {
  width: 100%;
}
</style>
