<template>
  <!-- 未领取红包二维码列表 -->
  <div>
    <RedPacketList :list-type="2" />
  </div>
</template>

<script>
import RedPacketList from '../components/redPacketList.vue';

export default {
  components: { RedPacketList },
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
